import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, Modal, Button } from '@themesberg/react-bootstrap';
import ReactDatatable from '@mkikets/react-datatable';
import {
    cerrarModalComisionesAction,
    getEmpleadosComisionesAction
} from '../../actions/empleadosActions';

export default () => {
    const dispatch = useDispatch();

    const { empleado, comisiones, showModalComisiones } = useSelector(state => state.empleados);

    const columns = [
        {
            key: "id",
            text: "ID",
            className: "id",
            align: "left",
            sortable: true
        },
        {
            key: "created_at",
            text: "Fecha",
            className: "created_at",
            align: "left",
            sortable: true,
        },
        {
            key: "id_tratamiento",
            text: "ID Tratamiento",
            className: "id_tratamiento",
            align: "left",
            sortable: true,
        },
        {
            key: "id_cliente_tratamiento",
            text: "ID Cliente Tratamiento",
            className: "id_cliente_tratamiento",
            sortable: true
        },
        {
            key: "comision",
            text: "Comision (%)",
            className: "comision",
            sortable: true
        },
        {
            key: "total",
            text: "Total",
            className: "total",
            sortable: true
        }
    ];

    const state = {
        records: comisiones
    }

    const handleClose = () => {
        dispatch(cerrarModalComisionesAction())
    }

    return (
        <>
            <Modal as={Modal.Dialog} size="lg" centered show={showModalComisiones} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title className="h6">Historial comisiones</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleClose} />
                </Modal.Header>
                <Modal.Body className="table-wrapper table-responsive shadow-sm">
                    <ReactDatatable hover
                        records={comisiones}
                        columns={columns}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
  );
}