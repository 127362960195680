import {
    LISTA_ROLES,
    LISTA_ROLES_EXITO,
    LISTA_ROLES_ERROR,
    COMENZAR_AGREGAR_ROL,
    AGREGAR_ROL,
    AGREGAR_ROL_EXITO,
    AGREGAR_ROL_ERROR,
    CERRAR_MODAL_AGREGAR_ROL,
    COMENZAR_EDITAR_ROL,
    EDITAR_ROL,
    EDITAR_ROL_EXITO,
    EDITAR_ROL_ERROR,
    CERRAR_MODAL_EDITAR_ROL,
    COMENZAR_ELIMINAR_ROL,
    ROL_ELIMINADO_EXITO,
    ROL_ELIMINADO_ERROR,
    COMENZAR_PERMISOS_ROL,
    CERRAR_MODAL_PERMISOS_ROL
} from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getRolesAction() {
    return async (dispatch) => {
        dispatch( getRoles() );
        try {
            const response = await clienteAxios.get('roles/list');
            if (response.data.data) {
                dispatch( getRolesExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getRolesError(error) );
        }
    }
}

const getRoles = () => ({
    type: LISTA_ROLES
})

const getRolesExito = usuarios => ({
    type: LISTA_ROLES_EXITO,
    payload: usuarios
})

const getRolesError = error => ({
    type: LISTA_ROLES_ERROR,
    payload: error
})

export function comenzarAgregarRol() {
    return (dispatch) => {
        dispatch( comenzarAgregarRolAction() );
    }
}

const comenzarAgregarRolAction = () => ({
    type: COMENZAR_AGREGAR_ROL
})

export function cerrarModalAgregarAction() {
    return (dispatch) => {
        dispatch( cerrarModalAgregar() );
    }
}

const cerrarModalAgregar = () => ({
    type: CERRAR_MODAL_AGREGAR_ROL
})

export function agregarRolAction(rol) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarRol() );

        try {
            const response = await clienteAxios.post('roles/agregar', rol);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarRolExito(response.data.data) );
                
                rol.nombre = '';
                rol.descripcion = '';
            }
        } catch (error) {
            dispatch( agregarRolError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarRol = () => ({
    type: AGREGAR_ROL
})

const agregarRolExito = rol => ({
    type: AGREGAR_ROL_EXITO,
    payload: rol
})

const agregarRolError = error => ({
    type: AGREGAR_ROL_ERROR,
    payload: error
})

export function comenzarEditarRol(rol) {
    return (dispatch) => {
        dispatch( comenzarEditarRolAction(rol) );
    }
}

const comenzarEditarRolAction = rol => ({
    type: COMENZAR_EDITAR_ROL,
    payload: rol
})

export function editarRolAction(rol) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarRol() );

        try {
            const response = await clienteAxios.put('roles/actualizar/'+rol.id, rol);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarRolExito(response.data.data) );
            }
        } catch (error) {
            dispatch( editarRolError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const editarRol = () => ({
    type: EDITAR_ROL
})

const editarRolExito = rol => ({
    type: EDITAR_ROL_EXITO,
    payload: rol
})

const editarRolError = error => ({
    type: EDITAR_ROL_ERROR,
    payload: error
})

export function cerrarModalEditarAction() {
    return (dispatch) => {
        dispatch( cerrarModalEditar() );
    }
}

const cerrarModalEditar = () => ({
    type: CERRAR_MODAL_EDITAR_ROL
})

export function eliminarRolAction(rol) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( eliminarRol(rol) );

        try {
            const response = await clienteAxios.delete('roles/eliminar/'+rol.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarRolExito() );
            }
        } catch (error) {
            dispatch( eliminarRolError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarRol = rol => ({
    type: COMENZAR_ELIMINAR_ROL,
    payload: rol
})

const eliminarRolExito = () => ({
    type: ROL_ELIMINADO_EXITO
})

const eliminarRolError = error => ({
    type: ROL_ELIMINADO_ERROR,
    payload: error
})

export function comenzarPermisosRol(rol) {
    return (dispatch) => {
        dispatch( comenzarPermisosRolAction(rol) );
    }
}

const comenzarPermisosRolAction = rol => ({
    type: COMENZAR_PERMISOS_ROL,
    payload: rol
})


export function cerrarModalPermisosAction() {
    return (dispatch) => {
        dispatch( cerrarModalPermisos() );
        dispatch(getRolesAction());
    }
}

const cerrarModalPermisos = () => ({
    type: CERRAR_MODAL_PERMISOS_ROL
})

export function actualizarPermisoRol(permiso) {
    return async (dispatch) => {
        const response = await clienteAxios.put('roles/permisos/actualizar', permiso);
    }
}