import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card,Breadcrumb } from '@themesberg/react-bootstrap';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from '../../components/DataTable';
import { 
    getRolesAction,
    comenzarAgregarRol,
    comenzarEditarRol,
    eliminarRolAction,
    comenzarPermisosRol
} from '../../actions/rolesActions';

import { getModulosAction } from '../../actions/modulosAction';
import { getMenusAction  } from '../../actions/menuActions';
import { obtenerRolesMenuAction  } from '../../actions/rolesMenusActions';

import { ConfirmModal } from '../../components/ConfirmModal';
import NuevoRol from './NuevoRol';
import EditarRol from './EditarRol';
import PermisosRol from './PermisosRol';
import MenusRol from './MenusRol';
import AccionesRoles from "./AccionesRoles";
import { getAccionesAction } from "../../actions/accionesActions";
import { obtenerAccionesRolAction } from "../../actions/accionesRolesActions";

export default () => {
    const dispatch = useDispatch();

    const { rol, roles } = useSelector(state => state.roles);

    const [showModalAcciones, setShowModalAcciones] = useState(false);

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        dispatch(getRolesAction());
    }, [])

    const columns = [
        {
            key: "id",
            text: "ID",
            className: "id",
            align: "left",
            sortable: true
        },
        {
            key: "nombre",
            text: "Nombre",
            className: "nombre",
            align: "left",
            sortable: true,
        },
        {
            key: "descripcion",
            text: "Descripcion",
            className: "descripcion",
            sortable: true
        }
    ];

    const state = {
        records: roles
    }

    const extraButtons =[
        {
            className:"btn btn-info",
            title:"Agregar rol",
            children:[
                <span>
                    <i class="fas fa-plus"></i> Nuevo
                </span>
            ],
            onClick:(event)=>{
                dispatch(comenzarAgregarRol());
            },
        },
    ]

    const editRecord = (record) => {
        dispatch(comenzarEditarRol(record));
    }

    const deleteRecord = (record) => {
        const callbacks = [
            {
                event: dispatch,
                action: eliminarRolAction,
                args: [record]
            }
        ]

        ConfirmModal(callbacks);
    }

    const permisosRol = (record) => {
        dispatch( comenzarPermisosRol(record) );
        dispatch( getModulosAction() );
    }

    const menusRol = (record) => {
        dispatch( getMenusAction() );
        dispatch( obtenerRolesMenuAction(record) );
    }

    const accionesRol = (record) => {
        dispatch( getAccionesAction() );
        dispatch( obtenerAccionesRolAction(record) );
        setShowModalAcciones(true);
        setSelected(record);
    }

    const acciones = [
        {
            description: 'Menus',
            icon: 'fas fa-bars',
            className: 'btn btn-info btn-sm',
            event: menusRol,
            style: {marginRight: '5px'}
        },
        {
            description: 'Permisos',
            icon: 'fas fa-user-tag',
            className: 'btn btn-tertiary btn-sm',
            event: permisosRol,
            style: {marginRight: '5px'}
        },
        {
            description: 'Acciones especiales',
            icon: 'fas fa-tasks',
            className: 'btn btn-warning btn-sm',
            event: accionesRol,
            style: {marginRight: '5px'}
        },
    ];

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Roles</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body>
                    <h5 className="mb-4">Roles</h5>
                    <DataTable 
                        columns = {columns}
                        editRecord = {editRecord}
                        deleteRecord = {deleteRecord}
                        acciones = {acciones}
                        state = {state}
                        extraButtons = {extraButtons}
                    />
                </Card.Body>
            </Card>

            <NuevoRol/>
            <EditarRol/>
            <PermisosRol/>
            <MenusRol/>
            <AccionesRoles 
                showModal={showModalAcciones}
                onHide={() => setShowModalAcciones(false)}
                rol={selected}
            />
        </>
  );
}