import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../components/DataTable";
import { ConfirmModal } from "../../components/ConfirmModal";

import {
  showModalTratamientoPreciosProductosAction,
  showModalAgregarTratamientoPreciosProductosAction,
  eliminarPrecioProductoAction
} from "../../actions/preciosProductosActions";
import ModalAgregarTratamientoPrecioProducto from "./ModalAgregarTratamientoPrecioProducto";

export default (props) => {
  const dispatch = useDispatch();

  const { showModalPreciosProductos, preciosProductos } = useSelector(
    (state) => state.preciosProductos
  );

  const columns = [
    {
        text: "ID Producto",
        sortable: true,
        cell: record => {
          return record.producto?.id
        }
    },
    {
        text: "Producto",
        sortable: true,
        cell: record => {
          return record.producto?.nombre
        }
    },
    {
      key: "cantidad",
      text: "Cantidad",
      align: "left",
      sortable: true,
    },
  ];

  const state = {
    records: preciosProductos,
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar producto",
      children: [
        <span>
          <i class="fas fa-plus"></i> Agregar producto
        </span>,
      ],
      onClick: (event) => {
        dispatch(showModalAgregarTratamientoPreciosProductosAction(true));
      },
    },
  ];

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: dispatch,
        action: eliminarPrecioProductoAction,
        args: [record],
      },
    ];

    ConfirmModal(callbacks);
  };

  const handleClose = () => {
    dispatch(showModalTratamientoPreciosProductosAction(false));
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="lg"
        centered
        show={showModalPreciosProductos}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Tratamiento Precio Productos</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={columns}
            deleteRecord={deleteRecord}
            state={state}
            extraButtons={extraButtons}
            config={{ show_filter: false, show_pagination: false }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalAgregarTratamientoPrecioProducto />
    </>
  );
};
