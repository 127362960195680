import React, { useEffect, useState } from "react";
import {
  Card,
  Breadcrumb,
  Tab,
  Tabs,
  Row,
  Col,
  Button,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../../components/DataTable";

import { ConfirmModal } from "../../../components/ConfirmModal";
import clienteAxios from "../../../config/axios";
import Swal from "sweetalert2";
import { numberFormat } from "../../../helpers";
import GastosOperacionForm from "./GastosOperacionForm";
import GenerarBitacoraGastoOperacion from "./GenerarBitacoraGastoOperacion";

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalBitacora, setShowModalBitacora] = useState(false);
  const [gastos, setGastos] = useState([]);
  const [selected, setSelected] = useState({});
  const [accionForm, setAccionForm] = useState("agregar");

  useEffect(() => {
    getGastos();
  }, []);

  const getGastos = () => {
    clienteAxios.get("tesoreria/gastos-operaciones").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setGastos(respuesta.data.data);
      }
    });
  };

  const columns = [
    {
      key: "id",
      text: "ID",
      sortable: true,
    },
    {
      text: "Concepto",
      cell: (record) => {
        return record.concepto?.descripcion || "NO ENCONTRADO";
      },
    },
    {
      key: "descripcion",
      text: "Descripción",
    },
    {
      key: "fecha",
      text: "fecha",
    },
    {
      text: "Recurrente",
      cell: (record) => {
        return record.recurrente ? "Si" : "No";
      },
    },
    {
      text: "Frecuencia",
      cell: (record) => {
        return record.frecuencia?.descripcion || "NO ENCONTRADO";
      },
    },
    {
      text: "Monto",
      cell: (record) => {
        return numberFormat(record.monto);
      },
    },
    {
      text: "Activo",
      cell: (record) => {
        return record.activo ? "Si" : "No";
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-success",
      title: "Agregar gasto",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo
        </span>,
      ],
      onClick: (event) => {
        setShowModal(true);
        setAccionForm("agregar");
        setSelected(null);
      },
    },
  ];

  const editRecord = (record) => {
    setSelected(record);
    setShowModal(true);
    setAccionForm("editar");
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: eliminarGasto,
        args: [record.id],
      },
    ];

    ConfirmModal(callbacks);
  };

  const eliminarGasto = (id) => {
    clienteAxios
      .delete("tesoreria/gastos-operaciones/" + id)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
          setGastos(gastos.filter((row) => row.id !== id));
        }
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Gastos de operación</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Gastos de operación</h5>

          <Row className="justify-content-end text-right">
            <Col>
              <Button variant="info" onClick={() => setShowModalBitacora(true)}>
                Generar Bitacora
              </Button>
            </Col>
          </Row>
          <Tabs defaultActiveKey="no_recurrentes" className="mb-3">
            <Tab eventKey="no_recurrentes" title="No Recurrentes">
              <DataTable
                columns={columns}
                editRecord={editRecord}
                deleteRecord={deleteRecord}
                state={{ records: gastos.filter((row) => !row.recurrente) }}
                extraButtons={extraButtons}
              />
            </Tab>
            <Tab eventKey="recurrentes" title="Recurrentes">
              <DataTable
                columns={columns}
                editRecord={editRecord}
                deleteRecord={deleteRecord}
                state={{ records: gastos.filter((row) => row.recurrente) }}
                extraButtons={extraButtons}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>

      <GastosOperacionForm
        accion={accionForm}
        showModal={showModal}
        selectedRow={selected}
        onUpdate={(rowSuccess) => {
          setGastos(
            gastos.map((row) =>
              row.id === rowSuccess.id ? (row = rowSuccess) : row
            )
          );

          setShowModal(false);
        }}
        onCreate={(rowSuccess) => {
          setGastos(gastos.concat(rowSuccess));

          setShowModal(false);
        }}
        onHide={() => setShowModal(false)}
      />

      <GenerarBitacoraGastoOperacion
        showModal={showModalBitacora}
        onHide={() => setShowModalBitacora(false)}
      />
    </>
  );
};
