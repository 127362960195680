import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button } from "@themesberg/react-bootstrap";
import ReactDatatable from '@mkikets/react-datatable';

export default (props) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(props.showModal);
  }, [props]);

  const columns = [
    {
      text: "ID Producto",
      cell: (record) => {
        return record.id_producto;
      },
    },
    {
      text: "Nombre Producto",
      cell: (record) => {
        return record.producto?.nombre;
      },
    },
    {
      text: "ID Tratamiento",
      cell: (record) => {
        return record.id_tratamiento;
      },
    },
    {
      text: "Nombre Tratamiento",
      cell: (record) => {
        return record.tratamiento?.nombre;
      },
    },
    {
      key: "cantidad",
      text: "Cantidad"
    },
    {
      text: "Empleado",
      cell: (record) => {
        return record.empleado ? (record.empleado?.nombre + " " + record.empleado?.apellido_paterno) : "";
      },
    },
    {
      key: "precio_unitario",
      text: "Precio unitario ($)",
    },
    {
      key: "subtotal",
      text: "Subtotal ($)",
    },
    {
      key: "total",
      text: "Total ($)",
    },
  ];

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Venta detalle</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="table-wrapper table-responsive shadow-sm">
          <ReactDatatable hover records={props.detalles} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
