import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function cerrarModalListaAction() {
    return (dispatch) => {
        dispatch( cerrarModalLista() );
    }
}

const cerrarModalLista = () => ({
    type: Types.CERRAR_MODAL_LISTA_TRATAMIENTO_PRECIO
})

export function comenzarListaAction() {
    return (dispatch) => {
        dispatch( comenzarLista() );
    }
}

const comenzarLista = () => ({
    type: Types.COMENZAR_LISTA_TRATAMIENTOS_PRECIOS
})

export function getTratamientosPreciosAction(tratamiento) {
    return async (dispatch) => {
        dispatch( getTratamientosPrecios(tratamiento) );
        try {
            const response = await clienteAxios.get('tratamientos/precios/list?id_tratamiento='+tratamiento.id);
            if (response.data.data) {
                dispatch( getTratamientosPreciosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getTratamientosPreciosError(error) );
        }
    }
}

const getTratamientosPrecios = tratamiento => ({
    type: Types.LISTA_TRATAMIENTOS_PRECIOS,
    payload: tratamiento
})

const getTratamientosPreciosExito = precios => ({
    type: Types.LISTA_TRATAMIENTOS_PRECIOS_EXITO,
    payload: precios
})

const getTratamientosPreciosError = error => ({
    type: Types.LISTA_TRATAMIENTOS_PRECIOS_ERROR,
    payload: error
})

export function comenzarAgregarTratamientoPrecioAction(tratamiento) {
    return (dispatch) => {
        dispatch( comenzarAgregarTratamientoPrecio(tratamiento) );
    }
}

const comenzarAgregarTratamientoPrecio = tratamiento => ({
    type: Types.COMENZAR_AGREGAR_TRATAMIENTO_PRECIO,
    payload: tratamiento
})

export function agregarTratamientoPrecioAction(precio) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarTratamientoPrecio() );
        try {
            const response = await clienteAxios.post('tratamientos/precios/agregar', precio);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarTratamientoPrecioExito(response.data.data) );
                precio.descripcion = '';
                precio.precio = 0;
            }
        } catch (error) {
            dispatch( agregarTratamientoPrecioError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarTratamientoPrecio = () => ({
    type: Types.AGREGAR_TRATAMIENTO_PRECIO
})

const agregarTratamientoPrecioExito = precio => ({
    type: Types.AGREGAR_TRATAMIENTO_PRECIO_EXITO,
    payload: precio
})

const agregarTratamientoPrecioError = error => ({
    type: Types.AGREGAR_TRATAMIENTO_PRECIO_ERROR,
    payload: error
})

export function cerrarModalAgregarAction() {
    return (dispatch) => {
        dispatch( cerrarModalAgregar() );
    }
}

const cerrarModalAgregar = () => ({
    type: Types.CERRAR_MODAL_AGREGAR_TRATAMIENTO_PRECIO
})

export function comenzarEditarTratamientoPrecioAction(precio) {
    return (dispatch) => {
        dispatch( comenzarEditarTratamientoPrecio(precio) );
    }
}

const comenzarEditarTratamientoPrecio = precio => ({
    type: Types.COMENZAR_EDITAR_TRATAMIENTO_PRECIO,
    payload: precio
})

export function editarTratamientoPrecioAction(precio) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarTratamientoPrecio() );
        try {
            const response = await clienteAxios.put('tratamientos/precios/actualizar/'+precio.id, precio);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarTratamientoPrecioExito(response.data.data) );
            }
        } catch (error) {
            dispatch( editarTratamientoPrecioError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const editarTratamientoPrecio = () => ({
    type: Types.EDITAR_TRATAMIENTO_PRECIO
})

const editarTratamientoPrecioExito = precio => ({
    type: Types.EDITAR_TRATAMIENTO_PRECIO_EXITO,
    payload: precio
})

const editarTratamientoPrecioError = error => ({
    type: Types.EDITAR_TRATAMIENTO_PRECIO_ERROR,
    payload: error
})

export function cerrarModalEditarAction() {
    return (dispatch) => {
        dispatch( cerrarModalEditar() );
    }
}

const cerrarModalEditar = () => ({
    type: Types.CERRAR_MODAL_EDITAR_TRATAMIENTO_PRECIO
})

export function eliminarTratamientoPrecioAction(precio) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('tratamientos/precios/eliminar/'+precio.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarTratamientoPrecioExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarTratamientoPrecioError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarTratamientoPrecioExito = precio => ({
    type: Types.ELIMINAR_TRATAMIENTO_PRECIO_EXITO,
    payload: precio
})

const eliminarTratamientoPrecioError = error => ({
    type: Types.ELIMINAR_TRATAMIENTO_PRECIO_ERROR,
    payload: error
})