import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getNotificacionesAction() {
    return async (dispatch) => {
        dispatch( getNotificaciones() );
        try {
            const response = await clienteAxios.get('notificaciones');
            if (response.data.data) {
                dispatch( getNotificacionesExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getNotificacionesError(error) );
        }
    }
}

const getNotificaciones = () => ({
    type: Types.LISTA_NOTIFICACIONES
})

const getNotificacionesExito = notificaciones => ({
    type: Types.LISTA_NOTIFICACIONES_EXITO,
    payload: notificaciones
})

const getNotificacionesError = error => ({
    type: Types.LISTA_NOTIFICACIONES_ERROR,
    payload: error
})

export function marcarLeidoAction() {
    return async (dispatch) => {
        dispatch( marcarLeido() );
        try {
            const response = await clienteAxios.put('notificaciones/marcar-leido');
            if (response.data.data) {
                dispatch( marcarLeidoExito() );
            }
        } catch (error) {
            dispatch( marcarLeidoError(error) );
        }
    }
}

const marcarLeido = () => ({
    type: Types.MARCAR_LEIDO_NOTIFICACIONES
})

const marcarLeidoExito = () => ({
    type: Types.MARCAR_LEIDO_NOTIFICACIONES_EXITO
})

const marcarLeidoError = error => ({
    type: Types.MARCAR_LEIDO_NOTIFICACIONES_ERROR,
    payload: error
})

export function agregarNotificacionAction(notificacion) {
    return (dispatch) => {
        dispatch( agregarNotificacion(notificacion) );
    }
}

const agregarNotificacion = (notificacion) => ({
    type: Types.AGREGAR_NOTIFICACION,
    payload: notificacion
})