import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@mkikets/react-datatable";
import { ConfirmModal } from "../../components/ConfirmModal";
import {
  eliminarProductoAction,
  eliminarVentaAction,
  imprimirVenta,
} from "../../actions/ventaActions";
import ModalHistorialVentasDetalle from "../Venta/ModalHistorialVentasDetalle";
import ModalFormEmpleadosPorcentajes from "./ModalFormEmpleadosPorcentajes";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

export default (props) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const [state, guardar] = useState({
    selectedRow: {},
    showModal: false,
    accionModal: "agregar",
  });

  useEffect(() => {
    getRows();
  }, []);

  const getRows = () => {
    clienteAxios.get("empleados/porcentajes").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setRows(respuesta.data.data);
      }
    });
  };

  const editar = (row) => {
    row.aplicar_a = !row.id_empleado ? "todos" : "individual";
    row.tipo = row.id_tratamiento ? "tratamiento" : "producto";

    guardar({
      ...state,
      selectedRow: row,
      showModal: true,
      accionModal: "editar",
    });
  };

  const columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      text: "Empleado",
      sortable: true,
      cell: (record) => {
        return record.empleado
          ? record.empleado?.nombre + " " + record.empleado?.apellido_paterno
          : record.id_empleado
          ? "NO ENCONTRADO"
          : "TODOS";
      },
    },
    {
      text: "Tratamiento",
      sortable: true,
      cell: (record) => {
        return record.tratamiento
          ? record.tratamiento?.nombre
          : record.id_tratamiento
          ? "NO ENCONTRADO"
          : "";
      },
    },
    {
      text: "Nombre Tratamiento Precio",
      sortable: true,
      cell: (record) => {
        return record.tratamiento
          ? record.tratamiento_precio?.descripcion
          : record.id_tratamiento_precio
          ? "NO ENCONTRADO"
          : "";
      },
    },
    {
      text: "Producto",
      sortable: true,
      cell: (record) => {
        return record.producto
          ? record.producto?.nombre
          : "";
      },
    },
    {
      text: "Tipo comisión",
      sortable: true,
      cell: (record) => {
        return record.tipo_comision;
      },
    },
    {
      text: "Valor",
      sortable: true,
      cell: (record) => {
        return record.tipo_comision == 'porcentaje' ? record.porcentaje : record.monto;
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Editar</Tooltip>}
              >
                <button
                  onClick={() => editar(record)}
                  className="btn btn-info btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-edit"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Eliminar</Tooltip>}
              >
                <button
                  onClick={() => eliminarVentaConfirm(record.id)}
                  className="btn btn-danger btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-trash"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
    },
  ];

  const eliminarVentaConfirm = (id) => {
    const callbacks = [
      {
        event: eliminarVenta,
        args: [id],
      },
    ];

    ConfirmModal(callbacks);
  };

  const eliminarVenta = (id) => {
    console.log(id);
    clienteAxios.delete("empleados/porcentajes/"+id).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const extraButtons = [
    {
      className: "btn btn-success",
      title: "Nuevo",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo
        </span>,
      ],
      onClick: (event) => {
        guardar({
          ...state,
          showModal: true,
          selectedRow: {
            id_empleado: null,
            id_tratamiento: null,
            id_tratamiento_precio: null,
            id_producto: null,
            porcentaje: 0,
            monto: 0,
            tipo_comision: 'porcentaje',
            aplicar_a: "todos",
            tipo: "tratamiento",
            empleado: null,
          },
          accionModal: "agregar",
        });
      },
    },
  ];

  const config = {
    button: {
      extra: true,
    },
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Empleados Porcentajes</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Empleados Porcentajes</h5>
          <ReactDatatable
            hover
            records={rows}
            columns={columns}
            extraButtons={extraButtons}
            config={config}
          />
        </Card.Body>
      </Card>

      <ModalFormEmpleadosPorcentajes
        selectedRow={state.selectedRow}
        showModal={state.showModal}
        onHide={() => guardar({ ...state, showModal: false })}
        accion={state.accionModal}
        onUpdate={(rowSuccess) => {
          setRows(
            rows.map((row) =>
              row.id === rowSuccess.id ? (row = rowSuccess) : row
            )
          );

          guardar({
            ...state,
            showModal: false,
          });
        }}
        onCreate={(rowSuccess) => {
          setRows(rows.concat(rowSuccess));

          guardar({
            ...state,
            showModal: false,
          });
        }}
      />
    </>
  );
};
