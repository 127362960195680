import * as Types from '../types'

const initialState = {
    cliente_tratamiento_abono: null,
    tabla_abono: null,
    id_tabla: null,
    abono: {},
    abonos: [],
    loadingAbonos: false,
    loadingAbonosTratamientos: false,
    loadingAgregarAbono: false,
    showModalAbonar: false,
    showModalAbonos: false,
    successAgregarAbono : false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.LISTA_ABONOS:
      return {
        ...state,
        loadingAbonos: true,
      }
    case Types.LISTA_ABONOS_EXITO:
      return {
        ...state,
        loadingAbonos: false,
        abonos: action.payload,
      }
    case Types.LISTA_ABONOS_ERROR:
      return {
        ...state,
        loadingAbonos: false
      }
    case Types.LISTA_ABONOS_TRATAMIENTOS:
      return {
        ...state,
        loadingAbonosTratamientos: true,
        cliente_tratamiento_abono: action.payload
      }
    case Types.LISTA_ABONOS_TRATAMIENTOS_EXITO:
      return {
        ...state,
        loadingAbonosTratamientos: false,
        abonos: action.payload
      }
    case Types.LISTA_ABONOS_TRATAMIENTOS_ERROR:
      return {
        ...state,
        loadingAbonosTratamientos: false
      }
    case Types.AGREGAR_ABONO:
      return {
        ...state,
        loadingAgregarAbono: true,
        successAgregarAbono: false
      }
    case Types.AGREGAR_ABONO_EXITO:
        return {
            ...state,
            loadingAgregarAbono: false,
            abonos: state.abonos.concat(action.payload),
            showModalAbonar: false,
            successAgregarAbono: true
        }
    case Types.AGREGAR_ABONO_ERROR:
        return {
            ...state,
            loadingAgregarAbono: false,
            successAgregarAbono: false
        }
    case Types.ELIMINAR_ABONO_EXITO:
        return {
            ...state,
            abonos: state.abonos.filter( abono => 
                abono.id != action.payload.id
            ),
        }
    case Types.MODAL_ABONOS:
        return {
            ...state,
            showModalAbonos: action.payload
        }
    case Types.MODAL_AGREGAR_ABONO:
        return {
            ...state,
            showModalAbonar: action.payload
        }
    default:
      return state
  }
}
