import {
    OBTENER_ROLES_USUARIO,
    OBTENER_ROLES_USUARIO_EXITO,
    OBTENER_ROLES_USUARIO_ERROR,
    SET_SELECTED_USER_ROLES,
    ACTUALIZAR_ROLES_USUARIO_EXITO,
    ACTUALIZAR_ROLES_USUARIO_ERROR,
    CERRAR_MODAL_ROLES_USUARIO
} from '../types';

const user = JSON.parse(localStorage.getItem("user"));

//cada reducer tiene su propio state
const initialState = {
    usuario: {},
    userRoles: [],
    loading: false,
    showModal: false,
    selected: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case OBTENER_ROLES_USUARIO:
            return {
                ...state,
                usuario: action.payload,
                showModal: true,
                loading: true
            }
        case OBTENER_ROLES_USUARIO_EXITO:
            return {
                ...state,
                loading: false,
                userRoles: action.payload,
                selected: action.payload.map(function (userRol) { return userRol.id_rol })
            }
        case OBTENER_ROLES_USUARIO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                selected: []
            }
        case SET_SELECTED_USER_ROLES:
            return {
                ...state,
                selected: action.payload
            }
        case ACTUALIZAR_ROLES_USUARIO_EXITO:
            return {
                ...state,
                userRoles: action.payload
            }
        case ACTUALIZAR_ROLES_USUARIO_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case CERRAR_MODAL_ROLES_USUARIO:
            return {
                ...state,
                showModal: false
            }
        default:
            return state;
    }
}