import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
} from "@themesberg/react-bootstrap";

export default (props) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(props.showModal);
    guardarForm({
      ...props.state,
    });
  }, [props]);

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const [form, guardarForm] = useState({
    reference: "",
    referenceId: "",
    titulo: "",
    descripcion: "",
    fechaSeleccionada: "",
    repetir: false,
    monto: "",
    repetido: false,
    color: "#ED0707",
  });

  const submitAgregar = (e) => {
    e.preventDefault();
    props.agregarEvento(form);
  };

  const actualizar = () => {
    props.actualizarEvento(form);
  };

  const eliminar = () => {
    props.eliminarEvento(form.referenceId);
  };

  const onChangeForm = (e) => {
    guardarForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeRepetir = (e) => {
    guardarForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Evento</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitAgregar}>
        <Modal.Body>
          {form.repetido === false && (
            <div key="checkbox" className="mb-3" style={{ float: "right" }}>
              <Form.Check type="checkbox" id="formRepetir">
                <Form.Check.Label style={{ marginRight: "5px" }}>
                  Repetir
                </Form.Check.Label>
                <Form.Check.Input
                  type="checkbox"
                  name="repetir"
                  onChange={onChangeRepetir}
                  defaultChecked={form.repetir == true}
                />
              </Form.Check>
            </div>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Fecha seleccionada</Form.Label>
            <Form.Control
              type="text"
              value={form.fechaSeleccionada}
              onChange={onChangeForm}
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Titulo*</Form.Label>
            <Form.Control
              type="text"
              name="titulo"
              placeholder="Titulo"
              value={form.titulo}
              onChange={onChangeForm}
              disabled={form.repetido === true}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Monto</Form.Label>
            <Form.Control
              type="number"
              min="0"
              name="monto"
              placeholder="Monto"
              value={form.monto}
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Descripcion</Form.Label>
            <Form.Control
              as="textarea"
              rows="4"
              name="descripcion"
              placeholder="Descripcion"
              value={form.descripcion}
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Label htmlFor="exampleColorInput">Color</Form.Label>
          <Form.Control
            type="color"
            id="colorInputCalendario"
            title="Color"
            name="color"
            value={form.color}
            onChange={onChangeForm}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          {props.accion == "AGREGAR" && (
            <Button type="submit" variant="secondary">
              Agregar
            </Button>
          )}
          {props.accion == "ACTUALIZAR" && (
            <>
              {form.repetido === false && (
                <Button type="button" variant="danger" onClick={eliminar}>
                  Eliminar
                </Button>
              )}
              <Button type="button" variant="info" onClick={actualizar}>
                Guardar
              </Button>
            </>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
