import * as Types from "../types";
import clienteAxios from "../config/axios";
import { loadingRequest } from "./axiosActions";
import Swal from "sweetalert2";

export function getDatosAdicionalesAction(tabla, id_tabla = null) {
  return async (dispatch) => {
    dispatch(getDatosAdicionales());
    dispatch(limpiarDatoAdicionalDetalle());
    try {
      const response = await clienteAxios.get("datos-adicionales/" + tabla);
      if (response.data.data) {
        dispatch(getDatosAdicionalesExito(response.data.data));
        response.data.data.map((datoAdicional) => {
          dispatch(
            agregarDatoAdicionalDetalle({
              id_dato_adicional: datoAdicional.id,
              valor: "",
              id_tabla: id_tabla,
              id_valor: null,
            })
          );
        });
      }
    } catch (error) {
      console.log(error);
      dispatch(getDatosAdicionalesError(error));
    }
  };
}

const agregarDatoAdicionalDetalle = (detalle) => ({
  type: Types.AGREGAR_DATOS_ADICIONALES_DETALLE,
  payload: detalle,
});

const limpiarDatoAdicionalDetalle = () => ({
    type: Types.LIMPIAR_DATOS_ADICIONALES_DETALLE,
});

const getDatosAdicionales = () => ({
  type: Types.LISTA_DATOS_ADICIONALES,
});

const getDatosAdicionalesExito = (agenda) => ({
  type: Types.LISTA_DATOS_ADICIONALES_EXITO,
  payload: agenda,
});

const getDatosAdicionalesError = (error) => ({
  type: Types.LISTA_DATOS_ADICIONALES_ERROR,
  payload: error,
});

export function agregarDatoAdicionalAction(parameters) {
  return async (dispatch) => {
    try {
      dispatch(loadingRequest(true));
      dispatch(agregarDatoAdicional());
      const response = await clienteAxios.post("datos-adicionales", parameters);
      if (response.data.data) {
        dispatch(agregarDatoAdicionalExito(response.data.data));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(agregarDatoAdicionalError(error));
    }

    dispatch(loadingRequest(false));
  };
}

const agregarDatoAdicional = () => ({
  type: Types.AGREGAR_DATOS_ADICIONALES,
});

const agregarDatoAdicionalExito = (agenda) => ({
  type: Types.AGREGAR_DATOS_ADICIONALES_EXITO,
  payload: agenda,
});

const agregarDatoAdicionalError = (error) => ({
  type: Types.AGREGAR_DATOS_ADICIONALES_ERROR,
  payload: error,
});

export function comenzarActualizarDatoAdicionalAction(datoAdicional) {
  return (dispatch) => {
    dispatch(comenzarActualizarDatoAdicional(datoAdicional));
  };
}

const comenzarActualizarDatoAdicional = (datoAdicional) => ({
  type: Types.COMENZAR_ACTUALIZAR_DATOS_ADICIONALES,
  payload: datoAdicional,
});

export function actualizarDatoAdicionalAction(datoAdicional, parameters) {
  return async (dispatch) => {
    try {
      dispatch(loadingRequest(true));
      dispatch(actualizarDatoAdicional());
      const response = await clienteAxios.put(
        "datos-adicionales/" + datoAdicional.id,
        parameters
      );
      if (response.data.data) {
        dispatch(actualizarDatoAdicionalExito(response.data.data));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(actualizarDatoAdicionalError(error));
    }
    dispatch(loadingRequest(false));
  };
}

const actualizarDatoAdicional = () => ({
  type: Types.ACTUALIZAR_DATOS_ADICIONALES,
});

const actualizarDatoAdicionalExito = (datoAdicional) => ({
  type: Types.ACTUALIZAR_DATOS_ADICIONALES_EXITO,
  payload: datoAdicional,
});

const actualizarDatoAdicionalError = (error) => ({
  type: Types.ACTUALIZAR_DATOS_ADICIONALES_ERROR,
  payload: error,
});

export function eliminarDatoAdicionalAction(datoAdicional) {
  return async (dispatch) => {
    dispatch(loadingRequest(true));
    try {
      const response = await clienteAxios.delete(
        "datos-adicionales/" + datoAdicional.id
      );
      if (response.data.data) {
        dispatch(eliminarDatoAdicionalExito(datoAdicional));
        Swal.fire("¡Muy bien!", "Acción realizada con éxito", "success");
      }
    } catch (error) {
      dispatch(eliminarDatoAdicionalError(error));
    }

    dispatch(loadingRequest(false));
  };
}

const eliminarDatoAdicionalExito = (datoAdicional) => ({
  type: Types.ELIMINAR_DATOS_ADICIONALES_EXITO,
  payload: datoAdicional,
});

const eliminarDatoAdicionalError = (error) => ({
  type: Types.ELIMINAR_DATOS_ADICIONALES_ERROR,
  payload: error,
});

export function setModalDatosAdicionalesAction(estatus) {
  return (dispatch) => {
    dispatch(setModalDatosAdicionales(estatus));
  };
}

const setModalDatosAdicionales = (estatus) => ({
  type: Types.MODAL_DATOS_ADICIONALES,
  payload: estatus,
});

export function setModalAgregarDatosAdicionalesAction(estatus) {
  return (dispatch) => {
    dispatch(setModalAgregarDatosAdicionales(estatus));
  };
}

const setModalAgregarDatosAdicionales = (estatus) => ({
  type: Types.MODAL_AGREGAR_DATOS_ADICIONALES,
  payload: estatus,
});

export function setModalActualizarDatosAdicionalesAction(estatus) {
  return (dispatch) => {
    dispatch(setModalActualizarDatosAdicionales(estatus));
  };
}

const setModalActualizarDatosAdicionales = (estatus) => ({
  type: Types.MODAL_ACTUALIZAR_DATOS_ADICIONALES,
  payload: estatus,
});

export function getTabsDatosAdicionalesAction(tabla) {
  return async (dispatch) => {
    dispatch(getTabsDatosAdicionales());
    try {
      const response = await clienteAxios.get(
        "datos-adicionales/tabs/" + tabla
      );
      if (response.data.data) {
        dispatch(getTabsDatosAdicionalesExito(response.data.data));
      }
    } catch (error) {
      console.log(error);
      dispatch(getTabsDatosAdicionalesError(error));
    }
  };
}

const getTabsDatosAdicionales = () => ({
  type: Types.LISTA_TABS_DATOS_ADICIONALES,
});

const getTabsDatosAdicionalesExito = (tabs) => ({
  type: Types.LISTA_TABS_DATOS_ADICIONALES_EXITO,
  payload: tabs,
});

const getTabsDatosAdicionalesError = (error) => ({
  type: Types.LISTA_TABS_DATOS_ADICIONALES_ERROR,
  payload: error,
});
