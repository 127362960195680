import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import ReactDatatable from '@mkikets/react-datatable';
import {
  comenzarActualizarDatoAdicionalAction,
  setModalAgregarDatosAdicionalesAction,
  setModalDatosAdicionalesAction,
} from "../../actions/datosAdicionalesActions";
import AgregarDatosAdicionales from "./AgregarDatosAdicionales";
import EditarDatosAdicionales from "./EditarDatosAdicionales";

export default (props) => {
  const dispatch = useDispatch();

  const {
    showModalDatosAdicionales,
    datosAdicionales,
    showModalAgregarDatosAdicionales,
    showModalActualizarDatosAdicionales,
    datoAdicional
  } = useSelector((state) => state.datosAdicionales);

  const columns = [
    {
      key: "nombre",
      text: "Nombre",
      align: "left",
      sortable: false,
    },
    {
      key: "descripcion",
      text: "Descripcion",
      align: "left",
      sortable: false,
    },
    {
      key: "etiqueta",
      text: "Etiqueta",
      align: "left",
      sortable: false,
    },
    {
      key: "tabulador",
      text: "Tabulador",
      align: "left",
      sortable: true,
    },
    {
      key: "tipo",
      text: "Tipo",
      align: "left",
      sortable: true,
    },
    {
      key: "requerido",
      text: "Obligatorio",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.requerido ? "Si" : "No";
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>Editar</Tooltip>}
            >
              <button
                className="btn btn-primary btn-sm"
                onClick={() => dispatch(comenzarActualizarDatoAdicionalAction(record)) }
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            </OverlayTrigger>

            <button
              className="btn btn-success btn-sm"
              onClick={() => console.log(record)}
              style={{ marginRight: "5px" }}
            >
              Permisos
            </button>
          </Fragment>
        );
      },
    },
  ];

  const handleClose = () => {
    dispatch(setModalDatosAdicionalesAction(false));
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar",
      children: [
        <span>
          <i class="fas fa-plus"></i> Agregar
        </span>,
      ],
      onClick: (event) => {
        dispatch(setModalAgregarDatosAdicionalesAction(true));
        console.log(showModalAgregarDatosAdicionales);
      },
    },
  ];

  const config = {
    button: {
      extra: true,
    },
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModalDatosAdicionales}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Datos adicionales</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="table-wrapper table-responsive shadow-sm">
          <ReactDatatable
            hover
            records={datosAdicionales}
            columns={columns}
            extraButtons={extraButtons}
            config={config}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <AgregarDatosAdicionales tabla={props.tabla} />
      <EditarDatosAdicionales tabla={props.tabla} />
    </>
  );
};
