import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from '@themesberg/react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  showModalEditarAction,
  editarCategoriaProductoAction
} from '../../actions/categoriasProductosActions'

export default props => {
  const dispatch = useDispatch()

  const { categoria, showModalEditarCategoria } = useSelector(
    state => state.categoriasProductos
  )

  const [edicion, guardar] = useState({
    nombre: ''
  })

  useEffect(() => {
    guardar(categoria)
  }, [categoria])

  const submitForm = e => {
    e.preventDefault()
    dispatch(editarCategoriaProductoAction(edicion))
  }

  const handleClose = () => {
    dispatch(showModalEditarAction(false))
  }

  const onChangeForm = e => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalEditarCategoria}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className='h6'>Editar categoria</Modal.Title>
        <Button variant='close' aria-label='Close' onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type='text'
              name='nombre'
              placeholder='Nombre categoria'
              onChange={onChangeForm}
              value={edicion.nombre}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='link'
            className='text-gray ms-auto'
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type='submit' variant='secondary'>
            Actualizar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
