import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  cerrarModalListaAction,
  comenzarAgregarTratamientoPrecioAction,
  comenzarEditarTratamientoPrecioAction,
  eliminarTratamientoPrecioAction,
} from "../../actions/tratamientosPreciosActions";

import DataTable from "../../components/DataTable";
import { ConfirmModal } from "../../components/ConfirmModal";

import AgregarTratamientosPrecios from "./AgregarTratamientosPrecios";
import EditarTratamientosPrecios from "./EditarTratamientosPrecios";
import {
  getPreciosProductosAction,
  showModalTratamientoPreciosProductosAction,
} from "../../actions/preciosProductosActions";
import ModalPreciosProductos from "./ModalPreciosProductos";

export default (props) => {
  const dispatch = useDispatch();

  const { showModalLista, precios } = useSelector(
    (state) => state.tratamientosPrecios
  );

  const columns = [
    {
      key: "descripcion",
      text: "Descripción",
      className: "descripcion",
      align: "left",
      sortable: true,
    },
    {
      key: "precio",
      text: "Precio",
      className: "precio",
      align: "left",
      sortable: true,
    },
  ];

  const state = {
    records: precios,
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar precio",
      children: [
        <span>
          <i class="fas fa-plus"></i> Agregar precio
        </span>,
      ],
      onClick: (event) => {
        dispatch(comenzarAgregarTratamientoPrecioAction());
      },
    },
  ];

  const editRecord = (record) => {
    dispatch(comenzarEditarTratamientoPrecioAction(record));
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: dispatch,
        action: eliminarTratamientoPrecioAction,
        args: [record],
      },
    ];

    ConfirmModal(callbacks);
  };

  const handleClose = () => {
    dispatch(cerrarModalListaAction());
  };

  const productos = (record) => {
    dispatch(getPreciosProductosAction(record));
    dispatch(showModalTratamientoPreciosProductosAction(true));
  };

  const acciones = [
    {
      description: "Productos",
      icon: "fas fa-cubes",
      className: "btn btn-success btn-sm",
      event: productos,
      style: { marginRight: "5px" },
    },
  ];

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="lg"
        centered
        show={showModalLista}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Precios tratamiento</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={columns}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            state={state}
            extraButtons={extraButtons}
            config={{ show_filter: false, show_pagination: false }}
            acciones={acciones}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <AgregarTratamientosPrecios />
      <EditarTratamientosPrecios />
      <ModalPreciosProductos />
    </>
  );
};
