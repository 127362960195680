import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { agregarMenuAction, cerrarModalAgregarAction } from '../../actions/menuActions';

export default (props) => {
    const dispatch = useDispatch();
    
    const { showModalAgregar } = useSelector(state => state.menu);

    const [ menuAgregar, guardarMenu] = useState({
        'nombre': '',
        'url': '',
        'funciones': false,
        'icono': ''
    })

    const submitAgregarMenu = e => {
        e.preventDefault();
        dispatch( agregarMenuAction(menuAgregar) );
    }

    const handleClose = () => {
        dispatch( cerrarModalAgregarAction() );
    }

    const onChangeForm = (e) => {
        guardarMenu({
            ...menuAgregar,
            [e.target.name] : e.target.value
        })
    }

    const onChangeCheckboxForm = (e) => {
        guardarMenu({
            ...menuAgregar,
            [e.target.name] : e.target.checked
        })
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModalAgregar} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Agregar Menu</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitAgregarMenu}>
            <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" name="nombre" placeholder="Nombre del menu" 
                            value={menuAgregar.nombre} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group style={{ display: menuAgregar.funciones ? 'none' : 'block' }} className="mb-3">
                        <Form.Label>URL</Form.Label>
                        <Form.Control type="text" placeholder="URL" 
                            name="url" value={menuAgregar.url} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Icono</Form.Label>
                        <Form.Control type="text" name="icono" placeholder="Icono" 
                            value={menuAgregar.icono} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Funciones</Form.Label>
                        <Form.Check style={{"marginLeft": "20px"}}
                            name="funciones"
                            id="funciones"
                            htmlFor="funciones"
                            onChange={onChangeCheckboxForm}
                        />
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary">
                    Agregar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}