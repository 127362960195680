import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Breadcrumb } from '@themesberg/react-bootstrap'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DataTable from '../../components/DataTable'

import {
  showModalAgregarAction,
  getCategoriasProductosAction,
  comenzarEditarCategoriaProductoAction,
  eliminarCategoriaProductoAction
} from '../../actions/categoriasProductosActions'

import { ConfirmModal } from '../../components/ConfirmModal'
import AgregarCategoriaProducto from './AgregarCategoriaProducto'
import EditarCategoriaProducto from './EditarCategoriaProducto'
import { columnaSucursalDT } from '../../helpers'

export default () => {
  const dispatch = useDispatch()

  const { loadingCategoriasProductos, categoriasProductos } = useSelector(
    state => state.categoriasProductos
  )

  useEffect(() => {
    dispatch(getCategoriasProductosAction())
  }, [])

  const columns = [
    {
      key: 'id',
      text: 'ID',
      className: 'id',
      align: 'left',
      sortable: true
    },
    {
      key: 'nombre',
      text: 'Nombre',
      className: 'nombre',
      align: 'left',
      sortable: true
    },
    columnaSucursalDT()
  ]

  const state = {
    records: categoriasProductos
  }

  const extraButtons = [
    {
      className: 'btn btn-info',
      title: 'Agregar categoria',
      children: [
        <span>
          <i class='fas fa-plus'></i> Nuevo
        </span>
      ],
      onClick: event => {
        dispatch(showModalAgregarAction(true))
      }
    }
  ]

  const editRecord = record => {
    dispatch(comenzarEditarCategoriaProductoAction(record))
  }

  const deleteRecord = record => {
    const callbacks = [
      {
        event: dispatch,
        action: eliminarCategoriaProductoAction,
        args: [record]
      }
    ]

    ConfirmModal(callbacks)
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Categorias Productos</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border='light' className='table-wrapper table-responsive shadow-sm'>
        <Card.Body>
          <h5 className='mb-4'>Categorias Productos</h5>
          <DataTable
            columns={columns}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            state={state}
            extraButtons={extraButtons}
            loading = {loadingCategoriasProductos}
          />
        </Card.Body>
      </Card>

      <AgregarCategoriaProducto />
      <EditarCategoriaProducto />
    </>
  )
}
