import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  InputGroup,
  Form,
  FormControl,
  Button,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import { busquedaFiltroAction, setCriteriaAction, setRequestAction } from '../actions/filtroActions';
import Swal from 'sweetalert2';
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";

export default (props) => {
  const dispatch = useDispatch();
  const { loadingFiltro } = useSelector(state => state.filtro)

  const [state, setState] = useState({
    opcionSeleccionada: props.defaultSelected ?? "all",
    opcionObj: props.opciones[0],
    valorInput: "",
    tipo: "text",
    format: "YYYY-MM-DD",
    viewMode: "days",
  });

  const handleChange = (e) => {
    const opcion = props.opciones?.find(
      (opcion) => opcion.valor == e.target.value
    );

    setState({
      ...state,
      opcionSeleccionada: e.target.value,
      tipo: opcion ? opcion.tipo : "text",
      opcionObj: opcion,
      valorInput: "",
      format: opcion ? (opcion.format ? opcion.format : "YYYY-MM-DD") : "YYYY-MM-DD",
      viewMode: opcion ? (opcion.viewMode ?? "days") : "days",
    });
  };

  const handleChangeInput = (e) => {
    setState({
      ...state,
      valorInput: e.target.value,
    });
  };

  const handleChangeDate = (fecha, format = state.format) => {
    setState({
      ...state,
      valorInput: moment(fecha).format(format),
    });
  };

  const onSubmit = () => {
    let request = null;
    const criteriasObligatorios = props.criteriasObligatorios ?? [];

    if(state.opcionSeleccionada == "all") {
      request = {
        ...props.request,
        criteria: ['all']
      }

      if(criteriasObligatorios.length > 0) {
        request.criteria = [...request.criteria, ...criteriasObligatorios]
      }

      dispatch(setCriteriaAction(request.criteria));
      dispatch(setRequestAction(request));
      dispatch(busquedaFiltroAction(props.modelo, request));
      return;
    }

    if(state.valorInput == "" && state.tipo != "options") {
      Swal.fire('¡UPS!', "Ingresa un valor en el filtro de busqueda", 'error');
      return;
    }

    if(state.tipo == "options" && state.valorInput == "") {
      state.valorInput = state.opcionObj.opciones[0].valor
    }

    const criteria = state.opcionObj.criteria ?? [];

    request = {
      ...props.request,
      valor: state.opcionSeleccionada + "|" + state.valorInput,
      criteria: [...criteria, ...criteriasObligatorios],
      params: props.params ?? null
    };

    dispatch(setCriteriaAction(request.criteria));
    dispatch(setRequestAction(request));
    dispatch(busquedaFiltroAction(props.modelo, request));
  };

  return (
    <>
      <Row>
        <Col xs={12} md={2}>
          <Form.Select onChange={handleChange.bind(this)}>
            { props.buscarTodo === true || props.buscarTodo == undefined &&
              <option value="all">Buscar todo</option>
            }
            {props.opciones?.map((opcion, index) => {
              return <option value={opcion.valor}>{opcion.label}</option>;
            })}
          </Form.Select>
        </Col>
        <Col xs={12} md={10} >
          <InputGroup className="mb-3">
            {(state.tipo == "text" ||
              state.tipo == "number") && (
              <FormControl
                disabled={state.opcionSeleccionada == "all"}
                type={state.tipo}
                onChange={handleChangeInput}
                value={state.valorInput}
                placeholder="Ingresa un valor"
              />
            )}
            {(state.tipo == "date") && (
              <Datetime
                closeOnSelect={true}
                onChange={handleChangeDate}
                utc={true}
                value={state.valorInput}
                initialViewMode={state.viewMode}
                dateFormat={state.format}
                renderInput={(propsDate, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Selecciona una fecha*"
                      onFocus={openCalendar}
                      value={state.valorInput ? moment(state.valorInput).format(state.format) : ""}
                      onChange={(e) => handleChangeDate(e.target.value, state.format)}
                    />
                  </InputGroup>
                )}
              />
            )}
            {state.tipo == "options" && (
              <Form.Select onChange={handleChangeInput.bind(this)}>
                {state.opcionObj.opciones?.map((opcion, index) => {
                  return <option value={opcion.valor}>{opcion.label}</option>;
                })}
              </Form.Select>
            )}
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={loadingFiltro}
            >
              <i className="fas fa-search"></i> Buscar
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};
