import * as Types from '../types';
import clienteAxios from '../config/axios';
import { loadingRequest } from './axiosActions';
import Swal from 'sweetalert2';

export function getImpuestosAction() {
    return async (dispatch) => {
        dispatch( getImpuestos() );
        try {
            const response = await clienteAxios.get('impuestos');
            if (response.data.data) {
                dispatch( getImpuestosExito(response.data.data) );
            }
        } catch (error) {
            console.log(error);
            dispatch( getImpuestosError(error) );
        }
    }
}

const getImpuestos = () => ({
    type: Types.LISTA_IMPUESTOS
})

const getImpuestosExito = impuestos => ({
    type: Types.LISTA_IMPUESTOS_EXITO,
    payload: impuestos
})

const getImpuestosError = error => ({
    type: Types.LISTA_IMPUESTOS_ERROR,
    payload: error
})

export function actualizarInputAction(impuesto) {
    return (dispatch) => {
        dispatch( actualizarInput(impuesto) );
    }
}

const actualizarInput = impuesto => ({
    type: Types.ACTUALIZAR_INPUT_IMPUESTO,
    payload: impuesto
})

export function agregarImpuestoAction(impuesto) {
    return async (dispatch) => {
        dispatch( agregarImpuesto() );
        try {
            const response = await clienteAxios.post('impuestos', impuesto);
            if (response.data.data) {
                dispatch( agregarImpuestoExito(response.data.data) );
                Swal.fire('¡Muy bien!', response.data.message, 'success');
            }
        } catch (error) {
            dispatch( agregarImpuestoError(error) );
        }
    }
}

const agregarImpuesto = () => ({
    type: Types.AGREGAR_IMPUESTO
})

const agregarImpuestoExito = impuesto => ({
    type: Types.AGREGAR_IMPUESTO_EXITO,
    payload: impuesto
})

const agregarImpuestoError = error => ({
    type: Types.AGREGAR_IMPUESTO_ERROR,
    payload: error
})

export function actualizarImpuestosAction(impuestos) {
    return async (dispatch) => {
        dispatch( actualizarImpuesto() );
        try {
            const response = await clienteAxios.put(`impuestos/0`, {'impuestos': impuestos});
            if (response.data.data) {
                dispatch( actualizarImpuestoExito(response.data.data) );
                Swal.fire('¡Muy bien!', response.data.message, 'success');
            }
        } catch (error) {
            dispatch( actualizarImpuestoError(error) );
        }
    }
}

const actualizarImpuesto = () => ({
    type: Types.ACTUALIZAR_IMPUESTO
})

const actualizarImpuestoExito = impuesto => ({
    type: Types.ACTUALIZAR_IMPUESTO_EXITO,
    payload: impuesto
})

const actualizarImpuestoError = error => ({
    type: Types.ACTUALIZAR_IMPUESTO_ERROR,
    payload: error
})

export function eliminarImpuestoAction(id) {
    return async (dispatch) => {
        try {
            const response = await clienteAxios.delete(`impuestos/${id}`);
            if (response.data.data) {
                dispatch( eliminarImpuestoExito(response.data.data) );
                Swal.fire('¡Muy bien!', response.data.message, 'success');
            }
        } catch (error) {
            dispatch( eliminarImpuestoError(error) );
        }
    }
}

const eliminarImpuestoExito = impuesto => ({
    type: Types.ELIMINAR_IMPUESTO_EXITO,
    payload: impuesto
})

const eliminarImpuestoError = error => ({
    type: Types.ELIMINAR_IMPUESTO_ERROR,
    payload: error
})