import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import clienteAxios from "../../config/axios";
import Swal from 'sweetalert2';

export default (props) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [entradas, setEntradas] = useState([]);
  const [inventario, setInventario] = useState({});

  useEffect(() => {
    setShowModal(props.showModal);
    if (props.inventario && props.inventario.id) {
      setInventario(props.inventario);
    }
  }, [props]);

  useEffect(() => {
    if (inventario && inventario.id) {
      getEntradas();
    }
  }, [inventario]);

  const getEntradas = () => {
    clienteAxios
      .get("inventario/" + inventario.id + "/entradas")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setEntradas(respuesta.data.data);
        }
      });
  };

  const columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      key: "fecha_entrada",
      text: "Fecha entrada",
      sortable: true,
    },
    {
      key: "cantidad",
      text: "Cantidad",
      align: "left",
      sortable: true,
    },
    {
      key: "precio_unidad",
      text: "Precio unidad",
      sortable: true,
    },
    {
      key: "stock",
      text: "Stock",
    },
    {
      text: "Salidas",
      cell: (record) => {
        return record.cantidad - record.stock;
      },
    },
    {
      text: "Proveedor",
      sortable: true,
      cell: (record) => {
        return record.proveedor?.nombre;
      },
    },
    {
      text: "Financiado",
      sortable: true,
      cell: (record) => {
        return record.financiamiento === true ? "Si" : "No";
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Eliminar</Tooltip>}
              >
                <button
                  onClick={() => eliminar(record)}
                  className="btn btn-danger btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
    },
  ];

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const eliminar = (record) => {
    clienteAxios
      .delete("inventario/" + inventario.id + "/entradas/" + record.id)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setEntradas(entradas.filter((row) => row.id != record.id));

          Swal.fire("¡Muy bien!", "Acción realizada con exito", "success");
        }
      });
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Entradas</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <ReactDatatable hover records={entradas} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
