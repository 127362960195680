import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button } from "@themesberg/react-bootstrap";
import ReactDatatable from '@mkikets/react-datatable';
import {
  showModalTratamientoPreciosAction,
  agregarProductoAction,
  actualizarProductoAction,
  actualizarDescuentoGeneralAction,
} from "../../actions/ventaActions";

var crypto = require("crypto");

export default (props) => {
  const dispatch = useDispatch();

  const { precios } = useSelector((state) => state.tratamientosPrecios);
  const { showModalVentaTratamientoPrecios, productosAgregados } = useSelector(
    (state) => state.venta
  );

  const columns = [
    {
      key: "descripcion",
      text: "Descripcion",
      className: "descripcion",
      align: "left",
      sortable: true,
    },
    {
      key: "precio",
      text: "Precio",
      className: "precio",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-success btn-sm"
              onClick={() => seleccionar(record)}
              style={{ marginRight: "5px" }}
            >
              Seleccionar
            </button>
          </Fragment>
        );
      },
    },
  ];

  const seleccionar = (record) => {
    /*const checkExistProducto = productosAgregados.filter(
      (productoAgregado) =>
        productoAgregado.id == props.tratamiento.id &&
        productoAgregado.tipo == "TRATAMIENTO"
    );*/

    const producto = {
      key: crypto.randomBytes(20).toString('hex'),
      id: props.tratamiento.id,
      id_precio: record.id,
      cantidad: 1,
      precio: record.precio,
      nombre: props.tratamiento.nombre,
      tratamiento_financiado: false,
      anticipo: 0,
      id_empleado: null,
      tipo: "TRATAMIENTO",
      descuento_general: false,
      descuento_por_cupon: false,
      descuento_tipo: null,
      descuento_porcentaje: 0,
      descuento_monto: 0
    };

    dispatch(agregarProductoAction(producto));
    if (props.stateDescuento.descuento_general) {
      dispatch(actualizarDescuentoGeneralAction(props.stateDescuento));
    }
  };

  const handleClose = () => {
    dispatch(showModalTratamientoPreciosAction(false));
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="lg"
        centered
        show={showModalVentaTratamientoPrecios}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Precios tratamiento</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="table-wrapper table-responsive shadow-sm">
          <ReactDatatable hover records={precios} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
