import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Form,
} from "@themesberg/react-bootstrap";
import Typeahead from "../../components/Typeahead";
import { el } from "@fullcalendar/core/internal-common";

export default (props) => {
  const [accion, setAccion] = useState("agregar");
  const [atributos, setAtributos] = useState([]);
  const [catalogoValor, setCatalogoValor] = useState({
    id: null,
    valor: "",
    descripcion: "",
  });
  const [catalogoValores, setCatalogoValores] = useState([]);

  useEffect(() => {
    setCatalogoValor(props.catalogo_valor);
  }, [props.catalogo_valor]);

  useEffect(() => {
    setAtributos(props.atributos);
  }, [props.atributos]);

  useEffect(() => {
    setCatalogoValores(props.catalogo_valores);
  }, [props.catalogo_valores]);

  useEffect(() => {
    setAccion(props.accion);
  }, [props.accion]);

  useEffect(() => {
    props.onChangeCatalogoValor(catalogoValor);
  }, [catalogoValor]);

  const actualizarValor = (
    nombreColumna,
    valorNuevo,
    id_valor = null,
    idCatalogoValor = null
  ) => {
    if (typeof valorNuevo === "boolean") {
      valorNuevo = valorNuevo ? "true" : "false";
    }

    if (accion == "editar" && catalogoValores.length > 0) {
      if (nombreColumna == "valor" || nombreColumna == "descripcion") {
        setCatalogoValores(
          catalogoValores.map((valor) =>
            valor.id == idCatalogoValor
              ? (valor = {
                  ...valor,
                  [nombreColumna]: valorNuevo,
                })
              : valor
          )
        );
        setCatalogoValor({
          ...catalogoValor,
          [nombreColumna]: valorNuevo,
        });
      } else {
        setCatalogoValores(
          catalogoValores.map((valor) =>
            valor.id == idCatalogoValor
              ? (valor = {
                  ...valor,
                  [`${nombreColumna}_valor`]: valorNuevo,
                  [`${nombreColumna}_id_valor`]: id_valor,
                })
              : valor
          )
        );
        setCatalogoValor({
          ...catalogoValor,
          [`${nombreColumna}_valor`]: valorNuevo,
          [`${nombreColumna}_id_valor`]: id_valor,
        });
      }

      return;
    }

    if (nombreColumna == "valor" || nombreColumna == "descripcion") {
      setCatalogoValor({
        ...catalogoValor,
        [nombreColumna]: valorNuevo,
      });
    } else {
      setCatalogoValor({
        ...catalogoValor,
        [`${nombreColumna}_valor`]: valorNuevo,
        [`${nombreColumna}_id_valor`]: id_valor,
      });
    }
  };

  const getValorColumna = (nombreColumna) => {
    return {
      valor: catalogoValor ? catalogoValor[nombreColumna + "_valor"] : "",
      id_valor: catalogoValor
        ? catalogoValor[nombreColumna + "_id_valor"]
        : null,
    };
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Valor*</Form.Label>
            <Form.Control
              type="text"
              name="valor"
              placeholder="Valor"
              value={catalogoValor?.valor}
              onChange={(e) =>
                actualizarValor(`valor`, e.target.value, null, catalogoValor.id)
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              name="descripcion"
              placeholder="Descripcion"
              value={catalogoValor?.descripcion}
              onChange={(e) =>
                actualizarValor(
                  `descripcion`,
                  e.target.value,
                  null,
                  catalogoValor.id
                )
              }
            />
          </Form.Group>
        </Col>
      </Row>
      {Object.entries(atributos).map((atributo) => (
        <>
          <Fragment key={atributo[1].numero}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{atributo[1].nombre}</Form.Label>
                  {atributo[1].tipo == "text" && (
                    <Form.Control
                      type="text"
                      name={`atributo${atributo[1].numero}`}
                      value={
                        getValorColumna(`atributo${atributo[1].numero}`).valor
                          ? getValorColumna(`atributo${atributo[1].numero}`)
                              .valor
                          : atributo[1].valor_default
                      }
                      onChange={(e) =>
                        actualizarValor(
                          `atributo${atributo[1].numero}`,
                          e.target.value,
                          null,
                          catalogoValor.id
                        )
                      }
                    />
                  )}
                  {atributo[1].tipo == "number" && (
                    <Form.Control
                      type="number"
                      name={`atributo${atributo[1].numero}`}
                      value={
                        getValorColumna(`atributo${atributo[1].numero}`).valor
                          ? getValorColumna(`atributo${atributo[1].numero}`)
                              .valor
                          : atributo[1].valor_default
                      }
                      onChange={(e) =>
                        actualizarValor(
                          `atributo${atributo[1].numero}`,
                          e.target.value,
                          null,
                          catalogoValor.id
                        )
                      }
                      min="0"
                    />
                  )}
                  {atributo[1].tipo == "boolean" && (
                    <Form.Check
                      type="switch"
                      label=""
                      name={`atributo${atributo[1].numero}`}
                      checked={
                        getValorColumna(`atributo${atributo[1].numero}`).valor
                          ? getValorColumna(`atributo${atributo[1].numero}`)
                              .valor == "true"
                          : atributo[1].valor_default == "true"
                      }
                      onChange={(e) =>
                        actualizarValor(
                          `atributo${atributo[1].numero}`,
                          e.target.checked,
                          null,
                          catalogoValor.id
                        )
                      }
                    />
                  )}
                  {atributo[1].tipo == "service" && (
                    <Typeahead
                      url={atributo[1].valor_default}
                      labelKey="label"
                      name={`atributo${atributo[1].numero}`}
                      onChange={(selected) => {
                        actualizarValor(
                          `atributo${atributo[1].numero}`,
                          selected.length > 0 ? selected[0].label : "",
                          selected.length > 0 ? selected[0].id : null,
                          catalogoValor.id
                        );
                      }}
                      selected={
                        getValorColumna(`atributo${atributo[1].numero}`)
                          .id_valor
                          ? [
                              {
                                id: getValorColumna(
                                  `atributo${atributo[1].numero}`
                                ).id_valor,
                                label: getValorColumna(
                                  `atributo${atributo[1].numero}`
                                ).valor,
                              },
                            ]
                          : undefined
                      }
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Fragment>
        </>
      ))}
    </>
  );
};
