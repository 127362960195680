import React, { useContext, useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import { useSelector } from "react-redux";

// pages
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";
import Usuarios from "./Usuarios/Usuarios";
import Roles from "./Roles/Roles";
import Menu from "./Menu/Menu";
import Pacientes from "./Pacientes/Pacientes";
import Tratamientos from "./Tratamientos/Tratamientos";
import Empleados from "./Empleados/Empleados";
import Agenda from "./Agenda/Agenda";
import Venta from "./Venta/Venta";
import HistorialVentas from "./Venta/HistorialVentas";
import Caja from "./Caja/Caja";
import Productos from "./Productos/Productos";
import CategoriasProductos from "./Productos/CategoriasProductos";
import HistorialComisiones from "./Empleados/HistorialComisiones";
import HistorialCierreCaja from "./Caja/HistorialCierreCaja";
import Configuracion from './Configuracion/Configuracion';
import Financiados from "./Pacientes/Financiados";
import Loading from 'react-fullscreen-loading';
import Kardex from './Inventario/Kardex';
import Inventario from "./Inventario/Inventario";
import Notificaciones from './Notificaciones/Notificaciones';

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import { limpiarFiltroAction } from '../actions/filtroActions';
import { pageInterceptor } from './Interceptor';
import Impuestos from './Impuestos/Impuestos';
import HistorialCitas from './Agenda/HistorialCitas';
import MensajesMasivos from './MensajesMasivos/MensajesMasivos';
import Calendario from './Calendario/Calendario';
import Reportes from './Reportes/Reportes';
import Catalogos from './Catalogos/Catalogos';
import EmpleadosPorcentajes from './Empleados/EmpleadosPorcentajes';
import Sucursales from './Sucursales/Sucursales';
import Nominas from './Nominas/Nominas';
import Proveedores from './Proveedores/Proveedores';
import ProveedoresFinanciamientos from './Proveedores/ProveedoresFinanciamientos';
import PuestosLaborales from './Empleados/PuestosLaborales/PuestosLaborales';
import Estadisticas from './Estadisticas/Estadisticas';
import GastosOperacion from './Tesoreria/Gastos/GastosOperacion';
import WhatsappChat from './CRM/WhatsappChat';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { user } = useSelector(state => state.auth);
  const { loadingRequest } = useSelector(state => state.axios);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      user != null ?
      <>
        <Preloader show={loaded ? false : true} />
        <Loading 
          loading={loadingRequest}
          background="rgba(0, 0, 0, 0.24)"
          loaderColor="#3498db"
        />
        <Sidebar />

        <Navbar />
        <main className="content">
          <Component {...props} />
          { /*<Footer toggleSettings={toggleSettings} showSettings={showSettings} /> */ }
        </main>
      </>
      : <Redirect to='/login' />
    )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />
    <RouteWithSidebar exact path={Routes.Usuarios.path} component={Usuarios} />
    <RouteWithSidebar exact path={Routes.Roles.path} component={Roles} />
    <RouteWithSidebar exact path={Routes.Menu.path} component={Menu} />
    <RouteWithSidebar exact path={Routes.Pacientes.path} component={Pacientes} />
    <RouteWithSidebar exact path={Routes.Tratamientos.path} component={Tratamientos} />
    <RouteWithSidebar exact path={Routes.Empleados.path} component={Empleados} />
    <RouteWithSidebar exact path={Routes.Agenda.path} component={pageInterceptor('Agenda','Agenda')} />
    <RouteWithSidebar exact path={Routes.Venta.path} component={Venta} />
    <RouteWithSidebar exact path={Routes.HistorialVentas.path} component={pageInterceptor('Venta','HistorialVentas')} />
    <RouteWithSidebar exact path={Routes.Caja.path} component={Caja} />
    <RouteWithSidebar exact path={Routes.Productos.path} component={Productos} />
    <RouteWithSidebar exact path={Routes.CategoriasProductos.path} component={CategoriasProductos} />
    <RouteWithSidebar exact path={Routes.Inventario.path} component={Inventario} />
    {/*<RouteWithSidebar exact path={Routes.Inventario.path} component={pageInterceptor('Inventario','Inventario')} />*/}
    <RouteWithSidebar exact path={Routes.HistorialComisiones.path} component={HistorialComisiones} />
    <RouteWithSidebar exact path={Routes.HistorialCierreCaja.path} component={HistorialCierreCaja} />
    <RouteWithSidebar exact path={Routes.Configuracion.path} component={Configuracion} />
    <RouteWithSidebar exact path={Routes.PacientesFinanciados.path} component={Financiados} />
    <RouteWithSidebar exact path={Routes.Kardex.path} component={Kardex} />
    <RouteWithSidebar exact path={Routes.Notificaciones.path} component={Notificaciones} />
    <RouteWithSidebar exact path={Routes.Impuestos.path} component={Impuestos} />
    <RouteWithSidebar exact path={Routes.HistorialCitas.path} component={HistorialCitas} />
    <RouteWithSidebar exact path={Routes.MensajesMasivos.path} component={MensajesMasivos} />
    <RouteWithSidebar exact path={Routes.Calendario.path} component={Calendario} />
    <RouteWithSidebar exact path={Routes.Estadisticas.path} component={Estadisticas} />
    <RouteWithSidebar exact path={Routes.Reportes.path} component={Reportes} />
    <RouteWithSidebar exact path={Routes.Catalogos.path} component={Catalogos} />
    <RouteWithSidebar exact path={Routes.EmpleadosPorcentajes.path} component={EmpleadosPorcentajes} />
    <RouteWithSidebar exact path={Routes.Sucursales.path} component={Sucursales} />
    <RouteWithSidebar exact path={Routes.Nominas.path} component={Nominas} />
    <RouteWithSidebar exact path={Routes.Proveedores.path} component={Proveedores} />
    <RouteWithSidebar exact path={Routes.FinanciamientosProveedores.path} component={ProveedoresFinanciamientos} />
    <RouteWithSidebar exact path={Routes.PuestosLaborales.path} component={PuestosLaborales} />
    <RouteWithSidebar exact path={Routes.GastosOperacion.path} component={GastosOperacion} />
    <RouteWithSidebar exact path={Routes.WhatsappChat.path} component={WhatsappChat} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
