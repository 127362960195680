import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { getCajaAction, getCajaEstadisticasAction } from './cajaActions';

export function getCajaMovimientosAction() {
    return async (dispatch) => {
        dispatch( getCajaMovimientos() );
        try {
            const response = await clienteAxios.get('caja/movimientos/ultima-apertura');
            if (response.data.data) {
                dispatch( getCajaMovimientosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getCajaMovimientosError(error) );
        }
    }
}

const getCajaMovimientos = () => ({
    type: Types.CAJA_MOVIMIENTOS
})

const getCajaMovimientosExito = movimientos => ({
    type: Types.CAJA_MOVIMIENTOS_EXITO,
    payload: movimientos
})

const getCajaMovimientosError = error => ({
    type: Types.CAJA_MOVIMIENTOS_EXITO,
    payload: error
})

export function agregarCajaMovimientoAction(movimiento) {
    return async (dispatch) => {
        dispatch( agregarCajaMovimiento() );
        try {
            const response = await clienteAxios.post('caja/movimientos/agregar', movimiento);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarCajaMovimientoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( agregarCajaMovimientoError(error) );
        }
    }
}

const agregarCajaMovimiento = () => ({
    type: Types.CAJA_AGREGAR_MOVIMIENTO
})

const agregarCajaMovimientoExito = movimiento => ({
    type: Types.CAJA_AGREGAR_MOVIMIENTO_EXITO,
    payload: movimiento
})

const agregarCajaMovimientoError = error => ({
    type: Types.CAJA_AGREGAR_MOVIMIENTO_ERROR,
    payload: error
})

export function showModalCajaMovimientoAction(estatus) {
    return (dispatch) => {
        dispatch( showModalCajaMovimiento(estatus) );
    }
}

const showModalCajaMovimiento = estatus => ({
    type: Types.MODAL_CAJA_AGREGAR_MOVIMIENTO,
    payload: estatus
})

export function getTipoMovimientosAction() {
    return async (dispatch) => {
        dispatch( getTipoMovimientos() );
        try {
            const response = await clienteAxios.get('caja/movimientos/tipos');
            if (response.data.data) {
                dispatch( getTipoMovimientosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getTipoMovimientosError(error) );
        }
    }
}

const getTipoMovimientos = () => ({
    type: Types.LISTA_TIPOS_MOVIMIENTOS_CAJA
})

const getTipoMovimientosExito = tipos => ({
    type: Types.LISTA_TIPOS_MOVIMIENTOS_CAJA_EXITO,
    payload: tipos
})

const getTipoMovimientosError = error => ({
    type: Types.LISTA_TIPOS_MOVIMIENTOS_CAJA_ERROR,
    payload: error
})

export function eliminarGastoAction(gasto) {
    return async (dispatch) => {
        dispatch( eliminarGasto() );
        try {
            const response = await clienteAxios.delete('caja/movimientos/eliminar-gasto/'+gasto.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarGastoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarGastoError(error) );
        }
    }
}

const eliminarGasto = () => ({
    type: Types.ELIMINAR_GASTO
})

const eliminarGastoExito = movimiento => ({
    type: Types.ELIMINAR_GASTO_EXITO,
    payload: movimiento
})

const eliminarGastoError = error => ({
    type: Types.ELIMINAR_GASTO_ERROR,
    payload: error
})