import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';
import { actualizarRowFiltroAction, eliminarRowFiltroAction } from './filtroActions';

export function getPacientesAction() {
    return async (dispatch) => {
        dispatch( getPacientes() );
        try {
            const response = await clienteAxios.get('clientes/list');
            if (response.data.data) {
                dispatch( getPacientesExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getPacientesError(error) );
        }
    }
}

const getPacientes = () => ({
    type: Types.LISTA_PACIENTES
})

const getPacientesExito = pacientes => ({
    type: Types.LISTA_PACIENTES_EXITO,
    payload: pacientes
})

const getPacientesError = error => ({
    type: Types.LISTA_PACIENTES_ERROR,
    payload: error
})

export function comenzarAgregarPacienteAction() {
    return (dispatch) => {
        dispatch( comenzarAgregarPaciente() );
    }
}

const comenzarAgregarPaciente = () => ({
    type: Types.COMENZAR_AGREGAR_PACIENTE
})

export function agregarPacienteAction(paciente) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarPaciente() );
        try {
            const response = await clienteAxios.post('clientes/agregar', paciente);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarPacienteExito(response.data.data) );
                paciente.nombre = '';
                paciente.apellido_paterno = '';
                paciente.apellido_materno = '';
                paciente.correo_electronico = '';
                paciente.genero = '';
                paciente.telefono = '';
                paciente.fecha_nacimiento = '';
            }
        } catch (error) {
            dispatch( agregarPacienteError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarPaciente = () => ({
    type: Types.AGREGAR_PACIENTE
})

const agregarPacienteExito = paciente => ({
    type: Types.AGREGAR_PACIENTE_EXITO,
    payload: paciente
})

const agregarPacienteError = error => ({
    type: Types.AGREGAR_PACIENTE_ERROR,
    payload: error
})

export function cerrarModalAgregarAction() {
    return (dispatch) => {
        dispatch( cerrarModalAgregar() );
    }
}

const cerrarModalAgregar = () => ({
    type: Types.CERRAR_MODAL_AGREGAR_PACIENTE
})

export function comenzarEditarPacienteAction(paciente) {
    return (dispatch) => {
        dispatch( comenzarEditarPaciente(paciente) );
    }
}

const comenzarEditarPaciente = paciente => ({
    type: Types.COMENZAR_EDITAR_PACIENTE,
    payload: paciente
})

export function editarPacienteAction(paciente) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarPaciente() );
        try {
            const response = await clienteAxios.put('clientes/actualizar/'+paciente.id, paciente);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarPacienteExito(response.data.data) );
                dispatch(actualizarRowFiltroAction(`item.id == ${paciente.id}`, response.data.data));
            }
        } catch (error) {
            dispatch( editarPacienteError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const editarPaciente = () => ({
    type: Types.EDITAR_PACIENTE
})

const editarPacienteExito = paciente => ({
    type: Types.EDITAR_PACIENTE_EXITO,
    payload: paciente
})

const editarPacienteError = error => ({
    type: Types.EDITAR_PACIENTE_ERROR,
    payload: error
})

export function cerrarModalEditarAction() {
    return (dispatch) => {
        dispatch( cerrarModalEditar() );
    }
}

const cerrarModalEditar = () => ({
    type: Types.CERRAR_MODAL_EDITAR_PACIENTE
})

export function eliminarPacienteAction(paciente) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('clientes/eliminar/'+paciente.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarPacienteExito(response.data.data) );
                dispatch(eliminarRowFiltroAction(`item.id != ${paciente.id}`));
            }
        } catch (error) {
            dispatch( eliminarPacienteError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarPacienteExito = paciente => ({
    type: Types.ELIMINAR_PACIENTE_EXITO,
    payload: paciente
})

const eliminarPacienteError = error => ({
    type: Types.ELIMINAR_PACIENTE_ERROR,
    payload: error
})

export function getPacientesFinanciadosAction() {
    return async (dispatch) => {
        dispatch( getPacientesFinanciados() );
        try {
            const response = await clienteAxios.get('clientes/financiados');
            if (response.data.data) {
                dispatch( getPacientesFinanciadosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getPacientesFinanciadosError(error) );
        }
    }
}

const getPacientesFinanciados = () => ({
    type: Types.LISTA_PACIENTES_FINANCIADOS
})

const getPacientesFinanciadosExito = pacientes => ({
    type: Types.LISTA_PACIENTES_FINANCIADOS_EXITO,
    payload: pacientes
})

const getPacientesFinanciadosError = error => ({
    type: Types.LISTA_PACIENTES_FINANCIADOS_ERROR,
    payload: error
})

export function getPacienteTratamientosAction(id_cliente) {
    return async (dispatch) => {
        dispatch( getPacienteTratamientos() );
        try {
            const response = await clienteAxios.get('clientes/tratamientos/list-cliente/'+id_cliente);
            if (response.data.data) {
                dispatch( getPacienteTratamientosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getPacienteTratamientosError(error) );
        }
    }
}

export function getPacienteTratamientosFinanciadosAction(id_cliente) {
    return async (dispatch) => {
        dispatch( getPacienteTratamientos() );
        try {
            const response = await clienteAxios.get('clientes/tratamientos/list-financiados/'+id_cliente);
            if (response.data.data) {
                dispatch( getPacienteTratamientosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getPacienteTratamientosError(error) );
        }
    }
}

const getPacienteTratamientos = () => ({
    type: Types.LISTA_CLIENTES_TRATAMIENTOS
})

const getPacienteTratamientosExito = tratamientos => ({
    type: Types.LISTA_CLIENTES_TRATAMIENTOS_EXITO,
    payload: tratamientos
})

const getPacienteTratamientosError = error => ({
    type: Types.LISTA_CLIENTES_TRATAMIENTOS_ERROR,
    payload: error
})

export function showModalPacienteTratamientosAction(estatus) {
    return  (dispatch) => {
        dispatch( showModalPacienteTratamientos(estatus) );
    }
}

const showModalPacienteTratamientos = estatus => ({
    type: Types.MODAL_LISTA_CLIENTES_TRATAMIENTOS,
    payload: estatus
})