import * as Types from '../types';

const initialState = {
    empleado: {},
    empleados: [],
    comisiones: [],
    loadingEmpleados: false,
    loadingEmpleadosAgregar: false,
    loadingEmpleadosEditar: false,
    showModalAgregar: false,
    showModalEditar: false,
    showModalComisiones: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_EMPLEADOS:
            return {
                ...state,
                loadingEmpleados: true
            }
        case Types.LISTA_EMPLEADOS_EXITO:
            return {
                ...state,
                loadingEmpleados: false,
                empleados: action.payload
            }
        case Types.LISTA_EMPLEADOS_ERROR:
            return {
                ...state,
                loadingEmpleados: false,
                error: action.payload
            }
        case Types.COMENZAR_AGREGAR_EMPLEADO:
            return {
                ...state,
                showModalAgregar: true
            }
        case Types.AGREGAR_EMPLEADO:
            return {
                ...state,
                loadingEmpleadosAgregar: true
            }
        case Types.AGREGAR_EMPLEADO_EXITO:
            return {
                ...state,
                loadingEmpleadosAgregar: false,
                empleados: state.empleados.concat(action.payload),
                showModalAgregar: false
            }
        case Types.AGREGAR_EMPLEADO_ERROR:
            return {
                ...state,
                loadingEmpleadosAgregar: false,
                error: action.payload
            }
        case Types.CERRAR_MODAL_AGREGAR_EMPLEADO:
            return {
                ...state,
                showModalAgregar: false
            }
        case Types.COMENZAR_EDITAR_EMPLEADO:
            return {
                ...state,
                showModalEditar: true,
                empleado: action.payload
            }
        case Types.EDITAR_EMPLEADO:
            return {
                ...state,
                loadingEmpleadosEditar: true
            }
        case Types.EDITAR_EMPLEADO_EXITO:
            return {
                ...state,
                loadingEmpleadosEditar: false,
                empleados: state.empleados.map( empleado => 
                    empleado.id === action.payload.id ? empleado = action.payload : empleado
                )
            }
        case Types.EDITAR_EMPLEADO_ERROR:
            return {
                ...state,
                loadingEmpleadosEditar: false,
                error: action.payload
            }
        case Types.CERRAR_MODAL_EDITAR_EMPLEADO:
            return {
                ...state,
                showModalEditar: false
            }
        case Types.ELIMINAR_EMPLEADO_EXITO:
            return {
                ...state,
                empleados: state.empleados.filter( empleado => 
                    empleado.id != action.payload.id
                )
            }
        case Types.ELIMINAR_EMPLEADO_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.COMENZAR_LISTA_COMISIONES_EMPLEADO:
            return {
                ...state,
                empleado: action.payload,
                showModalComisiones: true
            }
        case Types.LISTA_COMISIONES_EXITO:
            return {
                ...state,
                comisiones: action.payload
            }
        case Types.LISTA_COMISIONES_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.CERRAR_MODAL_COMISIONES_EMPLEADO:
            return {
                ...state,
                showModalComisiones: false
            }
        default:
            return state;
    }
}