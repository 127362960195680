import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getEmpleadosAction() {
    return async (dispatch) => {
        dispatch( getEmpleados() );
        try {
            const response = await clienteAxios.get('empleados/list');
            if (response.data.data) {
                dispatch( getEmpleadosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getEmpleadosError(error) );
        }
    }
}

export function getEmpleadosConAgendaActivaAction() {
    return async (dispatch) => {
        dispatch( getEmpleados() );
        try {
            const response = await clienteAxios.get('empleados/agenda-activa');
            if (response.data.data) {
                dispatch( getEmpleadosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getEmpleadosError(error) );
        }
    }
}

const getEmpleados = () => ({
    type: Types.LISTA_EMPLEADOS
})

const getEmpleadosExito = empleados => ({
    type: Types.LISTA_EMPLEADOS_EXITO,
    payload: empleados
})

const getEmpleadosError = error => ({
    type: Types.LISTA_EMPLEADOS_ERROR,
    payload: error
})

export function comenzarAgregarEmpleadoAction() {
    return (dispatch) => {
        dispatch( comenzarAgregarEmpleado() );
    }
}

const comenzarAgregarEmpleado = () => ({
    type: Types.COMENZAR_AGREGAR_EMPLEADO
})

export function agregarEmpleadoAction(empleado) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarEmpleado() );
        try {
            const response = await clienteAxios.post('empleados/agregar', empleado);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarEmpleadoExito(response.data.data) );
                empleado.nombre = '';
                empleado.apellido_paterno = '';
                empleado.apellido_materno = '';
                empleado.correo_electronico = '';
                empleado.genero = '';
                empleado.telefono = '';
                empleado.fecha_nacimiento = '';
                empleado.comision = 0;
            }
        } catch (error) {
            dispatch( agregarEmpleadoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarEmpleado = () => ({
    type: Types.AGREGAR_EMPLEADO
})

const agregarEmpleadoExito = empleado => ({
    type: Types.AGREGAR_EMPLEADO_EXITO,
    payload: empleado
})

const agregarEmpleadoError = error => ({
    type: Types.AGREGAR_EMPLEADO_ERROR,
    payload: error
})

export function cerrarModalAgregarAction() {
    return (dispatch) => {
        dispatch( cerrarModalAgregar() );
    }
}

const cerrarModalAgregar = () => ({
    type: Types.CERRAR_MODAL_AGREGAR_EMPLEADO
})

export function comenzarEditarEmpleadoAction(empleado) {
    return (dispatch) => {
        dispatch( comenzarEditarEmpleado(empleado) );
    }
}

const comenzarEditarEmpleado = empleado => ({
    type: Types.COMENZAR_EDITAR_EMPLEADO,
    payload: empleado
})

export function editarEmpleadoAction(empleado) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarEmpleado() );
        try {
            const response = await clienteAxios.put('empleados/actualizar/'+empleado.id, empleado);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarEmpleadoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( editarEmpleadoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const editarEmpleado = () => ({
    type: Types.EDITAR_EMPLEADO
})

const editarEmpleadoExito = empleado => ({
    type: Types.EDITAR_EMPLEADO_EXITO,
    payload: empleado
})

const editarEmpleadoError = error => ({
    type: Types.EDITAR_EMPLEADO_ERROR,
    payload: error
})

export function cerrarModalEditarAction() {
    return (dispatch) => {
        dispatch( cerrarModalEditar() );
    }
}

const cerrarModalEditar = () => ({
    type: Types.CERRAR_MODAL_EDITAR_EMPLEADO
})

export function eliminarEmpleadoAction(empleado) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('empleados/eliminar/'+empleado.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarEmpleadoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarEmpleadoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarEmpleadoExito = empleado => ({
    type: Types.ELIMINAR_EMPLEADO_EXITO,
    payload: empleado
})

const eliminarEmpleadoError = error => ({
    type: Types.ELIMINAR_EMPLEADO_ERROR,
    payload: error
})


export function comenzarListaComisionesAction(empleado) {
    return (dispatch) => {
        dispatch( comenzarListaComisiones(empleado) );
    }
}

const comenzarListaComisiones = (empleado) => ({
    type: Types.COMENZAR_LISTA_COMISIONES_EMPLEADO,
    payload: empleado
})

export function getEmpleadosComisionesAction(empleado) {
    return async (dispatch) => {
        dispatch( getEmpleadosComisiones() );
        try {
            const response = await clienteAxios.get('empleados/comisiones/'+empleado.id);
            if (response.data.data) {
                dispatch( getEmpleadosComisionesExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getEmpleadosComisionesError(error) );
        }
    }
}

const getEmpleadosComisiones = () => ({
    type: Types.LISTA_COMISIONES_EMPLEADO
})

const getEmpleadosComisionesExito = comisiones => ({
    type: Types.LISTA_COMISIONES_EXITO,
    payload: comisiones
})

const getEmpleadosComisionesError = error => ({
    type: Types.LISTA_COMISIONES_ERROR,
    payload: error
})

export function cerrarModalComisionesAction() {
    return (dispatch) => {
        dispatch( cerrarModalComisiones() );
    }
}

const cerrarModalComisiones = () => ({
    type: Types.CERRAR_MODAL_COMISIONES_EMPLEADO
})