import {
    LISTA_ROLES,
    LISTA_ROLES_EXITO,
    LISTA_ROLES_ERROR,
    COMENZAR_AGREGAR_ROL,
    AGREGAR_ROL,
    AGREGAR_ROL_EXITO,
    AGREGAR_ROL_ERROR,
    CERRAR_MODAL_AGREGAR_ROL,
    COMENZAR_EDITAR_ROL,
    EDITAR_ROL,
    EDITAR_ROL_EXITO,
    EDITAR_ROL_ERROR,
    CERRAR_MODAL_EDITAR_ROL,
    COMENZAR_ELIMINAR_ROL,
    ROL_ELIMINADO_EXITO,
    ROL_ELIMINADO_ERROR,
    COMENZAR_PERMISOS_ROL,
    CERRAR_MODAL_PERMISOS_ROL
} from '../types';

const initialState = {
    roles: [],
    rol: {},
    loading: false,
    loadingAgregar: false,
    loadingEditar: false,
    loadingPermisos: false,
    showModalAgregar: false,
    showModalEditar: false,
    showModalPermisos: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LISTA_ROLES:
            return {
                ...state,
                loading: true
            }
        case LISTA_ROLES_EXITO:
            return {
                ...state,
                loading: false,
                roles: action.payload
            }
        case LISTA_ROLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case COMENZAR_AGREGAR_ROL:
            return {
                ...state,
                showModalAgregar: true
            }
        case AGREGAR_ROL:
            return {
                ...state,
                loadingAgregar: true
            }
        case AGREGAR_ROL_EXITO:
            return {
                ...state,
                roles: state.roles.concat(action.payload),
                showModalAgregar: false,
                loadingAgregar: false
            }
        case AGREGAR_ROL_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingAgregar: false
            }
        case CERRAR_MODAL_AGREGAR_ROL:
            return {
                ...state,
                showModalAgregar: false
            }
        case CERRAR_MODAL_EDITAR_ROL:
            return {
                ...state,
                showModalEditar: false
            }
        case COMENZAR_EDITAR_ROL:
            return {
                ...state,
                showModalEditar: true,
                rol: action.payload
            }
        case EDITAR_ROL:
            return {
                ...state,
                loadingEditar: true
            }
        case EDITAR_ROL_EXITO:
            return {
                ...state,
                loadingEditar: false,
                rol: action.payload,
                roles: state.roles.map( rol => 
                    rol.id === action.payload.id ? rol = action.payload : rol
                ),
                showModalEditar: false
            }
        case EDITAR_ROL_ERROR:
            return {
                ...state,
                loadingEditar: false,
                error: action.payload
            }
        case COMENZAR_ELIMINAR_ROL:
            return {
                ...state,
                rol: action.payload
            }
        case ROL_ELIMINADO_EXITO:
            return {
                ...state,
                roles: state.roles.filter( rol => 
                    rol.id != state.rol.id
                ),
                rol: {}
            }
        case ROL_ELIMINADO_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case COMENZAR_PERMISOS_ROL:
            return {
                ...state,
                showModalPermisos: true,
                rol: action.payload
            }
        case CERRAR_MODAL_PERMISOS_ROL:
            return {
                ...state,
                showModalPermisos: false,
                rol: {}
            }
        default:
            return state;
    }
}