import {
    LISTA_ROLES_MENU,
    LISTA_ROLES_MENU_EXITO,
    LISTA_ROLES_MENU_ERROR,
    SET_SELECTED_ROL_MENUS,
    CERRAR_MODAL_ROLES_MENUS,
    ACTUALIZAR_ROLES_MENUS_ERROR,
    ACTUALIZAR_ROLES_MENUS_EXITO,
} from '../types';

const initialState = {
    rol: {},
    rol_menus: [],
    loading: false,
    showModalMenu: false,
    selected: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LISTA_ROLES_MENU:
            return {
                ...state,
                loading: true,
                showModalMenu: true,
                rol: action.payload
            }
        case LISTA_ROLES_MENU_EXITO:
            return {
                ...state,
                loading: false,
                rol_menus: action.payload,
                selected: action.payload.map(function (rolMenu) { return rolMenu.id_menu })
            }
        case LISTA_ROLES_MENU_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                selected: []
            } 
        case SET_SELECTED_ROL_MENUS:
            return {
                ...state,
                selected: action.payload
            }
        case ACTUALIZAR_ROLES_MENUS_EXITO:
            return {
                ...state,
                rol_menus: action.payload
            }
        case ACTUALIZAR_ROLES_MENUS_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case CERRAR_MODAL_ROLES_MENUS:
            return {
                ...state,
                showModalMenu: false
            }
        default:
            return state;
    }
}