import React, { useEffect, useState } from "react";
import { Card, Breadcrumb } from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../../components/DataTable";

import { ConfirmModal } from "../../../components/ConfirmModal";
import clienteAxios from "../../../config/axios";
import PuestosLaboralesForm from "./PuestosLaboralesForm";
import Swal from "sweetalert2";

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [puestos, setPuestos] = useState([]);
  const [selected, setSelected] = useState({});
  const [accionForm, setAccionForm] = useState("agregar");

  useEffect(() => {
    getSucursales();
  }, []);

  const getSucursales = () => {
    clienteAxios.get("puestos-laborales").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setPuestos(respuesta.data.data);
      }
    });
  };

  const columns = [
    {
      key: "id",
      text: "ID",
      sortable: true,
    },
    {
      key: "nombre",
      text: "Nombre",
      sortable: true,
    },
    {
      key: "descripcion",
      text: "Descripcion"
    }
  ];

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar puesto",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo
        </span>,
      ],
      onClick: (event) => {
        setShowModal(true);
        setAccionForm("agregar");
      },
    },
  ];

  const editRecord = (record) => {
    setSelected(record);
    setShowModal(true);
    setAccionForm("editar");
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: eliminarPuesto,
        args: [record.id],
      },
    ];

    ConfirmModal(callbacks);
  };

  const eliminarPuesto = (id) => {
    clienteAxios.delete("puestos-laborales/" + id).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        setPuestos(puestos.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Puestos laborales</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Puestos laborales</h5>
          <DataTable
            columns={columns}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            state={{ records: puestos }}
            extraButtons={extraButtons}
          />
        </Card.Body>
      </Card>

      <PuestosLaboralesForm
        accion={accionForm}
        showModal={showModal}
        selectedRow={selected}
        onUpdate={(rowSuccess) => {
          setPuestos(
            puestos.map((row) =>
              row.id === rowSuccess.id ? (row = rowSuccess) : row
            )
          );

          setShowModal(false)
        }}
        onCreate={(rowSuccess) => {
          setPuestos(puestos.concat(rowSuccess));

          setShowModal(false)
        }}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};
