import {
    LISTA_MODULOS,
    LISTA_MODULOS_EXITO,
    LISTA_MODULOS_ERROR
} from '../types';

const initialState = {
    modulos: [],
    loading: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LISTA_MODULOS:
            return {
                ...state,
                loading: true
            }
        case LISTA_MODULOS_EXITO:
            return {
                ...state,
                loading: false,
                modulos: action.payload
            }
        case LISTA_MODULOS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}