import * as Types from '../types';

const initialState = {
    inventario: [],
    loadingInventario: false,
    loadingEntradaSalida: false,
    showModalEntradaSalida: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_INVENTARIO:
            return {
                ...state,
                loadingInventario: true
        }
        case Types.LISTA_INVENTARIO_EXITO:
            return {
                ...state,
                loadingInventario: false,
                inventario: action.payload
        }
        case Types.MODAL_REGISTRAR_ENTRADA_SALIDA:
            return {
                ...state,
                showModalEntradaSalida: action.payload
        }
        case Types.REGISTRAR_ENTRADA_SALIDA:
            return {
                ...state,
                loadingEntradaSalida: true,
        }
        case Types.REGISTRAR_ENTRADA_SALIDA_EXITO:
            return {
                ...state,
                loadingEntradaSalida: false,
                inventario: action.payload,
                showModalEntradaSalida: false
        }
        default:
            return state;
    }
}