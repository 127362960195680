import React, { useState, useEffect } from "react";
import { Row, Col, Nav, Modal, Button, Tab, Table, Form} from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { actualizarPermisoRol, cerrarModalPermisosAction } from '../../actions/rolesActions';

export default (props) => {
    const dispatch = useDispatch();
    const [state, setState] = useState('')

    const { rol: selectedRol, roles, showModalPermisos } = useSelector(state => state.roles);
    const { modulos } = useSelector(state => state.modulos);

    const handleClose = () => {
        dispatch( cerrarModalPermisosAction() );
    }

    const getValoresPermiso = function (id_modulo, id_submodulo = null) {
        let valores = {
            crear: false,
            actualizar: false,
            borrar: false,
            consultar: false
        };

        if(Object.keys(selectedRol).length == 0) {
            return valores;
        }

        if(selectedRol.permisos.length > 0) {
            selectedRol.permisos.forEach(permiso => {
                if(permiso.id_modulo == id_modulo && permiso.id_submodulo == id_submodulo) {
                    valores = permiso;
                }
            })
        }
        
        return valores;
    }

    const actualizarPermiso = (e, tipo, id_modulo, id_submodulo = 0) => {
        setState({
            [e.target.name] : e.target.value
        })
        
        const permiso = {
            id_rol: selectedRol.id,
            id_modulo: id_modulo,
            id_submodulo: id_submodulo,
        };

        permiso[tipo] = e.target.checked;

        dispatch( actualizarPermisoRol(permiso) );
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModalPermisos} onHide={handleClose} size="lg">
            <Modal.Header>
                <Modal.Title className="h6">Permisos rol</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                {
                    modulos.length > 0 ?
                        <Tab.Container defaultActiveKey={modulos.length > 0 ? modulos[0].identificador_str : ""}>
                            <Row>
                                <Col lg={12}>
                                <Nav className="nav-tabs">
                                {modulos.map((modulo, index) => (
                                    <Nav.Item>
                                        <Nav.Link key={index} eventKey={modulo.identificador_str} className="mb-sm-3 mb-md-0">
                                            {modulo.nombre}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                                </Nav>
                                </Col>
                                <Col lg={12}>
                                <Tab.Content>
                                    {
                                    modulos.map((modulo, index) => (
                                        <Tab.Pane key={index} eventKey={modulo.identificador_str} className="py-4">
                                            <Table hover className="user-table align-items-center">
                                                <thead>
                                                    <tr>
                                                    <th className="border-bottom"></th>
                                                    <th className="border-bottom">Crear</th>
                                                    <th className="border-bottom">Actualizar</th>
                                                    <th className="border-bottom">Borrar</th>
                                                    <th className="border-bottom">Consultar</th>
                                                    <th className="border-bottom">Notificación</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                     <tr>
                                                        <th scope="row">{modulo.nombre}</th>
                                                        <td>
                                                            <Form.Check style={{"marginLeft": "20px"}} 
                                                                id={`crear-modulo-${modulo.id}`} 
                                                                htmlFor={`crear-modulo-${modulo.id}`}
                                                                defaultChecked={getValoresPermiso(modulo.id).crear}
                                                                onChange={event => { actualizarPermiso(event, 'crear', modulo.id) }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check style={{"marginLeft": "20px"}}
                                                                id={`actualizar-modulo-${modulo.id}`} 
                                                                htmlFor={`actualizar-modulo-${modulo.id}`}
                                                                defaultChecked={getValoresPermiso(modulo.id).actualizar}
                                                                onChange={event => { actualizarPermiso(event, 'actualizar', modulo.id) }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check style={{"marginLeft": "20px"}} 
                                                                id={`borrar-modulo-${modulo.id}`} 
                                                                htmlFor={`borrar-modulo-${modulo.id}`}
                                                                defaultChecked={getValoresPermiso(modulo.id).borrar}
                                                                onChange={event => { actualizarPermiso(event, 'borrar', modulo.id) }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check style={{"marginLeft": "20px"}} 
                                                                id={`consultar-modulo-${modulo.id}`} 
                                                                htmlFor={`consultar-modulo-${modulo.id}`}
                                                                defaultChecked={getValoresPermiso(modulo.id).consultar}
                                                                onChange={event => { actualizarPermiso(event, 'consultar', modulo.id) }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check style={{"marginLeft": "20px"}} 
                                                                id={`notificacion-modulo-${modulo.id}`} 
                                                                htmlFor={`notificacion-modulo-${modulo.id}`}
                                                                defaultChecked={getValoresPermiso(modulo.id).notificacion}
                                                                onChange={event => { actualizarPermiso(event, 'notificacion', modulo.id) }}
                                                            />
                                                        </td>
                                                    </tr>
                                                    {
                                                        modulo.submodulos.map((submodulo, index) => (
                                                            <tr>
                                                                <th scope="row">{submodulo.nombre}</th>
                                                                <td>
                                                                    <Form.Check style={{"marginLeft": "20px"}} 
                                                                        id={`crear-submodulo-${submodulo.id}`} 
                                                                        htmlFor={`crear-submodulo-${submodulo.id}`}
                                                                        defaultChecked={getValoresPermiso(submodulo.id_modulo, submodulo.id).crear}
                                                                        onChange={event => { actualizarPermiso(event, 'crear', submodulo.id_modulo, submodulo.id) }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Form.Check style={{"marginLeft": "20px"}} 
                                                                        id={`actualizar-submodulo-${submodulo.id}`} 
                                                                        htmlFor={`actualizar-submodulo-${submodulo.id}`}
                                                                        defaultChecked={getValoresPermiso(submodulo.id_modulo, submodulo.id).actualizar}
                                                                        onChange={event => { actualizarPermiso(event, 'actualizar', submodulo.id_modulo, submodulo.id) }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Form.Check style={{"marginLeft": "20px"}} 
                                                                        id={`borrar-submodulo-${submodulo.id}`} 
                                                                        htmlFor={`borrar-submodulo-${submodulo.id}`}
                                                                        defaultChecked={getValoresPermiso(submodulo.id_modulo, submodulo.id).borrar}
                                                                        onChange={event => { actualizarPermiso(event, 'borrar', submodulo.id_modulo, submodulo.id) }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Form.Check style={{"marginLeft": "20px"}} 
                                                                        id={`consultar-submodulo-${submodulo.id}`} 
                                                                        htmlFor={`consultar-submodulo-${submodulo.id}`}
                                                                        defaultChecked={getValoresPermiso(submodulo.id_modulo, submodulo.id).consultar}
                                                                        onChange={event => { actualizarPermiso(event, 'consultar', submodulo.id_modulo, submodulo.id) }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Form.Check style={{"marginLeft": "20px"}} 
                                                                        id={`notificacion-submodulo-${submodulo.id}`} 
                                                                        htmlFor={`notificacion-submodulo-${submodulo.id}`}
                                                                        defaultChecked={getValoresPermiso(submodulo.id_modulo, submodulo.id).notificacion}
                                                                        onChange={event => { actualizarPermiso(event, 'notificacion', submodulo.id_modulo, submodulo.id) }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    :   
                        <div class="spinner-border text-info mx-auto d-block" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}