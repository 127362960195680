import * as Types from '../types';

import clienteAxios from '../config/axios';
import { loadingRequest } from './axiosActions';

export function busquedaFiltroAction(modelo, request, info = true) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( busquedaFiltro() );
        try {
            request = { ...request, info: info };
            
            const response = await clienteAxios.get('filtro/' +modelo, { params: request });
            if (response.data.data) {
                dispatch( busquedaFiltroExito(response.data.data) );
            }
        } catch (error) {
            dispatch( busquedaFiltroError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const busquedaFiltro = () => ({
    type: Types.BUSQUEDA_FILTRO
})

const busquedaFiltroExito = respuesta => ({
    type: Types.BUSQUEDA_FILTRO_EXITO,
    payload: respuesta
})

const busquedaFiltroError = error => ({
    type: Types.BUSQUEDA_FILTRO_ERROR,
    payload: error
})

export function limpiarFiltroAction() {
    return (dispatch) => {
        dispatch( limpiarFiltro() );
        dispatch( setCriteria([]) );
        dispatch( setRequest({}) );
    }
}

const limpiarFiltro = () => ({
    type: Types.LIMPIAR_FILTRO_BUSQUEDA
})

export function eliminarRegistroFiltroAction(id) {
    return (dispatch) => {
        dispatch( eliminarRegistroFiltro(id) );
    }
}

const eliminarRegistroFiltro = id => ({
    type: Types.LIMPIAR_FILTRO_BUSQUEDA,
    payload: id
})

export function setCriteriaAction(criteria) {
    return (dispatch) => {
        dispatch( setCriteria(criteria) );
    }
}

const setCriteria = (criteria) => ({
    type: Types.SET_CRITERIA_FILTRO,
    payload: criteria
})

export function setRequestAction(request) {
    return (dispatch) => {
        dispatch( setRequest(request) );
    }
}

const setRequest = (request) => ({
    type: Types.SET_REQUEST_FILTRO,
    payload: request
})

export function actualizarBusquedaFiltroAction(data) {
    return (dispatch) => {
        dispatch( actualizarBusquedaFiltro(data) );
    }
}

const actualizarBusquedaFiltro = (data) => ({
    type: Types.BUSQUEDA_FILTRO_ACTUALIZACION,
    payload: data
})

export function actualizarRowFiltroAction(expresion, data) {
    return (dispatch) => {
        dispatch( actualizarRowFiltro(expresion, data) );
    }
}

const actualizarRowFiltro = (expresion, data) => ({
    type: Types.ACTUALIZAR_ROW_FILTRO,
    payload: data,
    expresion: expresion
})

export function eliminarRowFiltroAction(expresion) {
    return (dispatch) => {
        dispatch( eliminarRowFiltro(expresion) );
    }
}

const eliminarRowFiltro = (expresion) => ({
    type: Types.ELIMINAR_ROW_FILTRO,
    expresion: expresion
})