import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getCategoriasProductosAction() {
    return async (dispatch) => {
        dispatch( getCategoriasProductos() );
        try {
            const response = await clienteAxios.get('productos/categorias/list');
            if (response.data.data) {
                dispatch( getCategoriasProductosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getCategoriasProductosError(error) );
        }
    }
}

const getCategoriasProductos = () => ({
    type: Types.LISTA_CATEGORIAS_PRODUCTOS
})

const getCategoriasProductosExito = categorias => ({
    type: Types.LISTA_CATEGORIAS_PRODUCTOS_EXITO,
    payload: categorias
})

const getCategoriasProductosError = error => ({
    type: Types.LISTA_CATEGORIAS_PRODUCTOS_ERROR,
    payload: error
})

export function agregarCategoriaProductoAction(categoria) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarCategoriaProducto() );
        try {
            const response = await clienteAxios.post('productos/categorias/agregar', categoria);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarCategoriaProductoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( agregarCategoriaProductoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarCategoriaProducto = () => ({
    type: Types.AGREGAR_CATEGORIA_PRODUCTO
})

const agregarCategoriaProductoExito = categoria => ({
    type: Types.AGREGAR_CATEGORIA_PRODUCTO_EXITO,
    payload: categoria
})

const agregarCategoriaProductoError = error => ({
    type: Types.AGREGAR_CATEGORIA_PRODUCTO_ERROR,
    payload: error
})

export function showModalEditarAction(estatus) {
    return (dispatch) => {
        dispatch( showModalEditar(estatus) );
    }
}

const showModalEditar = estatus => ({
    type: Types.MODAL_EDITAR_CATEGORIA_PRODUCTO,
    payload: estatus
})

export function showModalAgregarAction(estatus) {
    return (dispatch) => {
        dispatch( showModalAgregar(estatus) );
    }
}

const showModalAgregar = estatus => ({
    type: Types.MODAL_AGREGAR_CATEGORIA_PRODUCTO,
    payload: estatus
})


export function comenzarEditarCategoriaProductoAction(categoria) {
    return (dispatch) => {
        dispatch( comenzarEditarCategoriaProducto(categoria) );
    }
}

const comenzarEditarCategoriaProducto = categoria => ({
    type: Types.COMENZAR_EDITAR_CATEGORIA_PRODUCTO,
    payload: categoria
})

export function editarCategoriaProductoAction(categoria) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarCategoriaProducto() );
        try {
            const response = await clienteAxios.put('productos/categorias/actualizar/'+categoria.id, categoria);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarCategoriaProductoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( editarCategoriaProductoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const editarCategoriaProducto = () => ({
    type: Types.EDITAR_CATEGORIA_PRODUCTO
})

const editarCategoriaProductoExito = categoria => ({
    type: Types.EDITAR_CATEGORIA_PRODUCTO_EXITO,
    payload: categoria
})

const editarCategoriaProductoError = error => ({
    type: Types.EDITAR_CATEGORIA_PRODUCTO_ERROR,
    payload: error
})

export function eliminarCategoriaProductoAction(categoria) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('productos/categorias/eliminar/'+categoria.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch(eliminarCategoriaProductoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarCategoriaProductoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarCategoriaProductoExito = (categoria) => ({
    type: Types.ELIMINAR_CATEGORIA_PRODUCTO_EXITO,
    payload: categoria
})

const eliminarCategoriaProductoError = error => ({
    type: Types.ELIMINAR_CATEGORIA_PRODUCTO_ERROR,
    payload: error
})