import * as Types from '../types';

const initialState = {
    impuestos: [],
    loadingImpuestos: false,
    loadingAgregarImpuesto: false,
    loadingActualizarImpuesto: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_IMPUESTOS:
            return {
                ...state,
                loadingImpuestos: true
            }
        case Types.LISTA_IMPUESTOS_EXITO:
            return {
                ...state,
                loadingImpuestos: false,
                impuestos: action.payload
            }
        case Types.LISTA_IMPUESTOS_ERROR:
            return {
                ...state,
                loadingImpuestos: false,
                error: action.payload
            }
        case Types.AGREGAR_IMPUESTO:
            return {
                ...state,
                loadingAgregarImpuesto: true
            }
        case Types.AGREGAR_IMPUESTO_EXITO:
            return {
                ...state,
                impuestos: state.impuestos.concat(action.payload),
                loadingAgregarImpuesto: false
            }
        case Types.AGREGAR_IMPUESTO_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingAgregarImpuesto: false
            }
        case Types.ACTUALIZAR_IMPUESTO:
            return {
                ...state,
                loadingActualizarImpuesto: true
            }
        case Types.ACTUALIZAR_IMPUESTO_EXITO:
            return {
                ...state,
                impuestos: state.impuestos.map( impuesto => 
                    impuesto.id === action.payload.id ? impuesto = action.payload : impuesto
                ),
                loadingActualizarImpuesto: false
            }
        case Types.ACTUALIZAR_IMPUESTO_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingActualizarImpuesto: false
            }
        case Types.ELIMINAR_IMPUESTO_EXITO:
            return {
                ...state,
                impuestos: state.impuestos.filter( impuesto => 
                    impuesto.id != action.payload.id
                )
            }
        case Types.ELIMINAR_IMPUESTO_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.ACTUALIZAR_INPUT_IMPUESTO:
            return {
                ...state,
                impuestos: state.impuestos.map( impuesto =>
                    impuesto.id === action.payload.id ? impuesto = action.payload : impuesto
                )
            }
        default:
            return state;
    }
}