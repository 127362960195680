import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '@mkikets/react-datatable';
import { busquedaFiltroAction } from "../../actions/filtroActions";
import Filtro from "../../components/Filtro";
import { columnaSucursalDT, formatAMPM } from "../../helpers";
import { confirmarCitaAction } from "../../actions/agendaActions";

export default (props) => {
  const dispatch = useDispatch();
  const { loadingFiltro, respuestaFiltro, criteria, request } = useSelector(
    (state) => state.filtro
  );
  const modelo = "Agenda.Agenda";
  const criteriasObligatorios = ["order-id", "historial-citas", "agenda"];

  const [requestFiltro, guardarRequestFiltro] = useState({
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    dispatch(
      busquedaFiltroAction(modelo, {
        offset: 0,
        limit: 10,
        criteria: ["all", "order-id"],
      })
    );
  }, []);

  const columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      key: "uuid",
      text: "Folio",
      align: "left",
    },
    {
      text: "Fecha",
      sortable: true,
      cell: (record) => {
        return record.fecha.split("T")[0];
      },
    },
    {
      text: "Hora",
      sortable: true,
      cell: (record) => {
        const date = new Date(Date.parse(record.fecha));
        return formatAMPM(date, true);
      },
    },
    {
      text: "Doctor",
      sortable: true,
      cell: (record) => {
        return record.empleado?.nombre + " " + record.empleado?.apellido_paterno;
      },
    },
    {
      text: "Confirmado",
      sortable: false,
      cell: (record) => {
        return record.confirmado ? (
          <i class="fas fa-check-circle fa-2x" style={{ color: "green" }}></i>
        ) : (
          <i class="fas fa-times-circle fa-2x" style={{ color: "red" }}></i>
        );
      },
    },
    {
      text: "Teléfono",
      sortable: false,
      cell: (record) => {
        return record.cliente.telefono;
      },
    },
    {
      key: "nota",
      text: "Nota",
      align: "left",
      sortable: false,
    },
    columnaSucursalDT(),
    {
      text: "Acciones",
      sortable: false,
      cell: (record) => {
        return (
        <>
          {record.confirmado === false && (
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Confirmar cita</Tooltip>}
              >
                <button
                  onClick={() => confirmarCita(record)}
                  className="btn btn-success btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-check"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          )}
        </>
        );
      },
    },
  ];

  const confirmarCita = (agenda) => {
    dispatch(confirmarCitaAction(agenda)).then((response) => {
      dispatch(
        busquedaFiltroAction(modelo, {
          offset: requestFiltro.offset,
          limit: requestFiltro.limit,
          criteria: criteria,
          valor: request.valor,
        })
      );
    });
  };

  const opcionesFiltro = [
    { valor: "id", label: "ID", tipo: "number", criteria: ["equal"] },
    {
      valor: "fecha",
      label: "Fecha",
      tipo: "date",
      criteria: ["date"],
    },
    {
      valor: "uuid",
      label: "Folio",
      tipo: "text",
    },
    {
      valor: "estatus_confirmacion",
      label: "Estatus de confirmación",
      tipo: "options",
      opciones: [
        { valor: "confirmado", label: "Confirmado" },
        { valor: "no_confirmado", label: "No confirmado" },
      ],
    },
    {
      valor: "empleado",
      label: "Empleado",
      criteria: ["empleado"],
      tipo: "text",
    },
  ];

  const onChangeDT = (event) => {
    requestFiltro.limit = event.page_size;
    requestFiltro.offset = event.page_size * (event.page_number - 1);
    guardarRequestFiltro({ ...requestFiltro });

    dispatch(
      busquedaFiltroAction(modelo, {
        offset: requestFiltro.offset,
        limit: requestFiltro.limit,
        criteria: criteria,
        valor: request.valor,
      })
    );
  };

  const configDT = {
    show_filter: false,
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Historial citas</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Historial citas</h5>
          <Filtro
            opciones={opcionesFiltro}
            modelo={modelo}
            criteriasObligatorios={criteriasObligatorios}
            request={requestFiltro}
          />
          <hr></hr>
          <ReactDatatable
            hover
            records={respuestaFiltro.data ?? []}
            columns={columns}
            dynamic
            total_record={respuestaFiltro.count ?? 0}
            onChange={onChangeDT}
            loading={loadingFiltro}
            config={configDT}
          />
        </Card.Body>
      </Card>
    </>
  );
};
