import React, { useState } from "react";
import { Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { 
    setSelectedAction, 
    actualizarRolesUsuarioAction, 
    cerrarModalAction 
} from '../../actions/usuarioRolesActions';

export default (props) => {
    const dispatch = useDispatch();

    const { usuario, selected, showModal, loading } = useSelector(state => state.usuarioRoles);
    const { roles } = useSelector(state => state.roles);


    const submitUserRoles = e => {
        e.preventDefault();
        dispatch( actualizarRolesUsuarioAction(usuario, selected) )
    }

    const options = roles.map(function (rol) { return {value: rol.id, label: rol.nombre} });

    const onChange = (selected) => {
        dispatch(setSelectedAction(selected))
    };

    const handleClose = () => {
        dispatch( cerrarModalAction() );
    }
    
    return (
        <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Roles del usuario</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitUserRoles}>
            <Modal.Body>
            {loading &&
                <div class="spinner-border text-info mx-auto d-block" role="status">
                    <span class="visually-hidden">Cargando...</span>
                </div>
            }

            {!loading &&
                <DualListBox
                    options={options}
                    selected={selected}
                    onChange={onChange}
                />
            }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary">
                    Guardar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}