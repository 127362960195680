import {
    OBTENER_ROLES_USUARIO,
    OBTENER_ROLES_USUARIO_EXITO,
    OBTENER_ROLES_USUARIO_ERROR,
    SET_SELECTED_USER_ROLES,
    ACTUALIZAR_ROLES_USUARIO_EXITO,
    ACTUALIZAR_ROLES_USUARIO_ERROR,
    CERRAR_MODAL_ROLES_USUARIO
} from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function obtenerRolesUsuarioAction(usuario) {
    return async (dispatch) => {
        dispatch( obtenerRolesUsuario(usuario) )

        try {
            const response = await clienteAxios.get('roles/user/list?id_user='+usuario.id);
            if (response.data.data) {
                dispatch( obtenerRolesUsuarioExito(response.data.data) );
            }
        } catch (error) {
            dispatch( obtenerRolesUsuarioError(error) );
        }
    }
}

const obtenerRolesUsuario = usuario => ({
    type: OBTENER_ROLES_USUARIO,
    payload: usuario
})

const obtenerRolesUsuarioExito = roles => ({
    type: OBTENER_ROLES_USUARIO_EXITO,
    payload: roles
})

const obtenerRolesUsuarioError = error => ({
    type: OBTENER_ROLES_USUARIO_ERROR,
    payload: error
})

export function setSelectedAction(selected) {
    return (dispatch) => {
        dispatch(setSelected(selected));
    }
}

const setSelected = selected => ({
    type: SET_SELECTED_USER_ROLES,
    payload: selected
})

export function actualizarRolesUsuarioAction(usuario, id_roles) {
    return async (dispatch) => {
        try {
            const response = await clienteAxios.put('roles/user/actualizar', 
                { id_usuario: usuario.id, roles: id_roles }
            );

            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( actualizarRolesUsuarioExito(response.data.data) );
            }
        } catch (error) {
            dispatch( actualizarRolesUsuarioError(error) );
        }
    }
}

const actualizarRolesUsuarioExito = userRoles => ({
    type: ACTUALIZAR_ROLES_USUARIO_EXITO,
    payload: userRoles
})

const actualizarRolesUsuarioError = error => ({
    type: ACTUALIZAR_ROLES_USUARIO_ERROR,
    payload: error
})

export function cerrarModalAction() {
    return async (dispatch) => {
        dispatch( cerrarModal() )
    }
}

const cerrarModal = () => ({
    type: CERRAR_MODAL_ROLES_USUARIO
})