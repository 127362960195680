import React, { useState } from 'react'
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  showModalAgregarAction,
  agregarCategoriaProductoAction
} from '../../actions/categoriasProductosActions'

export default props => {
  const dispatch = useDispatch()

  const { showModalAgregarCategoria } = useSelector(state => state.categoriasProductos)

  const [edicion, guardar] = useState({
    nombre: '',
  })

  const submitForm = e => {
    e.preventDefault()
    dispatch(agregarCategoriaProductoAction(edicion))
  }

  const handleClose = () => {
    dispatch(showModalAgregarAction(false))
  }

  const onChangeForm = e => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalAgregarCategoria}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className='h6'>Agregar categoria</Modal.Title>
        <Button variant='close' aria-label='Close' onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type='text'
              name='nombre'
              placeholder='Nombre categoria'
              onChange={onChangeForm}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='link'
            className='text-gray ms-auto'
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type='submit' variant='secondary'>
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
