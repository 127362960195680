import React, { useState, useEffect, useContext } from "react";
import ChatListItem from "../../components/CRM/WhatsappChat/ChatListItem";

import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import ChatIcon from "@mui/icons-material/Chat";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import ChatSection from "../../components/CRM/WhatsappChat/ChatSection";
import clienteAxios from "../../config/axios";
import moment from "moment-timezone";
import { SocketContext } from "../../context/socket";

export default () => {
  const [chatList, setChatList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chatSelected, setChatSelected] = useState(null);
  const echo = useContext(SocketContext);

  useEffect(() => {
    getChats();
  }, []);

  useEffect(() => {
    echo.private("Whatsapp.Chat").listen(".whatsapp.recibido", (data) => {
      const chatRecibido = data.mensaje.conversacion;
      const chatFiltro = chatList.filter(chat => chat.id == chatRecibido.id);
      if (chatRecibido && chatFiltro.length == 0) {
        setChatList([
          chatRecibido,
          ...chatList
        ]);
      } else {
        setChatList(
          chatList.map((chat) => {
            if (chat.id != data.mensaje.conversacion.id) {
              return chat;
            }

            return {
              ...chat,
              nuevos_mensajes:
                !chatSelected || (chatSelected && chatSelected.id != data.mensaje.conversacion.id)
                  ? true
                  : false,
              cantidad_mensajes_nuevos:
                !chatSelected || (chatSelected && chatSelected.id != data.mensaje.conversacion.id)
                  ? chat.cantidad_mensajes_nuevos + 1
                  : 0,
              ultimo_mensaje: data.mensaje,
              id_ultimo_mensaje: data.mensaje.id,
            };
          }).sort((a, b) => a.id_ultimo_mensaje > b.id_ultimo_mensaje)
        );
      }

      if (chatSelected && chatSelected.id == data.mensaje.conversacion.id) {
        setMessages(
          messages.concat({
            ...data.mensaje,
            type:
              data.mensaje.emisor == data.mensaje.conversacion.usuario1
                ? "received"
                : "sent",
            text: data.mensaje.mensaje,
            date: moment(data.mensaje.fecha_mensaje),
          })
        );
      }
    });
  }, [chatList, messages]);

  const getChats = () => {
    clienteAxios.get("wp-chats").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setChatList(respuesta.data.data);
      }
    });
  };

  const getMensajes = (chat) => {
    if (chatSelected) {
      echo.leave(`Whatsapp.Chat.${chatSelected.id}`);
    }

    setChatSelected(chat);

    echo.private(`Whatsapp.Chat.${chat.id}`).listen(".whatsapp.recibido", (data) => {
      marcarLeido(chat.id);
    });

    const remitente = chat.usuario1;

    clienteAxios.get("wp-chats/" + chat.id).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setMessages(
          respuesta.data.data.map((message) => {
            return {
              type: message.emisor == remitente ? "received" : "sent",
              text: message.mensaje,
              date: moment(message.fecha_mensaje),
            };
          })
        );

        marcarLeido(chat.id);
      }
    });
  };

  const marcarLeido = (idChat) => {
    clienteAxios.put("wp-chats/" + idChat + "/leido").then((respuesta) => {
      if (respuesta && respuesta.data.success === true) {
        setChatList(
          chatList.map((element) => {
            if (element.id != idChat) {
              return element;
            }

            return {
              ...element,
              nuevos_mensajes: false,
              cantidad_mensajes_nuevos: 0,
            };
          })
        );

        if(chatSelected && chatSelected.id == idChat) {
          setChatSelected({
            ...chatSelected,
            nuevos_mensajes: false,
            cantidad_mensajes_nuevos: 0
          })
        }
      }
    });
  }

  return (
    <div className="app-window">
      <div className="sideBar">
        {/*<header>
          <img className="header--avatar" src="assets/iconAvatar.png" alt="Avatar" />
          <div className="header--buttons">
            <div className="header--btn">
              <DonutLargeIcon />
            </div>
            <div className="header--btn">
              <ChatIcon />
            </div>
            <div className="header--btn">
              <MoreVertIcon />
            </div>
          </div>
        </header>
  */}

        <div className="search">
          <div className="search--input">
            <SearchIcon fontSize="small" style={{ color: `#919191` }} />
            <input type="search" placeholder="Buscar" />
          </div>
        </div>

        <div className="chatList">
          {chatList.map((item, key) => (
            <ChatListItem
              key={key}
              chat={item}
              onClick={() => getMensajes(item)}
              chatSelected={chatSelected}
            />
          ))}
        </div>
      </div>
      <ChatSection messages={messages} chat={chatSelected} setMessages={setMessages} />
    </div>
  );
};
