import * as Types from '../types';

const initialState = {
    paciente: {},
    pacientes: [],
    pacientesFinanciados: [],
    clienteTratamientos: [],
    abonos: [],
    loading: false,
    loadingAgregar: false,
    loadingEditar: false,
    loadingClienteTratamientos: false,
    loadingPacientesFinanciados: false,
    loadingAbonos: false,
    showModalAgregar: false,
    showModalEditar: false,
    showModalClienteTratamientos: false,
    showModalAbonos: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_PACIENTES:
            return {
                ...state,
                loading: true
            }
        case Types.LISTA_PACIENTES_EXITO:
            return {
                ...state,
                loading: false,
                pacientes: action.payload
            }
        case Types.LISTA_PACIENTES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case Types.COMENZAR_AGREGAR_PACIENTE:
            return {
                ...state,
                showModalAgregar: true
            }
        case Types.AGREGAR_PACIENTE:
            return {
                ...state,
                loadingAgregar: true
            }
        case Types.AGREGAR_PACIENTE_EXITO:
            return {
                ...state,
                loadingAgregar: false,
                pacientes: state.pacientes.concat(action.payload),
                showModalAgregar: false
            }
        case Types.AGREGAR_PACIENTE_ERROR:
            return {
                ...state,
                loadingAgregar: false,
                error: action.payload
            }
        case Types.CERRAR_MODAL_AGREGAR_PACIENTE:
            return {
                ...state,
                showModalAgregar: false
            }
        case Types.COMENZAR_EDITAR_PACIENTE:
            return {
                ...state,
                showModalEditar: true,
                paciente: action.payload
            }
        case Types.EDITAR_PACIENTE:
            return {
                ...state,
                loadingEditar: true
            }
        case Types.EDITAR_PACIENTE_EXITO:
            return {
                ...state,
                loadingEditar: false,
                pacientes: state.pacientes.map( paciente => 
                    paciente.id === action.payload.id ? paciente = action.payload : paciente
                )
            }
        case Types.EDITAR_PACIENTE_ERROR:
            return {
                ...state,
                loadingEditar: false,
                error: action.payload
            }
        case Types.CERRAR_MODAL_EDITAR_PACIENTE:
            return {
                ...state,
                showModalEditar: false
            }
        case Types.ELIMINAR_PACIENTE_EXITO:
            return {
                ...state,
                pacientes: state.pacientes.filter( paciente => 
                    paciente.id != action.payload.id
                )
            }
        case Types.ELIMINAR_PACIENTE_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case Types.MODAL_LISTA_CLIENTES_TRATAMIENTOS:
            return {
                ...state,
                showModalClienteTratamientos: action.payload,
            }
        case Types.LISTA_CLIENTES_TRATAMIENTOS:
            return {
                ...state,
                loadingClienteTratamientos: true
            }
        case Types.LISTA_CLIENTES_TRATAMIENTOS_EXITO:
            return {
                ...state,
                loadingClienteTratamientos: false,
                clienteTratamientos: action.payload
            }
        case Types.LISTA_ABONOS_TRATAMIENTOS:
            return {
                ...state,
                loadingAbonos: true
            }
        case Types.LISTA_ABONOS_TRATAMIENTOS_EXITO:
            return {
                ...state,
                loadingAbonos: false,
                abonos: action.payload
            }
        case Types.LISTA_PACIENTES_FINANCIADOS:
            return {
                ...state,
                loadingPacientesFinanciados: true,
                pacientesFinanciados: []
            }
        case Types.LISTA_PACIENTES_FINANCIADOS_EXITO:
            return {
                ...state,
                loadingPacientesFinanciados: false,
                pacientesFinanciados: action.payload
            }
        case Types.LISTA_PACIENTES_FINANCIADOS_ERROR:
            return {
                ...state,
                loadingPacientesFinanciados: false,
                error: action.payload
            }
        default:
            return state;
    }
}