import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
} from "@themesberg/react-bootstrap";
import { capitalize } from "../../../helpers";
import clienteAxios from "../../../config/axios";
import Swal from "sweetalert2";

export default (props) => {
  const [showModal, setShowModal] = useState(false);
  const [accion, setAccion] = useState("agregar");

  useEffect(() => {
    setShowModal(props.showModal);
    if (props.accion) {
      setAccion(props.accion);
    }

    guardar({
      ...edicion,
      ...props.selectedRow,
    });
  }, [props]);

  const [edicion, guardar] = useState({
    nombre: null,
    descripcion: null,
  });

  const submitForm = (e) => {
    e.preventDefault();

    if (accion == "editar") {
      clienteAxios
        .put("puestos-laborales/" + edicion.id, edicion)
        .then((respuesta) => {
          if (
            respuesta &&
            respuesta.data.data &&
            respuesta.data.success === true
          ) {
            props.onUpdate(respuesta.data.data);
            Swal.fire("¡Muy bien!", respuesta.data.message, "success");
          }
        });
      return;
    }

    if (accion == "agregar") {
      clienteAxios.post("puestos-laborales", edicion).then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          props.onCreate(respuesta.data.data);
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">
          {capitalize(accion)} Puesto laboral
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Nombre*</Form.Label>
            <Form.Control
              type="text"
              name="nombre"
              placeholder="Nombre"
              value={edicion.nombre}
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              name="descripcion"
              placeholder="Descripción"
              value={edicion.descripcion}
              onChange={onChangeForm}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            {accion == "editar" ? "Actualizar" : "Agregar"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
