import * as Types from '../types'
import { faSquareFull } from '@fortawesome/free-solid-svg-icons'

const initialState = {
  caja: {},
  estadisticas: [],
  loadingCaja: false,
  loadingEstadisticas: false,
  showModalCaja: false,
  showModalCorteZ: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.CAJA_DATOS_GENERALES:
      return {
        ...state,
        loadingCaja: true
      }
    case Types.CAJA_DATOS_GENERALES_EXITO:
      return {
        ...state,
        loadingCaja: false,
        caja: action.payload
      }
    case Types.CAJA_DATOS_GENERALES_ERROR:
      return {
        ...state,
        loadingCaja: false,
        error: action.payload
      }

    case Types.CAJA_ESTADISTICAS:
      return {
        ...state,
        loadingEstadisticas: true
      }
    case Types.CAJA_ESTADISTICAS_EXITO:
      return {
        ...state,
        loadingEstadisticas: false,
        estadisticas: [
          {
            id: 1,
            label: 'Monto inicial',
            value: action.payload.montoInicial,
            color: 'secondary',
            icon: faSquareFull
          },
          {
            id: 2,
            label: 'Ingresos',
            value: action.payload.ingresos,
            color: 'primary',
            icon: faSquareFull
          },
          {
            id: 3,
            label: 'Gastos',
            value: action.payload.gastos,
            color: 'tertiary',
            icon: faSquareFull
          }
        ]
      }
    case Types.CAJA_ESTADISTICAS_ERROR:
      return {
        ...state,
        loadingEstadisticas: false,
        error: action.payload
      }
    case Types.MODAL_CAJA_APERTURA_CIERRE:
      return {
        ...state,
        showModalCaja: action.payload,
      }
    case Types.CAJA_CIERRE_EXITO:
      return {
        ...state,
        showModalCaja: false,
        caja: action.payload
      }
    case Types.CAJA_APERTURA_EXITO:
      return {
        ...state,
        showModalCaja: false,
        caja: action.payload
      }
    case Types.MODAL_CAJA_CORTE_Z:
      return {
        ...state,
        showModalCorteZ: action.payload,
      }
    default:
      return state
  }
}
