import { REQUEST_AXIOS } from '../types';


//cada reducer tiene su propio state
const initialState = {
    loadingRequest: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case REQUEST_AXIOS:
            return {
                ...state,
                loadingRequest: action.payload
            }
        default:
            return state;
    }
}