import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Breadcrumb,
  Col,
  Form,
  Row,
  Modal,
  Button,
} from "@themesberg/react-bootstrap";
import clienteAxios from "../../config/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import CatalogoTable from "./CatalogoTable";

export default (props) => {
  const [catalogo, setCatalogo] = useState(null);

  useEffect(() => {
    getCatalogo(props.match.params.nombre);
  }, [props.match.params.nombre]);

  const getCatalogo = (nombreCatalogo) => {
    clienteAxios.get("catalogo/" + nombreCatalogo).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        const catalogo = respuesta.data.data;
        setCatalogo(catalogo);
      } else {
        props.history.push("/");
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{catalogo?.descripcion}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">{catalogo?.descripcion}</h5>
          {catalogo && <CatalogoTable nombreCatalogo={catalogo?.nombre} />}
        </Card.Body>
      </Card>
    </>
  );
};
