import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Tab,
  Nav,
} from "@themesberg/react-bootstrap";
import { capitalize } from "../../helpers";
import Typeahead from "../../components/Typeahead";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

export default (props) => {
  const [showModal, setShowModal] = useState(false);
  const [accion, setAccion] = useState("agregar");
  const [tratamientos, setTratamientos] = useState([]);
  const [productos, setProductos] = useState([]);
  const [tratamientos_precios, setTratamientosPrecios] = useState([]);

  useEffect(() => {
    setShowModal(props.showModal);
    if (props.accion) {
      setAccion(props.accion);
    }

    if (props.selectedRow && props.selectedRow.id_tratamiento) {
      getTratamientoPrecios(props.selectedRow.id_tratamiento);
    }

    guardar({
      ...edicion,
      ...props.selectedRow
    });
  }, [props]);

  useEffect(() => {
    getTratamientos();
  }, []);

  const [edicion, guardar] = useState({
    id_tratamiento: null,
    id_tratamiento_precio: null,
    id_empleado: null,
    id_producto: null,
    aplicar_a: "todos",
    tipo: "tratamiento",
    tipo_comision: '',
    porcentaje: 0,
    monto: 0,
    empleado: null,
  });

  const submitForm = (e) => {
    e.preventDefault();

    if (edicion.aplicar_a == "individual" && !edicion.id_empleado) {
      Swal.fire("UPS", "Selecciona un empleado", "error");
    }

    edicion.id_tratamiento =
      edicion.id_tratamiento != 0 ? edicion.id_tratamiento : null;

    edicion.id_tratamiento_precio =
      edicion.id_tratamiento_precio != 0 ? edicion.id_tratamiento_precio : null;

    edicion.id_producto = edicion.id_producto != 0 ? edicion.id_producto : null;

    if (accion == "editar") {
      clienteAxios
        .put("empleados/porcentajes/" + edicion.id, {
          ...edicion,
          tipo: edicion.tipo_comision
        })
        .then((respuesta) => {
          if (
            respuesta &&
            respuesta.data.data &&
            respuesta.data.success === true
          ) {
            props.onUpdate(respuesta.data.data);
            Swal.fire("¡Muy bien!", respuesta.data.message, "success");
          }
        });
      return;
    }

    if (accion == "agregar") {
      clienteAxios.post("empleados/porcentajes", {
        ...edicion,
        tipo_comision: edicion.tipo_comision
      }).then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          props.onCreate(respuesta.data.data);
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeTipo = (tipo) => {
    guardar({
      ...edicion,
      tipo: tipo,
      id_tratamiento: null,
      id_tratamiento_precio: null,
      id_producto: null,
    });

    if (tipo == "tratamiento") {
      getTratamientos();
    } else {
      getProductos();
    }
  };

  const onChangeCheckbox = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.checked,
    });
  };

  const getTratamientos = () => {
    if (tratamientos.length > 0) {
      return;
    }

    clienteAxios.get("tratamientos/list").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setTratamientos(respuesta.data.data);
      }
    });
  };

  const getProductos = () => {
    if (productos.length > 0) {
      return;
    }

    clienteAxios.get("productos/list").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setProductos(respuesta.data.data);
      }
    });
  };

  const onChangeTratamiento = (idTratamiento) => {
    if (idTratamiento == 0) {
      guardar({
        ...edicion,
        id_tratamiento: null,
        id_tratamiento_precio: null,
      });
      setTratamientosPrecios([]);
      return;
    }

    getTratamientoPrecios(idTratamiento);
  };

  const getTratamientoPrecios = (idTratamiento) => {
    clienteAxios
      .get("tratamientos/precios/list?id_tratamiento=" + idTratamiento)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setTratamientosPrecios(respuesta.data.data);
        }
      });
  };

  const onChangeProducto = (idProducto) => {
    if (idProducto == 0) {
      guardar({
        ...edicion,
        id_producto: null,
      });
    }
  };

  const getEmpleado = () => {
    if (edicion.empleado !== null) {
      return [
        {
          id: edicion.empleado.id,
          label:
            edicion.empleado.nombre + " " + edicion.empleado.apellido_paterno,
        },
      ];
    }

    return [];
  };

  const onChangeEmpleados = (selectedOption) => {
    if (selectedOption.length > 0) {
      guardar({
        ...edicion,
        id_empleado: selectedOption[0].id,
        empleado: selectedOption[0],
      });
    } else {
      guardar({
        ...edicion,
        id_empleado: null,
        empleado: null,
      });
    }
  };

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">
          {capitalize(accion)} porcentaje
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Select
              name="aplicar_a"
              onChange={onChangeForm}
              value={edicion.aplicar_a}
            >
              <option value="todos">Todos los empleados</option>
              <option value="individual">Solo un empleado</option>
            </Form.Select>
          </Form.Group>
          {edicion.aplicar_a == "individual" && (
            <Form.Group className="mb-3">
              <Typeahead
                url="typeahead/empleados"
                labelKey="label"
                id="async-empleados"
                placeholder="Buscar empleado"
                onChange={(selected) => {
                  onChangeEmpleados(selected);
                }}
                selected={getEmpleado()}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Aplicar a</Form.Label>
            <Form.Select
              name="tipo"
              value={edicion.tipo}
              onChange={(e) => {
                onChangeTipo(e.target.value);
              }}
            >
              <option value="tratamiento">Tratamiento</option>
              <option value="producto">Producto</option>
            </Form.Select>
          </Form.Group>
          {edicion.tipo == "tratamiento" && (
            <Form.Group className="mb-3">
              <Form.Label>Selecciona tratamiento</Form.Label>
              <Form.Select
                name="id_tratamiento"
                onChange={(e) => {
                  onChangeForm(e);
                  onChangeTratamiento(e.target.value);
                }}
                value={edicion.id_tratamiento ? edicion.id_tratamiento : "0"}
              >
                <option value="0">Selecciona un tratamiento</option>
                {tratamientos.map((tratamiento) => (
                  <option value={tratamiento.id}> {tratamiento.nombre} </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {edicion.tipo == "tratamiento" &&
            tratamientos_precios.length > 0 &&
            edicion.id_tratamiento && (
              <Form.Group className="mb-3">
                <Form.Label>Tratamiento precio (opcional)</Form.Label>
                <Form.Select
                  name="id_tratamiento_precio"
                  onChange={onChangeForm}
                  value={
                    edicion.id_tratamiento_precio
                      ? edicion.id_tratamiento_precio
                      : "0"
                  }
                >
                  <option value="0">Selecciona un precio</option>
                  {tratamientos_precios.map((tratamientoPrecio) => (
                    <option value={tratamientoPrecio.id}>
                      {" "}
                      {tratamientoPrecio.descripcion}{" "}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          {edicion.tipo == "producto" && (
            <Form.Group className="mb-3">
              <Form.Label>Selecciona producto</Form.Label>
              <Form.Select
                name="id_producto"
                onChange={(e) => {
                  onChangeForm(e);
                  onChangeProducto(e.target.value);
                }}
                value={edicion.id_producto ? edicion.id_producto : "0"}
              >
                <option value="0">Selecciona un producto</option>
                {productos.map((producto) => (
                  <option value={producto.id}> {producto.nombre} </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Tipo comisión</Form.Label>
            <Form.Select
              name="tipo_comision"
              value={edicion.tipo_comision}
              onChange={onChangeForm}
            >
              <option value="porcentaje">Porcentaje</option>
              <option value="monto">Monto</option>
            </Form.Select>
          </Form.Group>
          { edicion.tipo_comision == 'porcentaje' && 
          <Form.Group className="mb-3">
            <Form.Label>Porcentaje (%)</Form.Label>
            <Form.Control
              type="number"
              name="porcentaje"
              placeholder="Porcentaje"
              value={edicion.porcentaje}
              onChange={onChangeForm}
              min={0}
              step="any"
            />
          </Form.Group>
          }
          { edicion.tipo_comision == 'monto' && 
          <Form.Group className="mb-3">
            <Form.Label>Monto ($)</Form.Label>
            <Form.Control
              type="number"
              name="monto"
              placeholder="Monto"
              value={edicion.monto}
              onChange={onChangeForm}
              min={0}
              step="any"
            />
          </Form.Group>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            {accion == "editar" ? "Actualizar" : "Agregar"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
