import * as Types from '../types'

const initialState = {
  categoriasProductos: [],
  categoria: {},
  loadingCategoriasProductos: false,
  showModalAgregarCategoria: false,
  showModalEditarCategoria: false,
  loadingAgregarCategoria: false,
  loadingEditarCategoria: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.LISTA_CATEGORIAS_PRODUCTOS:
      return {
        ...state,
        loadingCategoriasProductos: true
      }
    case Types.LISTA_CATEGORIAS_PRODUCTOS_EXITO:
      return {
        ...state,
        loadingCategoriasProductos: false,
        categoriasProductos: action.payload
      }
    case Types.LISTA_CATEGORIAS_PRODUCTOS_ERROR:
      return {
        ...state,
        loadingCategoriasProductos: false,
        error: action.payload
      }
    case Types.AGREGAR_CATEGORIA_PRODUCTO:
      return {
        ...state,
        loadingAgregarCategoria: true
      }
    case Types.AGREGAR_CATEGORIA_PRODUCTO_EXITO:
      return {
        ...state,
        loadingAgregarCategoria: false,
        categoriasProductos: state.categoriasProductos.concat(action.payload),
        showModalAgregarCategoria: false
      }
    case Types.AGREGAR_CATEGORIA_PRODUCTO_ERROR:
      return {
        ...state,
        loadingAgregarCategoria: false,
        error: action.payload
      }
    case Types.MODAL_AGREGAR_CATEGORIA_PRODUCTO:
      return {
        ...state,
        showModalAgregarCategoria: action.payload
      }
    case Types.MODAL_EDITAR_CATEGORIA_PRODUCTO:
      return {
        ...state,
        showModalEditarCategoria: action.payload
      }
    case Types.COMENZAR_EDITAR_CATEGORIA_PRODUCTO:
      return {
        ...state,
        categoria: action.payload,
        showModalEditarCategoria: true
      }
    case Types.EDITAR_CATEGORIA_PRODUCTO:
      return {
        ...state,
        loadingEditarCategoria: true
      }
    case Types.EDITAR_CATEGORIA_PRODUCTO_EXITO:
      return {
        ...state,
        loadingEditarCategoria: false,
        showModalEditarCategoria: false,
        categoriasProductos: state.categoriasProductos.map(categoria =>
          categoria.id === action.payload.id ? action.payload : categoria
        )
      }
    case Types.EDITAR_CATEGORIA_PRODUCTO_ERROR:
      return {
        ...state,
        loadingEditarCategoria: false,
        error: action.payload
      }
    case Types.ELIMINAR_CATEGORIA_PRODUCTO_EXITO:
      return {
        ...state,
        categoriasProductos: state.categoriasProductos.filter(
          categoria => categoria.id != action.payload.id
        )
      }
    default:
      return state
  }
}
