import React, { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card, Breadcrumb, Tooltip, OverlayTrigger } from '@themesberg/react-bootstrap';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from '../../components/DataTable';
import { ConfirmModal } from '../../components/ConfirmModal';

import { 
    comenzarAgregarMenuAction, 
    getMenusAction,
    comenzarActualizarMenuAction,
    eliminarMenuAction
} from '../../actions/menuActions';

import { comenzarListaFuncionesAction ,getFuncionesAction } from '../../actions/menuFuncionesActions';


import AgregarMenu from './AgregarMenu';
import EditarMenu from './EditarMenu';
import Funciones from './Funciones';

export default () => {
    const dispatch = useDispatch();

    const { loadingMenu, menu, menus } = useSelector(state => state.menu);

    useEffect(() => {
        dispatch(getMenusAction());
    }, [])

    const columns = [
        {
            key: "id",
            text: "ID",
            className: "id",
            align: "left",
            sortable: true
        },
        {
            key: "nombre",
            text: "Nombre",
            className: "nombre",
            align: "left",
            sortable: true,
        },
        {
            key: "url",
            text: "Url",
            className: "url",
            sortable: true
        },
        {
            key: "funciones",
            text: "Funciones",
            className: "funciones",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => { 
                return (
                    <Fragment>
                        { record.funciones == true &&
                            <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    overlay={
                                    <Tooltip>Funciones</Tooltip>
                                    }>
                                    <button
                                        className="btn btn-tertiary btn-sm"
                                        onClick={() => funciones(record)}
                                        style={{marginRight: '5px'}}>
                                        Funciones
                                    </button>
                                </OverlayTrigger>
                        }
                    </Fragment>
                );
            }
        }
    ];

    const state = {
        records: menus
    }

    const extraButtons =[
        {
            className:"btn btn-info",
            title:"Agregar menu",
            children:[
                <span>
                    <i class="fas fa-plus"></i> Nuevo
                </span>
            ],
            onClick:(event)=>{
                dispatch(comenzarAgregarMenuAction());
            },
        },
    ]

    const editRecord = (record) => {
        dispatch(comenzarActualizarMenuAction(record));
    }

    const deleteRecord = (record) => {
        const callbacks = [
            {
                event: dispatch,
                action: eliminarMenuAction,
                args: [record]
            }
        ]

        ConfirmModal(callbacks);
    }

    const funciones = (record) => {
        dispatch( comenzarListaFuncionesAction(record) );
        dispatch( getFuncionesAction(record) );
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Menu</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body>
                    <h5 className="mb-4">Menu</h5>
                    <DataTable 
                        columns = {columns}
                        editRecord = {editRecord}
                        deleteRecord = {deleteRecord}
                        state = {state}
                        extraButtons = {extraButtons}
                        loading = {loadingMenu}
                    />
                </Card.Body>
            </Card>

            <AgregarMenu />
            <EditarMenu />
            <Funciones />
        </>
  );
}