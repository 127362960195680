import React, { useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Breadcrumb } from '@themesberg/react-bootstrap'
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDatatable from '@mkikets/react-datatable'
import { busquedaFiltroAction } from '../../actions/filtroActions';
import Filtro from '../../components/Filtro';
import { columnaSucursalDT, numberFormat } from '../../helpers';

export default props => {
  const dispatch = useDispatch()
  const { respuestaFiltro } = useSelector(state => state.filtro)
  const modelo = 'Caja.CierreCaja';

  useEffect(() => {
    dispatch(busquedaFiltroAction(modelo, { criteria: ["all", "order-id"] }));
  }, [])

  const columns = [
    {
        key: "id",
        text: "ID",
        align: "left",
        sortable: true
    },
    {
        text: "Nombre Cajero",
        sortable: true,
        cell: record => {
            return record.apertura_caja?.cajero?.name
        }
    },
    {
        text: "Fecha Apertura",
        sortable: true,
        cell: record => {
            return record.apertura_caja?.created_at
        }
    },
    {
        text: "Caja chica apertura",
        sortable: true,
        cell: record => {
          return numberFormat(record.apertura_caja?.efectivo_caja)
        }
    },
    {
        key: "created_at",
        text: "Fecha cierre",
        sortable: true
    },
    {
        text: "Total cierre calculado",
        sortable: true,
        cell: record => {
            return numberFormat(record.total_cierre)
        }
    },
    {
        text: "Caja chica cierre",
        sortable: true,
        cell: record => {
          return numberFormat(record.dinero_caja)
        }
    },
    {
        text: "Total cierre reportado",
        sortable: true,
        cell: record => {
          return numberFormat(record.total_cierre_reportado)
        }
    },
    columnaSucursalDT()
];

  return (
    <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                    <Breadcrumb.Item active>Historial Cierre Caja</Breadcrumb.Item>
                </Breadcrumb>
            </div>
        </div>
        <Card border="light" className="table-wrapper table-responsive shadow-sm">
            <Card.Body>
                <h5 className="mb-4">Historial Cierre Caja</h5>
                <ReactDatatable hover records={(respuestaFiltro.data ?? [])} columns={columns}/>
            </Card.Body>
        </Card>
    </>
  )
}
