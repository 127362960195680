import {
  LISTA_ACCIONES_ROLES,
  LISTA_ACCIONES_ROLES_EXITO,
  LISTA_ACCIONES_ROLES_ERROR,
  SET_SELECTED_ACCION_ROLES,
  ACTUALIZAR_ACCIONES_ROLES_ERROR,
  ACTUALIZAR_ACCIONES_ROLES_EXITO,
} from "../types";

const initialState = {
  accion: {},
  acciones_rol: [],
  loadingAccionesRol: false,
  selectedAccionesRol: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LISTA_ACCIONES_ROLES:
      return {
        ...state,
        loadingAccionesRol: true,
        accion: action.payload,
      };
    case LISTA_ACCIONES_ROLES_EXITO:
      return {
        ...state,
        loadingAccionesRol: false,
        acciones_rol: action.payload,
        selectedAccionesRol: action.payload.map(function (accion) {
          return {
            id_accion: accion.id_accion,
            id_rol: accion.id_rol,
            id_sucursal: accion.id_sucursal
          };
        }),
      };
    case LISTA_ACCIONES_ROLES_ERROR:
      return {
        ...state,
        loadingAccionesRol: false,
        error: action.payload,
        selectedAccionesRol: [],
      };
    case SET_SELECTED_ACCION_ROLES:
      return {
        ...state,
        selectedAccionesRol: action.payload,
      };
    case ACTUALIZAR_ACCIONES_ROLES_EXITO:
      return {
        ...state,
        acciones_rol: action.payload,
      };
    case ACTUALIZAR_ACCIONES_ROLES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
