import * as Types from '../types';

import clienteAxios from '../config/axios';

export function getUnidadesMedicionAction() {
    return async (dispatch) => {
        dispatch( getUnidadesMedicion() );
        try {
            const response = await clienteAxios.get('unidad-medicion/list');
            if (response.data.data) {
                dispatch( getUnidadesMedicionExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getUnidadesMedicionError(error) );
        }
    }
}

const getUnidadesMedicion = () => ({
    type: Types.LISTA_UNIDADES_MEDICION
})

const getUnidadesMedicionExito = unidades => ({
    type: Types.LISTA_UNIDADES_MEDICION_EXITO,
    payload: unidades
})

const getUnidadesMedicionError = error => ({
    type: Types.LISTA_UNIDADES_MEDICION_ERROR,
    payload: error
})