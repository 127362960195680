import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { 
    editarTratamientoPrecioAction,
    cerrarModalEditarAction 
} from '../../actions/tratamientosPreciosActions';

export default (props) => {
    const dispatch = useDispatch();
    
    const { precio, showModalEditar } = useSelector(state => state.tratamientosPrecios);

    const [ edicionPrecio, guardar] = useState({
        'descripcion': '',
        'precio': 0
    })

    useEffect(() => {
        if(precio == undefined) {
            return;
        }

        guardar(precio);
    }, [precio])

    const submitForm = e => {
        e.preventDefault();
        dispatch( editarTratamientoPrecioAction(edicionPrecio) );
    }

    const handleClose = () => {
        dispatch( cerrarModalEditarAction() );
    }

    const onChangeForm = (e) => {
        guardar({
            ...edicionPrecio,
            [e.target.name] : e.target.value
        })
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModalEditar} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Editar precio</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitForm}>
            <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control type="text" name="descripcion" placeholder="Descripción" 
                            value={edicionPrecio.descripcion} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Precio</Form.Label>
                        <Form.Control type="number" name="precio" placeholder="Precio" 
                            step="any" value={edicionPrecio.precio} onChange={onChangeForm} />
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary">
                    Actualizar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}