import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { agregarRolAction, cerrarModalAgregarAction } from '../../actions/rolesActions';
import { agregarImpuestoAction } from "../../actions/impuestosActions";

export default (props) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(props.showModal);
    }, [props]);
    
    const [ agregar, guardar] = useState({
        'concepto': '',
        'porcentaje': ''
    })

    const submit = e => {
        e.preventDefault();
        dispatch( agregarImpuestoAction(agregar) );
    }

    const onChangeForm = (e) => {
        guardar({
            ...agregar,
            [e.target.name] : e.target.value
        })
    }

    const handleClose = () => {
        setShowModal(false);
        props.onHide();
      };

    return (
        <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Agregar impuesto</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submit}>
            <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Concepto</Form.Label>
                        <Form.Control type="text" name="concepto" placeholder="Concepto" 
                            value={agregar.concepto} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Porcentaje</Form.Label>
                        <Form.Control type="text" name="porcentaje" placeholder="Porcentaje" 
                            value={agregar.porcentaje} onChange={onChangeForm} />
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary" onClick={submit}>
                    Agregar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}