import React from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useState } from "react";
import { useEffect } from "react";
import clienteAxios from "../../config/axios";

export default (props) => {
  const { title, data, serieLabel } = props;

  const option = {
    title: {
        text: title,
    },
    backgroundColor: "#fff",
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: Object.keys(data),
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: serieLabel,
        type: 'bar',
        barWidth: '60%',
        data: Object.values(data),
      }
    ]
  };

  return <ReactECharts option={option} />;
};
