import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Form,
  Button,
  Col,
  OverlayTrigger,
  Tooltip,
  Row,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { faCalendarAlt, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '@mkikets/react-datatable';
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { ConfirmModal } from "../../components/ConfirmModal";
import clienteAxios from "../../config/axios";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import "moment/locale/es-us";
import { getTratamientosAction } from "../../actions/tratamientosActions";
import {SeleccionMultiple} from "./Toolbars/SeleccionMultiple";

export default () => {
  const dispatch = useDispatch();

  const [gruposDifusion, guardarGruposDifusion] = useState([]);
  const [options_grupos_difusion, guardarOptionsGruposDifusion] = useState([]);
  const [seleccionar_tratamientos, guardarSeleccionarTratamientos] =
    useState(false);
  const [selected_grupos_difusion, guardarSelectedGruposDifusion] = useState(
    []
  );
  const [mensajesMasivos, guardarMensajesMasivos] = useState([]);

  const [state, guardar] = useState({
    editorState: EditorState.createEmpty(),
    fecha_programada: null,
    options_tratamientos: [],
    selected_tratamientos: [],
    lista_numeros: ''
  });

  const { tratamientos } = useSelector((state) => state.tratamientos);

  useEffect(() => {
    getMensajesMasivos();
    getGruposDifusion();
  }, []);

  useEffect(() => {
    if (gruposDifusion.length > 0) {
      getGruposDifusionOptions();
    }
  }, [gruposDifusion]);

  useEffect(() => {
    getTratamientosOptions();
  }, [tratamientos]);

  useEffect(() => {
    checarGruposDifusionSeleccionados();
  }, [selected_grupos_difusion]);

  useEffect(() => {
    if (seleccionar_tratamientos) {
      dispatch(getTratamientosAction());
    }
  }, [seleccionar_tratamientos]);

  const getMensajesMasivos = async () => {
    const mensajes = await clienteAxios.get("/mensajes-masivos");
    if (mensajes.data.data) {
      guardarMensajesMasivos(mensajes.data.data);
    }
  };

  const getGruposDifusion = async () => {
    const grupos = await clienteAxios.get("/grupos-difusion");
    if (grupos.data.data) {
      guardarGruposDifusion(grupos.data.data);
    }
  };

  const onChangeGrupoDifusion = (selected, selection, controlKey) => {
    guardarSelectedGruposDifusion(selected);
  };

  const getGruposDifusionOptions = () => {
    const options = gruposDifusion.map(function (grupo) {
      return { value: grupo.id, label: grupo.descripcion };
    });

    guardarOptionsGruposDifusion(options);
  };

  const checarGruposDifusionSeleccionados = () => {
    let seleccionar_tratamientos = false;

    gruposDifusion.forEach((grupo) => {
      if (selected_grupos_difusion.includes(grupo.id)) {
        if (grupo.nombre === "ClientesConTratamientoEspecifico") {
          seleccionar_tratamientos = true;
        }
      }
    });

    guardarSeleccionarTratamientos(seleccionar_tratamientos);
  };

  const getTratamientosOptions = () => {
    const options = tratamientos.map(function (tratamiento) {
      return { value: tratamiento.id, label: tratamiento.nombre };
    });

    state.options_tratamientos = options;

    guardar({
      ...state,
      options_tratamientos: options,
    });
  };

  const onEditorStateChange = (editorState) => {
    guardar({
      ...state,
      editorState,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    crearMensajeMasivo().then((response) => {
      if (response && response.data.data) {
        Swal.fire("¡Muy bien!", response.data.message, "success");
        limpiarFormulario();
        getMensajesMasivos();
      }
    });
  };

  const crearMensajeMasivo = () => {
    const mensaje = {
      mensaje: state.editorState.getCurrentContent().getPlainText(),
      grupos_difusion: selected_grupos_difusion,
      lista_numeros: state.lista_numeros,
      metodo: "whatsapp",
      fecha_programada: state.fecha_programada
        ? state.fecha_programada.utc().unix()
        : null,
      tratamientos: state.selected_tratamientos,
    };

    return clienteAxios.post("/mensajes-masivos", mensaje);
  };

  const limpiarFormulario = () => {
    state.editorState = EditorState.createEmpty();
    guardarSelectedGruposDifusion([]);
    state.fecha_programada = null;
  };

  const onChangeTratamientos = (selected, selection, controlKey) => {
    guardar({
      ...state,
      selected_tratamientos: selected,
    });
  };

  const columnsMensajesMasivos = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      text: "Mensaje",
      key: "mensaje",
      sortable: false,
      className: "w-25 text-break",
    },
    {
      text: "Fecha creación",
      key: "created_at",
      sortable: true,
    },
    {
      text: "Fecha programada",
      key: "fecha_programada",
      sortable: true,
    },
    {
      text: "Estatus",
      key: "estatus",
      sortable: true,
    },
    {
      text: "Creado por",
      sortable: true,
      cell: (record) => {
        return record.audits ? record.audits[0]?.user?.username : 'NO ENCONTRADO';
      },
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Mensajes Masivos</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Cuerpo del mensaje</h5>
          <Col>
            <Row>
              <Col sm={12} md={8}>
                <label>Mensaje</label>
                <Editor
                  editorState={state.editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    options: ["emoji"],
                  }}
                  //toolbarCustomButtons={[<SeleccionMultiple />]}
                  placeholder="Escribe tu mensaje"
                />
              </Col>
              <Col sm={12} md={4}>
                <label>Programar mensaje</label>
                <Datetime
                  timeFormat={true}
                  closeOnSelect={false}
                  onChange={(e) => {
                    guardar({ ...state, fecha_programada: e });
                  }}
                  utc={true}
                  value={state.fecha_programada}
                  initialViewMode="days"
                  locale="es"
                  dateFormat="YYYY-MM-DD HH:mm"
                  renderInput={(propsDate, openCalendar) => {
                    function clear() {
                      propsDate.onChange({ target: { value: "" } });
                    }

                    return (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          value={
                            state.fecha_programada
                              ? moment(state.fecha_programada).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                              : ""
                          }
                          placeholder="Selecciona una fecha*"
                          onFocus={openCalendar}
                          onChange={(e) =>
                            guardar({ ...state, fecha_programada: e })
                          }
                        />

                        <Button variant="info" onClick={clear}>
                          Limpiar
                        </Button>
                      </InputGroup>
                    );
                  }}
                />
              </Col>
            </Row>
            <hr></hr>

            <Row>
              <h6 className="mb-4">Encuesta (opcional)</h6>
            </Row>


            </Col>
        </Card.Body>
      </Card>
      <br />
      <Card border="light" className="shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Destinatarios</h5>
          <Row>
              <Col sm={12}>
                <label>Grupos de difusión</label>
                {gruposDifusion.length > 0 && (
                  <>
                    <DualListBox
                      options={options_grupos_difusion}
                      selected={selected_grupos_difusion}
                      onChange={onChangeGrupoDifusion}
                      showHeaderLabels={true}
                      name="grupos_difusion"
                      lang={{
                        availableHeader: "Disponibles",
                        selectedHeader: "Seleccionados",
                      }}
                    />
                  </>
                )}
              </Col>
            </Row>
            {seleccionar_tratamientos && (
              <>
                <hr></hr>
                <Row>
                  <Col sm={12}>
                    <label>Seleccionar tratamientos</label>
                    {state.options_tratamientos.length > 0 && (
                      <DualListBox
                        options={state.options_tratamientos}
                        selected={state.selected_tratamientos}
                        onChange={onChangeTratamientos}
                        showHeaderLabels={true}
                        name="tratamientos"
                        lang={{
                          availableHeader: "Disponibles",
                          selectedHeader: "Seleccionados",
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </>
            )}

            <hr></hr>

            <Row>
              <Col sm={12}>
                <label>Lista de numeros</label>

                <Form.Control
                  as="textarea"
                  rows="4"
                  name="lista_numeros"
                  placeholder="Un número de celular por línea"
                  value={state.lista_numeros}
                  onChange={(e) => guardar({...state, lista_numeros: e.target.value})}
                />
              </Col>
            </Row>
            <Button
              variant="info"
              className="mt-5 d-block mx-auto"
              onClick={onSubmit}
            >
              Crear mensaje masivo
            </Button>
        </Card.Body>
      </Card>
      <br></br>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Historial Mensajes Masivos</h5>
          <Col>
            {mensajesMasivos.length == 0 ? (
              <div
                class="spinner-border text-info mx-auto d-block"
                role="status"
              >
                <span class="visually-hidden">Cargando...</span>
              </div>
            ) : (
              <ReactDatatable
                hover
                records={mensajesMasivos}
                columns={columnsMensajesMasivos}
              />
            )}
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};
