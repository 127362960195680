import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Modal, Button, Form } from '@themesberg/react-bootstrap'
import { abrirCajaAction, cerrarCajaAction, getCajaAction, getCajaEstadisticasAction, showModalCajaAction } from '../../actions/cajaActions'
import { getCajaMovimientosAction } from '../../actions/cajaMovimientosActions'

export default props => {
  const dispatch = useDispatch()

  const { caja, showModalCaja } = useSelector(state => state.caja)

  const [dinero_caja, guardarDineroCaja] = useState(0);
  const [saldo_final_reportado, guardarSaldoFinal] = useState(0);
  const [diferencia_caja, guardarDiferencia] = useState(0);

  const handleClose = () => {
    dispatch(showModalCajaAction(false))
  }

  const onChangeDineroCajaForm = e => {
    guardarDineroCaja(e.target.value);
  }

  const onChangeSaldoFinal = e => {
    const saldo_final = parseFloat(e.target.value);
    guardarSaldoFinal(saldo_final);

    const diferencia = parseFloat(saldo_final - caja.saldo).toFixed(2);
    guardarDiferencia(diferencia);
  }

  const submitForm = e => {
    e.preventDefault()
    if(props.accion == 'cerrar'){
        dispatch(cerrarCajaAction(dinero_caja, saldo_final_reportado))
    } else if(props.accion == 'abrir') {
        dispatch(abrirCajaAction(dinero_caja))
        dispatch(getCajaEstadisticasAction())
        dispatch(getCajaMovimientosAction())
    }
  }

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size='md'
        centered
        show={showModalCaja}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className='h6'>{ props.accion == 'cerrar' ? 'Cerrar Caja' : 'Abrir Caja'}</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body>
            <Form.Group className='mb-3'>
              <Form.Label>Fondo en caja chica ($)</Form.Label>
              <Form.Control
                type='number'
                name='dinero_caja'
                step="any"
                onChange={onChangeDineroCajaForm}
                min='0'
              />
            </Form.Group>
            { /* props.accion == 'cerrar' &&
              <>
                <Form.Group className='mb-3'>
                  <Form.Label>Saldo final a reportar ($)</Form.Label>
                  <Form.Control
                    type='number'
                    name='saldo_final_reportado'
                    onChange={onChangeSaldoFinal}
                    min='0'
                    step="any"
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label>Saldo final totalizado por el sistema ($)</Form.Label>
                  <Form.Control
                    type='number'
                    value={caja.saldo}
                    disabled
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  { (diferencia_caja == 0 || isNaN(diferencia_caja)) &&
                    <Form.Label>Diferencia de caja: ($)</Form.Label>
                  }
                  { diferencia_caja > 0 &&
                    <Form.Label className='text-success'>Diferencia de caja. Esta sobrando: ($)</Form.Label>
                  }
                  { diferencia_caja < 0 &&
                    <Form.Label className='text-danger'>Diferencia de caja. Esta faltando: ($)</Form.Label>
                  }
                  <Form.Control
                    type='number'
                    value={diferencia_caja}
                    disabled
                  />
                </Form.Group>
              </>
                */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='link'
              className='text-gray ms-auto'
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type='submit' variant='secondary'>
                { props.accion == 'cerrar' ? 'Cerrar caja' : 'Abrir'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
