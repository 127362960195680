import {
    COMENZAR_LISTA_MENU_FUNCIONES,
    LISTA_MENU_FUNCIONES,
    LISTA_MENU_FUNCIONES_EXITO,
    LISTA_MENU_FUNCIONES_ERROR,
    COMENZAR_AGREGAR_MENU_FUNCION,
    AGREGAR_MENU_FUNCION,
    AGREGAR_MENU_FUNCION_EXITO,
    AGREGAR_MENU_FUNCION_ERROR,
    CERRAR_MODAL_AGREGAR_MENU_FUNCION,
    CERRAR_MODAL_LISTA_MENU_FUNCION,
    COMENZAR_ACTUALIZAR_MENU_FUNCION,
    ACTUALIZAR_MENU_FUNCION,
    ACTUALIZAR_MENU_FUNCION_EXITO,
    ACTUALIZAR_MENU_FUNCION_ERROR,
    CERRAR_MODAL_ACTUALIZAR_MENU_FUNCION,
    ELIMINAR_MENU_FUNCION_EXITO,
    ELIMINAR_MENU_FUNCION_ERROR
} from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function comenzarListaFuncionesAction(menu) {
    return (dispatch) => {
        dispatch( comenzarListaFunciones(menu) );
    }
}

const comenzarListaFunciones = menu => ({
    type: COMENZAR_LISTA_MENU_FUNCIONES,
    payload: menu
})

export function getFuncionesAction(menu) {
    return async (dispatch) => {
        dispatch( getFunciones() );
        try {
            const response = await clienteAxios.get('menu/funciones/'+menu.id);
            if (response.data.data) {
                dispatch( getFuncionesExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getFuncionesError(error) );
        }
    }
}

const getFunciones = () => ({
    type: LISTA_MENU_FUNCIONES
})

const getFuncionesExito = funciones => ({
    type: LISTA_MENU_FUNCIONES_EXITO,
    payload: funciones
})

const getFuncionesError = error => ({
    type: LISTA_MENU_FUNCIONES_ERROR,
    payload: error
})

export function comenzarAgregarFuncionAction() {
    return (dispatch) => {
        dispatch( comenzarAgregarFuncion() );
    }
}

const comenzarAgregarFuncion = () => ({
    type: COMENZAR_AGREGAR_MENU_FUNCION
})

export function cerrarModalAgregarAction() {
    return (dispatch) => {
        dispatch( cerrarModalAgregar() );
    }
}

const cerrarModalAgregar = () => ({
    type: CERRAR_MODAL_AGREGAR_MENU_FUNCION
})

export function cerrarModalListaAction() {
    return (dispatch) => {
        dispatch( cerrarModalLista() );
    }
}

const cerrarModalLista = () => ({
    type: CERRAR_MODAL_LISTA_MENU_FUNCION
})

export function agregarFuncionAction(funcion) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarFuncion() );
        try {
            const response = await clienteAxios.post('menu/funciones/agregar', funcion);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');

                funcion.nombre = '';
                funcion.url = '';
                funcion.icono = '';

                dispatch( agregarFuncionExito(response.data.data) );
            }
        } catch (error) {
            dispatch( agregarFuncionError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarFuncion = () => ({
    type: AGREGAR_MENU_FUNCION
})

const agregarFuncionExito = funcion => ({
    type: AGREGAR_MENU_FUNCION_EXITO,
    payload: funcion
})

const agregarFuncionError = error => ({
    type: AGREGAR_MENU_FUNCION_ERROR,
    payload: error
})

export function comenzarEditarFuncionAction(funcion) {
    return (dispatch) => {
        dispatch( comenzarEditarFuncion(funcion) );
    }
}

const comenzarEditarFuncion = funcion => ({
    type: COMENZAR_ACTUALIZAR_MENU_FUNCION,
    payload: funcion
})

export function editarFuncionAction(funcion) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarFunction() );
        try {
            const response = await clienteAxios.put('menu/funciones/actualizar/'+funcion.id, funcion);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarFuncionExito(response.data.data) );
            }
        } catch (error) {
            dispatch( editarFuncionError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const editarFunction = () => ({
    type: ACTUALIZAR_MENU_FUNCION
})

const editarFuncionExito = funcion => ({
    type: ACTUALIZAR_MENU_FUNCION_EXITO,
    payload: funcion
})

const editarFuncionError = error => ({
    type: ACTUALIZAR_MENU_FUNCION_ERROR,
    payload: error
})

export function cerrarModalEditarAction() {
    return (dispatch) => {
        dispatch( cerrarModalEditar() );
    }
}

const cerrarModalEditar = () => ({
    type: CERRAR_MODAL_ACTUALIZAR_MENU_FUNCION
})

export function eliminarFuncionAction(funcion) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('menu/funciones/eliminar/'+funcion.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarFuncionExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarFuncionError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarFuncionExito = funcion => ({
    type: ELIMINAR_MENU_FUNCION_EXITO,
    payload: funcion
})

const eliminarFuncionError = error => ({
    type: ELIMINAR_MENU_FUNCION_ERROR,
    payload: error
})