import {
    LISTA_ACCIONES,
    LISTA_ACCIONES_EXITO,
    LISTA_ACCIONES_ERROR
} from '../types';

const initialState = {
    acciones: [],
    loadingAcciones: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LISTA_ACCIONES:
            return {
                ...state,
                loadingAcciones: true
            }
        case LISTA_ACCIONES_EXITO:
            return {
                ...state,
                loadingAcciones: false,
                acciones: action.payload
            }
        case LISTA_ACCIONES_ERROR:
            return {
                ...state,
                loadingAcciones: false,
                error: action.payload
            }
        default:
            return state;
    }
}