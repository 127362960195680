import React, { useEffect, useState } from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
} from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";
import withDragDropContext from "./withDnDContext";
import moment from "moment-timezone";
import { C } from "@fullcalendar/core/internal-common";
import {
  Card,
  Modal,
  Button,
  Form,
  InputGroup,
  Row,
  ListGroup,
  Container,
  Nav,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  ThemeProvider,
  Image,
  Tab as TabReact,
  Tabs as TabsReact,
} from "@themesberg/react-bootstrap";

export const SchedulerComponent = (props) => {
  const { resources, events } = props;
  const [esMovil, setEsMovil] = useState(window.innerWidth <= 768);

  const [schedulerData, setSchedulerData] = useState(
    new SchedulerData(
      new moment().format(DATE_FORMAT),
      ViewTypes.Day,
      false,
      false,
      {
        views: [],
        schedulerWidth: "78%",
        resourceName: "Comisionistas",
        dayCellWidth: 160,
        eventItemHeight: 80,
        eventItemLineHeight: 60,
        dayResourceTableWidth: 270,
      }
    )
  );

  const [state, setState] = useState({
    viewModel: schedulerData,
  });

  const toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    setState({
      viewModel: schedulerData,
    });
  };

  useEffect(() => {
    const manejadorResize = () => {
      setEsMovil(window.innerWidth <= 768);
    };

    window.addEventListener("resize", manejadorResize);

    return () => {
      window.removeEventListener("resize", manejadorResize);
    };
  }, []);

  useEffect(() => {
    if (esMovil) {
      schedulerData.setViewType(ViewTypes.Day, true);
    }
  }, [esMovil]);

  useEffect(() => {
    moment.locale("es-ES");
    schedulerData.setLocaleMoment(moment);
  }, []);

  useEffect(() => {
    schedulerData.setResources(resources);
    setState({
      viewModel: schedulerData,
    });
  }, [resources]);

  useEffect(() => {
    schedulerData.setEvents(events);
    setState({
      viewModel: schedulerData,
    });
  }, [events]);

  /*let events = [
    {
      id: 5,
      start: "2024-05-18 15:30:00",
      end: "2024-05-18 16:30:00",
      resourceId: "r1",
      title: "R1 has recurring tasks every week on Tuesday, Friday",
      //rrule: "FREQ=WEEKLY;DTSTART=20171219T013000Z;BYDAY=TU,FR",
      //bgColor: "#f759ab",
      resizable: true,
    },
  ];*/

  const prevClick = (schedulerData) => {
    //
  };

  const nextClick = (schedulerData) => {
    //
  };

  const onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    //schedulerData.setEvents(DemoData.events);
    setState({
      viewModel: schedulerData,
    });
  };

  const onViewChange = (schedulerData, view) => {
    //
  };

  const eventClicked = (schedulerData, event) => {
    //
  };

  const eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let borderWidth = isStart ? "4" : "0";
    let borderColor = "rgba(0,139,236,1)",
      backgroundColor = "#80C5F6";
    let titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event
    );
    if (!!event.type) {
      borderColor =
        event.type == 1
          ? "rgba(0,139,236,1)"
          : event.type == 3
          ? "rgba(245,60,43,1)"
          : "#999";
      backgroundColor =
        event.type == 1 ? "#80C5F6" : event.type == 3 ? "#FA9E95" : "#D9D9D9";
    }
    let divStyle = {
      borderLeft: borderWidth + "px solid " + borderColor,
      backgroundColor: backgroundColor,
      height: mustBeHeight,
    };
    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span
          style={{
            fontSize: "10px",
            fontWeight: "bold",
            color: "black",
            marginLeft: "6px",
          }}
        >
          {event.cliente}
        </span>
        <br></br>
        <ul
          style={{ listStyleType: "none", padding: "0px", paddingLeft: "6px" }}
        >
          <li style={{ padding: "0px" }}>
            <span
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                color: "rgb(62, 62, 62)",
              }}
            >
              {titleText}
            </span>
          </li>
          <li>
            <span
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                color: "rgb(62, 62, 62)",
              }}
            >
              <i className="fas fa-clock"></i> {event.hora_inicio} -{" "}
              {event.hora_fin}
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    return (
      // <React.Fragment>
      //     <h3>{title}</h3>
      //     <h5>{start.format("HH:mm")} - {end.format("HH:mm")}</h5>
      //     <img src="./icons8-ticket-96.png" />
      // </React.Fragment>
      <div style={{ width: "300px" }}>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div
              className="status-dot"
              style={{ backgroundColor: statusColor }}
            />
          </Col>
          <Col span={22} className="overflow-text">
            <span className="header2-text" title={title}>
              {title}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <span className="header1-text">
              {start.format("HH:mm")} - {end.format("HH:mm")}
            </span>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={2}>
            <div />
          </Col>
          <Col span={22}>
            <Button
              onClick={() => {
                demoButtonClicked(eventItem);
              }}
            >
              Demo
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const demoButtonClicked = (eventItem) => {
    alert(`You just clicked demo button. event title: ${eventItem.title}`);
  };

  return (
    <>
      <Scheduler
        schedulerData={schedulerData}
        prevClick={prevClick}
        nextClick={nextClick}
        onSelectDate={onSelectDate}
        onViewChange={onViewChange}
        eventItemClick={eventClicked}
        toggleExpandFunc={toggleExpandFunc}
        eventItemTemplateResolver={eventItemTemplateResolver}
        eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
      />
    </>
  );
};

export default withDragDropContext(SchedulerComponent);
