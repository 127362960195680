import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col
} from "@themesberg/react-bootstrap";
import { capitalize } from "../../../helpers";
import clienteAxios from "../../../config/axios";
import Swal from "sweetalert2";

export default (props) => {
  const [showModal, setShowModal] = useState(false);
  const [accion, setAccion] = useState("agregar");
  const [catalogoConceptos, setCatalogoConceptos] = useState([]);
  const [frecuencias, setFrecuencias] = useState([]);

  useEffect(() => {
    if (props.accion) {
      setAccion(props.accion);
    }

    guardar({
      ...edicion,
      ...props.selectedRow,
    });
  }, [props]);

  useEffect(() => {
    setShowModal(props.showModal);

    if (props.showModal && props.selectedRow === null) {
      limpiarForm();
    }
  }, [props.showModal]);

  useEffect(() => {
    getConceptos();
    getFrecuencias();
  }, []);

  const [edicion, guardar] = useState({
    id_gasto_concepto: null,
    descripcion: null,
    recurrente: false,
    id_frecuencia: null,
    activo: true,
    fecha: null,
    monto: null
  });

  const getConceptos = () => {
    clienteAxios
      .get("typeahead/valores/CONCEPTOS_GASTOS_OPERACION?query=**")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setCatalogoConceptos(respuesta.data.data);
        }
      });
  };

  const getFrecuencias = () => {
    clienteAxios
      .get("typeahead/valores/FRECUENCIAS_CALENDARIO?query=**")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setFrecuencias(respuesta.data.data);
        }
      });
  };

  const onChangeCheckbox = (e) => {
    if (e.target.name == 'recurrente') {
      guardar({
        ...edicion,
        [e.target.name]: e.target.checked,
        fecha: (e.target.checked === true) ? null : edicion.fecha,
        id_frecuencia: (e.target.checked === true) ? edicion.id_frecuencia : null,
        activo: (e.target.checked === true) ? edicion.activo : true
      });
      return;
    }

    guardar({
      ...edicion,
      [e.target.name]: e.target.checked,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (accion == "editar") {
      clienteAxios
        .put("tesoreria/gastos-operaciones/" + edicion.id, edicion)
        .then((respuesta) => {
          if (
            respuesta &&
            respuesta.data.data &&
            respuesta.data.success === true
          ) {
            props.onUpdate(respuesta.data.data);
            Swal.fire("¡Muy bien!", respuesta.data.message, "success");
          }
        });
      return;
    }

    if (accion == "agregar") {
      clienteAxios.post("tesoreria/gastos-operaciones", edicion).then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          props.onCreate(respuesta.data.data);
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    limpiarForm();
    props.onHide();
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const limpiarForm = () => {
    guardar({
      id_gasto_concepto: null,
      descripcion: null,
      recurrente: false,
      id_frecuencia: null,
      activo: true,
      fecha: null,
      monto: null
    });
  }

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">
          {capitalize(accion)} Gasto
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
        <Row className="justify-content-end">
            <Col>
              <Form.Group className="mb-3">
                <Form.Check
                  reverse
                  type="switch"
                  label="Recurrente"
                  name="recurrente"
                  checked={edicion.recurrente}
                  onChange={onChangeCheckbox}
                />
              </Form.Group>
            </Col>
            {
              edicion.recurrente && (
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Check
                      reverse
                      type="switch"
                      label="Activo"
                      name="activo"
                      checked={edicion.activo}
                      onChange={onChangeCheckbox}
                    />
                  </Form.Group>
                </Col>
              )
            }
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Tipo concepto*</Form.Label>
              <Form.Select
                name="id_gasto_concepto"
                onChange={onChangeForm}
                defaultValue={edicion.id_gasto_concepto}
              >
                <option value="0">Selecciona un concepto</option>
                {catalogoConceptos.map((concepto) => (
                  <>
                    <option value={concepto.id}> {concepto.descripcion} </option>
                  </>
                ))}
              </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              name="descripcion"
              placeholder="Descripción"
              value={edicion.descripcion}
              onChange={onChangeForm}
            />
          </Form.Group>
          {
            edicion.recurrente ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Frecuencia*</Form.Label>
                  <Form.Select
                    name="id_frecuencia"
                    onChange={onChangeForm}
                    defaultValue={edicion.id_frecuencia}
                  >
                    <option value="0">Selecciona la frecuencia</option>
                    {frecuencias.map((frecuencia) => (
                      <>
                        <option value={frecuencia.id}> {frecuencia.descripcion} </option>
                      </>
                    ))}
                  </Form.Select>
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha"
                    placeholder="Fecha"
                    value={edicion.fecha}
                    onChange={onChangeForm}
                  />
                </Form.Group>
              </>
            )
          }
          <Form.Group className="mb-3">
            <Form.Label>Monto*</Form.Label>
            <Form.Control
              type="number"
              min={0}
              name="monto"
              placeholder="Monto"
              value={edicion.monto}
              onChange={onChangeForm}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            {accion == "editar" ? "Actualizar" : "Agregar"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
