import * as Types from '../types'

const initialState = {
    loadingConfiguracion: false,
    loadingActualizarConfiguracion: false,
    configuracion: [],
    parametrosConfiguracion: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.CARGANDO_CONFIGURACION:
      return {
        ...state,
        loadingConfiguracion: true
      }
    case Types.CARGANDO_CONFIGURACION_EXITO:
      return {
        ...state,
        loadingConfiguracion: false,
        configuracion: action.payload,
        parametrosConfiguracion: action.payload.reduce((obj, param) => ({ ...obj, [param.parametro]: param.valor }), {})
      }
    case Types.CARGANDO_CONFIGURACION_ERROR:
      return {
        ...state,
        loadingConfiguracion: false,
        error: action.payload
      }
    case Types.ACTUALIZAR_CONFIGURACION:
        return {
          ...state,
          loadingActualizarConfiguracion: true
        }
    case Types.ACTUALIZAR_CONFIGURACION_EXITO:
        return {
          ...state,
          loadingActualizarConfiguracion: false,
          configuracion: action.payload
        }
    case Types.ACTUALIZAR_CONFIGURACION_ERROR:
        return {
          ...state,
          loadingActualizarConfiguracion: false,
          error: action.payload
        }
    default:
      return state
  }
}
