import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@ashvin27/react-datatable";
import { busquedaFiltroAction } from "../../../actions/filtroActions";
import Filtro from "../../../components/Filtro";
import ModalHistorialVentasDetalle from "../../../pages/Venta/ModalHistorialVentasDetalle";
import { ConfirmModal } from "../../../components/ConfirmModal";
import {
  eliminarVentaAction,
  imprimirVenta,
} from "../../../actions/ventaActions";
import ModalCancelacionVenta from "../../../pages/Venta/ModalCancelacionVenta";
import { columnaSucursalDT } from "../../../helpers";

export default (props) => {
  const dispatch = useDispatch();
  const { loadingFiltro, respuestaFiltro, criteria } = useSelector(
    (state) => state.filtro
  );
  const modelo = "Ventas.Venta";
  const criteriasObligatorios = ["order-id"];

  const [state, guardar] = useState({
    detalles: [],
    showModalDetalles: false,
    showModalCancelar: false,
    idVentaCancelacion: null,
  });

  const [requestFiltro, guardarRequestFiltro] = useState({
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    dispatch(
      busquedaFiltroAction(
        modelo,
        { offset: 0, limit: 10, criteria: ["all", "order-id"] },
        true
      )
    );
  }, []);

  const columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      text: "Nombre Vendedor",
      sortable: true,
      cell: (record) => {
        return record.audits ? record.audits[0]?.user?.name : "NO ENCONTRADO";
      },
    },
    {
      text: "Cliente",
      sortable: true,
      cell: (record) => {
        return record.cliente?.nombre_completo;
      },
    },
    {
      key: "fecha",
      text: "Fecha",
      sortable: true,
    },
    {
      text: "Metodos de pago",
      sortable: true,
      cell: (record) => {
        const metodos = record.metodos_pago.map((item) => {
          return item.metodo_pago.descripcion;
        });

        return metodos.join(", ");
      },
    },
    {
      text: "Comisionista",
      sortable: true,
      cell: (record) => {
        let nombreEmpleado = "";
        const nombre = record.detalle?.reduce(
          (acc, item) =>
            item.empleado
              ? item.empleado.nombre + " " + item.empleado.apellido_paterno
              : "",
          nombreEmpleado
        );

        return nombre;
      },
    },
    {
      key: "nota",
      text: "Observación",
      sortable: true,
    },
    {
      key: "total",
      text: "Total",
      sortable: true,
    },
    columnaSucursalDT(),
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Detalle</Tooltip>}
              >
                <button
                  onClick={() => showDetalles(record.detalle)}
                  className="btn btn-info btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-boxes"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Reimprimir ticket</Tooltip>}
              >
                <button
                  onClick={() => reimprimir(record.id)}
                  className="btn btn-success btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-file-invoice"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Eliminar Venta</Tooltip>}
              >
                <button
                  onClick={() => eliminarVenta(record.id)}
                  className="btn btn-danger btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-trash"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
    },
  ];

  const showDetalles = (detalle) => {
    guardar({ ...state, showModalDetalles: true, detalles: detalle });
  };

  const eliminarVenta = (id) => {
    guardar({
      ...state,
      showModalCancelar: true,
      idVentaCancelacion: id,
    });
  };

  const reimprimir = (id) => {
    dispatch(imprimirVenta(id));
  };

  const opcionesFiltro = [
    { valor: "id", label: "ID", tipo: "number", criteria: ["equal"] },
    {
      valor: "fecha",
      label: "Fecha",
      tipo: "date",
      criteria: ["date", "order-id"],
    },
    {
      valor: "nombre_completo",
      label: "Cliente",
      tipo: "text",
      criteria: ["cliente", "order-id"],
    },
    {
      valor: "id_metodo_pago",
      label: "Metodo de pago",
      tipo: "options",
      criteria: ["equal", "order-id"],
      opciones: [
        { valor: "1", label: "Efectivo" },
        { valor: "2", label: "Tarjeta Debito/Crédito" },
      ],
    },
  ];

  const onChangeDT = (event) => {
    requestFiltro.limit = event.page_size;
    requestFiltro.offset = event.page_size * (event.page_number - 1);
    guardarRequestFiltro({ ...requestFiltro });

    dispatch(
      busquedaFiltroAction(modelo, {
        offset: requestFiltro.offset,
        limit: requestFiltro.limit,
        criteria: ["all", "order-id"],
      })
    );
  };

  const configDT = {
    show_filter: false,
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Historial ventas</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Historial ventas</h5>
          <Filtro
            opciones={opcionesFiltro}
            modelo={modelo}
            criteriasObligatorios={criteriasObligatorios}
            request={requestFiltro}
          />
          <hr></hr>
          <ReactDatatable
            hover
            records={respuestaFiltro.data ?? []}
            columns={columns}
            dynamic
            total_record={respuestaFiltro.count ?? 0}
            onChange={onChangeDT}
            loading={loadingFiltro}
            config={configDT}
          />
        </Card.Body>
      </Card>

      <ModalHistorialVentasDetalle
        detalles={state.detalles}
        showModal={state.showModalDetalles}
        onHide={() => guardar({ ...state, showModalDetalles: false })}
      />

      <ModalCancelacionVenta
        showModal={state.showModalCancelar}
        idVenta={state.idVentaCancelacion}
        onHide={() => guardar({ ...state, showModalCancelar: false })}
      />
    </>
  );
};
