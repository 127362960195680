import React, { useState, useEffect, useRef } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setModalLecturaMembresiaAction, setNumeroMembresiaAction } from "../../actions/membresiasActions";

export default (props) => {
    const dispatch = useDispatch();
    
    const { showModalLecturaMembresia, numeroMembresia } = useSelector(state => state.membresias);

    const numMembresia = useRef(null);

    useEffect(() => {
        if(showModalLecturaMembresia) {
            numMembresia.current.focus();
        }
    }, [showModalLecturaMembresia]);

    const submitForm = e => {
        e.preventDefault();
        numMembresia.current.value = '';
    }

    const handleClose = () => {
        dispatch( setModalLecturaMembresiaAction(false) );
        dispatch( setNumeroMembresiaAction(null) );
    }

    const onChangeForm = (e) => {
        dispatch( setNumeroMembresiaAction(e.target.value, props.tabla) );
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModalLecturaMembresia} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Lector de Membresia</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitForm}>
            <Modal.Body>
                <Form.Control ref={numMembresia} type="text" onChange={onChangeForm} style={{ width: '0px', position: 'absolute', top: '-999px'}}/>
                <Card className="bg-dark text-white text-center">
                    <Card.Body>Pasa la membresia por el lector.</Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}