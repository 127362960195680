import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Breadcrumb,
  Tabs,
  Tab,
  Button,
  Form,
  Row,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmModal } from "../../components/ConfirmModal";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import { InputNumber } from "primereact/inputnumber";
import { Button as ButtonPrime } from "primereact/button";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";

export default () => {
  const [financiamientos, setFinanciamientos] = useState([]);
  const [metodosPago, setMetodosPago] = useState([]);
  const [expandedRowsActivos, setExpandedRowsActivos] = useState(null);
  const [filters, setFilters] = useState({
    fecha: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    "proveedor.nombre": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "referencia_venta.cliente.nombre_completo": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "sucursal.nombre": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    estatus: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const dataTable = useRef(null);

  useEffect(() => {
    getFinanciamientos();
    getMetodosPago();
  }, []);

  const getMetodosPago = () => {
    clienteAxios.get("typeahead/metodosPago?query=**").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setMetodosPago(respuesta.data.data);
      }
    });
  };

  const getFinanciamientos = () => {
    clienteAxios.get("proveedores/0/financiamientos").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setFinanciamientos(
          respuesta.data.data.map((p) => ({
            ...p,
            fecha: moment.utc(p.fecha).toDate(),
            estatus:
              parseFloat(p.total_pagado) >= parseFloat(p.total_financiado)
                ? "Pagado"
                : "Pendiente",
          }))
        );
      }
    });
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const montoBodyTemplate = (monto) => {
    return formatCurrency(monto);
  };

  const productoBodyTemplate = (rowData) => {
    return rowData.producto?.nombre;
  };

  const productoPagoBodyTemplate = (rowData, dataFinanciamiento) => {
    //return rowData.producto?.nombre;
    return (
      <>
        <Form.Select
          name="id_producto"
          onChange={(e) => onChangeRowPago(rowData, e)}
          defaultValue={rowData.id_producto}
          disabled={rowData.id != null && rowData.id !== 0}
        >
          <option value="">Selecciona el producto</option>
          {dataFinanciamiento.productos.map((producto) => (
            <option value={producto.id}> {producto.nombre} </option>
          ))}
        </Form.Select>
      </>
    );
  };

  const cantidadPagoBodyTemplate = (rowData, dataFinanciamiento) => {
    return (
      <>
        <Form.Control
          type="number"
          name="cantidad"
          placeholder="Cantidad"
          value={rowData.cantidad}
          onChange={(e) => onChangeRowPago(rowData, e)}
          min={0}
          step="any"
          disabled={rowData.id != null && rowData.id !== 0}
        />
      </>
    );
  };

  const referenciaPagoBodyTemplate = (rowData, dataFinanciamiento) => {
    return (
      <>
        <Form.Control
          type="text"
          name="referencia"
          placeholder="Referencia transacción"
          value={rowData.referencia}
          onChange={(e) => onChangeRowPago(rowData, e)}
          disabled={rowData.id != null && rowData.id !== 0}
        />
      </>
    );
  };

  const metodoPagoPagoBodyTemplate = (rowData, dataFinanciamiento) => {
    return (
      <>
        <Form.Select
          name="id_metodo_pago"
          onChange={(e) => onChangeRowPago(rowData, e)}
          defaultValue={rowData.id_metodo_pago}
          disabled={rowData.id != null && rowData.id !== 0}
        >
          <option value="">Selecciona el metodo de pago</option>
          {metodosPago.map((metodoPago) => (
            <option value={metodoPago.id}> {metodoPago.metodo} </option>
          ))}
        </Form.Select>
      </>
    );
  };

  const fechaPagoPagoBodyTemplate = (rowData, dataFinanciamiento) => {
    return (
      <>
        <Form.Control
          type="date"
          name="fecha_pago"
          placeholder="Fecha de pago"
          value={rowData.fecha_pago}
          onChange={(e) => onChangeRowPago(rowData, e)}
          disabled={rowData.id != null && rowData.id !== 0}
        />
      </>
    );
  };

  const montoPagoBodyTemplate = (rowData) => {
    return (
      <InputNumber
        name="monto"
        value={rowData.monto}
        onValueChange={(e) => onChangeRowPago(rowData, e)}
        mode="currency"
        currency="USD"
        locale="en-US"
        disabled={rowData.id != null && rowData.id !== 0}
      />
    );
  };

  const onChangeRowPago = (rowData, e) => {
    setFinanciamientos(
      financiamientos.map((p) =>
        p.id == rowData.id_financiamiento_proveedor
          ? {
              ...p,
              pagos: p.pagos.map((pago) =>
                pago.id == rowData.id
                  ? {
                      ...pago,
                      [e.target.name]: e.target.value,
                    }
                  : pago
              ),
            }
          : p
      )
    );
  };

  const actionPagoBodyTemplate = (rowData, dataFinanciamiento) => {
    return (
      <>
        {rowData.id == null ||
          (rowData.id == 0 && (
            <>
              <ButtonPrime
                icon="pi pi-save"
                rounded
                text
                severity="success"
                aria-label="Guardar"
                onClick={() => agregarPago(rowData, dataFinanciamiento)}
              />
            </>
          ))}

        <ButtonPrime
          icon="pi pi-times"
          rounded
          text
          severity="danger"
          aria-label="Eliminar"
          onClick={() => eliminarPago(rowData)}
        />
      </>
    );
  };

  const agregarPago = async (rowData, dataFinanciamiento) => {
    if (rowData.id != null && rowData.id != 0) {
      return;
    }
    const respuesta = await clienteAxios.post(
      "proveedores/0/financiamientos/" +
        rowData.id_financiamiento_proveedor +
        "/pagos",
      rowData
    );

    if (respuesta && respuesta.data.success === true) {
      Swal.fire(
        "Pago agregado",
        "El pago se ha agregado correctamente.",
        "success"
      );

      setFinanciamientos(
        financiamientos.map((p) =>
          p.id == rowData.id_financiamiento_proveedor
            ? {
                ...p,
                pagos: p.pagos.map((pago) =>
                  pago.id == rowData.id
                    ? {
                        ...pago,
                        id: respuesta.data.data.id,
                      }
                    : pago
                ),
                total_pagado: p.total_pagado + rowData.monto,
                estatus:
                  parseFloat(p.total_pagado) >= parseFloat(p.total_financiado)
                    ? "Pagado"
                    : "Pendiente",
              }
            : p
        )
      );
    }
  };

  const eliminarPago = async (rowData) => {
    if (rowData.id == null || rowData.id == 0) {
      setFinanciamientos(
        financiamientos.map((p) =>
          p.id == rowData.id_financiamiento_proveedor
            ? {
                ...p,
                pagos: p.pagos.filter((pago) => pago.id != rowData.id),
              }
            : p
        )
      );
    } else {
      const respuesta = await clienteAxios.delete(
        "proveedores/0/financiamientos/" +
          rowData.id_financiamiento_proveedor +
          "/pagos/" +
          rowData.id
      );

      if (respuesta && respuesta.data.success === true) {
        Swal.fire(
          "Pago eliminado",
          "El pago se ha eliminado correctamente.",
          "success"
        );

        setFinanciamientos(
          financiamientos.map((p) =>
            p.id == rowData.id_financiamiento_proveedor
              ? {
                  ...p,
                  total_pagado: p.total_pagado - rowData.monto,
                  estatus:
                    parseFloat(p.total_pagado) >= parseFloat(p.total_financiado)
                      ? "Pagado"
                      : "Pendiente",
                  pagos: p.pagos.filter((pago) => pago.id != rowData.id),
                }
              : p
          )
        );
      }
    }
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3" style={{ background: "#f8f8f8" }}>
        <Tabs defaultActiveKey="financiamiento_detalles" fill>
          <Tab
            eventKey="financiamiento_detalles"
            title={
              <span>
                <i class="fas fa-info-circle"></i> Detalle Financiamiento
              </span>
            }
          >
            <br></br>
            <br></br>

            <DataTable value={data.detalles} loading={data.loadingDetalles}>
              <Column field="id" header="Id" sortable></Column>
              <Column
                header="Producto"
                body={productoBodyTemplate}
                sortable
              ></Column>
              <Column field="cantidad" header="Cantidad" sortable></Column>
              <Column
                field="monto"
                header="Monto"
                body={(rowData) => montoBodyTemplate(rowData.monto)}
                sortable
              ></Column>
            </DataTable>
          </Tab>
          <Tab
            eventKey="financiamiento_pagos"
            title={
              <span>
                <i class="fas fa-money-check-alt"></i> Pagos
              </span>
            }
          >
            <br></br>
            <br></br>
            <DataTable
              value={data.pagos}
              loading={data.loadingPagos}
              header={() => renderHeaderPagos(data)}
            >
              <Column field="id" header="Id" sortable></Column>
              <Column
                header="Producto"
                body={(rowData) => productoPagoBodyTemplate(rowData, data)}
              ></Column>
              <Column
                field="cantidad"
                header="Cantidad"
                body={(rowData) => cantidadPagoBodyTemplate(rowData, data)}
              ></Column>
              <Column
                header="Metodo de pago"
                body={(rowData) => metodoPagoPagoBodyTemplate(rowData, data)}
              ></Column>
              <Column
                header="Fecha pago"
                body={(rowData) => fechaPagoPagoBodyTemplate(rowData, data)}
              ></Column>
              <Column
                header="Monto"
                body={(rowData) => montoPagoBodyTemplate(rowData, data)}
              ></Column>
              <Column
                header="Referencia"
                body={(rowData) => referenciaPagoBodyTemplate(rowData, data)}
              ></Column>
              <Column
                headerStyle={{ textAlign: "center" }}
                bodyStyle={{ textAlign: "center" }}
                body={(rowData) => actionPagoBodyTemplate(rowData, data)}
              />
            </DataTable>
          </Tab>
        </Tabs>
      </div>
    );
  };

  const nuevoPago = (idFinanciamiento) => {
    console.log("nuevo pago", idFinanciamiento);

    setFinanciamientos(
      financiamientos.map((p) =>
        p.id == idFinanciamiento
          ? {
              ...p,
              pagos: [
                ...p.pagos,
                {
                  id: 0,
                  cantidad: 0,
                  fecha_pago: "",
                  monto: 0,
                  id_metodo_pago: 0,
                  id_financiamiento_proveedor: p.id,
                  id_producto: null,
                  referencia: null,
                },
              ],
            }
          : p
      )
    );
  };

  const disableBtnPagosHandler = (idFinanciamiento) => {
    const pagoNuevo = financiamientos
      .find((p) => p.id == idFinanciamiento)
      ?.pagos.find((p) => p.id == 0);
    if (pagoNuevo !== undefined) {
      return true;
    }
    return false;
  };

  const renderHeaderPagos = (data) => (
    <div className="d-flex align-items-center justify-content-end gap-2">
      <div className="ml-auto">
        <Button
          variant="success"
          onClick={() => nuevoPago(data.id)}
          disabled={disableBtnPagosHandler(data.id)}
        >
          <i className="fas fa-plus"></i> Nuevo pago
        </Button>
      </div>
    </div>
  );

  const onRowExpand = async (event) => {
    setFinanciamientos(
      financiamientos.map((p) =>
        p.id == event.data.id
          ? {
              ...p,
              loadingDetalles: true,
              loadingPagos: true,
              pagos: [],
              detalles: [],
              productos: [],
            }
          : p
      )
    );
    const respuestaDetalles = await clienteAxios.get(
      "proveedores/0/financiamientos/" + event.data.id + "/detalles"
    );

    const respuestaPagos = await clienteAxios.get(
      "proveedores/0/financiamientos/" + event.data.id + "/pagos"
    );

    const respuestaProductos = await clienteAxios.get(
      "proveedores/0/financiamientos/" + event.data.id + "/productos"
    );

    setFinanciamientos(
      financiamientos.map((p) =>
        p.id == event.data.id
          ? {
              ...p,
              loadingDetalles: false,
              loadingPagos: false,
              detalles: respuestaDetalles.data.data,
              pagos: respuestaPagos.data.data,
              productos: respuestaProductos.data.data,
            }
          : p
      )
    );
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="yy-mm-dd"
        placeholder="Fecha"
        mask="9999-99-99"
      />
    );
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.fecha);
  };

  const formatDate = (value) => {
    const date = new Date(value);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const estatusBodyTemplate = (rowData) => {
    const option = rowData.estatus === "Pagado" ? "success" : "danger";
    return <Tag value={rowData.estatus} severity={option} />;
  };

  const estatusItemTemplate = (option) => {
    const severity = option === "Pagado" ? "success" : "danger";
    return <Tag value={option} severity={severity} />;
  };

  const estatusFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={["Pagado", "Pendiente"]}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={estatusItemTemplate}
        placeholder="Selecciona el estatus"
        className="p-column-filter"
        showClear
      />
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Financiamientos Proveedores
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <Tabs
            defaultActiveKey="financiamientos_activos"
            id="uncontrolled-tab-example"
            fill
          >
            <Tab
              eventKey="financiamientos_activos"
              title={
                <span>
                  <i class="fas fa-file-invoice-dollar"></i> Financiamientos
                </span>
              }
            >
              <br></br>
              <br></br>
              <DataTable
                ref={dataTable}
                value={financiamientos}
                expandedRows={expandedRowsActivos}
                onRowToggle={(e) => setExpandedRowsActivos(e.data)}
                onRowExpand={onRowExpand}
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="id"
                filters={filters}
                filterLocale="es"
              >
                <Column expander={true} style={{ width: "5rem" }} />
                <Column field="id" header="ID" sortable />
                <Column
                  field="proveedor.nombre"
                  header="Proveedor"
                  filterField="proveedor.nombre"
                  filter
                  filterPlaceholder="Buscar por proveedor"
                  sortable
                />
                <Column
                  field="referencia_venta.cliente.nombre_completo"
                  header="Cliente"
                  filterField="referencia_venta.cliente.nombre_completo"
                  filter
                  filterPlaceholder="Buscar por cliente"
                  sortable
                  style={{ minWidth: '12rem' }}
                />
                <Column
                  body={dateBodyTemplate}
                  filter
                  filterElement={dateFilterTemplate}
                  filterField="fecha"
                  dataType="date"
                  header="Fecha"
                  sortable
                />
                <Column
                  header="Total financiado ($)"
                  sortable
                  body={(rowData) =>
                    montoBodyTemplate(parseFloat(rowData.total_financiado))
                  }
                />
                <Column
                  header="Total pagado ($)"
                  sortable
                  body={(rowData) =>
                    montoBodyTemplate(parseFloat(rowData.total_pagado))
                  }
                />
                <Column
                  header="Saldo restante ($)"
                  sortable
                  body={(rowData) =>
                    montoBodyTemplate(
                      rowData.total_financiado - rowData.total_pagado
                    )
                  }
                />
                <Column
                  header="Referencia"
                  body={(rowData) =>
                    rowData.tabla_referencia +
                    " ID: " +
                    rowData.id_tabla_referencia
                  }
                />
                <Column
                  field="estatus"
                  header="Estatus"
                  body={estatusBodyTemplate}
                  sortable
                  filter
                  filterElement={estatusFilterTemplate}
                />
                <Column
                  field="sucursal.nombre"
                  header="Sucursal"
                  sortable
                  filterField="sucursal.nombre"
                  filter
                  filterPlaceholder="Buscar por sucursal"
                  dataType="text"
                />
              </DataTable>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};
