import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarMenuAction, cerrarModalActualizarAction } from '../../actions/menuActions';

export default (props) => {
    const dispatch = useDispatch();
    
    const { menu, showModalEditar } = useSelector(state => state.menu);

    const [ edicion, guardarMenu] = useState({
        'nombre': '',
        'url': '',
        'funciones': false,
        'icono': ''
    })

    useEffect(() => {
        guardarMenu(menu);
    }, [menu])

    const submitForm = e => {
        e.preventDefault();
        dispatch( actualizarMenuAction(edicion) );
    }

    const handleClose = () => {
        dispatch( cerrarModalActualizarAction() );
    }

    const onChangeForm = (e) => {
        guardarMenu({
            ...edicion,
            [e.target.name] : e.target.value
        })
    }

    const onChangeCheckboxForm = (e) => {
        guardarMenu({
            ...edicion,
            [e.target.name] : e.target.checked
        })
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModalEditar} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Editar Menu</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitForm}>
            <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" name="nombre" placeholder="Nombre del menu" 
                            value={edicion.nombre} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group style={{ display: edicion.funciones ? 'none' : 'block' }} className="mb-3">
                        <Form.Label>URL</Form.Label>
                        <Form.Control type="text" name="descripcion" placeholder="URL" 
                            name="url" value={edicion.url} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Icono</Form.Label>
                        <Form.Control type="text" name="icono" placeholder="Icono" 
                            value={edicion.icono} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Funciones</Form.Label>
                        <Form.Check style={{"marginLeft": "20px"}}
                            name="funciones"
                            id="funciones"
                            htmlFor="funciones"
                            defaultChecked={edicion.funciones}
                            onChange={onChangeCheckboxForm}
                        />
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary">
                    Actualizar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}