import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "@themesberg/react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";

export default (props) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [movimientos, setMovimientos] = useState([]);

  useEffect(() => {
    setShowModal(props.showModal);
    setMovimientos(props.movimientos);
  }, [props]);

  const columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      key: "created_at",
      text: "Fecha",
      sortable: true,
    },
    {
      key: "tipo",
      text: "Tipo",
      align: "left",
      sortable: true,
    },
    {
      key: "cantidad",
      text: "Cantidad",
      sortable: true,
    },
    {
      key: "motivo",
      text: "Descripción",
    },
    {
      key: "precio_unidad",
      text: "Costo unitario",
    },
    {
      text: "Proveedor",
      sortable: true,
      cell: (record) => {
        return record.proveedor?.nombre;
      },
    },
  ];

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Movimientos</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <ReactDatatable hover records={movimientos} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
