import React, { Suspense } from "react";

const load = async (path, folder, file) => {
    const defaultPath = "pages";
    const files = require.context("../", true, /\.js$/);
    try {
      return files(`./${path}/${folder}/${file}.js`);
    } catch (err) {
      return files(`./${defaultPath}/${folder}/${file}.js`);
    }
  };

export function pageInterceptor(folder, file) {
    const Component = React.lazy(() => load('reglas/pages', folder, file));

  return (props) => (
      <Suspense fallback={<div>Loading...</div>}>
        <Component {...props} />
      </Suspense>
  );
}
