import * as Types from '../types';

const initialState = {
    tratamiento: {},
    precios: [],
    loading: false,
    loadingAgregar: false,
    loadingEditar: false,
    showModalAgregar: false,
    showModalEditar: false,
    showModalLista: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_TRATAMIENTOS_PRECIOS:
            return {
                ...state,
                loading: true,
                showModalLista: true,
                tratamiento: action.payload
            }
        case Types.LISTA_TRATAMIENTOS_PRECIOS_EXITO:
            return {
                ...state,
                loading: false,
                precios: action.payload
            }
        case Types.LISTA_TRATAMIENTOS_PRECIOS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case Types.LISTA_TRATAMIENTOS_PRECIOS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case Types.COMENZAR_AGREGAR_TRATAMIENTO_PRECIO:
            return {
                ...state,
                showModalAgregar: true
            }
        case Types.AGREGAR_TRATAMIENTO_PRECIO:
            return {
                ...state,
                loadingAgregar: true
            }
        case Types.AGREGAR_TRATAMIENTO_PRECIO_EXITO:
            return {
                ...state,
                loadingAgregar: false,
                precios: state.precios.concat(action.payload),
                showModalAgregar: false
            }
        case Types.AGREGAR_TRATAMIENTO_PRECIO_ERROR:
            return {
                ...state,
                loadingAgregar: false,
                error: action.payload
            }
        case Types.CERRAR_MODAL_AGREGAR_TRATAMIENTO_PRECIO:
            return {
                ...state,
                showModalAgregar: false
            }
        case Types.COMENZAR_EDITAR_TRATAMIENTO_PRECIO:
            return {
                ...state,
                showModalEditar: true,
                precio: action.payload
            }
        case Types.EDITAR_TRATAMIENTO_PRECIO:
            return {
                ...state,
                loadingEditar: true
            }
        case Types.EDITAR_TRATAMIENTO_PRECIO_EXITO:
            return {
                ...state,
                loadingEditar: false,
                precios: state.precios.map( precio => 
                    precio.id === action.payload.id ? precio = action.payload : precio
                )
            }
        case Types.EDITAR_TRATAMIENTO_PRECIO_ERROR:
            return {
                ...state,
                loadingEditar: false,
                error: action.payload
            }
        case Types.CERRAR_MODAL_EDITAR_TRATAMIENTO_PRECIO:
            return {
                ...state,
                showModalEditar: false
            }
        case Types.CERRAR_MODAL_LISTA_TRATAMIENTO_PRECIO:
            return {
                ...state,
                showModalLista: false
            }
        case Types.ELIMINAR_TRATAMIENTO_PRECIO_EXITO:
            return {
                ...state,
                precios: state.precios.filter( precio => 
                    precio.id != action.payload.id
                )
            }
        case Types.ELIMINAR_TRATAMIENTO_PRECIO_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}