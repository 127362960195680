import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";

import Logo from "../assets/img/logo.png";

import { iniciarSesionAction } from "../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default (props) => {
  const [username, guardarUsuario] = useState("");
  const [password, guardarPassword] = useState("");

  const dispatch = useDispatch();

  const { loading: cargando, user } = useSelector((state) => state.auth);

  const history = useHistory();
  if (user != null) {
    history.push("/");
  }

  const iniciarSesion = (credenciales) =>
    dispatch(iniciarSesionAction(credenciales));

  const submitLogin = (e) => {
    e.preventDefault();

    if (username.trim() === "" || password.trim() === "") {
      return;
    }

    iniciarSesion({
      username,
      password,
    });
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <img
                    src={
                      process.env.REACT_APP_API_BASE_URL +
                      "/image/LOGO?conf=true"
                    }
                    alt="Logo"
                    style={{ maxHeight: "200px" }}
                  />
                  <h3 className="mb-0">Ingresa tus credenciales</h3>
                </div>
                <Form className="mt-4" onSubmit={submitLogin}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Usuario</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={username}
                        onChange={(e) => guardarUsuario(e.target.value)}
                        placeholder="Nombre de usuario"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          value={password}
                          onChange={(e) => guardarPassword(e.target.value)}
                          placeholder="Password"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    <div
                      style={{ display: cargando ? "block" : "none" }}
                      className="spinner-border text-light mx-auto"
                      role="status"
                    ></div>
                    {!cargando ? "Ingresar" : null}
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
