import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { 
    cerrarModalListaAction, 
    comenzarAgregarFuncionAction,
    comenzarEditarFuncionAction,
    eliminarFuncionAction
} from '../../actions/menuFuncionesActions';
import DataTable from '../../components/DataTable';
import { ConfirmModal } from '../../components/ConfirmModal';

import AgregarFunciones from './AgregarFunciones';
import EditarFunciones from './EditarFunciones';

export default (props) => {
    const dispatch = useDispatch();
    
    const { showModalFunciones, funciones } = useSelector(state => state.menuFunciones);

    const columns = [
        {
            key: "id",
            text: "ID",
            className: "id",
            align: "left",
            sortable: true
        },
        {
            key: "nombre",
            text: "Nombre",
            className: "nombre",
            align: "left",
            sortable: true,
        },
        {
            key: "url",
            text: "URL",
            className: "url",
            sortable: true
        }
    ];

    const state = {
        records: funciones
    }

    const extraButtons =[
        {
            className:"btn btn-info",
            title:"Agregar función",
            children:[
                <span>
                    <i class="fas fa-plus"></i> Nueva función
                </span>
            ],
            onClick:(event)=>{
                dispatch(comenzarAgregarFuncionAction());
            },
        },
    ]

    const editRecord = (record) => {
        dispatch(comenzarEditarFuncionAction(record));
    }

    const deleteRecord = (record) => {
        const callbacks = [
            {
                event: dispatch,
                action: eliminarFuncionAction,
                args: [record]
            }
        ]

        ConfirmModal(callbacks);
    }

    const handleClose = () => {
        dispatch( cerrarModalListaAction() );
    }

    return (
        <>
        <Modal as={Modal.Dialog} size="lg" centered show={showModalFunciones} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Funciones</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <DataTable 
                    columns = {columns}
                    editRecord = {editRecord}
                    deleteRecord = {deleteRecord}
                    state = {state}
                    extraButtons = {extraButtons}
                    config = {{show_filter: false, show_pagination: false}}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <AgregarFunciones />
        <EditarFunciones />
        </>
    );
}