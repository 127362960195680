import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function showModalEntradaSalidaAction(estatus) {
    return (dispatch) => {
        dispatch( showModalEntradaSalida(estatus) );
    }
}

const showModalEntradaSalida = estatus => ({
    type: Types.MODAL_REGISTRAR_ENTRADA_SALIDA,
    payload: estatus
})

export function registrarMovimientoAction(movimiento) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( registrarMovimiento() );
        try {
            const response = await clienteAxios.put('inventario/actualizar', movimiento);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( registrarMovimientoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( registrarMovimientoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const registrarMovimiento = () => ({
    type: Types.REGISTRAR_ENTRADA_SALIDA
})

const registrarMovimientoExito = movimiento => ({
    type: Types.REGISTRAR_ENTRADA_SALIDA_EXITO,
    payload: movimiento
})

const registrarMovimientoError = error => ({
    type: Types.REGISTRAR_ENTRADA_SALIDA_ERROR,
    payload: error
})

export function listaInventarioAction() {
    return async (dispatch) => {
        dispatch( listaInventario() );
        try {
            const response = await clienteAxios.get('inventario/list');
            if (response.data.data) {
                dispatch( listaInventarioExito(response.data.data) );
            }
        } catch (error) {
            dispatch( listaInventarioError(error) );
        }
    }
}

const listaInventario = () => ({
    type: Types.LISTA_INVENTARIO
})

const listaInventarioExito = inventario => ({
    type: Types.LISTA_INVENTARIO_EXITO,
    payload: inventario
})

const listaInventarioError = error => ({
    type: Types.LISTA_INVENTARIO_ERROR,
    payload: error
})