import {
    LISTA_MENU,
    LISTA_MENU_EXITO,
    LISTA_MENU_ERROR,
    COMENZAR_AGREGAR_MENU,
    CERRAR_MODAL_AGREGAR_MENU,
    AGREGAR_MENU,
    AGREGAR_MENU_EXITO,
    AGREGAR_MENU_ERROR,
    COMENZAR_ACTUALIZAR_MENU,
    ACTUALIZAR_MENU,
    ACTUALIZAR_MENU_EXITO,
    ACTUALIZAR_MENU_ERROR,
    CERRAR_MODAL_ACTUALIZAR_MENU,
    ELIMINAR_MENU_EXITO,
    ELIMINAR_MENU_ERROR
} from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getMenusAction() {
    return async (dispatch) => {
        dispatch( getMenus() );
        try {
            const response = await clienteAxios.get('menu/0');
            if (response.data.data) {
                dispatch( getMenusExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getMenusError(error) );
        }
    }
}

const getMenus = () => ({
    type: LISTA_MENU
})

const getMenusExito = menus => ({
    type: LISTA_MENU_EXITO,
    payload: menus
})

const getMenusError = error => ({
    type: LISTA_MENU_ERROR,
    payload: error
})

export function comenzarAgregarMenuAction() {
    return (dispatch) => {
        dispatch( comenzarAgregarMenu() );
    }
}

const comenzarAgregarMenu = () => ({
    type: COMENZAR_AGREGAR_MENU,
})

export function cerrarModalAgregarAction() {
    return (dispatch) => {
        dispatch( cerrarModalAgregar() );
    }
}

const cerrarModalAgregar = () => ({
    type: CERRAR_MODAL_AGREGAR_MENU,
})

export function agregarMenuAction(menu) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarMenu() );

        try {
            const response = await clienteAxios.post('menu/agregar', menu);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarMenuExito(response.data.data) );
                
                menu.nombre = '';
                menu.url = '';
                menu.icono = '';
            }
        } catch (error) {
            dispatch( agregarMenuError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarMenu = () => ({
    type: AGREGAR_MENU
})

const agregarMenuExito = menu => ({
    type: AGREGAR_MENU_EXITO,
    payload: menu
})

const agregarMenuError = error => ({
    type: AGREGAR_MENU_ERROR,
    payload: error
})

export function actualizarMenuAction(menu) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( actualizarMenu() );

        try {
            const response = await clienteAxios.put('menu/actualizar/'+menu.id, menu);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( actualizarMenuExito(response.data.data) );
            }
        } catch (error) {
            dispatch( actualizarMenuError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const actualizarMenu = () => ({
    type: ACTUALIZAR_MENU
})

const actualizarMenuExito = menu => ({
    type: ACTUALIZAR_MENU_EXITO,
    payload: menu
})

const actualizarMenuError = error => ({
    type: ACTUALIZAR_MENU_ERROR,
    payload: error
})

export function comenzarActualizarMenuAction(menu) {
    return (dispatch) => {
        dispatch( comenzarActualizarMenu(menu) );
    }
}

const comenzarActualizarMenu = menu => ({
    type: COMENZAR_ACTUALIZAR_MENU,
    payload: menu
})

export function cerrarModalActualizarAction() {
    return (dispatch) => {
        dispatch( cerrarModalActualizar() );
    }
}

const cerrarModalActualizar = () => ({
    type: CERRAR_MODAL_ACTUALIZAR_MENU,
})

export function eliminarMenuAction(menu) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('menu/eliminar/'+menu.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarMenuExito(menu) );
            }
        } catch (error) {
            dispatch( eliminarMenuError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarMenuExito = menu => ({
    type: ELIMINAR_MENU_EXITO,
    payload: menu
})

const eliminarMenuError = error => ({
    type: ELIMINAR_MENU_ERROR,
    payload: error
})