import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col
} from "@themesberg/react-bootstrap";
import { capitalize } from "../../../helpers";
import clienteAxios from "../../../config/axios";
import Swal from "sweetalert2";

export default (props) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(props.showModal);

    if (props.showModal) {
      limpiarForm();
    }
  }, [props.showModal]);


  const [form, guardar] = useState({
    fecha_desde: null,
    fecha_hasta: null,
  });

  const submitForm = (e) => {
    e.preventDefault();

    clienteAxios.post("tesoreria/gastos-operaciones/bitacoras", form).then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  const handleClose = () => {
    setShowModal(false);
    limpiarForm();
    props.onHide();
  };

  const onChangeForm = (e) => {
    guardar({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const limpiarForm = () => {
    guardar({
        fecha_desde: null,
        fecha_hasta: null,
    });
  }

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">
          Generar Bitácora de Gastos
        </Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
            <Form.Group className="mb-3">
                <Form.Label>Fecha desde*</Form.Label>
                <Form.Control
                    type="date"
                    name="fecha_desde"
                    placeholder="Fecha desde"
                    value={form.fecha_desde}
                    onChange={onChangeForm}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Fecha hasta*</Form.Label>
                <Form.Control
                    type="date"
                    name="fecha_hasta"
                    placeholder="Fecha hasta"
                    value={form.fecha_hasta}
                    onChange={onChangeForm}
                />
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Generar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
