import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import {
  agregarUsuarioAction,
  cerrarModalAgregarAction,
} from "../../actions/usuarioActions";
import { useDispatch, useSelector } from "react-redux";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import clienteAxios from "../../config/axios";
import DefaultUser from "../../assets/img/team/default-user.png";
import Typeahead from "../../components/Typeahead";

export default (props) => {
  var _cacheEmpleados = {};

  const dispatch = useDispatch();

  const [usuarioAgregar, guardarUsuario] = useState({
    email: "",
    name: "",
    username: "",
    password: "",
    id_empleado: null,
    id_sucursal: null,
    multisucursal: false
  });

  const [state, setState] = useState({
    query: "",
    options: [],
    isLoading: false,
    empleadoSeleccionado: null,
    sucursal: null,
    id_sucursal: null,
  });

  const { showModalAgregar, usuario } = useSelector((state) => state.usuario);

  const onChangeForm = (e) => {
    guardarUsuario({
      ...usuarioAgregar,
      [e.target.name]: e.target.value,
    });
  };

  const submitAgregarUsuario = (e) => {
    e.preventDefault();
    usuarioAgregar.id_empleado =
      state.empleadoSeleccionado !== null
        ? state.empleadoSeleccionado.id
        : null;
    dispatch(agregarUsuarioAction(usuarioAgregar));
  };

  const handleClose = () => {
    dispatch(cerrarModalAgregarAction());
  };

  const _handleInputChangeEmpleados = (query) => {
    state.query = query;
  };

  const makeAndHandleRequestEmpleados = (query) => {
    const config = { params: { query } };

    return clienteAxios.get("typeahead/empleados", config).then((res) => {
      if (res.data.data === null || res.data.data.length === 0) {
        return { options: [] };
      }

      const options = res.data.data.map((i) => ({
        id: i.id,
        label: i.nombre + " " + i.apellido_paterno,
      }));

      return { options };
    });
  };

  const _handleSearchEmpleados = (query) => {
    if (_cacheEmpleados[query]) {
      setState({
        ...state,
        options: _cacheEmpleados[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequestEmpleados(query).then((resp) => {
      _cacheEmpleados[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };

  const onChangeEmpleados = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        empleadoSeleccionado: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        empleadoSeleccionado: null,
      });
    }
  };

  const getSucursal = () => {
    if (usuarioAgregar.sucursal) {
      return [
        {
          id: usuarioAgregar.sucursal.id,
          label: usuarioAgregar.sucursal.nombre,
        },
      ];
    }

    return [];
  };

  const onChangeSucursal = (selectedOption) => {
    if (selectedOption.length > 0) {
      guardarUsuario({
        ...usuarioAgregar,
        id_sucursal: selectedOption[0].id,
        sucursal: selectedOption[0],
      });
    } else {
      guardarUsuario({
        ...usuarioAgregar,
        id_sucursal: null,
        sucursal: null,
      });
    }
  };

  const onChangeCheckbox = (e) => {
    guardarUsuario({
      ...usuarioAgregar,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalAgregar}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Agregar usuario</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitAgregarUsuario}>
        <Modal.Body>
          <Row className="justify-content-end">
            <Col>
              <Form.Group className="mb-3">
                <Form.Check
                  reverse
                  type="switch"
                  label="Multisucursal"
                  name="multisucursal"
                  checked={usuarioAgregar.multisucursal}
                  onChange={onChangeCheckbox}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Nombre completo"
              value={usuarioAgregar.name}
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nombre de usuario</Form.Label>
            <Form.Control
              type="text"
              name="username"
              placeholder="Nombre de usuario"
              value={usuarioAgregar.username}
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Correo electronico</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Correo electronico"
              value={usuarioAgregar.email}
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="Contraseña"
              value={usuarioAgregar.password}
              onChange={onChangeForm}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Empleado asociado</Form.Label>
            <AsyncTypeahead
              {...state}
              clearButton
              id="async-empleados"
              labelKey="label"
              minLength={2}
              onInputChange={_handleInputChangeEmpleados}
              onSearch={_handleSearchEmpleados}
              onChange={onChangeEmpleados}
              placeholder="Buscar empleado"
              renderMenuItemChildren={(option) => (
                <div key={option.id}>
                  <img
                    alt={option.nombre}
                    src={DefaultUser}
                    style={{
                      height: "24px",
                      marginRight: "10px",
                      width: "24px",
                    }}
                  />
                  <span>{option.label}</span>
                </div>
              )}
              useCache={false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Sucursal</Form.Label>
            <Typeahead
              url="typeahead/sucursales"
              labelKey="label"
              id="async-sucursales"
              placeholder="Buscar sucursal"
              onChange={(selected) => {
                onChangeSucursal(selected);
              }}
              selected={getSucursal()}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Agregar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
