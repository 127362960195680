import {
    LISTA_MODULOS,
    LISTA_MODULOS_EXITO,
    LISTA_MODULOS_ERROR
} from '../types';

import clienteAxios from '../config/axios';

export function getModulosAction() {
    return async (dispatch) => {
        dispatch( getModulos() );
        try {
            const response = await clienteAxios.get('modulos/0');
            if (response.data.data) {
                dispatch( getModulosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getModulosError(error) );
        }
    }
}

const getModulos = () => ({
    type: LISTA_MODULOS
})

const getModulosExito = modulos => ({
    type: LISTA_MODULOS_EXITO,
    payload: modulos
})

const getModulosError = error => ({
    type: LISTA_MODULOS_ERROR,
    payload: error
})