import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Tab,
  Row,
  Nav,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import {
  eliminarVentaAction,
} from "../../actions/ventaActions";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";

export default (props) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [motivos, setMotivos] = useState([]);
  const [idVenta, setIdVenta] = useState(null);

  const [form, guardar] = useState({
    id_motivo: null,
  });

  useEffect(() => {
    setShowModal(props.showModal);
    setIdVenta(props.idVenta);
  }, [props]);

  useEffect(() => {
    if (showModal === true) {
      getMotivos();
    }
  }, [showModal]);

  const getMotivos = () => {
    clienteAxios
      .get("typeahead/valores/MOTIVOS_CANCELACION_VENTA?query=**")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setMotivos(respuesta.data.data);
        }
      });
  };

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const submitForm = (e) => {
    e.preventDefault();

    dispatch(eliminarVentaAction(idVenta, form.id_motivo));
    handleClose();
  };

  const onChange = (e) => {
    guardar({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="sm"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Cancelar venta</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body className="shadow-sm">
            <Form.Group className="mb-3">
              <Form.Label>Motivo de cancelación</Form.Label>
              <Form.Select name="id_motivo" onChange={onChange}>
                <option value="">Selecciona el motivo de cancelación</option>
                {motivos.map((motivo, index) => {
                  return <option value={motivo.id}>{motivo.label}</option>;
                })}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              Cancelar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
