import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Tabs, Tab } from "@themesberg/react-bootstrap";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import MultiSelect from "@kenshooui/react-multi-select";
import { useDispatch, useSelector } from "react-redux";
import { getUsuariosAction } from "../../actions/usuarioActions";
import { getRolesAction } from "../../actions/rolesActions";

export default (props) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [permisos, setPermisos] = useState([]);
  const { usuarios } = useSelector((state) => state.usuario);
  const { roles } = useSelector((state) => state.roles);

  useEffect(() => {
    setShowModal(props.showModal);

    guardar({
      ...edicion,
      ...props.selectedRow,
    });
  }, [props]);

  useEffect(() => {
    if (props.showModal === true) {
      dispatch(getUsuariosAction());
      dispatch(getRolesAction());
      getPermisos();
    }
  }, [props.showModal]);

  useEffect(() => {
    setState({
      ...state,
      selectedUsers: permisos
        .filter((permiso) => permiso.id_usuario !== null)
        .map((item) => {
          return {
            id: item.id_usuario,
            name: item.usuario.name,
          };
        }),
      selectedRoles: permisos
        .filter((permiso) => permiso.id_rol !== null)
        .map((item) => {
          return {
            id: item.id_rol,
            name: item.rol.nombre
          };
        }),
    });
  }, [permisos]);

  const [edicion, guardar] = useState({
    id_usuarios: null,
    id_roles: null,
  });

  const [state, setState] = useState({
    selectedUsers: [],
    selectedRoles: [],
    defaultActiveKey: "usuarios",
  });

  const getPermisos = () => {
    clienteAxios
      .get("estadisticas/" + props.selectedRow.id + "/permisos")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setPermisos(respuesta.data.data);
        }
      });
  };

  const submitForm = (e) => {
    e.preventDefault();

    const request = {
      id_estadistica: edicion.id,
      id_usuarios: state.selectedUsers.map((item) => item.id),
      id_roles: state.selectedRoles.map((item) => item.id),
    };

    clienteAxios
      .put("estadisticas/" + edicion.id, request)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          props.onUpdate(respuesta.data.data);
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectTab = (key) => {
    setState({
      ...state,
      defaultActiveKey: key,
    });
  };

  const handleChange = (selectedItems) => {
    setState({
      ...state,
      selectedUsers: selectedItems,
    });
  };

  const handleChangeRoles = (selectedItems) => {
    setState({
      ...state,
      selectedRoles: selectedItems,
    });
  };

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Permisos</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Tabs
            defaultActiveKey={state.defaultActiveKey}
            onSelect={onSelectTab}
            id="uncontrolled-tab-example"
            fill
          >
            <Tab eventKey="usuarios" title="Usuarios">
              <MultiSelect
                items={usuarios.map((usuario) => {
                  return {
                    id: usuario.id,
                    label: usuario.name,
                  };
                })}
                selectedItems={state.selectedUsers}
                onChange={handleChange}
                messages={{
                  searchPlaceholder: "Buscar...",
                  noItemsMessage: "No se encontraron resultados...",
                  noneSelectedMessage: "Sin seleccionar",
                  selectedMessage: "Seleccionado",
                  selectAllMessage: "Seleccionar todo",
                  clearAllMessage: "Limpiar todo",
                  disabledItemsTooltip: "Solo puedes seleccionar una opción",
                }}
              />
            </Tab>
            <Tab eventKey="roles" title="Roles">
              <MultiSelect
                items={roles.map((rol) => {
                  return {
                    id: rol.id,
                    label: rol.nombre,
                  };
                })}
                selectedItems={state.selectedRoles}
                onChange={handleChangeRoles}
                messages={{
                  searchPlaceholder: "Buscar...",
                  noItemsMessage: "No se encontraron resultados...",
                  noneSelectedMessage: "Sin seleccionar",
                  selectedMessage: "Seleccionado",
                  selectAllMessage: "Seleccionar todo",
                  clearAllMessage: "Limpiar todo",
                  disabledItemsTooltip: "Solo puedes seleccionar una opción",
                }}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Actualizar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
