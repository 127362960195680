import * as Types from '../types';
import clienteAxios from '../config/axios';
import { loadingRequest } from './axiosActions';
import Swal from 'sweetalert2';

export function getDatosAdicionalesDetallesAction(tabla, id_tabla) {
    return async (dispatch) => {
        dispatch( getDatosAdicionalesDetalles() );
        try {
            const response = await clienteAxios.get('datos-adicionales/detalles/' + tabla + '/' + id_tabla);
            if (response.data.data && response.data.data.length > 0) {
                response.data.data.map(dato => {
                    dispatch( actualizarDatoAdicionalDetalle(dato) );
                });
            }

            dispatch( getDatosAdicionalesDetallesExito(response.data.data) );
        } catch (error) {
            console.log(error);
            dispatch( getDatosAdicionalesDetallesError(error) );
        }
    }
}

const getDatosAdicionalesDetalles = () => ({
    type: Types.LISTA_DATOS_ADICIONALES_DETALLES
})

const getDatosAdicionalesDetallesExito = detalles => ({
    type: Types.LISTA_DATOS_ADICIONALES_DETALLES_EXITO,
    payload: detalles
})

const getDatosAdicionalesDetallesError = error => ({
    type: Types.LISTA_DATOS_ADICIONALES_DETALLES_ERROR,
    payload: error
})

export function actualizarDatoAdicionalDetalleAction(detalle) {
    return (dispatch) => {
        dispatch( actualizarDatoAdicionalDetalle(detalle) );
    }
}

const actualizarDatoAdicionalDetalle = (detalle) => ({
    type: Types.ACTUALIZAR_DATOS_ADICIONALES_DETALLE,
    payload: detalle
})