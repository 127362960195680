import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { getPacientesFinanciadosAction, getPacienteTratamientosFinanciadosAction } from './pacientesActions';
import { loadingRequest } from './axiosActions';

export function getAbonosTratamientosAction(id_cliente, cliente_tratamiento) {
    return async (dispatch) => {
        dispatch( getAbonosTratamientos(cliente_tratamiento) );
        try {
            const response = await clienteAxios.get('abonos/list-abonos-tratamiento/'+cliente_tratamiento.id);
            if (response.data.data) {
                dispatch( getAbonosTratamientosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getAbonosTratamientosError(error) );
        }
    }
}

const getAbonosTratamientos = (id_cliente_tratamiento) => ({
    type: Types.LISTA_ABONOS_TRATAMIENTOS,
    payload: id_cliente_tratamiento
})

const getAbonosTratamientosExito = abonos => ({
    type: Types.LISTA_ABONOS_TRATAMIENTOS_EXITO,
    payload: abonos
})

const getAbonosTratamientosError = error => ({
    type: Types.LISTA_ABONOS_TRATAMIENTOS_ERROR,
    payload: error
})

export function showModalAbonosAction(estatus) {
    return (dispatch) => {
        dispatch(showModalAbonos(estatus));
    }
}

const showModalAbonos = estatus => ({
    type: Types.MODAL_ABONOS,
    payload: estatus
})

export function showModalAbonarAction(estatus) {
    return (dispatch) => {
        dispatch(showModalAbonar(estatus));
    }
}

const showModalAbonar = estatus => ({
    type: Types.MODAL_AGREGAR_ABONO,
    payload: estatus
})

export function agregarAbonoAction(abono) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarAbono() );
        try {
            const response = await clienteAxios.post('abonos/agregar', abono);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarAbonoExito(response.data.data) );

                const ticketResponse = await clienteAxios.get(
                    "imprimir/abono/" + response.data.data.id,
                    { responseType: "blob" }
                  );
                  const file = new Blob([ticketResponse.data], {
                    type: "application/pdf",
                  });
          
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL);
            }
        } catch (error) {
            dispatch( agregarAbonoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarAbono = () => ({
    type: Types.AGREGAR_ABONO
})

const agregarAbonoExito = abono => ({
    type: Types.AGREGAR_ABONO_EXITO,
    payload: abono
})

const agregarAbonoError = error => ({
    type: Types.AGREGAR_ABONO_ERROR,
    payload: error
})

export function eliminarAbonoAction(abono) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('abonos/eliminar/'+abono.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarAbonoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarAbonoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarAbonoExito = precio => ({
    type: Types.ELIMINAR_ABONO_EXITO,
    payload: precio
})

const eliminarAbonoError = error => ({
    type: Types.ELIMINAR_ABONO_ERROR,
    payload: error
})

export function imprimirAbonoAction(idAbono) {
    return async (dispatch) => {
      try {
        const response = await clienteAxios.get("imprimir/abono/" + idAbono, {
          responseType: "blob",
        });
        const file = new Blob([response.data], { type: "application/pdf" });
  
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } catch (error) {
        console.log(error);
      }
    };
  }