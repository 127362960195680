import * as Types from '../types';

const initialState = {
    tratamiento: {},
    tratamientos: [],
    loadingTratamientos: false,
    loadingAgregarTratamientosTratamientos: false,
    loadingEditarTratamientosTratamientos: false,
    showModalAgregar: false,
    showModalEditar: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_TRATAMIENTOS:
            return {
                ...state,
                loadingTratamientos: true
            }
        case Types.LISTA_TRATAMIENTOS_EXITO:
            return {
                ...state,
                loadingTratamientos: false,
                tratamientos: action.payload
            }
        case Types.LISTA_TRATAMIENTOS_ERROR:
            return {
                ...state,
                loadingTratamientos: false,
                error: action.payload
            }
        case Types.LISTA_TRATAMIENTOS_ERROR:
            return {
                ...state,
                loadingTratamientos: false,
                error: action.payload
            }
        case Types.COMENZAR_AGREGAR_TRATAMIENTO:
            return {
                ...state,
                showModalAgregar: true
            }
        case Types.AGREGAR_TRATAMIENTO:
            return {
                ...state,
                loadingAgregarTratamientos: true
            }
        case Types.AGREGAR_TRATAMIENTO_EXITO:
            return {
                ...state,
                loadingAgregarTratamientos: false,
                tratamientos: state.tratamientos.concat(action.payload),
                showModalAgregar: false
            }
        case Types.AGREGAR_TRATAMIENTO_ERROR:
            return {
                ...state,
                loadingAgregarTratamientos: false,
                error: action.payload
            }
        case Types.CERRAR_MODAL_AGREGAR_TRATAMIENTO:
            return {
                ...state,
                showModalAgregar: false
            }
        case Types.COMENZAR_EDITAR_TRATAMIENTO:
            return {
                ...state,
                showModalEditar: true,
                tratamiento: action.payload
            }
        case Types.EDITAR_TRATAMIENTO:
            return {
                ...state,
                loadingEditarTratamientos: true
            }
        case Types.EDITAR_TRATAMIENTO_EXITO:
            return {
                ...state,
                loadingEditarTratamientos: false,
                tratamientos: state.tratamientos.map( tratamiento => 
                    tratamiento.id === action.payload.id ? tratamiento = action.payload : tratamiento
                )
            }
        case Types.EDITAR_TRATAMIENTO_ERROR:
            return {
                ...state,
                loadingEditarTratamientos: false,
                error: action.payload
            }
        case Types.CERRAR_MODAL_EDITAR_TRATAMIENTO:
            return {
                ...state,
                showModalEditar: false
            }
        case Types.ELIMINAR_TRATAMIENTO_EXITO:
            return {
                ...state,
                tratamientos: state.tratamientos.filter( tratamiento => 
                    tratamiento.id != action.payload.id
                )
            }
        case Types.ELIMINAR_TRATAMIENTO_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}