import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faCog,
  faEnvelopeOpen,
  faSearch,
  faSignOutAlt,
  faStore,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
  DropdownButton,
} from "@themesberg/react-bootstrap";

import DefaultPicture from "../assets/img/team/default-user.png";
import NotifIcon from "../assets/img/notif.png";
import InventarioIcon from "../assets/img/inventario_icon.png";
import {
  cambiarSucursalAction,
  logoutAction,
  mantenerSesionAction,
} from "../actions/authActions";

import { SocketContext } from "../context/socket";
import {
  agregarNotificacionAction,
  getNotificacionesAction,
  marcarLeidoAction,
} from "../actions/notificacionesActions";
import { differenceDates } from "../helpers";
import clienteAxios from "../config/axios";

export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const echo = useContext(SocketContext);
  const { user } = useSelector((state) => state.auth);
  const { notificaciones } = useSelector((state) => state.notificaciones);
  const [notifSinLeer, setNotifSinLeer] = useState(false);
  const [mantenerSesionProp, setMantenerSesion] = useState(false);
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    echo.private(`App.Models.User.${user.user_id}`).notification((data) => {
      dispatch(agregarNotificacionAction({data: data}));
    });
  }, []);

  useEffect(() => {
    dispatch(getNotificacionesAction());
    getSucursales();
  }, []);

  useEffect(() => {
    checkNotificaciones();
  }, [notificaciones]);

  useEffect(() => {
    setMantenerSesion(user.mantener_sesion);
  }, [user]);

  const getSucursales = () => {
    clienteAxios.get("sucursales").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setSucursales(respuesta.data.data);
      }
    });
  };

  const checkNotificaciones = () => {
    let notif = notificaciones.filter((n) => n.read_at == null);
    setNotifSinLeer(notif.length > 0 ? true : false);
  };

  const logOut = () => {
    dispatch(logoutAction());
  };

  const markNotificationsAsRead = () => {
    if (notifSinLeer) {
      setNotifSinLeer(false);
      dispatch(marcarLeidoAction());
    }
  };

  const renderDifferenceDates = (date) => {
    return differenceDates(new Date(), date, "years") > 0
      ? "hace " + differenceDates(new Date(), date, "years") + " años"
      : differenceDates(new Date(), date, "months") > 0
      ? "hace " + differenceDates(new Date(), date, "months") + " meses"
      : differenceDates(new Date(), date, "days") > 0
      ? "hace " + differenceDates(new Date(), date, "days") + " días"
      : differenceDates(new Date(), date, "hours") > 0
      ? "hace " + differenceDates(new Date(), date, "hours") + " horas"
      : differenceDates(new Date(), date, "minutes") > 0
      ? "hace " + differenceDates(new Date(), date, "minutes") + " minutos"
      : differenceDates(new Date(), date, "seconds") > 0
      ? "hace " + differenceDates(new Date(), date, "seconds") + " segundos"
      : "hace unos segundos";
  };

  const Notification = (props) => {
    const { created_at = null } = props;
    const { link, sender, image, time, message, type } = props.data;
    const readClassName = created_at != null ? "" : "text-danger";

    return (
      <ListGroup.Item
        action
        href={link}
        className="border-bottom border-light"
        style={{ cursor: "default" }}
      >
        <Row className="align-items-center">
          <Col className="col-auto">
            {
              type == "StockInventario" ? (
                <Image
                  src={InventarioIcon}
                  className="user-avatar sm-avatar rounded-circle"
                />
              ) : (
                <Image
                  src={NotifIcon}
                  className="user-avatar sm-avatar rounded-circle"
                />
              )
            }
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>
                  {renderDifferenceDates(created_at)}
                </small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const mantenerSesion = (e) => {
    dispatch(mantenerSesionAction());
  };

  const cambiarSucursal = (idSucursal) => {
    dispatch(cambiarSucursalAction(idSucursal));
  };

  const getNombreSucursal = (idSucursal) => {
    return sucursales.map(function (sucursal) {
      return sucursal.id == idSucursal ? sucursal.nombre : "";
    });
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 menu-horizontal bg-white" style={{boxShadow: "0px 10px 22px -25px rgba(0,0,0,0.1)"}}>
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100" style={{padding: "0.5rem 1rem 0.5rem 1rem"}}>
          <div className="d-flex align-items-center">
            {/*<Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
                </Form>*/}
          </div>
          <Nav className="align-items-center">
            {(user.multisucursal === true || user.id_sucursal !== null) && (
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle
                  as={Nav.Link}
                  className="text-dark icon-notifications me-lg-3"
                >
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faStore} className="bell-shake" />
                  </span> 
                  {user.id_sucursal && (
                    <span> {getNombreSucursal(user.id_sucursal)}</span>
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                  <ListGroup className="list-group-flush">
                    <Dropdown.Header className="text-center text-primary fw-bold border-bottom border-light py-3">
                      Sucursales
                    </Dropdown.Header>
                    {sucursales.map((sucursal) => {
                      return (
                        <>
                          <Dropdown.Item
                            className="text-center text-primary fw-bold py-3"
                            onClick={() => cambiarSucursal(sucursal.id)}
                            active={user.id_sucursal === sucursal.id}
                          >
                            {sucursal.nombre}
                          </Dropdown.Item>
                        </>
                      );
                    })}
                    <Dropdown.Item
                      className="text-center text-primary fw-bold py-3"
                      onClick={() => cambiarSucursal(null)}
                      active={user.id_sucursal === null}
                    >
                      Todas las sucursales
                    </Dropdown.Item>
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle
                as={Nav.Link}
                className="text-dark icon-notifications me-lg-3"
              >
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {!notifSinLeer ? null : (
                    <span className="icon-badge rounded-circle unread-notifications" />
                  )}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link
                    href="#"
                    className="text-center text-primary fw-bold border-bottom border-light py-3"
                  >
                    Notificaciones
                  </Nav.Link>

                  {notificaciones.map((n) => (
                    <Notification key={`notification-${n.id}`} {...n} />
                  ))}

                  <Dropdown.Item
                    className="text-center text-primary fw-bold py-3"
                    onClick={() => history.push("/notificaciones")}
                  >
                    Ver todo
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image
                    src={DefaultPicture}
                    className="user-avatar md-avatar rounded-circle"
                  />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{user.name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" onClick={mantenerSesion}>
                  <Form.Check
                    type="checkbox"
                    id="mantener-sesion"
                    label="Mantener sesión"
                    onChange={mantenerSesion}
                    checked={mantenerSesionProp}
                  />
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item onClick={logOut} className="fw-bold">
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />{" "}
                  Salir
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
