import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Col,
  Form,
  Row,
  Modal,
  Button,
} from "@themesberg/react-bootstrap";
import { faHome, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clienteAxios from "../../config/axios";
import Typeahead from "../../components/Typeahead";
import { removeDuplicates } from "../../helpers";
import { MultiSelect } from "react-multi-select-component";

export default ({
  show,
  onHide,
  onSuccess,
  modelo,
  nombreReporte,
  onlyQuery = false,
  extraFilters = [],
}) => {
  const [filterType, setFilterType] = useState([]);
  const [parametrosCargados, setParametrosCargados] = useState(false);
  const [parametros, setParametros] = useState({
    fecha_desde: null,
    fecha_hasta: null,
  });
  const [criteria, setCriteria] = useState(["range_dates"]);

  useEffect(() => {
    cargarParametrosAdicionales();
  }, [extraFilters]);

  const handleApply = () => {
    parametros.criteria =
      onlyQuery === false ? [...criteria, "order-id"] : criteria;

    // Aquí puedes realizar alguna acción con el tipo de filtro seleccionado
    clienteAxios
      .get("reportes/ejecutar", {
        params: {
          ...parametros,
          model_name: modelo,
          nombre_reporte: nombreReporte,
          only_query: onlyQuery,
        },
      })
      .then((respuesta) => {
        if (respuesta.data.data) {
          onSuccess(respuesta.data.data);
        }
        cerrarModal();
      });
  };

  const cerrarModal = () => {
    cargarParametrosAdicionales();
    onHide();
  };

  const actualizarValor = (nuevoValor, nombre_parametro) => {
    let nuevosValores = Object.entries(parametros).map((value) =>
      value[0] === nombre_parametro
        ? { [value[0]]: nuevoValor }
        : { [value[0]]: value[1] }
    );

    nuevosValores = Object.assign({}, ...nuevosValores);

    setParametros(nuevosValores);
  };

  const onChangeFilter = (opcionesSeleccionadas) => {
    setFilterType(opcionesSeleccionadas);
  };

  const getValorDetalle = (parametro) => {
    const valor = Object.entries(parametros).filter(
      (parametroNombre, valorParametro) => parametroNombre === parametro
    );

    return valor[0] ?? null;
  };

  const cargarParametrosAdicionales = () => {
    setFilterType([]);
    setCriteria(["range_dates"]);

    const parametrosAdicionales = extraFilters.map((filter) => {
      return {
        [filter.value]: null,
      };
    });

    setParametros({
      fecha_desde: null,
      fecha_hasta: null,
    });

    if (parametrosAdicionales.length > 0) {
      setParametros({
        fecha_desde: null,
        fecha_hasta: null,
        ...Object.assign({}, ...parametrosAdicionales),
      });
      const criteriaNuevo = removeDuplicates(
        criteria.concat(
          extraFilters.map((filter) => {
            return filter.criteria;
          })
        )
      );

      setCriteria(criteriaNuevo);
    }

    setParametrosCargados(true);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar filtros de busqueda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Selecciona uno o mas filtros:</Form.Label>
            {parametrosCargados && (
              <MultiSelect
                options={() => {
                  return [
                    { label: "Rango de fechas", value: "rango_fechas" },
                    ...extraFilters.map((parametro) => {
                      return { label: parametro.label, value: parametro.value };
                    }),
                  ];
                }}
                value={filterType}
                onChange={onChangeFilter}
                labelledBy="Selecciona uno o mas filtros"
                overrideStrings={{
                  selectSomeItems: "Buscar todo",
                  selectAll: "Seleccionar todo",
                  allItemsAreSelected: "Todos los filtros fueron seleccionados",
                }}
              />
            )}
          </Form.Group>
          <hr />
          {filterType.filter((opcionSeleccionada) => {
            return opcionSeleccionada.value == "rango_fechas";
          }).length > 0 && (
            <>
              <Form.Group>
                <Form.Label>Fecha de inicio:</Form.Label>
                <Form.Control
                  type="date"
                  value={parametros.fecha_desde}
                  onChange={(e) =>
                    setParametros({
                      ...parametros,
                      fecha_desde: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Fecha de fin:</Form.Label>
                <Form.Control
                  type="date"
                  value={parametros.fecha_hasta}
                  onChange={(e) =>
                    setParametros({
                      ...parametros,
                      fecha_hasta: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </>
          )}
          {extraFilters.map((filtro) => (
            <>
              {filterType.filter((opcionSeleccionada) => {
                return opcionSeleccionada.value == filtro.value;
              }).length > 0 && (
                <Fragment key={filtro.value}>
                  <hr />
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        {filtro.type === "text" && (
                          <Form.Control
                            type="text"
                            onChange={(e) =>
                              actualizarValor(e.target.value, filtro.value)
                            }
                          />
                        )}
                        {filtro.type === "number" && (
                          <Form.Control
                            type="number"
                            onChange={(e) =>
                              actualizarValor(e.target.value, filtro.value)
                            }
                            min="0"
                          />
                        )}
                        {filtro.type === "service" && (
                          <>
                            <Form.Label>{filtro.label}</Form.Label>
                            <Typeahead
                              multiple // Permitir selección múltiple
                              url={filtro.endpoint}
                              labelKey="label"
                              id={filtro.value}
                              onChange={(selected) => {
                                const selectedIds = selected.map(
                                  (item) => item.id
                                );
                                actualizarValor(selectedIds, filtro.value);
                              }}
                            />
                          </>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Fragment>
              )}
            </>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleApply}>
          Aplicar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
