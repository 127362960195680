
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Breadcrumb,
  Form,
  Button,
  Col,
  OverlayTrigger,
  Tooltip,
  Row,
  Modal,
} from "@themesberg/react-bootstrap";

export const SeleccionMultiple = () => {
  const [modalShow, setModalShow] = useState(false);

  const openModal = () => {
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  return (
    <>
      <div
        onClick={openModal}
        style={{cursor: "pointer"}}
      >
        📶
      </div>

      <Modal show={modalShow} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Crear lista de selección</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Contenido del modal */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};