import * as Types from '../types';

const initialState = {
    notificaciones: [],
    loadingNotificaciones: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_NOTIFICACIONES:
            return {
                ...state,
                loadingNotificaciones: true
            }
        case Types.LISTA_NOTIFICACIONES_EXITO:
            return {
                ...state,
                loadingNotificaciones: false,
                notificaciones: action.payload
            }
        case Types.LISTA_NOTIFICACIONES_ERROR:
            return {
                ...state,
                loadingNotificaciones: false,
                error: action.payload
            }
        case Types.AGREGAR_NOTIFICACION:
            return {
                ...state,
                notificaciones: [action.payload, ...state.notificaciones]
            }
        default:
            return state;
    }
}