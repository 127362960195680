import * as Types from '../types';

const initialState = {
    unidadesMedicion: [],
    loadingUnidades: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_UNIDADES_MEDICION:
            return {
                ...state,
                loadingUnidades: true
            }
        case Types.LISTA_UNIDADES_MEDICION_EXITO:
            return {
                ...state,
                unidadesMedicion: action.payload,
                loadingUnidades: false
            }
        case Types.LISTA_UNIDADES_MEDICION_ERROR:
            return {
                ...state,
                loadingUnidades: false,
                error: action.payload
            }
        default:
            return state;
    }
}