import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { actualizarDatoAdicionalAction, setModalActualizarDatosAdicionalesAction } from "../../actions/datosAdicionalesActions";

export default (props) => {
  const dispatch = useDispatch();

  const { datoAdicional, showModalActualizarDatosAdicionales } = useSelector(
    (state) => state.datosAdicionales
  );

  const [edicion, guardar] = useState({
    nombre: "",
    etiqueta: "",
    descripcion: "",
    tipo: "",
    requerido: false,
    tabulador: "",
    valor_default: null,
    id_valor_default: null,
    valores: null,
    orden: 0,
    longitud: 0,
  });

  useEffect(() => {
    if (datoAdicional) {
      guardar({
        ...edicion,
        nombre: datoAdicional.nombre,
        etiqueta: datoAdicional.etiqueta,
        descripcion: datoAdicional.descripcion,
        tipo: datoAdicional.tipo,
        requerido: datoAdicional.requerido,
        tabulador: datoAdicional.tabulador,
        valor_default: datoAdicional.valor_default,
        id_valor_default: datoAdicional.id_valor_default,
        valores: datoAdicional.valores,
        orden: datoAdicional.orden,
        longitud: datoAdicional.longitud,
      });
    }
  }, [datoAdicional]);

  const submitForm = (e) => {
    e.preventDefault();
    edicion.tabla = props.tabla;
    edicion.requerido = edicion.requerido === "true" ? true : false;
    dispatch(actualizarDatoAdicionalAction(datoAdicional, edicion));
  };

  const handleClose = () => {
    dispatch(setModalActualizarDatosAdicionalesAction(false));
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalActualizarDatosAdicionales}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Editar dato adicional</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Nombre*</Form.Label>
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Nombre"
                  value={edicion.nombre}
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Etiqueta*</Form.Label>
                <Form.Control
                  type="text"
                  name="etiqueta"
                  placeholder="Etiqueta"
                  value={edicion.etiqueta}
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Descripción*</Form.Label>
                <Form.Control
                  type="text"
                  name="descripcion"
                  placeholder="Descripción"
                  value={edicion.descripcion}
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Tipo*</Form.Label>
                <Form.Control
                  as="select"
                  name="tipo"
                  value={edicion.tipo}
                  onChange={onChangeForm}
                >
                  <option value="">Seleccionar</option>
                  <option value="text">Texto</option>
                  <option value="number">Número</option>
                  <option value="date">Fecha</option>
                  <option value="list">Lista</option>
                  <option value="service">Servicio</option>
                  <option value="boolean">Booleano</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Obligatorio*</Form.Label>
                <Form.Control
                  as="select"
                  name="requerido"
                  value={edicion.requerido}
                  onChange={onChangeForm}
                >
                  <option value="">Seleccionar</option>
                  <option value="true">Si</option>
                  <option value="false">No</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Tabulador*</Form.Label>
                <Form.Control
                  type="text"
                  name="tabulador"
                  placeholder="Tabulador"
                  value={edicion.tabulador}
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Valor Default</Form.Label>
                <Form.Control
                  type="text"
                  name="valor_default"
                  placeholder="Valor Default"
                  value={edicion.valor_default}
                  onChange={onChangeForm}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Actualizar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
