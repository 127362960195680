import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getProductosAction() {
    return async (dispatch) => {
        dispatch( getProductos() );
        try {
            const response = await clienteAxios.get('productos/list');
            if (response.data.data) {
                dispatch( getProductosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getProductosError(error) );
        }
    }
}

const getProductos = () => ({
    type: Types.LISTA_PRODUCTOS
})

const getProductosExito = productos => ({
    type: Types.LISTA_PRODUCTOS_EXITO,
    payload: productos
})

const getProductosError = error => ({
    type: Types.LISTA_PRODUCTOS_ERROR,
    payload: error
})

export function showModalEditarProductoAction(estatus) {
    return (dispatch) => {
        dispatch( showModalEditarProducto(estatus) );
    }
}

const showModalEditarProducto = estatus => ({
    type: Types.MODAL_EDITAR_PRODUCTO,
    payload: estatus
})

export function comenzarEditarProductoAction(producto) {
    return (dispatch) => {
        dispatch( comenzarEditarProducto(producto) );
    }
}

const comenzarEditarProducto = producto => ({
    type: Types.COMENZAR_EDITAR_PRODUCTO,
    payload: producto
})

export function editarProductosAction(producto) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarProductos() );
        try {
            const response = await clienteAxios.put('productos/actualizar/'+producto.id, producto);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarProductosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( editarProductosError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const editarProductos = () => ({
    type: Types.EDITAR_PRODUCTO
})

const editarProductosExito = producto => ({
    type: Types.EDITAR_PRODUCTO_EXITO,
    payload: producto
})

const editarProductosError = error => ({
    type: Types.EDITAR_PRODUCTO_ERROR,
    payload: error
})

export function showModalAgregarProductoAction(estatus) {
    return (dispatch) => {
        dispatch( showModalAgregarProducto(estatus) );
    }
}

const showModalAgregarProducto = estatus => ({
    type: Types.MODAL_AGREGAR_PRODUCTO,
    payload: estatus
})

export function agregarProductoAction(producto) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarProducto() );
        try {
            const response = await clienteAxios.post('productos/agregar', producto);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch(agregarProductoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( agregarProductoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarProducto = () => ({
    type: Types.AGREGAR_PRODUCTO
})

const agregarProductoExito = producto => ({
    type: Types.AGREGAR_PRODUCTO_EXITO,
    payload: producto
})

const agregarProductoError = error => ({
    type: Types.AGREGAR_PRODUCTO_ERROR,
    payload: error
})

export function eliminarProductoAction(producto) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('productos/eliminar/'+producto.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch(eliminarProductoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarProductoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarProductoExito = (producto) => ({
    type: Types.ELIMINAR_PRODUCTO_EXITO,
    payload: producto
})

const eliminarProductoError = error => ({
    type: Types.ELIMINAR_PRODUCTO_ERROR,
    payload: error
})