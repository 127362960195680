import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getTratamientosAction() {
    return async (dispatch) => {
        dispatch( getTratamientos() );
        try {
            const response = await clienteAxios.get('tratamientos/list');
            if (response.data.data) {
                dispatch( getTratamientosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getTratamientosError(error) );
        }
    }
}

const getTratamientos = () => ({
    type: Types.LISTA_TRATAMIENTOS
})

const getTratamientosExito = tratamientos => ({
    type: Types.LISTA_TRATAMIENTOS_EXITO,
    payload: tratamientos
})

const getTratamientosError = error => ({
    type: Types.LISTA_TRATAMIENTOS_ERROR,
    payload: error
})

export function comenzarAgregarTratamientoAction() {
    return (dispatch) => {
        dispatch( comenzarAgregarTratamiento() );
    }
}

const comenzarAgregarTratamiento = () => ({
    type: Types.COMENZAR_AGREGAR_TRATAMIENTO
})

export function agregarTratamientoAction(tratamiento) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarTratamiento() );
        try {
            const response = await clienteAxios.post('tratamientos/agregar', tratamiento);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarTratamientoExito(response.data.data) );
                tratamiento.nombre = '';
            }
        } catch (error) {
            dispatch( agregarTratamientoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarTratamiento = () => ({
    type: Types.AGREGAR_TRATAMIENTO
})

const agregarTratamientoExito = tratamiento => ({
    type: Types.AGREGAR_TRATAMIENTO_EXITO,
    payload: tratamiento
})

const agregarTratamientoError = error => ({
    type: Types.AGREGAR_TRATAMIENTO,
    payload: error
})

export function cerrarModalAgregarAction() {
    return (dispatch) => {
        dispatch( cerrarModalAgregar() );
    }
}

const cerrarModalAgregar = () => ({
    type: Types.CERRAR_MODAL_AGREGAR_TRATAMIENTO
})

export function comenzarEditarTratamientoAction(tratamiento) {
    return (dispatch) => {
        dispatch( comenzarEditarTratamiento(tratamiento) );
    }
}

const comenzarEditarTratamiento = tratamiento => ({
    type: Types.COMENZAR_EDITAR_TRATAMIENTO,
    payload: tratamiento
})

export function editarTratamientoAction(tratamiento) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarTratamiento() );
        try {
            const response = await clienteAxios.put('tratamientos/actualizar/'+tratamiento.id, tratamiento);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarTratamientoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( editarTratamientoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const editarTratamiento = () => ({
    type: Types.EDITAR_TRATAMIENTO
})

const editarTratamientoExito = tratamiento => ({
    type: Types.EDITAR_TRATAMIENTO_EXITO,
    payload: tratamiento
})

const editarTratamientoError = error => ({
    type: Types.EDITAR_TRATAMIENTO_ERROR,
    payload: error
})

export function cerrarModalEditarAction() {
    return (dispatch) => {
        dispatch( cerrarModalEditar() );
    }
}

const cerrarModalEditar = () => ({
    type: Types.CERRAR_MODAL_EDITAR_TRATAMIENTO
})

export function eliminarTratamientoAction(tratamiento) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        try {
            const response = await clienteAxios.delete('tratamientos/eliminar/'+tratamiento.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarTratamientoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarTratamientoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarTratamientoExito = tratamiento => ({
    type: Types.ELIMINAR_TRATAMIENTO_EXITO,
    payload: tratamiento
})

const eliminarTratamientoError = error => ({
    type: Types.ELIMINAR_TRATAMIENTO_ERROR,
    payload: error
})