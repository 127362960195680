import React, { useEffect, useState } from "react";
import { Card, Breadcrumb } from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";

import { ConfirmModal } from "../../components/ConfirmModal";
import clienteAxios from "../../config/axios";
import ProveedoresForm from "./ProveedoresForm";
import Swal from "sweetalert2";
import { columnaSucursalDT } from "../../helpers";

export default () => {
  const [showModalForm, setShowModalForm] = useState(false);
  const [proveedores, setProveedores] = useState([]);
  const [accionForm, setAccionForm] = useState("agregar");
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    getProveedores();
  }, []);

  const columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      key: "nombre",
      text: "Nombre",
      align: "left",
      sortable: true,
    },
    {
      key: "direccion",
      text: "Dirección",
      align: "left",
      sortable: true,
    },
    {
      key: "telefono",
      text: "Teléfono",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      align: "left",
      sortable: true,
    },
    columnaSucursalDT()
  ];

  const state = {
    records: proveedores,
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar producto",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo
        </span>,
      ],
      onClick: (event) => {
        setShowModal(true);
        setAccionForm("agregar");
        setSelected({
            nombre: null,
            direccion: null,
            telefono: null,
            email: null,
            id: null
        });
      },
    },
  ];

  const editRecord = (record) => {
    setSelected(record);
    setShowModal(true);
    setAccionForm("editar");
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: eliminarProveedor,
        args: [record.id],
      },
    ];

    ConfirmModal(callbacks);
  };

  const eliminarProveedor = (id) => {
    clienteAxios.delete("proveedores/" + id).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        setProveedores(proveedores.filter((row) => row.id !== id));
      }
    });
  };

  const getProveedores = () => {
    clienteAxios.get("proveedores").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setProveedores(respuesta.data.data);
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Proveedores</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Proveedores</h5>
          <DataTable
            columns={columns}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            state={state}
            extraButtons={extraButtons}
          />
        </Card.Body>
      </Card>

      <ProveedoresForm
        accion={accionForm}
        showModal={showModal}
        selectedRow={selected}
        onUpdate={(rowSuccess) => {
          setProveedores(
            proveedores.map((row) =>
              row.id === rowSuccess.id ? (row = rowSuccess) : row
            )
          );

          setShowModal(false);
        }}
        onCreate={(rowSuccess) => {
          setProveedores(proveedores.concat(rowSuccess));

          setShowModal(false);
        }}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};
