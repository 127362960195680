import React, { useState } from "react";
import { Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { 
    setSelectedAction, 
    actualizarRolesMenusAction, 
    cerrarModalAction 
} from '../../actions/rolesMenusActions';

export default (props) => {
    const dispatch = useDispatch();

    const { rol, selected, showModalMenu, loading } = useSelector(state => state.rolesMenus);
    const { menus } = useSelector(state => state.menu);

    const submitRolesMenus = e => {
        e.preventDefault();
        dispatch( actualizarRolesMenusAction(rol, selected) )
    }

    const options = menus.map(function (menu) { return {value: menu.id, label: menu.nombre} });

    const onChange = (selected) => {
        dispatch(setSelectedAction(selected))
    };

    const handleClose = () => {
        dispatch( cerrarModalAction() );
    }
    
    return (
        <Modal as={Modal.Dialog} centered show={showModalMenu} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Menus del rol</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitRolesMenus}>
            <Modal.Body>
            {loading &&
                <div class="spinner-border text-info mx-auto d-block" role="status">
                    <span class="visually-hidden">Cargando...</span>
                </div>
            }

            {!loading &&
                <DualListBox
                    options={options}
                    selected={selected}
                    onChange={onChange}
                />
            }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary">
                    Guardar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}