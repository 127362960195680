import React, { Fragment, useEffect, useState } from "react";
import ReactDatatable from '@mkikets/react-datatable';
import { Tooltip, OverlayTrigger } from "@themesberg/react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import clienteAxios from "../config/axios";

export default (props) => {
    var _cache = {};

    useEffect(() => {
        setState({ ...state, ...props });
    }, [props]);

  const [state, setState] = useState({
    url: "",
    query: "",
    options: [],
    isLoading: false,
    labelKey: "",
    placeholder: "Buscar"
  });


  const _handleInputChange = (query) => {
    state.query = query;
  };

  const makeAndHandleRequest = (query) => {
    const config = { params: { query } };

    return clienteAxios.get(state.url, config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  const _handleSearch = (query) => {
    if (_cache[query]) {
      setState({
        ...state,
        options: _cache[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequest(query).then((resp) => {
        _cache[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };

  return (
    <>
      <AsyncTypeahead
        {...state}
        clearButton
        minLength={2}
        onInputChange={_handleInputChange}
        onSearch={_handleSearch}
        onChange={(selected) => { props.onChange(selected) }}
        placeholder={state.placeholder}
        renderMenuItemChildren={(option) => (
          <div key={option.id}>
            <span>{option[`${state.labelKey}`]}</span>
          </div>
        )}
        useCache={false}
      />
    </>
  );
};
