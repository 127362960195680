import {
    LISTA_USUARIOS,
    LISTA_USUARIOS_CARGANDO,
    LISTA_USUARIOS_EXITO,
    LISTA_USUARIOS_ERROR,
    COMENZAR_AGREGAR_USUARIO,
    USUARIO_CREACION,
    USUARIO_CREACION_EXITO,
    USUARIO_CREACION_ERROR,
    COMENZAR_ELIMINAR_USUARIO,
    USUARIO_ELIMINADO_EXITO,
    USUARIO_ELIMINADO_ERROR,
    USUARIO_ACTUALIZADO_EXITO,
    USUARIO_ACTUALIZADO_ERROR,
    OBTENER_USUARIO_EDITAR,
    COMENZAR_EDICION_USUARIO,
    CERRAR_MODAL_EDITAR_USUARIO,
    CERRAR_MODAL_AGREGAR_USUARIO,
    MANTENER_SESION,
    MANTENER_SESION_ERROR,
    MANTENER_SESION_EXITO
} from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getUsuariosAction() {
    return async (dispatch) => {
        try {
            const response = await clienteAxios.get('usuarios/list');
            if (response.data.data) {
                dispatch( getUsuariosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getUsuariosError(error) );
        }
    }
}

const getUsuariosExito = usuarios => ({
    type: LISTA_USUARIOS_EXITO,
    payload: usuarios
})


const getUsuariosError = error => ({
    type: LISTA_USUARIOS_ERROR,
    payload: error
})

export function obtenerUsuarioEditar(usuario) {
    return (dispatch) => {
        dispatch( obtenerUsuarioAction(usuario) )
    }
}

const obtenerUsuarioAction = usuario => ({
    type: OBTENER_USUARIO_EDITAR,
    payload: usuario
})

export function comenzarAgregarUsuario() {
    return (dispatch) => {
        dispatch( comenzarAgregarUsuarioAction() )
    }
}

const comenzarAgregarUsuarioAction = () => ({
    type: COMENZAR_AGREGAR_USUARIO,
    payload: {}
})

export function agregarUsuarioAction(usuario) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarUsuario() );

        try {
            const response = await clienteAxios.post('usuarios/agregar', usuario);
            if(response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarUsuarioExito(response.data.data) );

                usuario.name = '';
                usuario.username = '';
                usuario.email = '';
                usuario.password = '';
            }
        } catch (error) {
            dispatch( agregarUsuarioError(error) )
        }

        dispatch( loadingRequest(false) );
    }
}

export function cerrarModalAgregarAction() {
    return async (dispatch) => {
        dispatch( cerrarModalAgregar() )
    }
}

const cerrarModalAgregar = () => ({
    type: CERRAR_MODAL_AGREGAR_USUARIO
})

const agregarUsuario = () => ({
    type: USUARIO_CREACION
})

const agregarUsuarioExito = usuario => ({
    type: USUARIO_CREACION_EXITO,
    payload: usuario
})

const agregarUsuarioError = error => ({
    type: USUARIO_CREACION_ERROR,
    payload: error
})

export function editarUsuarioAction(usuario) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( editarUsuario(usuario) );

        try {
            const response = await clienteAxios.put('usuarios/'+usuario.id, usuario);
            if(response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( editarUsuarioExito(response.data.data) );
            }
        } catch (error) {
            //Swal.fire('UPS', error.response.message, 'error');
            dispatch( editarUsuarioError(error) )
        }

        dispatch( loadingRequest(false) );
    }
}


export function cerrarModalEditarAction() {
    return async (dispatch) => {
        dispatch( cerrarModalEditar() )
    }
}

const cerrarModalEditar = () => ({
    type: CERRAR_MODAL_EDITAR_USUARIO
})

const editarUsuario = usuario => ({
    type: COMENZAR_EDICION_USUARIO,
    payload: usuario
})

const editarUsuarioExito = usuario => ({
    type: USUARIO_ACTUALIZADO_EXITO,
    payload: usuario
})

const editarUsuarioError = error => ({
    type: USUARIO_ACTUALIZADO_ERROR,
    payload: error
})

export function eliminarUsuarioAction(usuario) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( eliminarUsuario(usuario) )

        try {
            const response = await clienteAxios.delete('usuarios/eliminar/'+usuario.id);
            if(response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarUsuarioExito() );
            }
        } catch (error) {
            //Swal.fire('UPS', error.response.message, 'error');
            dispatch( eliminarUsuarioError(error) )
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarUsuario = usuario => ({
    type: COMENZAR_ELIMINAR_USUARIO,
    payload: usuario
})

const eliminarUsuarioExito = () => ({
    type: USUARIO_ELIMINADO_EXITO
})

const eliminarUsuarioError = error => ({
    type: USUARIO_ELIMINADO_ERROR,
    payload: error
})