import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@mkikets/react-datatable";
import { busquedaFiltroAction } from "../../actions/filtroActions";
import Filtro from "../../components/Filtro";
import ModalAudits from "./ModalAudits";
import clienteAxios from "../../config/axios";

export default (props) => {
  const dispatch = useDispatch();
  const { loadingFiltro, respuestaFiltro, request } = useSelector(
    (state) => state.filtro
  );
  const modelo = "Notification";
  const criteriasObligatorios = ["notificaciones", "order-created"];

  const [state, guardar] = useState({
    detalles: [],
    showModalDetalles: false,
  });

  const [sucursales, setSucursales] = useState([]);

  const [requestFiltro, guardarRequestFiltro] = useState({
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    getSucursales();
    dispatch(
      busquedaFiltroAction(modelo, {
        offset: 0,
        limit: 10,
        criteria: ["all", "notificaciones", "order-created"],
      })
    );
  }, []);

  const getSucursales = () => {
    clienteAxios.get("sucursales").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        let sucursales = [];
        respuesta.data.data.forEach(sucursal => {
          sucursales[sucursal.id] = sucursal;
        });

        setSucursales(sucursales);
      }
    });
  };

  const columns = [
    {
      key: "created_at",
      text: "Fecha",
      align: "left",
      sortable: true,
    },
    {
      key: "read_at",
      text: "Fecha lectura",
      align: "left",
      sortable: true,
    },
    {
      text: "Mensaje",
      sortable: true,
      cell: (record) => {
        return record.data?.message;
      },
    },
    {
      text: "Evento",
      sortable: true,
      cell: (record) => {
        return record.data?.event.toUpperCase();
      },
    },
    {
      text: "Sucursal",
      cell: (record) => {
        return sucursales[record.data?.id_sucursal]?.nombre;
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Detalle</Tooltip>}
              >
                <button
                  onClick={() => showDetalles(record.data)}
                  className="btn btn-info btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i className="fas fa-boxes"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
    },
  ];

  const showDetalles = (detalle) => {
    guardar({ ...state, showModalDetalles: true, detalles: [detalle] });
  };

  const opcionesFiltro = [
    {
      valor: "created_at",
      label: "Fecha",
      tipo: "date",
      criteria: ["created_at"],
    },
    {
      valor: "event",
      label: "Evento",
      tipo: "options",
      opciones: [
        { valor: "creado", label: "Creado" },
        { valor: "actualizado", label: "Actualizado" },
        { valor: "borrado", label: "Eliminado" },
      ],
      criteria: ["evento_notifs"],
    },
  ];

  const onChangeDT = (event) => {
    requestFiltro.limit = event.page_size;
    requestFiltro.offset = event.page_size * (event.page_number - 1);
    guardarRequestFiltro({ ...requestFiltro });

    dispatch(
      busquedaFiltroAction(modelo, {
        offset: requestFiltro.offset,
        limit: requestFiltro.limit,
        criteria: ["all", "notificaciones", "order-created"],
        valor: request.valor,
      })
    );
  };

  const configDT = {
    show_filter: false,
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Notificaciones</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Notificaciones</h5>
          <Filtro
            opciones={opcionesFiltro}
            modelo={modelo}
            criteriasObligatorios={criteriasObligatorios}
            request={requestFiltro}
          />
          <hr></hr>

          <ReactDatatable
            hover
            dynamic
            records={respuestaFiltro.data ?? []}
            columns={columns}
            total_record={respuestaFiltro.count ?? 0}
            onChange={onChangeDT}
            config={configDT}
            loading={loadingFiltro}
          />
        </Card.Body>
      </Card>

      <ModalAudits
        detalles={state.detalles}
        showModal={state.showModalDetalles}
        onHide={() => guardar({ ...state, showModalDetalles: false })}
        sucursales={sucursales}
      />
    </>
  );
};
