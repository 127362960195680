import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getAccionesAction() {
    return async (dispatch) => {
        dispatch( getAcciones() );
        try {
            const response = await clienteAxios.get('acciones/0');
            if (response.data.data) {
                dispatch( getAccionesExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getAccionesError(error) );
        }
    }
}

const getAcciones = () => ({
    type: Types.LISTA_ACCIONES
})

const getAccionesExito = acciones => ({
    type: Types.LISTA_ACCIONES_EXITO,
    payload: acciones
})

const getAccionesError = error => ({
    type: Types.LISTA_ACCIONES_ERROR,
    payload: error
})