import {
    REQUEST_AXIOS
} from '../types';


export function loadingRequestAction(estatus) {
    return (dispatch) => {
        dispatch( loadingRequest(estatus) );
    }
}

export const loadingRequest = (estatus) => ({
    type: REQUEST_AXIOS,
    payload: estatus
})