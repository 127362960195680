import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Breadcrumb,
  Col,
  Form,
  Row,
  Modal,
  Button,
} from "@themesberg/react-bootstrap";
import clienteAxios from "../../config/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import DataTable from "../../components/DataTable";
import ModalCatalogoForm from "./CatalogoForm";
import CatalogoForm from "./CatalogoForm";
import { capitalize } from "../../helpers";
import Swal from "sweetalert2";
import { ConfirmModal } from "../../components/ConfirmModal";

export default (props) => {
  const columnasPredeterminadas = [
    {
      key: "id",
      text: "ID",
      sortable: true,
    },
    {
      key: "valor",
      text: "Valor",
      sortable: true,
    },
    {
      key: "descripcion",
      text: "Descripcion",
    },
  ];

  const [state, setState] = useState({
    catalogoCargado: false,
    columnas: columnasPredeterminadas,
    atributos: [],
    records: [],
    showModal: false,
    catalogoValor: null,
    catalogoValorForm: null,
    accionModal: "agregar",
  });
  const [catalogo, setCatalogo] = useState(null);

  useEffect(() => {
    getCatalogo(props.nombreCatalogo);
  }, [props.nombreCatalogo]);

  useEffect(() => {
    if (catalogo) {
      getValores();
    }
  }, [catalogo]);

  const getCatalogo = (nombreCatalogo) => {
    setState({
      ...state,
      catalogoCargado: false,
      columnas: columnasPredeterminadas,
      atributos: [],
      records: [],
    });

    clienteAxios.get("catalogo/" + nombreCatalogo).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        const catalogo = respuesta.data.data;
        setCatalogo(catalogo);

        const atributos = getAtributos(catalogo);

        setState({
          ...state,
          catalogoCargado: true,
          atributos: atributos,
          columnas: state.columnas.concat(getColumnasDT(atributos)),
        });
      }
    });
  };

  const getValores = () => {
    clienteAxios
      .get("catalogo/" + catalogo.nombre + "/valores")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          const valores = respuesta.data.data;
          setState({
            ...state,
            records: valores,
          });
        }
      });
  };

  const getAtributos = (catalogo) => {
    let atributos = [];
    Object.entries(catalogo).forEach((columna) => {
      const nombreColumna = columna[0];
      if (nombreColumna.indexOf("_nombre") !== -1 && columna[1] !== null) {
        const nombreAtributo = nombreColumna.replace("_nombre", "");
        atributos[nombreAtributo] = {
          nombre: catalogo[`${nombreAtributo}_nombre`],
          tipo: catalogo[`${nombreAtributo}_tipo`],
          valor_default: catalogo[`${nombreAtributo}_valor_default`],
          numero: nombreAtributo.replace(/[^0-9]/g, ""),
        };
      }
    });

    return atributos;
  };

  const getColumnasDT = (atributos) => {
    return Object.entries(atributos).map((atributo) => {
      return {
        key: atributo[0] + "_valor",
        text: atributo[1].nombre,
      };
    });
  };

  const editRecord = (record) => {
    setState({
      ...state,
      showModal: true,
      catalogoValor: record,
      accionModal: "editar",
    });
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: eliminarValor,
        args: [record],
      },
    ];

    ConfirmModal(callbacks);
  };

  const eliminarValor = (catalogoValor) => {
    clienteAxios
      .delete("catalogo/" + catalogo.id + "/valores/" + catalogoValor.id)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          const data = respuesta.data.data;
          setState({
            ...state,
            records: state.records.filter((row) => row.id != data.id),
          });

          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Nuevo",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo
        </span>,
      ],
      onClick: (event) => {
        setState({
          ...state,
          showModal: true,
          accionModal: "agregar",
          catalogoValor: {},
          catalogoValorForm: null,
        });
      },
    },
  ];

  const handleCloseModal = () => {
    setState({
      ...state,
      showModal: false,
    });
  };

  const onSubmitUpdate = (e) => {
    e.preventDefault();
    clienteAxios
      .put(
        "catalogo/" + catalogo.id + "/valores/" + state.catalogoValor.id,
        state.catalogoValorForm
      )
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          const data = respuesta.data.data;
          setState({
            ...state,
            records: state.records.map((row) =>
              row.id === data.id ? (row = data) : row
            ),
            showModal: false,
          });

          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  const onSubmitCreate = (e) => {
    e.preventDefault();
    clienteAxios
      .post("catalogo/" + catalogo.id + "/valores", state.catalogoValorForm)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          const data = respuesta.data.data;
          setState({
            ...state,
            records: state.records.concat(data),
            showModal: false,
          });

          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  return (
    <>
      {state.columnas.length > 0 && (
        <DataTable
          columns={state.columnas}
          editRecord={editRecord}
          deleteRecord={deleteRecord}
          state={state}
          extraButtons={extraButtons}
        />
      )}

      <Modal
        as={Modal.Dialog}
        size="md"
        centered
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {capitalize(state.accionModal)}
          </Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            state.accionModal == "editar"
              ? onSubmitUpdate(e)
              : onSubmitCreate(e);
          }}
        >
          <Modal.Body>
            <CatalogoForm
              atributos={state.atributos}
              catalogo_valor={state.catalogoValor}
              accion={state.accionModal}
              catalogo_valores={state.records}
              onChangeCatalogoValor={(catalogoValor) => {
                setState({
                  ...state,
                  catalogoValorForm: catalogoValor,
                });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleCloseModal}
            >
              Cerrar
            </Button>
            {state.accionModal == "editar" && (
              <Button type="submit" variant="secondary">
                Actualizar
              </Button>
            )}
            {state.accionModal == "agregar" && (
              <Button type="submit" variant="secondary">
                Agregar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
