import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Tab,
  Nav,
  Spinner,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  showModalEditarProductoAction,
  editarProductosAction,
} from "../../actions/productosActions";
import { getBase64 } from "../../helpers";
import DatosAdicionalesRender from "../../components/DatosAdicionales/DatosAdicionalesRender";

export default (props) => {
  const dispatch = useDispatch();

  const { producto, showModalEditarProducto } = useSelector(
    (state) => state.productos
  );

  const { datosAdicionales, tabsDatosAdicionales } = useSelector(
    (state) => state.datosAdicionales
  );
  const { datosAdicionalesDetalles, loadingDatosAdicionalesDetalles } =
    useSelector((state) => state.datosAdicionalesDetalles);

  const [
    datosAdicionalesDetallesCargados,
    setDatosAdicionalesDetallesCargados,
  ] = useState(false);

  const { unidadesMedicion } = useSelector((state) => state.unidadesMedicion);

  const { categoriasProductos } = useSelector(
    (state) => state.categoriasProductos
  );

  const [edicion, guardar] = useState({
    nombre: "",
    descripcion: "",
    precio: "",
    codigo: "",
    id_unidad_medicion: 0,
    imagen: null,
    financiamiento_proveedor: false,
    solo_servicio: false,
  });

  useEffect(() => {
    setDatosAdicionalesDetallesCargados(
      loadingDatosAdicionalesDetalles === false
    );
  }, [loadingDatosAdicionalesDetalles]);

  useEffect(() => {
    guardar({ ...producto, imagen: null });
  }, [producto]);

  const submitForm = (e) => {
    e.preventDefault();

    const datos_adicionales = {
      detalles: datosAdicionalesDetalles,
      tabla: "PRODUCTOS",
      id_tabla: edicion.id,
    };
    edicion.datos_adicionales = datos_adicionales;

    dispatch(editarProductosAction(edicion));
  };

  const handleClose = () => {
    dispatch(showModalEditarProductoAction(false));
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeFileForm = (e) => {
    getBase64(e.target.files[0], (result) => {
      guardar({
        ...edicion,
        imagen: result,
      });
    });

    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckbox = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalEditarProducto}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Editar producto</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Tab.Container defaultActiveKey="generales">
            <Row>
              <Nav fill variant="tabs" className="mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="generales">Generales</Nav.Link>
                </Nav.Item>
                {tabsDatosAdicionales.map((dato, index) => (
                  <Nav.Item>
                    <Nav.Link eventKey={dato.tabulador}>
                      {dato.tabulador}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="generales" title="Generales">
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Financiamiento por proveedor</Form.Label>
                      <Form.Check
                        type="switch"
                        name="financiamiento_proveedor"
                        checked={edicion.financiamiento_proveedor}
                        onChange={onChangeCheckbox}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Solo para servicios</Form.Label>
                      <Form.Check
                        type="switch"
                        name="solo_servicio"
                        checked={edicion.solo_servicio}
                        onChange={onChangeCheckbox}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    name="nombre"
                    placeholder="Nombre tratamiento"
                    value={edicion.nombre}
                    onChange={onChangeForm}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Descripcion</Form.Label>
                  <Form.Control
                    type="text"
                    name="descripcion"
                    placeholder="Descripcion"
                    value={edicion.descripcion}
                    onChange={onChangeForm}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Codigo</Form.Label>
                      <Form.Control
                        type="text"
                        name="codigo"
                        placeholder="Codigo"
                        value={edicion.codigo}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Precio venta</Form.Label>
                      <Form.Control
                        type="number"
                        name="precio"
                        placeholder="Precio"
                        value={edicion.precio}
                        onChange={onChangeForm}
                        step="any"
                        min="0"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Categoria</Form.Label>
                      <Form.Select
                        name="id_categoria_producto"
                        aria-label="Default select example"
                        onChange={onChangeForm}
                        className="mb-2"
                        value={edicion.id_categoria_producto}
                      >
                        <option value="0">Selecciona una categoria</option>
                        {categoriasProductos.map((categoria) => (
                          <option value={categoria.id}>
                            {" "}
                            {categoria.nombre}{" "}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Unidad de medición</Form.Label>
                      <Form.Select
                        name="id_unidad_medicion"
                        aria-label="Default select example"
                        onChange={onChangeForm}
                        className="mb-2"
                        value={edicion.id_unidad_medicion}
                      >
                        <option value="0">
                          Selecciona una unidad de medicion
                        </option>
                        {unidadesMedicion.map((unidadMedicion) => (
                          <option value={unidadMedicion.id}>
                            {" "}
                            {unidadMedicion.unidad}{" "}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Imagen</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={onChangeFileForm}
                    name="imagen"
                    accept=".png,.jpg,.jpeg,.gif"
                  />
                </Form.Group>
              </Tab.Pane>
              {tabsDatosAdicionales.map((dato, index) => (
                <Tab.Pane
                  eventKey={dato.tabulador}
                  title={dato.tabulador}
                  key={index}
                >
                  {datosAdicionalesDetallesCargados === true ? (
                    <>
                      <DatosAdicionalesRender
                        tabulador={dato.tabulador}
                        id_tabla={edicion.id}
                      />
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Actualizar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
