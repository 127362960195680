import * as Types from '../types'

const initialState = {
    loadingPreciosProductos: false,
    loadingAgregarPrecioProducto: false,
    loadingActualizarPrecioProducto: false,
    loadingEliminarPrecioProducto: false,
    preciosProductos : [],
    showModalPreciosProductos: false,
    showModalAgregarPrecioProducto: false,
    tratamientoPrecio: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS:
      return {
        ...state,
        loadingPreciosProductos: true,
        tratamientoPrecio: action.payload
      }
    case Types.LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO:
      return {
        ...state,
        loadingPreciosProductos: false,
        preciosProductos: action.payload
      }
    case Types.LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR:
        return {
            ...state,
            loadingPreciosProductos: false,
            error: action.payload
        }
    case Types.AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS:
      return {
        ...state,
        loadingAgregarPrecioProducto: true,
      }
    case Types.AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO:
      return {
        ...state,
        loadingAgregarPrecioProducto: false,
        preciosProductos: state.preciosProductos.concat(action.payload),
        showModalAgregarPrecioProducto: false,
      }
    case Types.AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR:
      return {
        ...state,
        loadingAgregarPrecioProducto: false,
        error: action.payload,
      }
    case Types.ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS:
      return {
        ...state,
        loadingActualizarPrecioProducto: true
      }
    case Types.ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO:
      return {
        ...state,
        loadingActualizarPrecioProducto: false,
        preciosProductos: state.preciosProductos.map( precioProducto => 
            precioProducto.id === action.payload.id ? precioProducto = action.payload : precioProducto
        ),
        showModalAgregarPrecioProducto: false
      }
    case Types.ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR:
      return {
        ...state,
        loadingActualizarPrecioProducto: false,
        error: action.payload
      }
    case Types.ELIMINAR_TRATAMIENTO_PRECIOS_PRODUCTOS:
      return {
        ...state,
        loadingEliminarPrecioProducto: true
      }
    case Types.ELIMINAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO:
      return {
        ...state,
        loadingEliminarPrecioProducto: false,
        preciosProductos: state.preciosProductos.filter( precioProducto => 
            precioProducto.id != action.payload.id
        )
      }
    case Types.MODAL_TRATAMIENTOS_PRECIOS_PRODUCTOS:
      return {
        ...state,
        showModalPreciosProductos: action.payload
      }
    case Types.MODAL_AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS:
      return {
        ...state,
        showModalAgregarPrecioProducto: action.payload
      }
    default:
      return state
  }
}
