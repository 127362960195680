import * as Types from "../types";

const initialState = {
  loadingFiltro: false,
  criteria: [],
  request: {},
  respuestaFiltro: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.BUSQUEDA_FILTRO:
      return {
        ...state,
        loadingFiltro: true,
        respuestaFiltro: [],
      };
    case Types.BUSQUEDA_FILTRO_EXITO:
      return {
        ...state,
        loadingFiltro: false,
        respuestaFiltro: action.payload,
      };
    case Types.BUSQUEDA_FILTRO_ERROR:
      return {
        ...state,
        loadingFiltro: false,
      };
    case Types.LIMPIAR_FILTRO_BUSQUEDA:
      return {
        ...state,
        respuestaFiltro: [],
      };
    case Types.ELIMINAR_REGISTRO_FILTRO:
      return {
        ...state,
        respuestaFiltro: state.respuestaFiltro.filter(
          (respuesta) => respuesta.id != action.payload
        ),
      };
    case Types.SET_CRITERIA_FILTRO:
      return {
        ...state,
        criteria: action.payload,
      };
    case Types.SET_REQUEST_FILTRO:
      return {
        ...state,
        request: action.payload,
      };
    case Types.BUSQUEDA_FILTRO_ACTUALIZACION:
      return {
        ...state,
        respuestaFiltro: {
          ...state.respuestaFiltro,
          data: action.payload
        }
      }
    case Types.ACTUALIZAR_ROW_FILTRO:
      return {
        ...state,
        respuestaFiltro: {
          ...state.respuestaFiltro,
          data: state.respuestaFiltro.data.map((item, index) => {
            return eval(`{${action.expresion}}`) ? action.payload : item;
          })
        }
      }
    case Types.ELIMINAR_ROW_FILTRO:
      return {
        ...state,
        respuestaFiltro: {
          ...state.respuestaFiltro,
          data: state.respuestaFiltro.data.filter((item, index) => {
            return eval(`{${action.expresion}}`);
          })
        }
      }
    default:
      return state;
  }
}
