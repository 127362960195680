import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from "@mkikets/react-datatable";
import { getProductosAction } from "../../actions/productosActions";

import { ConfirmModal } from "../../components/ConfirmModal";
import ModalEntradaSalida from "./ModalEntradaSalida";
import {
  showModalEntradaSalidaAction,
  listaInventarioAction,
} from "../../actions/inventarioActions";
import ModalDetallesInventario from "./ModalDetallesInventario";
import clienteAxios from "../../config/axios";
import { columnaSucursalDT } from "../../helpers";
import ModalEntradasInventario from "./ModalEntradasInventario";

export default () => {
  const dispatch = useDispatch();

  const { loadingInventario, inventario } = useSelector(
    (state) => state.inventario
  );

  const [state, setState] = useState({
    tipoRegistro: "",
    showModalDetalles: false,
    showModalEntradas: false,
    inventarioSeleccionado: null,
  });

  const [movimientos, setMovimientos] = useState([]);

  useEffect(() => {
    dispatch(listaInventarioAction());
  }, []);

  const columns = [
    {
      key: "id",
      text: "ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "producto",
      text: "Producto",
      className: "producto",
      align: "left",
      sortable: true,
    },
    {
      key: "stock",
      text: "Stock",
      className: "stock",
      sortable: true,
    },
    {
      key: "entradas",
      text: "Entradas",
      className: "entradas",
      sortable: true,
    },
    {
      key: "salidas",
      text: "Salidas",
      className: "salidas",
      sortable: true,
    },
    {
      text: "Sucursal",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.sucursal;
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Entradas</Tooltip>}
              >
                <button
                  onClick={() => showEntradas(record)}
                  className="btn btn-success btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-boxes"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Movimientos</Tooltip>}
              >
                <button
                  onClick={() => showMovimientos(record)}
                  className="btn btn-info btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-info-circle"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
    },
  ];

  const showMovimientos = (record) => {
    setState({ ...state, showModalDetalles: true });
    clienteAxios
      .get("/inventario/movimientos/list?id_producto=" + record.id_producto)
      .then((respuesta) => {
        if (respuesta.data.data) {
          setMovimientos(respuesta.data.data);
        }
      });
  };

  const showEntradas = (record) => {
    setState({
      ...state,
      inventarioSeleccionado: record,
      showModalEntradas: true,
    });
  };

  const extraButtons = [
    {
      className: "btn btn-success",
      title: "Registrar entrada",
      children: [
        <span>
          <i class="fas fa-arrow-alt-circle-up"></i> Registrar entrada
        </span>,
      ],
      onClick: (event) => {
        dispatch(getProductosAction());
        setState({ ...state, tipoRegistro: "ENTRADA" });
        dispatch(showModalEntradaSalidaAction(true));
      },
    },
    {
      className: "btn btn-info",
      title: "Registrar salida",
      children: [
        <span>
          <i class="fas fa-arrow-circle-down"></i> Registrar salida
        </span>,
      ],
      onClick: (event) => {
        dispatch(getProductosAction());
        setState({ ...state, tipoRegistro: "SALIDA" });
        dispatch(showModalEntradaSalidaAction(true));
      },
    },
  ];

  const configDataTable = {
    button: {
      extra: true,
      print: true,
      excel: true,
    },
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Inventario</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Inventario</h5>
          {loadingInventario ? (
            <div class="spinner-border text-info mx-auto d-block" role="status">
              <span class="visually-hidden">Cargando...</span>
            </div>
          ) : (
            <ReactDatatable
              hover
              records={inventario}
              columns={columns}
              extraButtons={extraButtons}
              config={configDataTable}
            />
          )}
        </Card.Body>
      </Card>
      <ModalEntradaSalida tipoRegistro={state.tipoRegistro} />
      <ModalDetallesInventario
        onHide={() => setState({ ...state, showModalDetalles: false })}
        showModal={state.showModalDetalles}
        movimientos={movimientos}
      />
      <ModalEntradasInventario
        onHide={() => setState({ ...state, showModalEntradas: false })}
        showModal={state.showModalEntradas}
        inventario={state.inventarioSeleccionado}
      />
    </>
  );
};
