import React from 'react';

import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');

let user = localStorage.getItem('user');
let token = null
if(user != null){
    user = JSON.parse(user);
    token = user.token;
}


const options = {
  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  forceTLS: process.env.REACT_APP_PUSHER_TLS,
  //authEndpoint is your apiUrl + /broadcasting/auth
  authEndpoint: process.env.REACT_APP_API_BASE_URL + "/broadcasting/auth" , 
  // As I'm using JWT tokens, I need to manually set up the headers.
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  },
};

export const echo = new Echo(options);
export const SocketContext = React.createContext();