import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import {
  actualizarAccionesRolAction,
  setSelectedAction,
} from "../../actions/accionesRolesActions";
import clienteAxios from "../../config/axios";

export default (props) => {
  const dispatch = useDispatch();

  const { accion, selectedAccionesRol, loadingAccionesRol, acciones_rol } =
    useSelector((state) => state.accionesRoles);
  const { acciones } = useSelector((state) => state.acciones);
  const [sucursales, setSucursales] = useState([]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(props.showModal);
    getSucursales();
  }, [props]);

  const getSucursales = () => {
    clienteAxios.get("sucursales").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setSucursales(respuesta.data.data);
      }
    });
  };

  const submitAccionesRol = (e) => {
    e.preventDefault();
    dispatch(actualizarAccionesRolAction(accion, selectedAccionesRol));
  };

  const options = acciones.map(function (accion) {
    return {
      id_accion: accion.id,
      nombre: accion.nombre,
      descripcion: accion.descripcion,
    };
  });

  const onChange = (selected) => {
    dispatch(setSelectedAction(selected));
  };

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const getAccionRol = (id_accion, id_sucursal) => {
    let accionRol = selectedAccionesRol.find(
      (accion_rol) =>
        accion_rol.id_accion === id_accion &&
        (accion_rol.id_sucursal === id_sucursal ||
          accion_rol.id_sucursal === null)
    );

    return accionRol ? true : false;
  };

  const actualizarAccionRol = (id_accion, id_sucursal, checked) => {
    let accionRol = selectedAccionesRol.find(
      (accion_rol) =>
        accion_rol.id_accion === id_accion &&
        accion_rol.id_sucursal === id_sucursal
    );

    if (checked && !accionRol) {
      selectedAccionesRol.push({
        id_accion: id_accion,
        id_sucursal: id_sucursal,
        id_rol: props.rol.id,
      });
    } else if (accionRol && checked === false) {
      selectedAccionesRol.splice(selectedAccionesRol.indexOf(accionRol), 1);
    } else if (!accionRol && checked === false) {
      let selected = selectedAccionesRol.find(
        (accion_rol) =>
          accion_rol.id_accion === id_accion && accion_rol.id_sucursal === null
      );

      if (!selected) {
        return;
      }

      selectedAccionesRol.splice(selectedAccionesRol.indexOf(selected), 1);

      sucursales.forEach((sucursal) => {
        let accionRol = selectedAccionesRol.find(
          (accion_rol) =>
            accion_rol.id_accion === id_accion &&
            accion_rol.id_sucursal === sucursal.id
        );

        if (!accionRol && sucursal.id !== id_sucursal) {
          selectedAccionesRol.push({
            id_accion: id_accion,
            id_sucursal: sucursal.id,
            id_rol: props.rol.id,
          });
        }
      });
    }

    console.log(selectedAccionesRol)
  };

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title className="h6">Acciones especiales</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitAccionesRol}>
        <Modal.Body>
          {loadingAccionesRol && (
            <div class="spinner-border text-info mx-auto d-block" role="status">
              <span class="visually-hidden">Cargando...</span>
            </div>
          )}

          {!loadingAccionesRol && (
            <>
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    <th className="border-bottom">Acción</th>
                    {sucursales.map((sucursal) => (
                      <th className="border-bottom" scope="col">
                        {sucursal.nombre}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {options.map((accion) => (
                    <>
                      <tr>
                        <th scope="row">{accion.descripcion}</th>
                        {sucursales.map((sucursal) => (
                          <td>
                            <Form.Check
                              style={{ marginLeft: "20px" }}
                              id={`sucursal-${sucursal.id}-accion-${accion.id}`}
                              htmlFor={`sucursal-${sucursal.id}-accion-${accion.id}`}
                              defaultChecked={getAccionRol(
                                accion.id_accion,
                                sucursal.id
                              )}
                              onChange={(event) => {
                                actualizarAccionRol(
                                  accion.id_accion,
                                  sucursal.id,
                                  event.target.checked
                                );
                              }}
                            />
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
              {/*<DualListBox
                    options={options}
                    selected={selectedAccionesRol}
                    onChange={onChange}
                />*/}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
