import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function obtenerAccionesRolAction(rol) {
    return async (dispatch) => {
        dispatch( obtenerAccionesRol(rol) )

        try {
            const response = await clienteAxios.get('acciones/rol/'+rol.id);
            if (response.data.data) {
                dispatch( obtenerAccionesRolExito(response.data.data) );
            }
        } catch (error) {
            dispatch( obtenerAccionesRolError(error) );
        }
    }
}

const obtenerAccionesRol = rol => ({
    type: Types.LISTA_ACCIONES_ROLES,
    payload: rol
})

const obtenerAccionesRolExito = menus => ({
    type: Types.LISTA_ACCIONES_ROLES_EXITO,
    payload: menus
})

const obtenerAccionesRolError = error => ({
    type: Types.LISTA_ACCIONES_ROLES_ERROR,
    payload: error
})

export function setSelectedAction(selected) {
    return (dispatch) => {
        dispatch(setSelected(selected));
    }
}

const setSelected = selected => ({
    type: Types.SET_SELECTED_ACCION_ROLES,
    payload: selected
})

export function actualizarAccionesRolAction(rol, acciones) {
    return async (dispatch) => {
        try {
            const response = await clienteAxios.put('acciones/rol/actualizar', 
                { id_rol: rol.id, acciones: acciones }
            );

            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( actualizarAccionesRolExito(response.data.data) );
            }
        } catch (error) {
            dispatch( actualizarAccionesRolError(error) );
        }
    }
}

const actualizarAccionesRolExito = accionesRol => ({
    type: Types.ACTUALIZAR_ACCIONES_ROLES_EXITO,
    payload: accionesRol
})

const actualizarAccionesRolError = error => ({
    type: Types.ACTUALIZAR_ACCIONES_ROLES_ERROR,
    payload: error
})