import * as Types from '../types';

const initialState = {
    producto: {},
    productos: [],
    loadingProductos: false,
    loadingEditarProductos: false,
    loadingAgregarProductos: false,
    showModalAgregarProducto: false,
    showModalEditarProducto: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_PRODUCTOS:
            return {
                ...state,
                loadingProductos: true
            }
        case Types.LISTA_PRODUCTOS_EXITO:
            return {
                ...state,
                loadingProductos: false,
                productos: action.payload
            } 
        case Types.LISTA_PRODUCTOS_ERROR:
            return {
                ...state,
                loadingProductos: false,
                error: action.payload
            }
        case Types.COMENZAR_EDITAR_PRODUCTO:
            return {
                ...state,
                showModalEditarProducto: true,
                producto: action.payload
            }
        case Types.EDITAR_PRODUCTO:
            return {
                ...state,
                loadingEditarProductos: true
            }
        case Types.EDITAR_PRODUCTO_EXITO:
            return {
                ...state,
                loadingEditarProductos: false,
                productos: state.productos.map( producto => 
                    producto.id === action.payload.id ? producto = action.payload : producto
                ),
                showModalEditarProducto: false
            }
        case Types.EDITAR_PRODUCTO_ERROR:
            return {
                ...state,
                loadingEditarProductos: false,
                error: action.payload
            }
        case Types.MODAL_EDITAR_PRODUCTO:
            return {
                ...state,
                showModalEditarProducto: action.payload
            }
        case Types.MODAL_AGREGAR_PRODUCTO:
            return {
                ...state,
                showModalAgregarProducto: action.payload
            }
        case Types.AGREGAR_PRODUCTO:
            return {
                ...state,
                loadingAgregarProductos: true
            }
        case Types.AGREGAR_PRODUCTO_EXITO:
            return {
                ...state,
                loadingAgregarProductos: false,
                productos: state.productos.concat(action.payload),
                showModalAgregarProducto: false
            }
        case Types.AGREGAR_PRODUCTO_ERROR:
            return {
                ...state,
                loadingAgregarProductos: false,
                error: action.payload
            }
        case Types.ELIMINAR_PRODUCTO_EXITO:
            return {
                ...state,
                productos: state.productos.filter( producto => 
                    producto.id != action.payload.id
                )
            }
        default:
            return state;
    }
}