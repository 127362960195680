import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Tab,
  Nav,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  editarEmpleadoAction,
  cerrarModalEditarAction,
} from "../../actions/empleadosActions";
import DatosAdicionalesRender from "../../components/DatosAdicionales/DatosAdicionalesRender";
import Typeahead from "../../components/Typeahead";

export default (props) => {
  const dispatch = useDispatch();

  const { empleado, showModalEditar } = useSelector((state) => state.empleados);
  const { datosAdicionales, tabsDatosAdicionales } = useSelector(
    (state) => state.datosAdicionales
  );
  const { datosAdicionalesDetalles, loadingDatosAdicionalesDetalles } =
    useSelector((state) => state.datosAdicionalesDetalles);
  const [
    datosAdicionalesDetallesCargados,
    setDatosAdicionalesDetallesCargados,
  ] = useState(false);

  useEffect(() => {
    setDatosAdicionalesDetallesCargados(
      loadingDatosAdicionalesDetalles === false
    );
  }, [loadingDatosAdicionalesDetalles]);

  const [edicion, guardar] = useState({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "",
    genero: "",
    telefono: "",
    correo_electronico: "",
    comision: 0,
    mostrar_agenda: false,
    sucursal: null,
    id_sucursal: null
  });

  useEffect(() => {
    guardar(empleado);
  }, [empleado]);

  const submitForm = (e) => {
    e.preventDefault();

    const datos_adicionales = {
      detalles: datosAdicionalesDetalles,
      tabla: "EMPLEADOS",
      id_tabla: empleado.id,
    };
    edicion.datos_adicionales = datos_adicionales;

    dispatch(editarEmpleadoAction(edicion));
  };

  const handleClose = () => {
    dispatch(cerrarModalEditarAction());
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckbox = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.checked,
    });
  };

  const getSucursal = () => {
    if (edicion.sucursal) {
      return [
        {
          id: edicion.sucursal.id,
          label:
            edicion.sucursal.nombre
        },
      ];
    }

    return [];
  };

  const onChangeSucursal = (selectedOption) => {
    if (selectedOption.length > 0) {
      guardar({
        ...edicion,
        id_sucursal: selectedOption[0].id,
        sucursal: selectedOption[0],
      });
    } else {
      guardar({
        ...edicion,
        id_sucursal: null,
        sucursal: null,
      });
    }
  };

  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={showModalEditar}
      onHide={handleClose}
    >
      <Modal.Header>
        <Modal.Title className="h6">Editar empleado</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <Tab.Container defaultActiveKey="generales">
            <Row>
              <Nav fill variant="tabs" className="mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="generales">Generales</Nav.Link>
                </Nav.Item>
                {tabsDatosAdicionales.map((dato, index) => (
                  <Nav.Item>
                    <Nav.Link eventKey={dato.tabulador}>
                      {dato.tabulador}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Row>
            <Tab.Content>
              <Tab.Pane eventKey="generales" title="Generales">
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Mostrar en la agenda</Form.Label>
                      <Form.Check
                        type="switch"
                        name="mostrar_agenda"
                        checked={edicion.mostrar_agenda}
                        onChange={onChangeCheckbox}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Nombre(s)*</Form.Label>
                      <Form.Control
                        type="text"
                        name="nombre"
                        placeholder="Nombre"
                        value={edicion.nombre}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Apellido paterno*</Form.Label>
                      <Form.Control
                        type="text"
                        name="apellido_paterno"
                        placeholder="Apellido paterno"
                        value={edicion.apellido_paterno}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Apellido materno</Form.Label>
                      <Form.Control
                        type="text"
                        name="apellido_materno"
                        placeholder="Apellido materno"
                        value={edicion.apellido_materno}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Fecha nacimiento</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_nacimiento"
                    placeholder="Fecha de nacimiento"
                    value={edicion.fecha_nacimiento}
                    onChange={onChangeForm}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label as="legend" column="sm">
                    Genero
                  </Form.Label>
                  <br />
                  <Form.Check
                    inline
                    type="radio"
                    name="genero"
                    label="Masculino"
                    value="M"
                    checked={edicion.genero == "M"}
                    onChange={onChangeForm}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="genero"
                    label="Femenino"
                    value="F"
                    checked={edicion.genero == "F"}
                    onChange={onChangeForm}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Correo electronico</Form.Label>
                      <Form.Control
                        type="email"
                        name="correo_electronico"
                        placeholder="Correo electronico"
                        value={edicion.correo_electronico}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Teléfono*</Form.Label>
                      <Form.Control
                        type="number"
                        name="telefono"
                        placeholder="Teléfono"
                        value={edicion.telefono}
                        onChange={onChangeForm}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Comision (%)</Form.Label>
                  <Form.Control
                    type="number"
                    name="comision"
                    placeholder="Comisión"
                    value={edicion.comision}
                    onChange={onChangeForm}
                    min={0}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Sucursal</Form.Label>
                  <Typeahead
                    url="typeahead/sucursales"
                    labelKey="label"
                    id="async-sucursales"
                    placeholder="Buscar sucursal"
                    onChange={(selected) => {
                      onChangeSucursal(selected);
                    }}
                    selected={getSucursal()}
                  />
                </Form.Group>
              </Tab.Pane>
              {tabsDatosAdicionales.map((dato, index) => (
                <Tab.Pane
                  eventKey={dato.tabulador}
                  title={dato.tabulador}
                  key={index}
                >
                  {datosAdicionalesDetallesCargados === true ? (
                    <>
                      <DatosAdicionalesRender
                        tabulador={dato.tabulador}
                        id_tabla={edicion.id}
                      />
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Actualizar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
