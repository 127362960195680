import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Tab,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { capitalize } from "../../helpers";
import Typeahead from "../../components/Typeahead";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Button as PrimeButton } from "primereact/button";
import { useRef } from "react";
import { Toast } from "primereact/toast";

var crypto = require("crypto");

export default (props) => {
  const [showModal, setShowModal] = useState(false);
  const [accion, setAccion] = useState("agregar");
  const [conceptos, setConceptos] = useState([]);
  const [impuestos, setImpuestos] = useState([]);
  const [catalogoImpuestos, setCatalogoImpuestos] = useState([]);
  const [catalogoConceptos, setCatalogoConceptos] = useState([]);
  const [frecuencias, setFrecuencias] = useState([]);
  const conceptoDatatableRef = useRef(null);
  const toast = useRef(null);

  useEffect(() => {
    setShowModal(props.showModal);
    if (props.accion) {
      setAccion(props.accion);
    }

    if (props.selectedRow && props.selectedRow.conceptos) {
      setConceptos(props.selectedRow.conceptos);
    }

    if (props.selectedRow && props.selectedRow.impuestos) {
      setImpuestos(props.selectedRow.impuestos);
    }

    guardar({
      ...edicion,
      ...props.selectedRow,
    });
  }, [props]);

  useEffect(() => {
    getConceptos();
    getImpuestos();
    getFrecuencias();
  }, []);

  const [edicion, guardar] = useState({
    id_empleado: null,
    sueldo_base: 0,
    valor_hora_extra: 0,
    empleado: null,
    id_frecuencia: null,
    horas_frecuencia: 0,
  });

  const getConceptos = () => {
    clienteAxios
      .get("typeahead/valores/SALARIO_CONCEPTOS?query=**")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setCatalogoConceptos(respuesta.data.data);
        }
      });
  };

  const getImpuestos = () => {
    clienteAxios.get("impuestos").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setCatalogoImpuestos(respuesta.data.data);
      }
    });
  };

  const getFrecuencias = () => {
    clienteAxios
      .get("typeahead/valores/FRECUENCIAS_PAGO?query=**")
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setFrecuencias(respuesta.data.data);
        }
      });
  };

  const submitForm = (e) => {
    e.preventDefault();

    const dataRequest = {
      ...edicion,
      conceptos: conceptos,
      impuestos: impuestos,
    };

    if (accion == "editar") {
      clienteAxios
        .put("salarios/" + edicion.id, dataRequest)
        .then((respuesta) => {
          if (
            respuesta &&
            respuesta.data.data &&
            respuesta.data.success === true
          ) {
            props.onUpdate(respuesta.data.data);
            Swal.fire("¡Muy bien!", respuesta.data.message, "success");
          }
        });
      return;
    }

    if (accion == "agregar") {
      clienteAxios.post("salarios", dataRequest).then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          props.onCreate(respuesta.data.data);
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const onChangeForm = (e) => {
    guardar({
      ...edicion,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeRowConcepto = (rowData, e) => {
    setConceptos(
      conceptos.map((concepto) =>
        concepto.id === rowData.id
          ? {
              ...concepto,
              [e.target.name]: e.target.value,
            }
          : concepto
      )
    );
  };

  const onChangeRowImpuesto = (rowData, e) => {
    setImpuestos(
      impuestos.map((impuesto) =>
        impuesto.id === rowData.id
          ? {
              ...impuesto,
              [e.target.name]: e.target.value,
            }
          : impuesto
      )
    );
  };

  const getTipoConcepto = (value) => {
    switch (value) {
      case "BONO":
        return "success";

      case "CARGO":
        return "danger";

      default:
        return null;
    }
  };

  const montoBodyTemplate = (rowData) => {
    return (
      <InputNumber
        name="monto"
        value={rowData.monto}
        onValueChange={(e) => onChangeRowConcepto(rowData, e)}
        mode="currency"
        currency="USD"
        locale="en-US"
        disabled={rowData.id_concepto === null}
      />
    );
  };

  const porcentajeBodyTemplate = (rowData) => {
    return (
      <Form.Control
        type="number"
        name="porcentaje"
        placeholder="Porcentaje"
        value={rowData.porcentaje}
        onChange={(e) => onChangeRowImpuesto(rowData, e)}
        min={0}
        step="any"
      />
    );
  };

  const frecuenciaBodyTemplate = (rowData) => {
    return (
      <>
        <Form.Select
          name="id_frecuencia"
          onChange={(e) => onChangeRowConcepto(rowData, e)}
          defaultValue={rowData.id_frecuencia}
          disabled={rowData.id_concepto === null}
        >
          <option value="">Manual</option>
          {frecuencias.map((frecuencia) => (
            <option value={frecuencia.id}> {frecuencia.descripcion} </option>
          ))}
        </Form.Select>
      </>
    );
  };

  const eliminarConceptoBodyTemplate = (rowData) => {
    return (
      <>
        <Fragment>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            overlay={<Tooltip>Eliminar Concepto</Tooltip>}
          >
            <button
              onClick={(e) => eliminarConcepto(rowData.id, e)}
              className="btn btn-danger btn-sm"
              style={{
                marginRight: "5px",
              }}
            >
              <i class="fas fa-trash"></i>
            </button>
          </OverlayTrigger>
        </Fragment>
      </>
    );
  };

  const eliminarConcepto = (id, e) => {
    e.preventDefault();

    setConceptos(conceptos.filter((concepto) => concepto.id !== id));
  };

  const eliminarImpuestoBodyTemplate = (rowData) => {
    return (
      <>
        <Fragment>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            overlay={<Tooltip>Eliminar Impuesto</Tooltip>}
          >
            <button
              onClick={(e) => eliminarImpuesto(rowData.id, e)}
              className="btn btn-danger btn-sm"
              style={{
                marginRight: "5px",
              }}
            >
              <i class="fas fa-trash"></i>
            </button>
          </OverlayTrigger>
        </Fragment>
      </>
    );
  };

  const eliminarImpuesto = (id, e) => {
    e.preventDefault();

    setImpuestos(impuestos.filter((impuesto) => impuesto.id !== id));
  };

  const conceptoBodyTemplate = (rowData) => {
    return (
      <>
        <Form.Select
          name="id_concepto"
          onChange={(e) => onChangeConcepto(rowData, e)}
          defaultValue={rowData.id_concepto}
        >
          <option value="0">Selecciona un concepto</option>
          {catalogoConceptos.map((concepto) => (
            <>
              {concepto.atributo2_valor != "true" && (
                <option value={concepto.id}> {concepto.descripcion} </option>
              )}
            </>
          ))}
        </Form.Select>
      </>
    );
  };

  const impuestoBodyTemplate = (rowData) => {
    return (
      <>
        <Form.Select
          name="id_impuesto"
          onChange={(e) => onChangeImpuesto(rowData, e)}
          defaultValue={rowData.id_impuesto}
        >
          <option value="">Selecciona el impuesto</option>
          {catalogoImpuestos.map((impuesto) => (
            <option value={impuesto.id}> {impuesto.concepto} </option>
          ))}
        </Form.Select>
      </>
    );
  };

  const tipoConceptoBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.id_concepto !== null && rowData.id_concepto !== "0" && (
          <Tag
            value={
              catalogoConceptos.filter(
                (valor) => valor.id == rowData.id_concepto
              )[0]?.atributo1_valor
            }
            severity={getTipoConcepto(
              catalogoConceptos.filter(
                (valor) => valor.id == rowData.id_concepto
              )[0]?.atributo1_valor
            )}
          ></Tag>
        )}
      </>
    );
  };

  const nuevoConcepto = () => {
    setConceptos([
      ...conceptos,
      {
        id: crypto.randomBytes(20).toString("hex"),
        id_concepto: null,
        tipo_concepto: null,
        concepto: null,
        monto: 0,
        id_frecuencia: null,
        editable: true,
      },
    ]);
  };

  const nuevoImpuesto = () => {
    setImpuestos([
      ...impuestos,
      {
        id: crypto.randomBytes(20).toString("hex"),
        id_impuesto: null,
        impuesto: null,
        porcentaje: null,
      },
    ]);
  };

  const renderHeaderConceptos = () => {
    return (
      <div className="d-flex align-items-center justify-content-between gap-2">
        <h5 className="text-xl font-weight-bold">Conceptos</h5>
        <Button
          variant="success"
          onClick={nuevoConcepto}
          disabled={catalogoConceptos.length === conceptos.length}
        >
          Nuevo concepto
        </Button>
      </div>
    );
  };

  const headerConceptos = renderHeaderConceptos();

  const renderHeaderImpuestos = () => {
    return (
      <div className="d-flex align-items-center justify-content-between gap-2">
        <h5 className="text-xl font-weight-bold">Impuestos</h5>
        <Button
          variant="success"
          onClick={nuevoImpuesto}
          disabled={catalogoImpuestos.length === impuestos.length}
        >
          Nuevo impuesto
        </Button>
      </div>
    );
  };

  const headerImpuestos = renderHeaderImpuestos();

  const onChangeEmpleado = (selectedOption) => {
    if (selectedOption.length > 0) {
      guardar({
        ...edicion,
        id_empleado: selectedOption[0].id,
        empleado: selectedOption[0],
      });
    } else {
      guardar({
        ...edicion,
        id_empleado: null,
        empleado: null,
      });
    }
  };

  const getEmpleado = () => {
    if (edicion.empleado) {
      return [
        {
          id: edicion.empleado.id,
          label:
            edicion.empleado.nombre + " " + edicion.empleado.apellido_paterno,
        },
      ];
    }

    return [];
  };

  const onChangeConcepto = (rowData, e) => {
    const idConceptoSeleccionado = parseInt(e.target.value);

    const conceptoFiltro = conceptos.filter(
      (concepto) => concepto.id_concepto === idConceptoSeleccionado
    );

    const conceptoValor = catalogoConceptos.filter(
      (valor) => valor.id === idConceptoSeleccionado
    );

    if (
      conceptoFiltro.length > 0 &&
      rowData.id_concepto != idConceptoSeleccionado
    ) {
      toast.current.show({
        severity: "error",
        summary: "No se puede agregar el concepto",
        detail: `No se permite agregar el mismo concepto dos veces en el salario.`,
        life: 5000,
      });
      e.target.value = rowData.id_concepto;
      return;
    }

    if (conceptoValor.length == 0) {
      return;
    }

    setConceptos(
      conceptos.map((concepto) =>
        concepto.id === rowData.id
          ? {
              ...concepto,
              id_concepto: conceptoValor[0].id,
              tipo_concepto: conceptoValor[0].atributo1_valor,
              concepto: conceptoValor[0].descripcion,
            }
          : concepto
      )
    );
  };

  const onChangeImpuesto = (rowData, e) => {
    const idImpuestoSeleccionado = parseInt(e.target.value);

    const impuestosFiltro = impuestos.filter(
      (impuesto) => impuesto.id_impuesto === idImpuestoSeleccionado
    );

    const impuestoValor = catalogoImpuestos.filter(
      (valor) => valor.id === idImpuestoSeleccionado
    );

    if (
      impuestosFiltro.length > 0 &&
      rowData.id_impuesto != idImpuestoSeleccionado
    ) {
      toast.current.show({
        severity: "error",
        summary: "No se puede agregar el impuesto",
        detail: `No se permite agregar el mismo impuesto dos veces en el salario.`,
        life: 5000,
      });
      e.target.value = rowData.id_impuesto;
      return;
    }

    if (impuestoValor.length == 0) {
      return;
    }

    setImpuestos(
      impuestos.map((impuesto) =>
        impuesto.id === rowData.id
          ? {
              ...impuesto,
              id_impuesto: impuestoValor[0].id,
              impuesto: impuestoValor[0].concepto,
            }
          : impuesto
      )
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Modal
        as={Modal.Dialog}
        centered
        show={showModal}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title className="h6">{capitalize(accion)} Salario</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body>
            <Row>
              <Col sm={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Empleado*</Form.Label>
                  <Typeahead
                    url="typeahead/empleados"
                    labelKey="label"
                    id="async-empleados"
                    placeholder="Buscar empleado"
                    onChange={(selected) => {
                      onChangeEmpleado(selected);
                    }}
                    selected={getEmpleado()}
                    disabled={accion == "editar"}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Frecuencia Sueldo ($)*</Form.Label>
                  <Form.Select
                    name="id_frecuencia"
                    onChange={onChangeForm}
                    defaultValue={edicion.id_frecuencia}
                  >
                    <option value="">Selecciona una opción</option>
                    {frecuencias.map((frecuencia) => (
                      <option value={frecuencia.id}>
                        {" "}
                        {frecuencia.descripcion}{" "}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Sueldo ($)*</Form.Label>
                  <Form.Control
                    type="number"
                    name="sueldo_base"
                    placeholder="Sueldo base"
                    value={edicion.sueldo_base}
                    onChange={onChangeForm}
                    min={0}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Horas de trabajo por frecuencia*</Form.Label>
                  <Form.Control
                    type="number"
                    name="horas_frecuencia"
                    placeholder="Horas de trabajo por frecuencia"
                    value={edicion.horas_frecuencia}
                    onChange={onChangeForm}
                    min={0}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Valor hora extra ($)*</Form.Label>
                  <Form.Control
                    type="number"
                    name="valor_hora_extra"
                    placeholder="Valor hora extra"
                    value={edicion.valor_hora_extra}
                    onChange={onChangeForm}
                    min={0}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="card p-fluid">
              <DataTable
                ref={conceptoDatatableRef}
                header={headerConceptos}
                value={conceptos.filter(
                  (concepto) => concepto.editable === true
                )}
                editMode="row"
                dataKey="id"
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  field="id_concepto"
                  header="Concepto"
                  body={conceptoBodyTemplate}
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="id_concepto"
                  header="Tipo"
                  body={tipoConceptoBodyTemplate}
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="monto"
                  header="Monto"
                  body={montoBodyTemplate}
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="id_frecuencia"
                  header="Frecuencia"
                  body={frecuenciaBodyTemplate}
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  headerStyle={{ width: "10%", minWidth: "8rem" }}
                  bodyStyle={{ textAlign: "center" }}
                  body={eliminarConceptoBodyTemplate}
                ></Column>
              </DataTable>
            </div>

            <hr></hr>

            <div className="card p-fluid">
              <DataTable
                header={headerImpuestos}
                value={impuestos}
                editMode="row"
                dataKey="id"
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  field="id_impuesto"
                  header="Impuesto"
                  body={impuestoBodyTemplate}
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  field="porcentaje"
                  header="Porcentaje"
                  body={porcentajeBodyTemplate}
                  style={{ width: "20%" }}
                ></Column>
                <Column
                  headerStyle={{ width: "10%", minWidth: "8rem" }}
                  bodyStyle={{ textAlign: "center" }}
                  body={eliminarImpuestoBodyTemplate}
                ></Column>
              </DataTable>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              {accion == "editar" ? "Actualizar" : "Agregar"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
