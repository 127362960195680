import * as Types from '../types';

import clienteAxios from '../config/axios';

export function getEstadisticasAction() {
    return async (dispatch) => {
        dispatch( getEstadisticas() );
        try {
            const response = await clienteAxios.get('stats');
            if (response.data.data) {
                dispatch( getEstadisticasExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getEstadisticasError(error) );
        }
    }
}

const getEstadisticas = () => ({
    type: Types.CARGANDO_ESTADISTICAS
})

const getEstadisticasExito = respuesta => ({
    type: Types.CARGANDO_ESTADISTICAS_EXITO,
    payload: respuesta
})

const getEstadisticasError = error => ({
    type: Types.CARGANDO_ESTADISTICAS_ERROR,
    payload: error
})
