import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { 
    editarFuncionAction, 
    cerrarModalEditarAction 
} from '../../actions/menuFuncionesActions';

export default (props) => {
    const dispatch = useDispatch();
    
    const { funcion, showModalEditar } = useSelector(state => state.menuFunciones);

    const [ funcionEditar, guardarFuncion] = useState({
        'nombre': '',
        'url': '',
        'icono': ''
    })

    useEffect(() => {
        guardarFuncion(funcion);
    }, [funcion])

    const submitEditarFuncion = e => {
        e.preventDefault();
        dispatch( editarFuncionAction(funcionEditar) );
    }

    const handleClose = () => {
        dispatch( cerrarModalEditarAction() );
    }

    const onChangeForm = (e) => {
        guardarFuncion({
            ...funcionEditar,
            [e.target.name] : e.target.value
        })
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModalEditar} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Funciones</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitEditarFuncion}>
            <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" name="nombre" placeholder="Nombre de la función" 
                            value={funcionEditar.nombre} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>URL</Form.Label>
                        <Form.Control type="text" name="descripcion" placeholder="URL" 
                            name="url" value={funcionEditar.url} onChange={onChangeForm} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Icono</Form.Label>
                        <Form.Control type="text" name="icono" placeholder="Icono" 
                            value={funcionEditar.icono} onChange={onChangeForm} />
                    </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary">
                    Actualizar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}