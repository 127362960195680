import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmModal } from "../../components/ConfirmModal";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import clienteAxios from "../../config/axios";
import {
  showModalAgregarTratamientoPreciosProductosAction,
  agregarPrecioProductoAction,
} from "../../actions/preciosProductosActions";

export default (props) => {
  const dispatch = useDispatch();

  const { showModalAgregarPrecioProducto, tratamientoPrecio } = useSelector(
    (state) => state.preciosProductos
  );

  const [state, setState] = useState({
    id_producto: 0,
    cantidad: 0,
    options: [],
    query: "",
    isLoading: false,
    producto: null,
  });

  const onChangeForm = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const request = {
        id_producto: state.producto?.id,
        cantidad: state.cantidad,
        id_tratamiento_precio: tratamientoPrecio.id,
    }
    dispatch(agregarPrecioProductoAction(request));
  };

  const handleClose = () => {
    dispatch(showModalAgregarTratamientoPreciosProductosAction(false));
  };

  var _cacheProducto = {};

  const onChange = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        producto: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        producto: null,
      });
    }
  };

  const _handleSearch = (query) => {
    if (_cacheProducto[query]) {
      setState({
        ...state,
        options: _cacheProducto[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequest(query).then((resp) => {
      _cacheProducto[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };
  
  const _handleInputChange = (query) => {
    state.query = query;
  };

  const makeAndHandleRequest = (query) => {
    const config = { params: { query } };

    return clienteAxios.get("typeahead/productos", config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="sm"
        centered
        show={showModalAgregarPrecioProducto}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Agregar Producto</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <AsyncTypeahead
                        {...state}
                        clearButton
                        id="async-productos"
                        labelKey="nombre"
                        minLength={2}
                        onInputChange={_handleInputChange}
                        onSearch={_handleSearch}
                        onChange={onChange}
                        placeholder="Buscar producto"
                        renderMenuItemChildren={(option) => (
                        <div key={option.id}>
                            <span>{option.nombre}</span>
                        </div>
                        )}
                        useCache={false}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Cantidad</Form.Label>
                    <Form.Control type="number" name="cantidad" placeholder="Cantidad" 
                        value={state.cantidad} onChange={onChangeForm} min="1" />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
            <Button
                variant="link"
                className="text-gray ms-auto"
                onClick={handleClose}
            >
                Cerrar
            </Button>
            <Button type="submit" variant="secondary">
                Agregar
            </Button>
            </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
