import React, { useState, useEffect } from "react";
import {
  Card,
  Modal,
  Form,
  Button,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  showModalEditarProductoAction,
  editarProductosAction,
} from "../../actions/productosActions";
import { getBase64 } from "../../helpers";
import ReactMultiSelect from "@kenshooui/react-multi-select";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";

export default (props) => {
  const dispatch = useDispatch();

  const [productoProveedores, setProductoProveedores] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [edicion, guardar] = useState({
    id_producto: null,
    proveedores: [],
  });

  useEffect(() => {
    guardar({
      ...edicion,
      ...props,
    });
  }, [props]);

  useEffect(() => {
    if (props.producto) {
      edicion.id_producto = props.producto.id;

      guardar({
        ...edicion,
        id_producto: props.producto.id,
      });

      getProductoProveedores();
    }
  }, [props.producto]);

  useEffect(() => {
    setShowModal(props.showModal);

    if (props.showModal === true) {
      getProveedores();
    }
  }, [props.showModal]);

  const submitForm = (e) => {
    e.preventDefault();
    clienteAxios
      .post("productos/agregar-proveedores", {
        id_producto: edicion.id_producto,
        proveedores: edicion.proveedores,
      })
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          props.onHide();
          setShowModal(false);
          Swal.fire("¡Muy bien!", respuesta.data.message, "success");
        }
      });
  };

  const handleClose = () => {
    props.onHide();
    setShowModal(false);
  };

  const getProductoProveedores = () => {
    clienteAxios
      .get("productos/proveedores/" + edicion.id_producto)
      .then((respuesta) => {
        if (
          respuesta &&
          respuesta.data.data &&
          respuesta.data.success === true
        ) {
          setProductoProveedores(respuesta.data.data);
          guardar({
            ...edicion,
            proveedores: respuesta.data.data.map((productoProveedor) => {
              return {
                id: productoProveedor.proveedor.id,
                label: productoProveedor.proveedor.nombre,
              };
            }),
          });
        }
      });
  };

  const getProveedores = () => {
    clienteAxios.get("proveedores").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setProveedores(respuesta.data.data);
      }
    });
  };

  const renderProveedores = () => {
    return proveedores.map((proveedor) => {
      return {
        id: proveedor.id,
        label: proveedor.nombre,
      };
    });
  };

  const handleChange = (selectedItems) => {
    guardar({
      ...edicion,
      proveedores: selectedItems,
    });
  };

  return (
    <Modal as={Modal.Dialog} centered show={showModal} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="h6">Producto Proveedores</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={handleClose} />
      </Modal.Header>
      <Form onSubmit={submitForm}>
        <Modal.Body>
          <ReactMultiSelect
            items={renderProveedores()}
            selectedItems={edicion.proveedores}
            onChange={handleChange}
            messages={{
              searchPlaceholder: "Buscar...",
              noItemsMessage: "No se encontraron resultados...",
              noneSelectedMessage: "Sin seleccionar",
              selectedMessage: "Seleccionado",
              selectAllMessage: "Seleccionar todo",
              clearAllMessage: "Limpiar todo",
              disabledItemsTooltip: "Solo puedes seleccionar una opción",
            }}
            showSelectedItems={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
          <Button type="submit" variant="secondary">
            Confirmar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
