import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, InputGroup } from "@themesberg/react-bootstrap";
import {
  showModalCajaCorteZAction,
  imprimirCorteZAction,
} from "../../actions/cajaActions";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

export default (props) => {
  const dispatch = useDispatch();
  const [fechaInicio, setFecha] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const { showModalCorteZ } = useSelector((state) => state.caja);

  const handleClose = () => {
    dispatch(showModalCajaCorteZAction(false));
  };

  const submitForm = (e) => {
    e.preventDefault();
    const parseFechaInicio =
      props.periodo == "diario"
        ? moment(fechaInicio).format("YYYY-MM-DD")
        : moment(fechaInicio).format("YYYY-MM");

    const parseFechaFin =
      props.periodo == "diario"
        ? moment(fechaFin).format("YYYY-MM-DD")
        : moment(fechaFin).format("YYYY-MM");

    dispatch(
      imprimirCorteZAction(props.periodo, parseFechaInicio, parseFechaFin)
    );
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="sm"
        centered
        show={showModalCorteZ}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            {props.periodo == "diario"
              ? "Corte Z Caja - DIARIO"
              : "Corte Z Caja - MENSUAL"}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>
                Fecha {props.periodo == "diario" ? "Inicio" : ""}
              </Form.Label>
              <Datetime
                timeFormat={false}
                utc={true}
                closeOnSelect={false}
                onChange={setFecha}
                initialViewMode={props.periodo == "diario" ? "days" : "months"}
                dateFormat={
                  props.periodo == "diario" ? "YYYY-MM-DD" : "YYYY-MM"
                }
                renderInput={(propsDate, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      value={
                        fechaInicio
                          ? props.periodo == "diario"
                            ? moment(fechaInicio).format("YYYY-MM-DD")
                            : moment(fechaInicio).format("YYYY-MM")
                          : ""
                      }
                      placeholder="Selecciona una fecha"
                      onFocus={openCalendar}
                      onChange={() => {}}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>
            {props.periodo == "diario" && (
              <Form.Group className="mb-3">
                <Form.Label>Fecha fin</Form.Label>
                <Datetime
                  timeFormat={false}
                  utc={true}
                  closeOnSelect={false}
                  onChange={setFechaFin}
                  initialViewMode={"days"}
                  dateFormat={"YYYY-MM-DD"}
                  renderInput={(propsDate, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={(fechaFin) ? moment(fechaFin).format("YYYY-MM-DD") : ""}
                        placeholder="Selecciona una fecha"
                        onFocus={openCalendar}
                        onChange={() => {}}
                      />
                    </InputGroup>
                  )}
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              Imprimir Corte
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
