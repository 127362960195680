import * as Types from "../types";

const initialState = {
  tipos_movimientos: [],
  movimientos: [],
  loadingMovimientos: false,
  loadingAgregarMovimientos: false,
  loadingEliminarGasto: false,
  showModalCajaMovimientos: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.CAJA_MOVIMIENTOS:
      return {
        ...state,
        loadingMovimientos: true,
      };
    case Types.CAJA_MOVIMIENTOS_EXITO:
      return {
        ...state,
        loadingMovimientos: false,
        movimientos: action.payload,
      };
    case Types.CAJA_MOVIMIENTOS_ERROR:
      return {
        ...state,
        loadingMovimientos: false,
        error: action.payload,
      };
    case Types.CAJA_AGREGAR_MOVIMIENTO:
      return {
        ...state,
        loadingAgregarMovimientos: true,
      };
    case Types.CAJA_AGREGAR_MOVIMIENTO_EXITO:
      return {
        ...state,
        loadingAgregarMovimientos: false,
        movimientos: state.movimientos.concat(action.payload),
        showModalCajaMovimientos: false,
      };
    case Types.CAJA_AGREGAR_MOVIMIENTO_ERROR:
      return {
        ...state,
        loadingAgregarMovimientos: false,
        error: action.payload,
      };
    case Types.MODAL_CAJA_AGREGAR_MOVIMIENTO:
      return {
        ...state,
        showModalCajaMovimientos: action.payload,
      };
    case Types.LISTA_TIPOS_MOVIMIENTOS_CAJA_EXITO:
      return {
        ...state,
        tipos_movimientos: action.payload,
      };
    case Types.LISTA_TIPOS_MOVIMIENTOS_CAJA_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case Types.ELIMINAR_GASTO:
      return {
        ...state,
        loadingEliminarGasto: true,
      };
    case Types.ELIMINAR_GASTO_EXITO:
      return {
        ...state,
        movimientos: state.movimientos.filter(
          (mov) => mov.id != action.payload.id
        ),
        loadingEliminarGasto: false,
      };
    case Types.ELIMINAR_GASTO_ERROR:
      return {
        ...state,
        loadingEliminarGasto: false,
      };
    default:
      return state;
  }
}
