import * as Types from '../types';
import clienteAxios from '../config/axios';
import { loadingRequest } from './axiosActions';
import Swal from 'sweetalert2';

export function setModalLecturaMembresiaAction(estatus) {
    return (dispatch) => {
        dispatch( setModalLecturaMembresia(estatus) );
    }
}

const setModalLecturaMembresia = (estatus) => ({
    type: Types.MODAL_LECTURA_MEMBRESIA,
    payload: estatus
})

export function setNumeroMembresiaAction(numero, tabla = null, accion = null) {
    return (dispatch) => {
        dispatch( setNumeroMembresia({ numero, tabla, accion }) );
    }
}

const setNumeroMembresia = (numero) => ({
    type: Types.SET_NUMERO_MEMBRESIA,
    payload: numero
})