import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Card,Breadcrumb, Form, Button, Col, OverlayTrigger, Tooltip } from '@themesberg/react-bootstrap';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDatatable from '@mkikets/react-datatable'

import { ConfirmModal } from '../../components/ConfirmModal';
import { actualizarImpuestosAction, actualizarInputAction, eliminarImpuestoAction, getImpuestosAction } from "../../actions/impuestosActions";
import AgregarImpuesto from "./AgregarImpuesto";
import { columnaSucursalDT } from "../../helpers";

export default () => {
    const dispatch = useDispatch();

    const { loadingImpuestos, impuestos } = useSelector(state => state.impuestos);

    const [state, guardar] = useState({
        showModalAgregar: false,
    });

    useEffect(() => {
        dispatch(getImpuestosAction());
    }, [])

    const onChangeForm = (e) => {
        const id = e.target.name.split('_')[1];
        let impuesto = impuestos.filter(impuesto => impuesto.id == id)[0];
        impuesto.porcentaje = e.target.value;

        dispatch(actualizarInputAction(impuesto));
    }

    const actualizar = () => {
        dispatch(actualizarImpuestosAction(impuestos));
    }

    const eliminar = (id) => {
        const callbacks = [
          {
            event: dispatch,
            action: eliminarImpuestoAction,
            args: [id],
          },
        ];
    
        ConfirmModal(callbacks);
      }

    const columns = [
        {
            key: "id",
            text: "ID",
            align: "left",
            sortable: true
        },
        {
            key: "concepto",
            text: "Concepto",
            align: "left",
        },
        {
            text: "Porcentaje",
            sortable: false,
            cell: (record) => {
                return (
                    <>
                        <Form.Control
                            type="number"
                            min="0"
                            name={`porcentaje_${record.id}`}
                            value={record.porcentaje}
                            onChange={onChangeForm}
                        />
                    </>
                );
            }
        },
        columnaSucursalDT(),
        {
            key: "action",
            text: "Acciones",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: (record) => {
              return (
                <>
                  <Fragment>
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      overlay={<Tooltip>Eliminar Impuesto</Tooltip>}
                    >
                      <button
                        onClick={() => eliminar(record.id)}
                        className="btn btn-danger btn-sm"
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </OverlayTrigger>
                  </Fragment>
                </>
              );
            },
          },
    ];

    const extraButtons =[
        {
            className:"btn btn-success",
            title:"Agregar impuesto",
            children:[
                <span>
                    <i class="fas fa-plus"></i> Agregar impuesto
                </span>
            ],
            onClick:(event)=>{
                guardar({ ...state, showModalAgregar: true });
            },
        }
    ]

    const configDataTable = {
        button: {
            extra: true
        }
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Impuestos</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body>
                    <h5 className="mb-4">Impuestos</h5>
                    { loadingImpuestos
                    ?   <div class="spinner-border text-info mx-auto d-block" role="status">
                            <span class="visually-hidden">Cargando...</span>
                        </div>
                    :   <ReactDatatable
                            hover 
                            records={impuestos} 
                            columns={columns} 
                            extraButtons={extraButtons} 
                            config={configDataTable} 
                        />
                    }
                    
                    <Col className="text-center">
                        <Button variant="info" className="mt-3" onClick={actualizar}>
                            Guardar cambios
                        </Button>
                    </Col>
                </Card.Body>
            </Card>
            <AgregarImpuesto
                showModal={state.showModalAgregar}
                onHide={() => guardar({ ...state, showModalAgregar: false })}
            />
        </>
  );
}