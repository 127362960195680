import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import { Fragment } from "react";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import ModalFormSalario from "./ModalFormSalario";

export default () => {
  const [salarios, setSalarios] = useState([]);
  const [showModalFormSalario, setShowModalFormSalario] = useState(false);
  const [accionForm, setAccionForm] = useState("agregar");
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    getSalarios();
  }, []);

  const getSalarios = () => {
    clienteAxios.get("salarios/0").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setSalarios(respuesta.data.data);
      }
    });
  };

  const columns = [
    {
      key: "id",
      text: "ID Salario",
      align: "left",
      sortable: true,
    },
    {
      text: "Empleado",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.empleado
          ? record.empleado.nombre + " " + record.empleado.apellido_paterno
          : "NO ENCONTRADO";
      },
    },
    {
      key: "sueldo_base",
      text: "Sueldo base ($)",
      align: "left",
      sortable: true,
    },
    {
      key: "valor_hora_extra",
      text: "Valor hora extra ($)",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Editar</Tooltip>}
              >
                <button
                  onClick={() => editar(record)}
                  className="btn btn-info btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-edit"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Eliminar</Tooltip>}
              >
                <button
                  onClick={() => eliminarSalario(record.id)}
                  className="btn btn-danger btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-trash"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
    },
  ];

  const editar = (salario) => {
    setAccionForm("editar");
    setShowModalFormSalario(true);
    setSelectedRow(salario);
  };

  const eliminarSalario = (idSalario) => {
    clienteAxios.delete("salarios/" + idSalario).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setSalarios(salarios.filter((salario) => salario.id !== idSalario));
        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
      }
    });
  };

  const extraButtons = [
    {
      className: "btn btn-success",
      title: "Agregar salario",
      children: [
        <span>
          <i class="fas fa-plus"></i> Agregar salario
        </span>,
      ],
      onClick: (event) => {
        setShowModalFormSalario(true);
        setAccionForm("agregar");
        setSelectedRow({
          monto: 0,
          valor_hora_extra: 0,
          empleado: null,
          id_empleado: null,
          sueldo_base: 0,
          conceptos: [],
          impuestos: [],
        });
      },
    },
  ];

  const configDataTable = {
    button: {
      extra: true,
      print: true,
      excel: true,
    },
  };

  return (
    <>
      <ReactDatatable
        hover
        records={salarios}
        columns={columns}
        extraButtons={extraButtons}
        config={configDataTable}
      />

      <ModalFormSalario
        showModal={showModalFormSalario}
        accion={accionForm}
        selectedRow={selectedRow}
        onHide={() => {
          setShowModalFormSalario(false);
        }}
        onUpdate={(salarioActualizado) => {
          setSalarios(
            salarios.map((salario) =>
              salario.id === salarioActualizado.id
                ? (salario = salarioActualizado)
                : salario
            )
          );
          setShowModalFormSalario(false);
        }}
        onCreate={(salarioNuevo) => {
          setSalarios(salarios.concat(salarioNuevo));
          setShowModalFormSalario(false);
        }}
      />
    </>
  );
};
