import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Form,
} from "@themesberg/react-bootstrap";
import { getDatosAdicionalesAction } from "../../actions/datosAdicionalesActions";
import { actualizarDatoAdicionalDetalleAction } from "../../actions/datosAdicionalesDetallesActions";
import Typeahead from "../Typeahead";

export default (props) => {
  const dispatch = useDispatch();

  const { datosAdicionales } = useSelector((state) => state.datosAdicionales);
  const { datosAdicionalesDetalles } = useSelector(
    (state) => state.datosAdicionalesDetalles
  );

  const [datosAdicionalesState, setDatosAdicionales] = useState([]);

  useEffect(() => {
    setDatosAdicionales(datosAdicionalesDetalles);
  }, [datosAdicionalesDetalles]);

  const getValorDetalle = (id_dato_adicional) => {
    const valor = datosAdicionalesState.filter(
      (dato) =>
        dato.id_dato_adicional === id_dato_adicional &&
        dato.id_tabla === props.id_tabla
    );

    return valor[0] ?? { valor: "", id_valor: null };
  };

  const actualizarValor = (valor, id_dato_adicional, id_valor = null) => {
    if (typeof valor === "boolean"){
      valor = valor ? "true" : "false";
    }

    setDatosAdicionales(
      datosAdicionalesState.map((dato) =>
        dato.id_dato_adicional === id_dato_adicional &&
        dato.id_tabla === props.id_tabla
          ? { ...dato, valor, id_valor }
          : dato
      )
    );

    dispatch(
      actualizarDatoAdicionalDetalleAction({
        id_dato_adicional,
        id_tabla: props.id_tabla,
        valor,
        id_valor,
      })
    );
  };

  return (
    <>
      {datosAdicionales.map((datoAdicional) => (
        <>
          {datoAdicional.tabulador == props.tabulador && (
            <Fragment key={datoAdicional.id}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>{datoAdicional.etiqueta}</Form.Label>
                    {datoAdicional.tipo == "text" && (
                      <Form.Control
                        type="text"
                        value={getValorDetalle(datoAdicional.id).valor}
                        onChange={(e) =>
                          actualizarValor(e.target.value, datoAdicional.id)
                        }
                      />
                    )}
                    {datoAdicional.tipo == "number" && (
                      <Form.Control
                        type="number"
                        value={getValorDetalle(datoAdicional.id).valor}
                        onChange={(e) =>
                          actualizarValor(e.target.value, datoAdicional.id)
                        }
                        maxLength={datoAdicional.longitud}
                        min="0"
                      />
                    )}
                    {datoAdicional.tipo == "boolean" && (
                      <Form.Check
                        type="switch"
                        id={datoAdicional.id}
                        label=""
                        checked={getValorDetalle(datoAdicional.id).valor == "true"}
                        onChange={(e) =>
                          actualizarValor(
                            e.target.checked,
                            datoAdicional.id
                          )
                        }
                      />
                    )}
                    {datoAdicional.tipo == "service" && (
                      <Typeahead
                        url={datoAdicional.valor_default}
                        labelKey="label"
                        id={datoAdicional.id}
                        onChange={(selected) => {
                          console.log(selected);
                          actualizarValor(
                            selected.length > 0 ? selected[0].label : "",
                            datoAdicional.id,
                            selected.length > 0 ? selected[0].id : null
                          );
                        }}
                        selected={
                          getValorDetalle(datoAdicional.id).id_valor
                            ? [
                                {
                                  id: getValorDetalle(datoAdicional.id).id_valor,
                                  label: getValorDetalle(datoAdicional.id).valor,
                                },
                              ]
                            : undefined
                        }
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Fragment>
          )}
        </>
      ))}
    </>
  );
};
