import React, { useState, useEffect, useContext, useRef } from "react";
import moment from "moment-timezone";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { SocketContext } from "../../../context/socket";
import clienteAxios from "../../../config/axios";

export default (props) => {
  const [newMessage, setNewMessage] = useState("");

  const echo = useContext(SocketContext);

  const scrollableDivRef = useRef(null);

  const scrollDown = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
    }
  }

  useEffect(() => {
    scrollDown();
  }, [props.messages]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (newMessage == "" || newMessage == undefined) {
      return;
    }

    props.setMessages(
      props.messages.concat({
        type: "sent",
        text: newMessage,
        date: moment.utc(),
      })
    );

    setNewMessage("");
    
    const requestParams = {
      mensaje: newMessage,
      id_mensajeria_conversacion: props.chat.id,
      receptor: props.chat.usuario1
    }

    clienteAxios.post("wp-chats/" + props.chat.id, requestParams).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        
      }
    });

    scrollDown();
  };

  return (
    <div className="contentArea">
      <div className="chatArea">
        <div className="messageContainer" ref={scrollableDivRef}>
          {props.messages.map(function (message) {
            return (
              <>
                <div className={"message " + message.type}>{message.text}</div>
                <div
                  className="messageInfo"
                  style={{
                    textAlign: message.type == "sent" ? "right" : "left",
                  }}
                >
                  {message.date.format("YYYY-MM-DD HH:mm")}
                </div>
              </>
            );
          })}
        </div>
        <Form onSubmit={onSubmit}>
            <div className="inputArea">
                <input
                  className="input"
                  type="text"
                  placeholder="Escribe un mensaje aquí..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
            </div>
        </Form>
      </div>
    </div>
  );
};
