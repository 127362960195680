import {
    COMENZAR_LISTA_MENU_FUNCIONES,
    LISTA_MENU_FUNCIONES,
    LISTA_MENU_FUNCIONES_EXITO,
    LISTA_MENU_FUNCIONES_ERROR,
    AGREGAR_MENU_FUNCION,
    AGREGAR_MENU_FUNCION_EXITO,
    AGREGAR_MENU_FUNCION_ERROR,
    COMENZAR_AGREGAR_MENU_FUNCION,
    CERRAR_MODAL_AGREGAR_MENU_FUNCION,
    CERRAR_MODAL_LISTA_MENU_FUNCION,
    COMENZAR_ACTUALIZAR_MENU_FUNCION,
    ACTUALIZAR_MENU_FUNCION,
    ACTUALIZAR_MENU_FUNCION_EXITO,
    ACTUALIZAR_MENU_FUNCION_ERROR,
    CERRAR_MODAL_ACTUALIZAR_MENU_FUNCION,
    ELIMINAR_MENU_FUNCION_EXITO,
    ELIMINAR_MENU_FUNCION_ERROR
} from '../types';

const initialState = {
    menu: {},
    funciones: [],
    funcion: {},
    loading: false,
    showModalFunciones: false,
    showModalAgregar: false,
    showModalEditar: false,
    loadingAgregar: false,
    loadingEditar: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case COMENZAR_LISTA_MENU_FUNCIONES:
            return {
                ...state,
                showModalFunciones: true,
                menu: action.payload
            }
        case LISTA_MENU_FUNCIONES:
            return {
                ...state,
                loading: true
            }
        case LISTA_MENU_FUNCIONES_EXITO:
            return {
                ...state,
                loading: false,
                funciones: action.payload
            }
        case LISTA_MENU_FUNCIONES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case COMENZAR_AGREGAR_MENU_FUNCION:
            return {
                ...state,
                showModalAgregar: true
            }
        case CERRAR_MODAL_AGREGAR_MENU_FUNCION:
            return {
                ...state,
                showModalAgregar: false
            }
        case CERRAR_MODAL_LISTA_MENU_FUNCION:
            return {
                ...state,
                showModalFunciones: false
            }
        case AGREGAR_MENU_FUNCION:
            return {
                ...state,
                loadingAgregar: true
            }
        case AGREGAR_MENU_FUNCION_EXITO:
            return {
                ...state,
                loadingAgregar: false,
                funciones: state.funciones.concat(action.payload),
                showModalAgregar: false
            }
        case AGREGAR_MENU_FUNCION_ERROR:
            return {
                ...state,
                loadingAgregar: false,
                error: action.payload
            }
        case COMENZAR_ACTUALIZAR_MENU_FUNCION:
            return {
                ...state,
                funcion: action.payload,
                showModalEditar: true
            }
        case ACTUALIZAR_MENU_FUNCION:
            return {
                ...state,
                loadingEditar: true
            }
        case ACTUALIZAR_MENU_FUNCION_EXITO:
            return {
                ...state,
                loadingEditar: false,
                funciones: state.funciones.map( funcion => 
                    funcion.id === action.payload.id ? funcion = action.payload : funcion
                ),
                showModalEditar: false
            }
        case ACTUALIZAR_MENU_FUNCION_ERROR:
            return {
                ...state,
                loadingEditar: false,
                error: action.payload
            }
        case CERRAR_MODAL_ACTUALIZAR_MENU_FUNCION:
            return {
                ...state,
                showModalEditar: false
            }
        case ELIMINAR_MENU_FUNCION_EXITO:
            return {
                ...state,
                funciones: state.funciones.filter( funcion => 
                    funcion.id != action.payload.id
                )
            }
        case ELIMINAR_MENU_FUNCION_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}