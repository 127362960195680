import Swal from 'sweetalert2';

export function ConfirmModal (callbacks) {
    Swal.fire({
        title: '¿Estás seguro de realizar esta acción?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
            callbacks.forEach(callback => {
                if(callback.action) {
                    callback.event(callback.action.apply(callback.action, callback.args));
                } else {
                    callback.event.apply(callback.event, callback.args ?? []);
                } 
            });
        }
      })
}
