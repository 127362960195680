import {
    LISTA_USUARIOS_CARGANDO,
    LISTA_USUARIOS_EXITO,
    LISTA_USUARIOS_ERROR,
    COMENZAR_AGREGAR_USUARIO,
    USUARIO_CREACION,
    USUARIO_CREACION_EXITO,
    USUARIO_CREACION_ERROR,
    COMENZAR_ELIMINAR_USUARIO,
    USUARIO_ELIMINADO_EXITO,
    USUARIO_ELIMINADO_ERROR,
    OBTENER_USUARIO_EDITAR,
    USUARIO_ACTUALIZADO_EXITO,
    USUARIO_ACTUALIZADO_ERROR,
    COMENZAR_EDICION_USUARIO,
    CERRAR_MODAL_EDITAR_USUARIO,
    CERRAR_MODAL_AGREGAR_USUARIO
} from '../types';

const initialState = {
    usuarios: [],
    usuario: {},
    loadingUsuarios: false,
    loadingAgregarUsuario: false,
    loadingEditarUsuario: false,
    showModalEditar: false,
    showModalAgregar: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LISTA_USUARIOS_CARGANDO:
            return {
                ...state,
                loadingUsuarios: true,
                usuarios: []
            }
        case LISTA_USUARIOS_EXITO:
            return {
                ...state,
                loadingUsuarios: false,
                usuarios: action.payload
            }
        case LISTA_USUARIOS_ERROR:
            return {
                ...state,
                loadingUsuarios: false,
                usuarios: [],
                error: action.payload
            }
        case OBTENER_USUARIO_EDITAR:
            return {
                ...state,
                usuario: action.payload,
                showModalEditar: true
            }
        case COMENZAR_EDICION_USUARIO:
            return {
                ...state,
                loadingEditarUsuario: true
            }
        case USUARIO_ACTUALIZADO_EXITO:
            return {
                ...state,
                usuario: action.payload,
                usuarios: state.usuarios.map( usuario => 
                    usuario.id === action.payload.id ? usuario = action.payload : usuario
                ),
                loadingEditarUsuario: false,
                showModalEditar: false
            }
        case USUARIO_ACTUALIZADO_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingEditarUsuario: false
            }
        case COMENZAR_AGREGAR_USUARIO:
            return {
                ...state,
                usuario: {},
                showModalAgregar: true
            }
        case USUARIO_CREACION:
            return {
                ...state,
                loadingAgregarUsuario: true
            }
        case USUARIO_CREACION_EXITO:
            return {
                ...state,
                usuario: action.payload,
                usuarios: state.usuarios.concat(action.payload),
                loadingAgregarUsuario: false,
                showModalAgregar: false
            }
        case USUARIO_CREACION_ERROR:
            return {
                ...state,
                error: action.payload,
                loadingAgregarUsuario: false
            }
        case CERRAR_MODAL_EDITAR_USUARIO:
            return {
                ...state,
                showModalEditar: false
            }
        case CERRAR_MODAL_AGREGAR_USUARIO:
            return {
                ...state,
                showModalAgregar: false
            }
        case COMENZAR_ELIMINAR_USUARIO:
            return {
                ...state,
                usuario: action.payload
            }
        case USUARIO_ELIMINADO_EXITO:
            return {
                ...state,
                usuarios: state.usuarios.filter( usuario => 
                    usuario.id != state.usuario.id
                ),
                usuario: {}
            }
        case USUARIO_ELIMINADO_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}