import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Breadcrumb } from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '@mkikets/react-datatable';
import { busquedaFiltroAction } from "../../actions/filtroActions";
import Filtro from "../../components/Filtro";
import { columnaSucursalDT } from "../../helpers";

export default (props) => {
  const dispatch = useDispatch();
  const { loadingFiltro, respuestaFiltro } = useSelector((state) => state.filtro);
  const modelo = "Empleado.EmpleadoComisiones";
  const criteriasObligatorios = ["order-id"];

  useEffect(() => {
    dispatch(busquedaFiltroAction(modelo, { offset: 0, limit: 10, criteria: ["all", "order-id"] }));
  }, []);

  const [requestFiltro, guardarRequestFiltro] = useState({
    offset: 0,
    limit: 10
  });

  const columns = [
    {
      key: "id",
      text: "ID",
      align: "left",
      sortable: true,
    },
    {
      key: "id_cliente_tratamiento",
      text: "ID Cliente Tratamiento",
      sortable: true,
    },
    {
      text: "Nombre empleado",
      sortable: true,
      cell: (record) => {
        return (
          record.empleado?.nombre +
          " " +
          record.empleado?.apellido_paterno +
          " " +
          (record.empleado?.apellido_materno || "")
        );
      },
    },
    {
      key: "created_at",
      text: "Fecha",
      sortable: true,
    },
    {
      key: "comision",
      text: "Comisión (%)",
      sortable: true,
    },
    {
      key: "total",
      text: "Total",
      sortable: true,
    },
    columnaSucursalDT()
  ];

  const opcionesFiltro = [
    {
      valor: "id",
      label: "ID",
      tipo: "number",
      criteria: ["equal", "order-id"],
    },
    {
      valor: "created_at",
      label: "Fecha",
      tipo: "date",
      criteria: ["created_at", "order-id"],
    },
    {
      valor: "nombre",
      label: "Empleado",
      tipo: "text",
      criteria: ["empleado", "order-id"],
    },
  ];

  const configDT = {
    show_filter: false,
  };

  const onChangeDT = (event) => {
    requestFiltro.limit = event.page_size;
    requestFiltro.offset = event.page_size * (event.page_number - 1);
    guardarRequestFiltro({ ...requestFiltro });

    dispatch(busquedaFiltroAction(modelo, { 
      offset: requestFiltro.offset,
      limit: requestFiltro.limit,
      criteria: ["all", "order-id"] 
    }));
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Historial comisiones</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Historial comisiones</h5>
          <Filtro
            opciones={opcionesFiltro}
            modelo={modelo}
            criteriasObligatorios={criteriasObligatorios}
          />
          <hr></hr>
          <ReactDatatable 
            hover 
            records={(respuestaFiltro.data ?? [])}
            total_record={(respuestaFiltro.count ?? 0)}
            columns={columns}
            dynamic
            loading={loadingFiltro}
            config={configDT}
            onChange={onChangeDT}
        />
        </Card.Body>
      </Card>
    </>
  );
};
