import * as Types from "../types";

const initialState = {
  datosAdicionales: [],
  tabsDatosAdicionales: [],
  datoAdicional: null,
  showModalDatosAdicionales: false,
  showModalAgregarDatosAdicionales: false,
  showModalActualizarDatosAdicionales: false,
  loadingDatosAdicionales: false,
  loadingAgregarDatosAdicionales: false,
  loadingActualizarDatosAdicionales: false,
  loadingTabsDatosAdicionales: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.LISTA_DATOS_ADICIONALES:
        return {
            ...state,
            loadingDatosAdicionales: true,
            datosAdicionales: []
        }
    case Types.LISTA_DATOS_ADICIONALES_EXITO:
        return {
            ...state,
            loadingDatosAdicionales: false,
            datosAdicionales: action.payload
        }
    case Types.LISTA_DATOS_ADICIONALES_ERROR:
        return {
            ...state,
            loadingDatosAdicionales: false,
            error: action.payload
        }
    case Types.MODAL_DATOS_ADICIONALES:
      return {
        ...state,
        showModalDatosAdicionales: action.payload,
      };
    case Types.MODAL_AGREGAR_DATOS_ADICIONALES:
      return {
        ...state,
        showModalAgregarDatosAdicionales: action.payload,
      };
    case Types.MODAL_ACTUALIZAR_DATOS_ADICIONALES:
      return {
        ...state,
        showModalActualizarDatosAdicionales: action.payload,
      };
    case Types.AGREGAR_DATOS_ADICIONALES:
      return {
        ...state,
        loadingAgregarDatosAdicionales: true,
      };
    case Types.AGREGAR_DATOS_ADICIONALES_EXITO:
      return {
        ...state,
        loadingAgregarDatosAdicionales: false,
        showModalAgregarDatosAdicionales: false,
        datosAdicionales: state.datosAdicionales.concat(action.payload),
      };
    case Types.AGREGAR_DATOS_ADICIONALES_ERROR:
      return {
        ...state,
        loadingAgregarDatosAdicionales: false,
        error: action.payload,
      };
    case Types.COMENZAR_ACTUALIZAR_DATOS_ADICIONALES:
      return {
        ...state,
        showModalActualizarDatosAdicionales: true,
        datoAdicional: action.payload
      };
    case Types.ACTUALIZAR_DATOS_ADICIONALES:
      return {
        ...state,
        loadingActualizarDatosAdicionales: true,
      };
    case Types.ACTUALIZAR_DATOS_ADICIONALES_EXITO:
      return {
        ...state,
        loadingActualizarDatosAdicionales: false,
        showModalActualizarDatosAdicionales: false,
        datosAdicionales: state.datosAdicionales.map((datoAdicional) =>
          datoAdicional.id === action.payload.id
            ? (datoAdicional = action.payload)
            : datoAdicional
        ),
      };
    case Types.ACTUALIZAR_DATOS_ADICIONALES_ERROR:
        return {
            ...state,
            loadingActualizarDatosAdicionales: false,
            error: action.payload
        }
    case Types.ELIMINAR_DATOS_ADICIONALES_EXITO:
        return {
            ...state,
            datosAdicionales: state.datosAdicionales.filter(datoAdicional => datoAdicional.id !== action.payload)
        }
    case Types.LISTA_TABS_DATOS_ADICIONALES:
        return {
            ...state,
            loadingTabsDatosAdicionales: true,
            tabsDatosAdicionales: []
        }
    case Types.LISTA_TABS_DATOS_ADICIONALES_EXITO:
        return {
            ...state,
            loadingTabsDatosAdicionales: false,
            tabsDatosAdicionales: action.payload
        }
    case Types.LISTA_TABS_DATOS_ADICIONALES_ERROR:
        return {
            ...state,
            loadingTabsDatosAdicionales: false,
            error: action.payload
        }
    default:
      return state;
  }
}
