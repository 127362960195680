import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Modal,
  Button,
  Form,
  Row,
  Col,
} from "@themesberg/react-bootstrap";
import {
  showModalEntradaSalidaAction,
  registrarMovimientoAction,
} from "../../actions/inventarioActions";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import clienteAxios from "../../config/axios";
import Typeahead from "../../components/Typeahead";

export default (props) => {
  const dispatch = useDispatch();

  const { showModalEntradaSalida } = useSelector((state) => state.inventario);

  const [state, setState] = useState({
    query: "",
    options: [],
    isLoading: false,
    producto: null,
    motivo: "",
    cantidad: "",
    precio_unidad: 0,
    proveedor: null,
    financiamiento: false,
    fecha_entrada: null,
  });

  var _cacheProducto = {};

  const _handleInputChange = (query) => {
    state.query = query;
  };

  const makeAndHandleRequest = (query) => {
    const config = { params: { query } };

    return clienteAxios.get("typeahead/productos", config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  const _handleSearch = (query) => {
    if (_cacheProducto[query]) {
      setState({
        ...state,
        options: _cacheProducto[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequest(query).then((resp) => {
      _cacheProducto[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };

  const onChange = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        producto: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        producto: null,
      });
    }
  };

  const handleClose = () => {
    dispatch(showModalEntradaSalidaAction(false));
  };

  const onChangeForm = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const movimiento = {
      id_producto: state.producto != null ? state.producto.id : null,
      cantidad: state.cantidad,
      motivo: state.motivo,
      precio_unidad: state.precio_unidad,
      tipo: props.tipoRegistro,
      id_proveedor: state.id_proveedor,
      financiamiento: state.financiamiento,
      fecha_entrada: state.fecha_entrada,
    };

    dispatch(registrarMovimientoAction(movimiento));
  };

  const getProveedor = () => {
    if (state.proveedor !== null) {
      return [
        {
          id: state.proveedor.id,
          label: state.proveedor.nombre,
        },
      ];
    }

    return [];
  };

  const onChangeCheckbox = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  const onChangeProveedor = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        id_proveedor: selectedOption[0].id,
        proveedor: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        id_proveedor: null,
        proveedor: null,
      });
    }
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        centered
        show={showModalEntradaSalida}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">
            Registrar {props.tipoRegistro}
          </Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body>
            {props.tipoRegistro === "ENTRADA" && (
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Financiamiento por proveedor</Form.Label>
                    <Form.Check
                      type="switch"
                      name="financiamiento"
                      checked={state.financiamiento}
                      onChange={onChangeCheckbox}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Producto*</Form.Label>
              <AsyncTypeahead
                {...state}
                clearButton
                id="async-productos"
                labelKey="nombre"
                minLength={2}
                onInputChange={_handleInputChange}
                onSearch={_handleSearch}
                onChange={onChange}
                placeholder="Buscar producto"
                renderMenuItemChildren={(option) => (
                  <div key={option.id}>
                    <span>{option.nombre}</span>
                  </div>
                )}
                useCache={false}
              />
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Control
                type="text"
                name="motivo"
                placeholder="Motivo"
                onChange={onChangeForm}
              />
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label>Cantidad*</Form.Label>
              <Form.Control
                type="number"
                name="cantidad"
                placeholder="Cantidad"
                onChange={onChangeForm}
                min="0"
                step="any"
              />
            </Form.Group>
            {props.tipoRegistro === "ENTRADA" && (
              <Form.Group className="mb-3">
                <Form.Label>Costo unidad*</Form.Label>
                <Form.Control
                  type="number"
                  name="precio_unidad"
                  placeholder="Precio unidad"
                  onChange={onChangeForm}
                  min="0"
                  step="any"
                />
              </Form.Group>
            )}
            {props.tipoRegistro === "ENTRADA" && (
              <Form.Group className="mb-3">
                <Form.Label>Fecha entrada</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_entrada"
                  placeholder="Fecha de entrada"
                  onChange={onChangeForm}
                />
              </Form.Group>
            )}
            {props.tipoRegistro === "ENTRADA" && (
              <Form.Group className="mb-3">
                <Form.Label>Proveedor</Form.Label>
                <Typeahead
                  url="typeahead/proveedores"
                  labelKey="label"
                  id="async-proveedores"
                  placeholder="Buscar proveedor"
                  onChange={(selected) => {
                    onChangeProveedor(selected);
                  }}
                  selected={getProveedor()}
                />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              Agregar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
