import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function listaKardexDetAction(fecha, id_producto = null) {
    return async (dispatch) => {
        dispatch( listaKardexDet() );

        let url = 'inventario/movimientos/kardex/detalle/' + fecha;
        if (id_producto) {
            url += '/'+id_producto;
        }

        try {
            const response = await clienteAxios.get(url);
            if (response.data.data) {
                dispatch( listaKardexDetExito(response.data.data) );
            }
        } catch (error) {
            dispatch( listaKardexDetError(error) );
        }
    }
}

const listaKardexDet = () => ({
    type: Types.LISTA_KARDEX_DETALLES
})

const listaKardexDetExito = detalles => ({
    type: Types.LISTA_KARDEX_DETALLES_EXITO,
    payload: detalles
})

const listaKardexDetError = error => ({
    type: Types.LISTA_KARDEX_DETALLES_ERROR,
    payload: error
})