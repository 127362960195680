import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Breadcrumb,
  Col,
  Form,
  Row,
  Modal,
  Button,
  Tabs,
  Tab,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import MultiSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import { getEmpleadosAction } from "../../actions/empleadosActions";
export default (props) => {
  const dispatch = useDispatch();

  const { loadingEmpleados, empleados } = useSelector(
    (state) => state.empleados
  );

  const [puestos, setPuestos] = useState([]);

  const [state, setState] = useState({
    items: [],
    selectedItems: [],
    selectedRoles: [],
    fecha_inicio: null,
    fecha_fin: null,
    fecha: null,
    pagado: false,
    defaultActiveKey: "empleados",
  });

  useEffect(() => {
    setState({
      ...state,
      showModal: props.showModal,
      pagado: "si",
    });
  }, [props.showModal]);

  useEffect(() => {
    if (props.showModal === true) {
      dispatch(getEmpleadosAction());
      getPuestosLaborales();
    }
  }, [props.showModal]);

  useEffect(() => {
    renderEmpleados();
  }, [empleados]);

  const renderEmpleados = () => {
    setState({
      ...state,
      selectedItems: [],
      items: empleados.map((empleado) => {
        return {
          id: empleado.id,
          label: empleado.nombre + " " + empleado.apellido_paterno,
        };
      }),
    });
  };

  const handleCloseModal = () => {
    props.onHide();
    setState({
      ...state,
      showModal: false,
    });
  };

  const handleChange = (selectedItems) => {
    setState({
      ...state,
      selectedItems: selectedItems,
    });
  };

  const handleChangeRoles = (selectedItems) => {
    setState({
      ...state,
      selectedRoles: selectedItems,
    });
  };

  const getPuestosLaborales = () => {
    clienteAxios.get("puestos-laborales").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setPuestos(respuesta.data.data);
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formRequest = {
      ids_empleados: state.selectedItems.map((item) => item.id),
      fecha_inicio: state.fecha_inicio,
      fecha_fin: state.fecha_fin,
      fecha: state.fecha,
      pagado: state.pagado == "si" ? true : false,
      ids_puestos_laborales: state.selectedRoles.map((item) => item.id),
    };

    clienteAxios.post("nominas", formRequest).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        const data = respuesta.data.data;
        setState({
          ...state,
          showModal: false,
        });

        props.onSubmit(data);

        Swal.fire("¡Muy bien!", respuesta.data.message, "success");
      }
    });
  };

  const onChangeForm = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectTab = (key) => {
    setState({
      ...state,
      selectedItems: key === "empleados" ? state.selectedItems : [],
      selectedRoles: key === "roles" ? state.selectedRoles : [],
      defaultActiveKey: key,
    });
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="lg"
        centered
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header>
          <Modal.Title className="h6">Generar nomina</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            <Tabs
              defaultActiveKey={state.defaultActiveKey}
              onSelect={onSelectTab}
              id="uncontrolled-tab-example"
              fill
            >
              <Tab eventKey="empleados" title="Empleados">
                <MultiSelect
                  items={state.items}
                  selectedItems={state.selectedItems}
                  onChange={handleChange}
                  messages={{
                    searchPlaceholder: "Buscar...",
                    noItemsMessage: "No se encontraron resultados...",
                    noneSelectedMessage: "Sin seleccionar",
                    selectedMessage: "Seleccionado",
                    selectAllMessage: "Seleccionar todo",
                    clearAllMessage: "Limpiar todo",
                    disabledItemsTooltip: "Solo puedes seleccionar una opción",
                  }}
                />
              </Tab>
              <Tab eventKey="roles" title="Roles">
                <MultiSelect
                  items={puestos.map((puesto) => {
                    return {
                      id: puesto.id,
                      label: puesto.nombre,
                    };
                  })}
                  selectedItems={state.selectedRoles}
                  onChange={handleChangeRoles}
                  messages={{
                    searchPlaceholder: "Buscar...",
                    noItemsMessage: "No se encontraron resultados...",
                    noneSelectedMessage: "Sin seleccionar",
                    selectedMessage: "Seleccionado",
                    selectAllMessage: "Seleccionar todo",
                    clearAllMessage: "Limpiar todo",
                    disabledItemsTooltip: "Solo puedes seleccionar una opción",
                  }}
                />
              </Tab>
            </Tabs>
            <br></br>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Fecha inicio</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_inicio"
                    placeholder="Fecha inicio"
                    value={state.fecha_inicio}
                    onChange={onChangeForm}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Fecha fin</Form.Label>
                  <Form.Control
                    type="date"
                    name="fecha_fin"
                    placeholder="Fecha fin"
                    value={state.fecha_fin}
                    onChange={onChangeForm}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Pagado</Form.Label>
                  <Form.Select name="pagado" onChange={onChangeForm}>
                    <option value="si">SI</option>
                    <option value="no">NO</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleCloseModal}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              Generar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
