import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button, Form } from "@themesberg/react-bootstrap";
import {
  agregarCajaMovimientoAction,
  showModalCajaMovimientoAction,
} from "../../actions/cajaMovimientosActions";
import clienteAxios from "../../config/axios";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import DefaultUser from "../../assets/img/team/default-user.png";

export default (props) => {
  const dispatch = useDispatch();

  const { caja } = useSelector((state) => state.caja);

  var _cacheEmpleado = {};

  const {
    loadingAgregarMovimientos,
    showModalCajaMovimientos,
    tipos_movimientos,
  } = useSelector((state) => state.cajaMovimientos);

  const [movimiento, guardar] = useState({
    id_tipo_movimiento: 0,
    tipo_movimiento: "",
    monto: 0,
    descripcion: "",
  });

  const [state, setState] = useState({
    query: "",
    options: [],
    isLoading: false,
    empleadoSeleccionado: null,
  });

  const _handleInputChangeEmpleado = (query) => {
    state.query = query;
  };

  const makeAndHandleRequestEmpleado = (query) => {
    const config = { params: { query } };

    return clienteAxios.get("typeahead/empleados", config).then((res) => {
      const options = res.data.data;
      return { options };
    });
  };

  const _handleSearchEmpleado = (query) => {
    if (_cacheEmpleado[query]) {
      setState({
        ...state,
        options: _cacheEmpleado[query].options,
      });
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    makeAndHandleRequestEmpleado(query).then((resp) => {
      _cacheEmpleado[query] = { ...resp };
      setState({
        ...state,
        isLoading: false,
        options: resp.options,
      });
    });
  };

  const onChangeEmpleado = (selectedOption) => {
    if (selectedOption.length > 0) {
      setState({
        ...state,
        empleadoSeleccionado: selectedOption[0],
      });
    } else {
      setState({
        ...state,
        empleadoSeleccionado: null,
      });
    }
  };

  const handleClose = () => {
    dispatch(showModalCajaMovimientoAction(false));
  };

  const onChangeForm = (e) => {
    guardar({
      ...movimiento,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeTipoMovimientoForm = (e) => {
    guardar({
      ...movimiento,
      [e.target.name]: e.target.value,
      tipo_movimiento: e.target[e.target.selectedIndex].text,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    movimiento.id_caja_apertura = caja.idCaja;
    if (
      movimiento.tipo_movimiento == "COMISIÓN EN EFECTIVO" ||
      movimiento.tipo_movimiento == "ADELANTO DE NOMINA"
    ) {
      movimiento.id_empleado = state.empleadoSeleccionado.id;
    }
    dispatch(agregarCajaMovimientoAction(movimiento));
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="sm"
        centered
        show={showModalCajaMovimientos}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Nuevo movimiento</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body>
            <Form.Select
              name="id_tipo_movimiento"
              aria-label="Default select example"
              onChange={onChangeTipoMovimientoForm}
              className="mb-2"
            >
              <option value="0">Selecciona un tipo de movimiento</option>
              {tipos_movimientos.map((tipo) => (
                <option value={tipo.id}> {tipo.tipo_movimiento} </option>
              ))}
            </Form.Select>
            {movimiento.tipo_movimiento == "COMISIÓN EN EFECTIVO" ||
            movimiento.tipo_movimiento == "ADELANTO DE NOMINA" ? (
              <Form.Group className="mb-3">
                <AsyncTypeahead
                  {...state}
                  clearButton
                  id="async-empleados"
                  labelKey={(option) =>
                    `${option.nombre} ${option.apellido_paterno} ${
                      option.apellido_materno ?? ""
                    }`
                  }
                  minLength={2}
                  onInputChange={_handleInputChangeEmpleado}
                  onSearch={_handleSearchEmpleado}
                  onChange={onChangeEmpleado}
                  placeholder="Buscar Empleado"
                  renderMenuItemChildren={(option) => (
                    <div key={option.id}>
                      <img
                        src={DefaultUser}
                        style={{
                          height: "24px",
                          marginRight: "10px",
                          width: "24px",
                        }}
                      />
                      <span>
                        {option.nombre} {option.apellido_paterno}{" "}
                        {option.apellido_materno ?? ""}
                      </span>
                    </div>
                  )}
                  useCache={false}
                />
              </Form.Group>
            ) : (
              ""
            )}
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                name="monto"
                placeholder="Monto"
                onChange={onChangeForm}
                step="any"
                min="0"
              />
            </Form.Group>
            <Form.Control
              name="descripcion"
              as="textarea"
              placeholder="Descripción"
              onChange={onChangeForm}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button
              type="submit"
              variant="secondary"
              disabled={loadingAgregarMovimientos}
            >
              Agregar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
