import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Modal, Button, Form } from '@themesberg/react-bootstrap'
import {
  showModalFinanciadoAction,
  actualizarProductoAction
} from '../../actions/ventaActions'

export default props => {
  const dispatch = useDispatch()

  const {
    showModalVentaFinanciado,
    tratamientoSeleccionado,
    productosAgregados
  } = useSelector(state => state.venta)

  const [tratamiento, guardar] = useState({
    anticipo: 0
  })

  useEffect(() => {
    guardar(tratamientoSeleccionado)
  }, [tratamientoSeleccionado])

  const handleClose = () => {
    dispatch(showModalFinanciadoAction(false))
  }

  const onChangeForm = e => {
    guardar({
      ...tratamiento,
      [e.target.name]: e.target.value
    })
  }

  const submitForm = e => {
    e.preventDefault()

    tratamiento.tratamiento_financiado = tratamiento.anticipo > 0 ? true : false
    dispatch(actualizarProductoAction(tratamiento))
    dispatch(showModalFinanciadoAction(false))
  }

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size='sm'
        centered
        show={showModalVentaFinanciado}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className='h6'>Precios tratamiento</Modal.Title>
          <Button variant='close' aria-label='Close' onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body>
            <Form.Group className='mb-3'>
              <Form.Label>Monto anticipo</Form.Label>
              <Form.Control
                type='number'
                name='anticipo'
                placeholder='Monto anticipo'
                value={tratamiento.anticipo}
                onChange={onChangeForm}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='link'
              className='text-gray ms-auto'
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type='submit' variant='secondary'>
              Actualizar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
