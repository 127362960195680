import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '@mkikets/react-datatable';
import { busquedaFiltroAction } from "../../actions/filtroActions";
import Filtro from "../../components/Filtro";
import { ConfirmModal } from "../../components/ConfirmModal";
import { localeDateString, numberFormat } from "../../helpers";
import ModalKardexDetalle from "./ModalKardexDetalle";
import { listaKardexDetAction } from "../../actions/kardexActions";

export default (props) => {
  const dispatch = useDispatch();
  const { loadingFiltro, respuestaFiltro } = useSelector((state) => state.filtro);
  const modelo = "Inventario.MovimientoInventario";
  const criteriasObligatorios = ["kardex"];

  const [state, guardar] = useState({
    showModalDetalles: false
  });

  useEffect(() => {
    dispatch(busquedaFiltroAction(modelo, { criteria: ["kardex"] }));
  }, []);

  const columns = [
    {
      text: "Fecha",
      sortable: true,
      cell: (record) => {
        const date = new Date(Date.parse(record.fecha));
        const dateUTC = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        return localeDateString(dateUTC);
      }
    },
    {
        key: "id_producto",
        text: "Id Producto",
        sortable: true,
    },
    {
      text: "Producto",
      sortable: true,
      cell: (record) => {
        return record.producto ? record.producto.nombre : 'NO ENCONTRADO';
      },
    },
    {
        text: "Entradas",
        sortable: true,
        cell: (record) => {
            return record.cantidad_entrada ?? 0;
        }
    },
    {
        text: "Costo Total Entradas",
        sortable: true,
        cell: (record) => {
            return numberFormat(record.costo_total_entrada);
        }
    },
    {
        text: "Salidas",
        sortable: true,
        cell: (record) => {
            return record.cantidad_salida ?? 0;
        }
    },
    {
        text: "Costo Total Salidas",
        sortable: true,
        cell: (record) => {
            return numberFormat(record.costo_total_salida);
        }
    },
    {
        text: "Inventario Inicial",
        sortable: true,
        cell: (record) => {
            const stockInicialArray = getStock(record.stock_inicial);
            return stockInicialArray[0] ?? 0;
        },
    },
    {
        text: "Inventario final",
        sortable: true,
        cell: (record) => {
            const stockFinalArray = getStock(record.stock_final);
            return stockFinalArray[0] ?? 0;
        },
    },
    {
      text: "Sucursal",
      sortable: true,
      cell: (record) => {
          return record.inventario?.sucursal?.nombre;
      },
  },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <Fragment>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                overlay={<Tooltip>Detalle</Tooltip>}
              >
                <button
                  onClick={() => showDetalles(record)}
                  className="btn btn-info btn-sm"
                  style={{
                    marginRight: "5px",
                  }}
                >
                  <i class="fas fa-info-circle"></i>
                </button>
              </OverlayTrigger>
            </Fragment>
          </>
        );
      },
    },
  ];

  const getStock = (value) => {
    try {
        value = value.replace("{", "[");
        value = value.replace("}", "]");
        const stockInicialArray = JSON.parse(value);
        return stockInicialArray;
    } catch (error) {
        return 0;
    }
  }

  const showDetalles = (record) => {
    guardar({ ...state, showModalDetalles: true});
    dispatch(listaKardexDetAction(record.fecha, record.id_producto));
  };

  const opcionesFiltro = [
    { 
        valor: "created_at",
        label: "Fecha",
        tipo: "date",
        criteria: ["created_at", "kardex"],
        format: "YYYY-MM",
        viewMode: "months"
    },
    {
      valor: "nombre",
      label: "Producto",
      tipo: "text",
      criteria: ["producto", "kardex"],
    }
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Kardex</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-1">Kardex</h5>
          <h6 className="text-muted mb-4">Movimientos y costos de entradas - salidas de productos. Puede ver la información detallada de los movimientos específicos de un producto por cada mes.</h6>
            <Filtro opciones={opcionesFiltro} modelo={modelo} criteriasObligatorios={criteriasObligatorios} />
          <hr></hr>
          { loadingFiltro
            ? <div class="spinner-border text-info mx-auto d-block" role="status">
                <span class="visually-hidden">Cargando...</span>
              </div>
            : <ReactDatatable hover records={(respuestaFiltro.data ?? [])} columns={columns} />
          }
        </Card.Body>
      </Card>

      <ModalKardexDetalle 
        showModal={state.showModalDetalles} 
        onHide={() => guardar({ ...state, showModalDetalles: false })} 
      />
    </>
  );
};
