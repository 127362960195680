import React from "react";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useState } from "react";
import { useEffect } from "react";
import clienteAxios from "../../config/axios";

export default (props) => {
  const [frecuencia, setFrecuencia] = useState("Mensuales");
  const [date, setDate] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    setDate(Object.keys(props.data));
    setValue(Object.values(props.data));
  }, [props.data]);

  const option = {
    backgroundColor: "#fff",
    tooltip: {
      trigger: "axis",
      position: function (pt) {
        return [pt[0], "10%"];
      },
    },
    title: {
      left: "center",
      text: props.title + " " + frecuencia,
      padding: 35,
    },
    toolbox: {
      show: true,
      feature: {
        myCustomDiarioButton: {
          show: true,
          title: "Diario",
          icon: "path://M 0 14.12 L 0 0.2 Q 0.94 0.1 1.84 0.05 A 30.262 30.262 0 0 1 2.677 0.016 Q 3.105 0.004 3.579 0.001 A 51.315 51.315 0 0 1 3.9 0 A 9.722 9.722 0 0 1 5.369 0.105 Q 6.156 0.226 6.822 0.484 A 5.839 5.839 0 0 1 7.01 0.56 A 6.196 6.196 0 0 1 8.468 1.424 A 5.445 5.445 0 0 1 9.17 2.1 Q 10.02 3.08 10.43 4.37 A 8.851 8.851 0 0 1 10.824 6.569 A 10.301 10.301 0 0 1 10.84 7.14 A 9.062 9.062 0 0 1 10.544 9.48 A 8.36 8.36 0 0 1 10.42 9.9 Q 10 11.2 9.13 12.19 A 5.794 5.794 0 0 1 7.479 13.48 A 7.051 7.051 0 0 1 6.91 13.75 Q 5.655 14.28 3.942 14.317 A 12.038 12.038 0 0 1 3.68 14.32 A 37.396 37.396 0 0 1 2.866 14.312 Q 2.463 14.303 2.104 14.285 A 20.3 20.3 0 0 1 1.69 14.26 Q 0.84 14.2 0 14.12 Z M 1.9 1.74 L 1.9 12.56 Q 2.32 12.62 2.78 12.64 Q 3.24 12.66 3.74 12.66 A 7.581 7.581 0 0 0 4.973 12.566 Q 5.643 12.455 6.191 12.215 A 4.006 4.006 0 0 0 6.68 11.96 Q 7.82 11.26 8.36 10.01 A 6.458 6.458 0 0 0 8.811 8.395 A 8.5 8.5 0 0 0 8.9 7.14 Q 8.9 5.7 8.36 4.44 Q 7.82 3.18 6.7 2.41 A 4.179 4.179 0 0 0 5.29 1.798 Q 4.628 1.64 3.84 1.64 Q 3.26 1.64 2.78 1.67 Q 2.3 1.7 1.9 1.74 Z", // ruta a un ícono personalizado si lo deseas
          onclick: function () {
            setFrecuencia("Diarios");
            clienteAxios
              .get("estadistica/" + props.identificador, {
                params: { frecuencia: "diario" },
              })
              .then((respuesta) => {
                if (
                  respuesta &&
                  respuesta.data.data &&
                  respuesta.data.success === true
                ) {
                  setFrecuencia("Diarios");
                  setDate(Object.keys(respuesta.data.data));
                  setValue(Object.values(respuesta.data.data));
                }
              });
          },
        },
        myCustomSemanalButton: {
          show: true,
          title: "Semanal",
          icon: "path://M 0 13.06 L 0.96 11.46 Q 1.68 12.06 2.58 12.44 Q 3.48 12.82 4.68 12.82 A 5.596 5.596 0 0 0 5.589 12.751 Q 6.405 12.616 6.96 12.22 A 2.056 2.056 0 0 0 7.519 11.639 Q 7.782 11.218 7.799 10.673 A 2.423 2.423 0 0 0 7.8 10.6 A 2.388 2.388 0 0 0 7.733 10.021 A 1.963 1.963 0 0 0 7.56 9.57 Q 7.321 9.122 6.637 8.694 A 5.256 5.256 0 0 0 6.63 8.69 Q 5.94 8.26 4.6 7.76 Q 3.581 7.364 2.852 6.962 A 7.899 7.899 0 0 1 2.3 6.63 A 4.386 4.386 0 0 1 1.682 6.141 Q 1.29 5.763 1.07 5.33 A 3.333 3.333 0 0 1 0.766 4.396 A 4.586 4.586 0 0 1 0.7 3.6 Q 0.7 2.6 1.25 1.79 Q 1.8 0.98 2.87 0.49 A 5.112 5.112 0 0 1 4.05 0.123 Q 4.577 0.023 5.178 0.004 A 9.045 9.045 0 0 1 5.46 0 Q 6.7 0 7.69 0.25 Q 8.68 0.5 9.62 0.96 L 8.92 2.58 Q 8.24 2.18 7.33 1.91 Q 6.42 1.64 5.42 1.64 Q 4.1 1.64 3.34 2.16 A 1.98 1.98 0 0 0 2.907 2.553 A 1.472 1.472 0 0 0 2.58 3.5 A 2.093 2.093 0 0 0 2.638 4.004 A 1.712 1.712 0 0 0 2.8 4.42 A 1.382 1.382 0 0 0 3.014 4.705 Q 3.254 4.957 3.67 5.2 Q 4.32 5.58 5.62 6.06 A 16.246 16.246 0 0 1 6.616 6.466 Q 7.102 6.685 7.503 6.911 A 7.101 7.101 0 0 1 8.09 7.28 Q 8.836 7.804 9.225 8.403 A 2.846 2.846 0 0 1 9.38 8.67 A 3.516 3.516 0 0 1 9.728 9.843 A 4.431 4.431 0 0 1 9.76 10.38 A 4.342 4.342 0 0 1 9.627 11.48 A 3.421 3.421 0 0 1 9.11 12.6 A 3.924 3.924 0 0 1 7.724 13.805 A 4.871 4.871 0 0 1 7.31 14 A 6.104 6.104 0 0 1 5.828 14.401 A 8.005 8.005 0 0 1 4.68 14.48 Q 3.22 14.48 2.02 14.09 A 7.641 7.641 0 0 1 0.947 13.654 A 5.687 5.687 0 0 1 0 13.06 Z", // ruta a un ícono personalizado si lo deseas
          onclick: function () {
            setFrecuencia("Semanales");
            clienteAxios
              .get("estadistica/" + props.identificador, {
                params: { frecuencia: "semanal" },
              })
              .then((respuesta) => {
                if (
                  respuesta &&
                  respuesta.data.data &&
                  respuesta.data.success === true
                ) {
                  setDate(Object.keys(respuesta.data.data));
                  setValue(Object.values(respuesta.data.data));
                }
              });
          },
        },
        myCustomMensualButton: {
          show: true,
          title: "Mensual",
          icon: "path://M 1.8 14 L 0 14 L 0 0 L 2.66 0 L 6.54 8.86 L 10.36 0 L 13.08 0 L 13.08 14 L 11.18 14 L 11.18 2.42 L 7.44 10.84 L 5.56 10.84 L 1.8 2.42 L 1.8 14 Z", // ruta a un ícono personalizado si lo deseas
          onclick: function () {
            setFrecuencia("Mensuales");
            clienteAxios
              .get("estadistica/" + props.identificador, {
                params: { frecuencia: "mensual" },
              })
              .then((respuesta) => {
                if (
                  respuesta &&
                  respuesta.data.data &&
                  respuesta.data.success === true
                ) {
                  setDate(Object.keys(respuesta.data.data));
                  setValue(Object.values(respuesta.data.data));
                }
              });
          },
        },
        dataZoom: {
          yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: date,
    },
    yAxis: {
      type: "value",
      boundaryGap: [0, "100%"],
    },
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: props.serieLabel,
        type: "line",
        symbol: "none",
        sampling: "lttb",
        itemStyle: {
          color: props.lineColor,
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: props.color1,
            },
            {
              offset: 1,
              color: props.color2,
            },
          ]),
        },
        data: value,
      },
    ],
  };

  return <ReactECharts option={option} />;
};
