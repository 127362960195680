import {
    LISTA_ROLES_MENU,
    LISTA_ROLES_MENU_EXITO,
    LISTA_ROLES_MENU_ERROR,
    SET_SELECTED_ROL_MENUS,
    CERRAR_MODAL_ROLES_MENUS,
    ACTUALIZAR_ROLES_MENUS_ERROR,
    ACTUALIZAR_ROLES_MENUS_EXITO,
} from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';

export function obtenerRolesMenuAction(rol) {
    return async (dispatch) => {
        dispatch( obtenerRolesMenu(rol) )

        try {
            const response = await clienteAxios.get('roles/menus/'+rol.id);
            if (response.data.data) {
                dispatch( obtenerRolesMenuExito(response.data.data) );
            }
        } catch (error) {
            dispatch( obtenerRolesMenuError(error) );
        }
    }
}

const obtenerRolesMenu = rol => ({
    type: LISTA_ROLES_MENU,
    payload: rol
})

const obtenerRolesMenuExito = menus => ({
    type: LISTA_ROLES_MENU_EXITO,
    payload: menus
})

const obtenerRolesMenuError = error => ({
    type: LISTA_ROLES_MENU_ERROR,
    payload: error
})

export function setSelectedAction(selected) {
    return (dispatch) => {
        dispatch(setSelected(selected));
    }
}

const setSelected = selected => ({
    type: SET_SELECTED_ROL_MENUS,
    payload: selected
})

export function actualizarRolesMenusAction(rol, id_menus) {
    return async (dispatch) => {
        try {
            const response = await clienteAxios.put('roles/menus/actualizar', 
                { id_rol: rol.id, menus: id_menus }
            );

            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( actualizarRolesMenusExito(response.data.data) );
            }
        } catch (error) {
            dispatch( actualizarRolesMenusError(error) );
        }
    }
}

const actualizarRolesMenusExito = rolesMenus => ({
    type: ACTUALIZAR_ROLES_MENUS_EXITO,
    payload: rolesMenus
})

const actualizarRolesMenusError = error => ({
    type: ACTUALIZAR_ROLES_MENUS_ERROR,
    payload: error
})

export function cerrarModalAction() {
    return async (dispatch) => {
        dispatch( cerrarModal() )
    }
}

const cerrarModal = () => ({
    type: CERRAR_MODAL_ROLES_MENUS
})