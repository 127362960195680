import * as Types from '../types'

const initialState = {
    loadingEstadisticas: false,
    estadisticas: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.CARGANDO_ESTADISTICAS:
      return {
        ...state,
        loadingEstadisticas: true
      }
    case Types.CARGANDO_ESTADISTICAS_EXITO:
      return {
        ...state,
        loadingEstadisticas: false,
        estadisticas: action.payload
      }
    case Types.CARGANDO_ESTADISTICAS_ERROR:
      return {
        ...state,
        loadingEstadisticas: false,
        error: action.payload
      }
    default:
      return state
  }
}
