import React from "react";
import "./ChatListItem.css";
import { formatAMPM } from "../../../helpers";
import DefaultPicture from "../../../assets/img/team/default-user.png";
import { Badge } from "@themesberg/react-bootstrap";

export default ({ key, chat, onClick, chatSelected }) => {
  const date = new Date(Date.parse(chat.ultimo_mensaje.fecha_mensaje));

  return (
    <div
      className="chatListItem"
      onClick={onClick}
      style={{ background: chatSelected?.id == chat?.id ? "#f6f6f6" : "white" }}
    >
      <img className="chatListItem--avatar" src={DefaultPicture} />
      <div className="chatListItem--lines">
        <div className="chatListItem--line">
          <div className="chatListItem--name"> {chat.usuario1} </div>
          <div
            className="chatlistItem--date"
            style={{
              color: chat.nuevos_mensajes === true ? "#00a5ff" : "#999",
            }}
          >
            {formatAMPM(date, true)}
          </div>
        </div>
        <div className="chatListItem--line">
          <div className="chatListItem--LastMsg">
            <p>{chat.ultimo_mensaje?.mensaje}</p>
          </div>
          {chat.cantidad_mensajes_nuevos > 0 && (
            <div className="chatlistItem--NewMessages">
              <Badge bg="secondary">{chat.cantidad_mensajes_nuevos}</Badge>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
