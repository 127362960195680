import React, { useEffect, useState } from "react";
import { Card, Breadcrumb } from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";

import { ConfirmModal } from "../../components/ConfirmModal";
import clienteAxios from "../../config/axios";
import Swal from "sweetalert2";
import PuestosLaboralesForm from "../Empleados/PuestosLaborales/PuestosLaboralesForm";
import EstadisticasPermisos from "./EstadisticasPermisos";

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [estadisticas, setEstadisticas] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    getEstadisticas();
  }, []);

  const getEstadisticas = () => {
    clienteAxios.get("estadisticas").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setEstadisticas(respuesta.data.data);
      }
    });
  };

  const columns = [
    {
      key: "id",
      text: "ID",
      sortable: true,
    },
    {
      key: "nombre",
      text: "Nombre",
      sortable: true,
    },
    {
      key: "descripcion",
      text: "Descripcion"
    }
  ];
  const editPermisos = (record) => {
    setShowModal(true);
    setSelected(record);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Estadisticas</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Estadisticas</h5>
          <DataTable
            columns={columns}
            editRecord={editPermisos}
            state={{ records: estadisticas }}
          />
        </Card.Body>
      </Card>

      <EstadisticasPermisos
        showModal={showModal}
        selectedRow={selected}
        onUpdate={(rowSuccess) => {
          setShowModal(false)
        }}
        onCreate={(rowSuccess) => {
          setShowModal(false)
        }}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};
