import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "../../components/DataTable";
import {
  comenzarEditarPacienteAction,
  comenzarAgregarPacienteAction,
  getPacientesAction,
  eliminarPacienteAction,
  showModalPacienteTratamientosAction,
  getPacienteTratamientosAction,
} from "../../actions/pacientesActions";

import { ConfirmModal } from "../../components/ConfirmModal";
import AgregarPaciente from "./AgregarPaciente";
import EditarPacientes from "./EditarPacientes";
import TratamientosPaciente from "./TratamientosPaciente";
import {
  getDatosAdicionalesAction,
  getTabsDatosAdicionalesAction,
  setModalDatosAdicionalesAction,
} from "../../actions/datosAdicionalesActions";
import DatosAdicionales from "../../components/DatosAdicionales/DatosAdicionales";
import { getDatosAdicionalesDetallesAction } from "../../actions/datosAdicionalesDetallesActions";
import { columnaSucursalDT } from "../../helpers";
import { busquedaFiltroAction } from "../../actions/filtroActions";
import ReactDatatable from "@mkikets/react-datatable";
import Filtro from "../../components/Filtro";

export default () => {
  const dispatch = useDispatch();

  const { loadingFiltro, respuestaFiltro, criteria, request } = useSelector(
    (state) => state.filtro
  );

  const modelo = "Cliente.Cliente";
  const criteriasObligatorios = ["order-id"];

  const [requestFiltro, guardarRequestFiltro] = useState({
    offset: 0,
    limit: 10,
  });

  useEffect(() => {
    dispatch(
      busquedaFiltroAction(modelo, {
        offset: 0,
        limit: 10,
        criteria: ["all", "order-id"],
      })
    );
  }, []);

  const columns = [
    {
      key: "id",
      text: "ID",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "nombre",
      text: "Nombre",
      className: "nombre",
      align: "left",
      sortable: true,
    },
    {
      key: "apellido_paterno",
      text: "Apellido paterno",
      className: "apellido_paterno",
      sortable: true,
    },
    {
      key: "apellido_materno",
      text: "Apellido materno",
      className: "apellido_materno",
      sortable: true,
    },
    {
      key: "genero",
      text: "Genero",
      className: "genero",
      sortable: true,
    },
    {
      key: "correo_electronico",
      text: "Correo electronico",
      className: "correo_electronico",
      sortable: true,
    },
    {
      key: "telefono",
      text: "Teléfono",
      className: "telefono",
      sortable: true,
    },
    columnaSucursalDT(),
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>Tratamientos</Tooltip>}
            >
              <button
                className="btn btn-success btn-sm"
                onClick={() => tratamientos(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fas fa-book-medical"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>Editar</Tooltip>}
            >
              <button
                className="btn btn-primary btn-sm"
                onClick={() => editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              trigger={["hover", "focus"]}
              overlay={<Tooltip>Eliminar</Tooltip>}
            >
              <button
                className="btn btn-danger btn-sm"
                onClick={() => deleteRecord(record)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </OverlayTrigger>
          </Fragment>
        );
      },
    },
  ];

  const opcionesFiltro = [
    { valor: "id", label: "ID", tipo: "number", criteria: ["equal"] },
    {
      valor: "nombre_completo",
      label: "Nombre",
      tipo: "text",
      criteria: ["like", "order-id"],
    },
    {
      valor: "telefono",
      label: "Teléfono",
      tipo: "text",
      criteria: ["like", "order-id"],
    },
  ];

  const onChangeDT = (event) => {
    requestFiltro.limit = event.page_size;
    requestFiltro.offset = event.page_size * (event.page_number - 1);
    guardarRequestFiltro({ ...requestFiltro });

    dispatch(
      busquedaFiltroAction(modelo, {
        offset: requestFiltro.offset,
        limit: requestFiltro.limit,
        criteria: criteria,
        valor: request.valor,
      })
    );
  };

  const configDT = {
    show_filter: false,
    button: {
      excel: true,
      print: true,
      extra: true,
    },
    language: {
      length_menu: "Mostrar _MENU_ registros por página",
      filter: "Buscar",
      info: "Mostrando _START_ a _END_ de _TOTAL_ resultados",
      pagination: {
        first: "Primero",
        previous: "Previa",
        next: "Próximo",
        last: "Última",
      },
    },
  };

  const extraButtons = [
    {
      className: "btn btn-info",
      title: "Agregar cliente",
      children: [
        <span>
          <i class="fas fa-plus"></i> Nuevo cliente
        </span>,
      ],
      onClick: (event) => {
        dispatch(comenzarAgregarPacienteAction());
        dispatch(getTabsDatosAdicionalesAction("CLIENTES"));
        dispatch(getDatosAdicionalesAction("CLIENTES"));
      },
    },
    {
      className: "btn btn-primary",
      title: "Datos adicionales",
      children: [
        <span>
          <i class="fas fa-plus"></i> Datos adicionales
        </span>,
      ],
      onClick: (event) => {
        dispatch(setModalDatosAdicionalesAction(true));
        dispatch(getDatosAdicionalesAction("CLIENTES"));
      },
    },
  ];

  const editRecord = (record) => {
    dispatch(comenzarEditarPacienteAction(record));
    dispatch(getTabsDatosAdicionalesAction("CLIENTES"));
    dispatch(getDatosAdicionalesAction("CLIENTES", record.id));
    dispatch(getDatosAdicionalesDetallesAction("CLIENTES", record.id));
  };

  const deleteRecord = (record) => {
    const callbacks = [
      {
        event: dispatch,
        action: eliminarPacienteAction,
        args: [record],
      },
    ];

    ConfirmModal(callbacks);
  };

  const tratamientos = (record) => {
    dispatch(getPacienteTratamientosAction(record.id));
    dispatch(showModalPacienteTratamientosAction(true));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Clientes</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Clientes</h5>
          <Filtro
            opciones={opcionesFiltro}
            modelo={modelo}
            criteriasObligatorios={criteriasObligatorios}
            request={requestFiltro}
          />
          <ReactDatatable
            hover
            records={respuestaFiltro.data ?? []}
            columns={columns}
            dynamic
            total_record={respuestaFiltro.count ?? 0}
            onChange={onChangeDT}
            loading={loadingFiltro}
            config={configDT}
            extraButtons={extraButtons}
          />
        </Card.Body>
      </Card>

      <AgregarPaciente />
      <EditarPacientes />
      <TratamientosPaciente />
      <DatosAdicionales tabla="CLIENTES" />
    </>
  );
};
