import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Breadcrumb } from "@themesberg/react-bootstrap";
import ReactDatatable from '@mkikets/react-datatable';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  showModalPacienteTratamientosAction,
  getPacientesFinanciadosAction,
  getPacienteTratamientosAction,
  getPacienteTratamientosFinanciadosAction,
} from "../../actions/pacientesActions";

import TratamientosPaciente from "./TratamientosPaciente";
import { numberFormat } from "../../helpers";

export default (props) => {
  const dispatch = useDispatch();

  const { successAgregarAbono } = useSelector((state) => state.abonos);

  const [id_cliente, setIdCliente] = useState(null);

  useEffect(() => {
    if(id_cliente == null || successAgregarAbono == true) {
      dispatch(getPacientesFinanciadosAction());
    }

    if(id_cliente > 0 && successAgregarAbono === true) {
      dispatch( getPacienteTratamientosFinanciadosAction(id_cliente) );
    }
  }, [successAgregarAbono]);

  const {
    clienteTratamientos,
    showModalClienteTratamientos,
    pacientesFinanciados,
  } = useSelector((state) => state.pacientes);

  const columns = [
    {
      key: "id",
      text: "ID Cliente",
      className: "id",
      align: "left",
      sortable: true,
    },
    {
      key: "nombre_completo",
      text: "Nombre",
      align: "left",
      sortable: true,
    },
    {
      text: "Total Financiado ($)",
      align: "left",
      sortable: true,
      cell: (record) => {
        var total = record.financiados.reduce(function (previous, current) {
          return previous + parseFloat(current.total_financiado);
        }, 0);

        return numberFormat(total);
      },
    },
    {
      text: "Total Saldo",
      align: "left",
      sortable: true,
      cell: (record) => {
        var total = record.financiados.reduce(function (previous, current) {
          return previous + parseFloat(current.saldo);
        }, 0);

        return numberFormat(total);
      },
    },
    {
      key: "action",
      text: "Acciones",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-success btn-sm"
              onClick={() => getTratamientos(record)}
              style={{ marginRight: "5px" }}
            >
              Ver Productos Financiados
            </button>
          </Fragment>
        );
      },
    },
  ];

  const getTratamientos = (record) => {
    setIdCliente(record.id);
    dispatch(getPacienteTratamientosFinanciadosAction(record.id));
    dispatch(showModalPacienteTratamientosAction(true));
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Pacientes Financiados</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Pacientes Financiados</h5>
          <ReactDatatable hover records={pacientesFinanciados} columns={columns} />
        </Card.Body>
      </Card>
      <TratamientosPaciente />
    </>
  );
};
