import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Breadcrumb } from "@themesberg/react-bootstrap";
import { faHome, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!
import ModalEventDialog from "./ModalEventDialog";
import clienteAxios from "../../config/axios";
import moment from "moment-timezone";

export default () => {
  const [state, setState] = useState({
    showModalAgregar: false,
    fechaSeleccionada: null,
    accion: "AGREGAR",
  });

  const [eventos, setEventos] = useState([]);
  const [duplicados, setDuplicados] = useState([]);
  const [eventosRepetidos, setEventosRepetidos] = useState([]);
  const [loadingEventos, setLoadingEventos] = useState(true);

  const calendarRef = useRef();

  useEffect(() => {
    getCalendario();
  }, []);

  const getCalendario = () => {
    clienteAxios.get("/calendario").then((res) => {
      if (res && res.data.data) {
        setEventos(
          res.data.data.map((event) => {
            return {
              title: event.titulo,
              descripcion: event.descripcion ?? "",
              date: event.fecha,
              repetir: event.repetir,
              monto: event.monto ?? "",
              referenceId: event.id,
              reference: event.id + "_" + event.fecha,
              color: event.color ?? "#0091FF",
            };
          })
        );

        const duplicados = [];

        res.data.data.forEach((event) => {
          if (event.repetidos && event.repetidos.length > 0) {
            event.repetidos.forEach((repetido) => {
              duplicados[event.id + "_" + repetido.fecha] = {
                descripcion: repetido.descripcion ?? "",
                monto: repetido.monto ?? "",
                color: repetido.color ?? "#ED0707",
              };
            });
          }
        });

        setDuplicados(duplicados);

        setEventosRepetidos(
          res.data.data.map((event) => {
            return event.id + "_" + event.fecha;
          })
        );
      }

      setLoadingEventos(false);
    });
  };

  const agregarEvento = (event) => {
    const request = {
      titulo: event.titulo,
      descripcion: event.descripcion,
      fecha: event.fechaSeleccionada,
      repetir: event.repetir ?? false,
      monto: event.monto,
      color: event.color,
    };

    clienteAxios.post("/calendario", request).then((res) => {
      if (res && res.data.data) {
        const eventoNuevo = {
          title: res.data.data.titulo,
          descripcion: res.data.data.descripcion ?? "",
          date: res.data.data.fecha,
          repetir: res.data.data.repetir,
          monto: res.data.data.monto ?? null,
          referenceId: res.data.data.id,
          reference: res.data.data.id + "_" + res.data.data.fecha,
          color: res.data.data.color ?? "#ED0707",
        };

        if (calendarRef.current && event.repetir === false) {
          setEventos([...eventos, eventoNuevo]);
          setEventosRepetidos(
            eventosRepetidos.concat(
              res.data.data.id + "_" + res.data.data.fecha
            )
          );
        }

        if (calendarRef.current && event.repetir === true) {
          const fechaCalendarioFormateado = moment(res.data.data.fecha)
            .add(1, "M")
            .format("YYYY-MM-DD");

          setEventos([
            ...eventos,
            eventoNuevo,
            {
              title: res.data.data.titulo,
              descripcion: res.data.data.descripcion ?? null,
              referenceId: res.data.data.id,
              reference: res.data.data.id + "_" + fechaCalendarioFormateado,
              date: fechaCalendarioFormateado,
              repetir: res.data.data.repetir,
              monto: res.data.data.monto ?? null,
              repetido: true,
              color: res.data.data.color ?? "#ED0707",
            },
          ]);

          setEventosRepetidos([
            ...eventosRepetidos,
            res.data.data.id + "_" + res.data.data.fecha,
            res.data.data.id + "_" + fechaCalendarioFormateado,
          ]);
        }

        setState({
          ...state,
          showModalAgregar: false,
        });
      }
    });
  };

  const actualizarEvento = (event) => {
    const request = {
      referenceId: event.referenceId,
      reference: event.referenceId + "_" + event.fechaSeleccionada,
      titulo: event.titulo,
      descripcion: event.descripcion,
      fecha: event.fechaSeleccionada,
      repetir: event.repetir,
      monto: event.monto,
      color: event.color,
    };

    clienteAxios
      .put("/calendario/" + event.referenceId, request)
      .then((res) => {
        if (res && res.data.data) {
          const eventoActualizado = {
            referenceId: event.referenceId,
            title: res.data.data.titulo ?? event.titulo,
            reference: event.referenceId + "_" + res.data.data.fecha,
            descripcion: res.data.data.descripcion ?? null,
            date: res.data.data.fecha,
            repetir: res.data.data.repetir ?? event.repetir,
            monto: res.data.data.monto ?? null,
            repetido: res.data.data.repetido ?? false,
            color: res.data.data.color ?? "#ED0707",
          };

          const eventosActualizados = eventos.map((evento) => {
            if (evento.reference == eventoActualizado.reference) {
              return eventoActualizado;
            } else {
              return evento;
            }
          });

          

          setEventos(eventosActualizados);

          if (calendarRef.current && event.repetir === true) {
            const fechaCalendarioProximoMes = moment(res.data.data.fecha)
              .add(1, "M")
              .format("YYYY-MM-DD");

            if (
              eventosRepetidos.includes(
                event.referenceId + "_" + fechaCalendarioProximoMes
              ) === false &&
              eventoActualizado.repetido === false
            ) {
              setEventos([
                ...eventosActualizados,
                {
                  title: res.data.data.titulo,
                  referenceId: res.data.data.id,
                  reference: res.data.data.id + "_" + fechaCalendarioProximoMes,
                  descripcion: res.data.data.descripcion ?? null,
                  date: fechaCalendarioProximoMes,
                  repetir: res.data.data.repetir,
                  monto: res.data.data.monto ?? null,
                  color: res.data.data.color ?? "#ED0707",
                  repetido: true,
                },
              ]);

              setEventosRepetidos(
                eventosRepetidos.concat(
                  res.data.data.id +
                    "_" +
                    moment(fechaCalendarioProximoMes).format("YYYY-MM-DD")
                )
              );
            }
          } else if (calendarRef.current && event.repetir === false) {
            // Eliminamos los eventos repetidos
            setEventos(
              eventosActualizados.filter((evento) => {
                return (
                  (evento.referenceId != event.referenceId) ||
                  (evento.referenceId == event.referenceId &&
                    evento.repetido != true)
                );
              })
            );
          }

          setState({
            ...state,
            showModalAgregar: false,
          });
        }
      });
  };

  const eliminarEvento = (id) => {
    clienteAxios.delete("/calendario/" + id).then((res) => {
      if (res && res.data.data) {
        setEventos(
          eventos.filter((evento) => {
            return parseInt(evento.referenceId) != parseInt(id);
          })
        );

        setState({
          ...state,
          showModalAgregar: false,
        });
      }
    });
  };

  const renderizarRepetidos = (arg) => {
    setTimeout(() => {
      const diaCalendario = arg.dayNumberText;

      const fechaCalendarioFormateado = moment(arg.date).format("YYYY-MM-DD");

      // Buscamos si hay eventos para el dia
      eventos.forEach((evento) => {
        const diaEvento = moment(evento.date).add(1, "M").format("D");
        const referenceId = evento.referenceId ?? evento.id;
        if (
          diaCalendario == diaEvento &&
          eventosRepetidos.includes(
            referenceId + "_" + fechaCalendarioFormateado
          ) === false &&
          evento.repetir === true &&
          moment(arg.date).unix() > moment(evento.date).unix()
        ) {
          const reference = referenceId + "_" + fechaCalendarioFormateado;
          const eventoRepetido = {
            ...evento,
            id: reference,
            referenceId: referenceId,
            date: fechaCalendarioFormateado,
            reference: reference,
            repetido: true,
            descripcion: duplicados[reference]
              ? duplicados[reference].descripcion
              : evento.descripcion,
            monto: duplicados[reference]
              ? duplicados[reference].monto
              : evento.monto,
            color: duplicados[reference]
              ? duplicados[reference].color
              : "#ED0707",
          };

          eventosRepetidos.push(reference);

          eventos.push(eventoRepetido);
          if (calendarRef.current) {
            calendarRef.current.getApi().addEvent(eventoRepetido);
          }
        }
      });
    }, 100);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Calendario</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Calendario</h5>
          {loadingEventos === false && (
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={eventos}
              ref={calendarRef}
              locale="es"
              showNonCurrentDates={false}
              buttonText={{
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Dia",
                list: "Lista",
              }}
              selectable={true}
              dateClick={function (arg) {
                setState({
                  referenceId: null,
                  titulo: "",
                  descripcion: "",
                  repetir: false,
                  repetido: false,
                  monto: "",
                  fechaSeleccionada: arg.dateStr,
                  showModalAgregar: true,
                  accion: "AGREGAR",
                  reference: "",
                  color: "#ED0707",
                });
              }}
              eventClick={function (arg) {
                setState({
                  ...state,
                  fechaSeleccionada: arg.event.startStr,
                  showModalAgregar: true,
                  titulo: arg.event.title,
                  descripcion: arg.event.extendedProps.descripcion,
                  repetir: arg.event.extendedProps.repetir,
                  monto: arg.event.extendedProps.monto,
                  referenceId: arg.event.extendedProps.referenceId,
                  reference:
                    arg.event.extendedProps.referenceId +
                    "_" +
                    arg.event.startStr,
                  accion: "ACTUALIZAR",
                  repetido: arg.event.extendedProps.repetido ?? false,
                  color: arg.event.backgroundColor ?? "#ED0707",
                });
              }}
              eventDidMount={function (arg) {
                if (arg.event.extendedProps.descripcion) {
                  arg.el.setAttribute(
                    "title",
                    arg.event.extendedProps.descripcion
                  );
                }
              }}
              dayCellDidMount={function (arg) {
                renderizarRepetidos(arg);
              }}
            />
          )}
        </Card.Body>
      </Card>

      <ModalEventDialog
        showModal={state.showModalAgregar}
        onHide={() => setState({ ...state, showModalAgregar: false })}
        state={state}
        agregarEvento={agregarEvento}
        accion={state.accion}
        actualizarEvento={actualizarEvento}
        eliminarEvento={eliminarEvento}
      />
    </>
  );
};
