import * as Types from '../types';

const initialState = {
    impuestos: [],
    showModalLecturaMembresia: false,
    numeroMembresia: '',
    tablaMembresia: '',
    accionMembresia: ''
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.MODAL_LECTURA_MEMBRESIA:
            return {
                ...state,
                showModalLecturaMembresia: action.payload
            }
        case Types.SET_NUMERO_MEMBRESIA:
            return {
                ...state,
                numeroMembresia: action.payload.numero,
                tablaMembresia: action.payload.tabla,
                accionMembresia: action.payload.accion
            }
        default:
            return state;
    }
}