import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button, Row, Col } from "@themesberg/react-bootstrap";
import ReactDatatable from '@mkikets/react-datatable';
import { numberFormat } from "../../helpers";

export default (props) => {
  const [showModal, setShowModal] = useState(false);

  const { loadingKardexDetalles, kardexDetalles } = useSelector(state => state.kardex);

  useEffect(() => {
    setShowModal(props.showModal);
  }, [props]);

  const columns = [
    {
        text: "Fecha",
        sortable: true,
        cell: (record) => {
            return record.fecha;
        },
    },
    {
        text: "Tipo movimiento",
        sortable: true,
        cell: (record) => {
            return record.tipo;
        },
    },
    {
        text: "Descripcion",
        sortable: true,
        cell: (record) => {
            return record.motivo;
        },
    },
    {
        text: "Unidades",
        sortable: true,
        cell: (record) => {
            return record.cantidad;
        },
    },
    {
        text: "Precio unitario",
        sortable: true,
        cell: (record) => {
            return numberFormat(record.precio_unidad);
        },
    },
    {
        text: "Total",
        sortable: true,
        cell: (record) => {
          return numberFormat(record.cantidad * record.precio_unidad);
        },
    },
  ];

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Detalle kardex</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="table-wrapper table-responsive shadow-sm">
            { /*<Row className="mb-5">
                <Col>
                    <Card>
                    <Card.Header as="h6">Entradas</Card.Header>
                    <Card.Body>
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Entrada unidades
                                <span>{kardexDetalles.entrada_unidades}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Costo total entradas
                                <span>{numberFormat(kardexDetalles.precio_total_entradas)}</span>
                            </li>
                        </ul>
                    </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                    <Card.Header as="h6">Salidas</Card.Header>
                    <Card.Body>
                        <ul class="list-group">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Entrada salidas
                                <span>{kardexDetalles.salida_unidades}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Costo total salidas
                                <span>{numberFormat(kardexDetalles.precio_total_salidas)}</span>
                            </li>
                        </ul>
                    </Card.Body>
                    </Card>
                </Col>
            </Row> */ }
          <ReactDatatable hover records={kardexDetalles.detalles} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
