import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getPreciosProductosAction(tratamiento_precio) {
    return async (dispatch) => {
        dispatch( getPreciosProductos(tratamiento_precio) );
        try {
            const response = await clienteAxios.get('tratamientos/precios/productos/getByIdTratamientoPrecio/'+tratamiento_precio.id);
            if (response.data.data) {
                dispatch( getPreciosProductosExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getPreciosProductosError(error) );
        }
    }
}

const getPreciosProductos = (tratamiento_precio) => ({
    type: Types.LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS,
    payload: tratamiento_precio
})

const getPreciosProductosExito = respuesta => ({
    type: Types.LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO,
    payload: respuesta
})

const getPreciosProductosError = error => ({
    type: Types.LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR,
    payload: error
})

export function agregarPrecioProductoAction(precioProducto) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( agregarPrecioProducto() );
        try {
            const response = await clienteAxios.post('tratamientos/precios/productos/agregar', precioProducto);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( agregarPrecioProductoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( agregarPrecioProductoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const agregarPrecioProducto = () => ({
    type: Types.AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS
})

const agregarPrecioProductoExito = respuesta => ({
    type: Types.AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO,
    payload: respuesta
})

const agregarPrecioProductoError = error => ({
    type: Types.AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR,
    payload: error
})

export function actualizarPrecioProductoAction(precio_producto) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( actualizarPrecioProducto() );
        try {
            const response = await clienteAxios.put('tratamientos/precios/productos/actualizar/'+precio_producto.id, precio_producto);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( actualizarPrecioProductoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( actualizarPrecioProductoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const actualizarPrecioProducto = () => ({
    type: Types.ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS
})

const actualizarPrecioProductoExito = respuesta => ({
    type: Types.ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO,
    payload: respuesta
})

const actualizarPrecioProductoError = error => ({
    type: Types.ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR,
    payload: error
})

export function eliminarPrecioProductoAction(precio_producto) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( eliminarPrecioProducto() );
        try {
            const response = await clienteAxios.delete('tratamientos/precios/productos/eliminar/'+precio_producto.id);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( eliminarPrecioProductoExito(response.data.data) );
            }
        } catch (error) {
            dispatch( eliminarPrecioProductoError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const eliminarPrecioProducto = () => ({
    type: Types.ELIMINAR_TRATAMIENTO_PRECIOS_PRODUCTOS
})

const eliminarPrecioProductoExito = respuesta => ({
    type: Types.ELIMINAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO,
    payload: respuesta
})

const eliminarPrecioProductoError = error => ({
    type: Types.ELIMINAR_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR,
    payload: error
})

export function showModalTratamientoPreciosProductosAction(estatus) {
    return (dispatch) => {
        dispatch( showModalTratamientoPreciosProductos(estatus) );
    }
}

const showModalTratamientoPreciosProductos = estatus => ({
    type: Types.MODAL_TRATAMIENTOS_PRECIOS_PRODUCTOS,
    payload: estatus
})

export function showModalAgregarTratamientoPreciosProductosAction(estatus) {
    return (dispatch) => {
        dispatch( showModalAgregarTratamientoPreciosProductos(estatus) );
    }
}

const showModalAgregarTratamientoPreciosProductos = estatus => ({
    type: Types.MODAL_AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS,
    payload: estatus
})