import * as Types from '../types';

import clienteAxios from '../config/axios';
import Swal from 'sweetalert2';
import { loadingRequest } from './axiosActions';

export function getConfiguracionAction() {
    return async (dispatch) => {
        dispatch( getConfiguracion() );
        try {
            const response = await clienteAxios.get('configuracion/list');
            if (response.data.data) {
                dispatch( getConfiguracionExito(response.data.data) );
            }
        } catch (error) {
            dispatch( getConfiguracionError(error) );
        }
    }
}

const getConfiguracion = () => ({
    type: Types.CARGANDO_CONFIGURACION
})

const getConfiguracionExito = respuesta => ({
    type: Types.CARGANDO_CONFIGURACION_EXITO,
    payload: respuesta
})

const getConfiguracionError = error => ({
    type: Types.CARGANDO_CONFIGURACION_ERROR,
    payload: error
})

export function actualizarConfiguracionAction(parametros) {
    return async (dispatch) => {
        dispatch( loadingRequest(true) );
        dispatch( actualizarConfiguracion() );
        try {
            const response = await clienteAxios.put('configuracion/actualizar', parametros);
            if (response.data.data) {
                Swal.fire('¡Muy bien!', response.data.message, 'success');
                dispatch( actualizarConfiguracionExito(response.data.data) );
            }
        } catch (error) {
            dispatch( actualizarConfiguracionError(error) );
        }

        dispatch( loadingRequest(false) );
    }
}

const actualizarConfiguracion = () => ({
    type: Types.ACTUALIZAR_CONFIGURACION
})

const actualizarConfiguracionExito = respuesta => ({
    type: Types.ACTUALIZAR_CONFIGURACION_EXITO,
    payload: respuesta
})

const actualizarConfiguracionError = error => ({
    type: Types.ACTUALIZAR_CONFIGURACION_ERROR,
    payload: error
})
