import * as Types from "../types";

const initialState = {
  datosAdicionalesDetalles: [],
  loadingDatosAdicionalesDetalles: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.LISTA_DATOS_ADICIONALES_DETALLES:
      return {
        ...state,
        loadingDatosAdicionalesDetalles: true,
        datosAdicionalesDetalles: [],
      };
    case Types.LISTA_DATOS_ADICIONALES_DETALLES_EXITO:
      return {
        ...state,
        loadingDatosAdicionalesDetalles: false,
        //datosAdicionalesDetalles: action.payload,
      };
    case Types.LISTA_DATOS_ADICIONALES_DETALLES_ERROR:
      return {
        ...state,
        loadingDatosAdicionalesDetalles: false,
        error: action.payload,
      };
    case Types.AGREGAR_DATOS_ADICIONALES_DETALLE:
      return {
        ...state,
        datosAdicionalesDetalles: state.datosAdicionalesDetalles.concat(
          action.payload
        ),
      };
    case Types.ACTUALIZAR_DATOS_ADICIONALES_DETALLE:
      return {
        ...state,
        datosAdicionalesDetalles: state.datosAdicionalesDetalles.map((dato) =>
          dato.id_dato_adicional === action.payload.id_dato_adicional
            ? action.payload
            : dato
        ),
      };
    case Types.LIMPIAR_DATOS_ADICIONALES_DETALLE:
        return {
            ...state,
            datosAdicionalesDetalles: []
        }
    default:
      return state;
  }
}
