import {
    LISTA_MENU,
    LISTA_MENU_EXITO,
    LISTA_MENU_ERROR,
    COMENZAR_AGREGAR_MENU,
    CERRAR_MODAL_AGREGAR_MENU,
    AGREGAR_MENU,
    AGREGAR_MENU_EXITO,
    AGREGAR_MENU_ERROR,
    COMENZAR_ACTUALIZAR_MENU,
    ACTUALIZAR_MENU,
    ACTUALIZAR_MENU_EXITO,
    ACTUALIZAR_MENU_ERROR,
    CERRAR_MODAL_ACTUALIZAR_MENU,
    ELIMINAR_MENU_EXITO,
    ELIMINAR_MENU_ERROR
} from '../types';

const initialState = {
    menus: [],
    menu: {},
    loadingMenu: false,
    showModalAgregar: false,
    showModalEditar: false,
    loadingAgregarMenu: false,
    loadingEditarMenu: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case LISTA_MENU:
            return {
                ...state,
                loadingMenu: true
            }
        case LISTA_MENU_EXITO:
            return {
                ...state,
                loadingMenu: false,
                menus: action.payload
            }
        case LISTA_MENU_ERROR:
            return {
                ...state,
                loadingMenu: false,
                error: action.payload
            }
        case COMENZAR_AGREGAR_MENU:
            return {
                ...state,
                showModalAgregar: true
            }
        case CERRAR_MODAL_AGREGAR_MENU:
            return {
                ...state,
                showModalAgregar: false
            }
        case AGREGAR_MENU:
            return {
                ...state,
                loadingAgregarMenu: true
            }
        case AGREGAR_MENU_EXITO:
            return {
                ...state,
                loadingAgregarMenu: false,
                menus: state.menus.concat(action.payload),
                showModalAgregar: false
            }
        case AGREGAR_MENU_ERROR:
            return {
                ...state,
                loadingAgregarMenu: false,
                error: action.payload
            }
        case COMENZAR_ACTUALIZAR_MENU:
            return {
                ...state,
                showModalEditar: true,
                menu: action.payload
            }
        case ACTUALIZAR_MENU:
            return {
                ...state,
                loadingEditarMenu: true
            }
        case ACTUALIZAR_MENU_EXITO:
            return {
                ...state,
                loadingEditarMenu: false,
                menus: state.menus.map( menu => 
                    menu.id === action.payload.id ? menu = action.payload : menu
                ),
                showModalEditar: false
            }
        case ACTUALIZAR_MENU_ERROR:
            return {
                ...state,
                loadingEditarMenu: false,
                error: action.payload
            }
        case CERRAR_MODAL_ACTUALIZAR_MENU:
            return {
                ...state,
                showModalEditar: false
            }
        case ELIMINAR_MENU_EXITO:
            return {
                ...state,
                menus: state.menus.filter( menu => 
                    menu.id != action.payload.id
                )
            }
        case ELIMINAR_MENU_ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}