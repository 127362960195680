export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const LISTA_USUARIOS = 'LISTA_USUARIOS';
export const LISTA_USUARIOS_CARGANDO = 'LISTA_USUARIOS_CARGANDO';
export const LISTA_USUARIOS_EXITO = 'LISTA_USUARIOS_EXITO';
export const LISTA_USUARIOS_ERROR = 'LISTA_USUARIOS_ERROR';

export const ACTUALIZAR_USER_AUTH = 'ACTUALIZAR_USER_AUTH';

export const COMENZAR_AGREGAR_USUARIO = 'COMENZAR_AGREGAR_USUARIO';
export const USUARIO_CREACION = 'USUARIO_CREACION';
export const USUARIO_CREACION_EXITO = 'USUARIO_CREACION_EXITO';
export const USUARIO_CREACION_ERROR = 'USUARIO_CREACION_ERROR';

export const COMENZAR_ELIMINAR_USUARIO = 'COMENZAR_ELIMINAR_USUARIO';
export const USUARIO_ELIMINADO_EXITO = 'USUARIO_ELIMINADO_EXITO';
export const USUARIO_ELIMINADO_ERROR = 'USUARIO_ELIMINADO_ERROR';

export const OBTENER_USUARIO_EDITAR = 'OBTENER_USUARIO_EDITAR';
export const COMENZAR_EDICION_USUARIO = 'COMENZAR_EDICION_USUARIO';
export const USUARIO_ACTUALIZADO_EXITO = 'USUARIO_ACTUALIZADO_EXITO';
export const USUARIO_ACTUALIZADO_ERROR = 'USUARIO_ACTUALIZADO_ERROR';
export const CERRAR_MODAL_EDITAR_USUARIO = 'CERRAR_MODAL_EDITAR_USUARIO';
export const CERRAR_MODAL_AGREGAR_USUARIO = 'CERRAR_MODAL_AGREGAR_USUARIO';

export const OBTENER_ROLES_USUARIO = 'OBTENER_ROLES_USUARIO';
export const OBTENER_ROLES_USUARIO_EXITO = 'OBTENER_ROLES_USUARIO_EXITO';
export const OBTENER_ROLES_USUARIO_ERROR = 'OBTENER_ROLES_USUARIO_ERROR';
export const SET_SELECTED_USER_ROLES = 'SET_SELECTED_USER_ROLES';
export const ACTUALIZAR_ROLES_USUARIO_EXITO = 'ACTUALIZAR_ROLES_USUARIO_EXITO';
export const ACTUALIZAR_ROLES_USUARIO_ERROR = 'ACTUALIZAR_ROLES_USUARIO_ERROR';
export const CERRAR_MODAL_ROLES_USUARIO = 'CERRAR_MODAL_ROLES_USUARIO';

export const MANTENER_SESION = 'MANTENER_SESION';
export const MANTENER_SESION_EXITO = 'MANTENER_SESION_EXITO';
export const MANTENER_SESION_ERROR = 'MANTENER_SESION_ERROR';

export const CAMBIAR_SUCURSAL = 'CAMBIAR_SUCURSAL';
export const CAMBIAR_SUCURSAL_EXITO = 'CAMBIAR_SUCURSAL_EXITO';
export const CAMBIAR_SUCURSAL_ERROR = 'CAMBIAR_SUCURSAL_ERROR';

export const LISTA_ROLES = 'LISTA_ROLES';
export const LISTA_ROLES_EXITO = 'LISTA_ROLES_EXITO';
export const LISTA_ROLES_ERROR = 'LISTA_ROLES_ERROR';

export const COMENZAR_AGREGAR_ROL = 'COMENZAR_AGREGAR_ROL';
export const AGREGAR_ROL = 'AGREGAR_ROL';
export const AGREGAR_ROL_EXITO = 'AGREGAR_ROL_EXITO';
export const AGREGAR_ROL_ERROR = 'AGREGAR_ROL_ERROR';
export const CERRAR_MODAL_AGREGAR_ROL = 'CERRAR_MODAL_AGREGAR_ROL';

export const COMENZAR_EDITAR_ROL = 'COMENZAR_EDITAR_ROL';
export const EDITAR_ROL = 'EDITAR_ROL';
export const EDITAR_ROL_EXITO = 'EDITAR_ROL_EXITO';
export const EDITAR_ROL_ERROR = 'EDITAR_ROL_ERROR';
export const CERRAR_MODAL_EDITAR_ROL = 'CERRAR_MODAL_EDITAR_ROL';

export const COMENZAR_ELIMINAR_ROL = 'COMENZAR_ELIMINAR_ROL';
export const ROL_ELIMINADO_EXITO = 'ROL_ELIMINADO_ROL';
export const ROL_ELIMINADO_ERROR = 'ROL_ELIMINADO_ERROR';

export const COMENZAR_PERMISOS_ROL = 'COMENZAR_PERMISOS_ROL';
export const CERRAR_MODAL_PERMISOS_ROL = 'CERRAR_MODAL_PERMISOS_ROL';

export const ACTUALIZAR_PERMISO_ROL = 'ACTUALIZAR_PERMISO_ROL';
export const ACTUALIZAR_PERMISO_ROL_EXITO = 'ACTUALIZAR_PERMISO_ROL_EXITO';
export const ACTUALIZAR_PERMISO_ROL_ERROR = 'ACTUALIZAR_PERMISO_ROL_ERROR';

export const LISTA_MODULOS_EXITO = 'LISTA_MODULOS_EXITO';
export const LISTA_MODULOS = 'LISTA_MODULOS';
export const LISTA_MODULOS_ERROR = 'LISTA_MODULOS_ERROR'; 

export const LISTA_MENU = 'LISTA_MENU';
export const LISTA_MENU_EXITO = 'LISTA_MENU_EXITO';
export const LISTA_MENU_ERROR = 'LISTA_MENU_ERROR';

export const COMENZAR_AGREGAR_MENU = 'AGREGAR_MENU';
export const AGREGAR_MENU = 'AGREGAR_MENU';
export const AGREGAR_MENU_EXITO = 'AGREGAR_MENU_EXITO';
export const AGREGAR_MENU_ERROR = 'AGREGAR_MENU_ERROR';
export const CERRAR_MODAL_AGREGAR_MENU = 'CERRAR_MODAL_AGREGAR_MENU';

export const COMENZAR_ACTUALIZAR_MENU = 'COMENZAR_ACTUALIZAR_MENU';
export const ACTUALIZAR_MENU = 'ACTUALIZAR_MENU';
export const ACTUALIZAR_MENU_EXITO = 'ACTUALIZAR_MENU_EXITO';
export const ACTUALIZAR_MENU_ERROR = 'ACTUALIZAR_MENU_ERROR';
export const CERRAR_MODAL_ACTUALIZAR_MENU = 'CERRAR_MODAL_ACTUALIZAR_MENU';

export const ELIMINAR_MENU_EXITO = 'ELIMINAR_MENU_EXITO';
export const ELIMINAR_MENU_ERROR = 'ELIMINAR_MENU_ERROR';

export const COMENZAR_LISTA_MENU_FUNCIONES = 'COMENZAR_LISTA_MENU_FUNCIONES';
export const LISTA_MENU_FUNCIONES = 'LISTA_MENU_FUNCIONES';
export const LISTA_MENU_FUNCIONES_EXITO = 'LISTA_MENU_FUNCIONES_EXITO';
export const LISTA_MENU_FUNCIONES_ERROR = 'LISTA_MENU_FUNCIONES_ERROR';
export const CERRAR_MODAL_LISTA_MENU_FUNCION = 'CERRAR_MODA_LISTA_MENU_FUNCION';

export const COMENZAR_AGREGAR_MENU_FUNCION = 'COMENZAR_AGREGAR_MENU_FUNCION';
export const AGREGAR_MENU_FUNCION = 'AGREGAR_MENU_FUNCION';
export const AGREGAR_MENU_FUNCION_EXITO = 'AGREGAR_MENU_FUNCION_EXITO';
export const AGREGAR_MENU_FUNCION_ERROR = 'AGREGAR_MENU_FUNCION_ERROR';
export const CERRAR_MODAL_AGREGAR_MENU_FUNCION = 'CERRAR_MODAL_AGREGAR_MENU_FUNCION';

export const COMENZAR_ACTUALIZAR_MENU_FUNCION = 'COMENZAR_ACTUALIZAR_MENU_FUNCION';
export const ACTUALIZAR_MENU_FUNCION = 'ACTUALIZAR_MENU_FUNCION';
export const ACTUALIZAR_MENU_FUNCION_EXITO = 'ACTUALIZAR_MENU_FUNCION_EXITO';
export const ACTUALIZAR_MENU_FUNCION_ERROR = 'ACTUALIZAR_MENU_FUNCION_ERROR';
export const CERRAR_MODAL_ACTUALIZAR_MENU_FUNCION = 'CERRAR_MODAL_ACTUALIZAR_MENU_FUNCION';

export const ELIMINAR_MENU_FUNCION_EXITO = 'ELIMINAR_MENU_FUNCION_EXITO';
export const ELIMINAR_MENU_FUNCION_ERROR = 'ELIMINAR_MENU_FUNCION_ERROR';

export const LISTA_ROLES_MENU = 'LISTA_ROLES_MENU';
export const LISTA_ROLES_MENU_EXITO = 'LISTA_ROLES_MENU_EXITO';
export const LISTA_ROLES_MENU_ERROR = 'LISTA_ROLES_MENU_ERROR';
export const SET_SELECTED_ROL_MENUS = 'SET_SELECTED_ROL_MENUS';
export const ACTUALIZAR_ROLES_MENUS_EXITO = 'ACTUALIZAR_ROLES_MENUS_EXITO';
export const ACTUALIZAR_ROLES_MENUS_ERROR = 'ACTUALIZAR_ROLES_MENUS_ERROR';
export const CERRAR_MODAL_ROLES_MENUS = 'CERRAR_MODAL_ROLES_MENUS';

export const LISTA_PACIENTES = 'LISTA_PACIENTES';
export const LISTA_PACIENTES_EXITO = 'LISTA_PACIENTES_EXITO';
export const LISTA_PACIENTES_ERROR  = 'LISTA_PACIENTES_ERROR';

export const COMENZAR_AGREGAR_PACIENTE = 'COMENZAR_AGREGAR_PACIENTE';
export const AGREGAR_PACIENTE = 'AGREGAR_PACIENTE';
export const AGREGAR_PACIENTE_EXITO = 'AGREGAR_PACIENTE_EXITO';
export const AGREGAR_PACIENTE_ERROR = 'AGREGAR_PACIENTE_ERROR';
export const CERRAR_MODAL_AGREGAR_PACIENTE = 'CERRAR_MODAL_AGREGAR_PACIENTE';

export const COMENZAR_EDITAR_PACIENTE = 'COMENZAR_EDITAR_PACIENTE';
export const EDITAR_PACIENTE = 'EDITAR_PACIENTE';
export const EDITAR_PACIENTE_EXITO = 'EDITAR_PACIENTE_EXITO';
export const EDITAR_PACIENTE_ERROR = 'EDITAR_PACIENTE_ERROR';
export const CERRAR_MODAL_EDITAR_PACIENTE = 'CERRAR_MODAL_EDITAR_PACIENTE';

export const ELIMINAR_PACIENTE = 'ELIMINAR_PACIENTE';
export const ELIMINAR_PACIENTE_EXITO = 'ELIMINAR_PACIENTE_EXITO';
export const ELIMINAR_PACIENTE_ERROR = 'ELIMINAR_PACIENTE_ERROR';

export const LISTA_PACIENTES_FINANCIADOS = 'LISTA_PACIENTES_FINANCIADOS';
export const LISTA_PACIENTES_FINANCIADOS_EXITO = 'LISTA_PACIENTES_FINANCIADOS_EXITO';
export const LISTA_PACIENTES_FINANCIADOS_ERROR = 'LISTA_PACIENTES_FINANCIADOS_ERROR';

export const LISTA_TRATAMIENTOS = 'LISTA_TRATAMIENTOS';
export const LISTA_TRATAMIENTOS_EXITO = 'LISTA_TRATAMIENTOS_EXITO';
export const LISTA_TRATAMIENTOS_ERROR = 'LISTA_TRATAMIENTOS_ERROR';

export const COMENZAR_AGREGAR_TRATAMIENTO = 'COMENZAR_AGREGAR_TRATAMIENTO';
export const AGREGAR_TRATAMIENTO = 'AGREGAR_TRATAMIENTO';
export const AGREGAR_TRATAMIENTO_EXITO = 'AGREGAR_TRATAMIENTO_EXITO';
export const AGREGAR_TRATAMIENTO_ERROR = 'AGREGAR_TRATAMIENTO_ERROR';
export const CERRAR_MODAL_AGREGAR_TRATAMIENTO = 'CERRAR_MODAL_AGREGAR_TRATAMIENTO';

export const COMENZAR_EDITAR_TRATAMIENTO = 'COMENZAR_EDITAR_TRATAMIENTO';
export const EDITAR_TRATAMIENTO = 'EDITAR_TRATAMIENTO';
export const EDITAR_TRATAMIENTO_EXITO = 'EDITAR_TRATAMIENTO_EXITO';
export const EDITAR_TRATAMIENTO_ERROR = 'EDITAR_TRATAMIENTO_ERROR';
export const CERRAR_MODAL_EDITAR_TRATAMIENTO = 'CERRAR_MODAL_EDITAR_TRATAMIENTO';

export const ELIMINAR_TRATAMIENTO = 'ELIMINAR_TRATAMIENTO';
export const ELIMINAR_TRATAMIENTO_EXITO = 'ELIMINAR_TRATAMIENTO_EXITO';
export const ELIMINAR_TRATAMIENTO_ERROR = 'ELIMINAR_TRATAMIENTO_ERROR';

export const COMENZAR_LISTA_TRATAMIENTOS_PRECIOS = 'COMENZAR_LISTA_TRATAMIENTOS_PRECIOS';
export const LISTA_TRATAMIENTOS_PRECIOS = 'LISTA_TRATAMIENTOS_PRECIOS';
export const LISTA_TRATAMIENTOS_PRECIOS_EXITO = 'LISTA_TRATAMIENTOS_PRECIOS_EXITO';
export const LISTA_TRATAMIENTOS_PRECIOS_ERROR = 'LISTA_TRATAMIENTOS_PRECIOS_ERROR';
export const CERRAR_MODAL_LISTA_TRATAMIENTO_PRECIO = 'CERRAR_MODAL_LISTA_TRATAMIENTO_PRECIO';

export const COMENZAR_AGREGAR_TRATAMIENTO_PRECIO = 'COMENZAR_AGREGAR_TRATAMIENTO_PRECIO';
export const AGREGAR_TRATAMIENTO_PRECIO = 'AGREGAR_TRATAMIENTO_PRECIO';
export const AGREGAR_TRATAMIENTO_PRECIO_EXITO = 'AGREGAR_TRATAMIENTO_PRECIO_EXITO';
export const AGREGAR_TRATAMIENTO_PRECIO_ERROR = 'AGREGAR_TRATAMIENTO_PRECIO_ERROR';
export const CERRAR_MODAL_AGREGAR_TRATAMIENTO_PRECIO = 'CERRAR_MODAL_AGREGAR_TRATAMIENTO_PRECIO';

export const COMENZAR_EDITAR_TRATAMIENTO_PRECIO = 'COMENZAR_EDITAR_TRATAMIENTO_PRECIO';
export const EDITAR_TRATAMIENTO_PRECIO = 'EDITAR_TRATAMIENTO_PRECIO';
export const EDITAR_TRATAMIENTO_PRECIO_EXITO = 'EDITAR_TRATAMIENTO_PRECIO_EXITO';
export const EDITAR_TRATAMIENTO_PRECIO_ERROR = 'EDITAR_TRATAMIENTO_PRECIO_ERROR';
export const CERRAR_MODAL_EDITAR_TRATAMIENTO_PRECIO = 'CERRAR_MODAL_EDITAR_TRATAMIENTO_PRECIO';

export const ELIMINAR_TRATAMIENTO_PRECIO = 'ELIMINAR_TRATAMIENTO_PRECIO';
export const ELIMINAR_TRATAMIENTO_PRECIO_EXITO = 'ELIMINAR_TRATAMIENTO_PRECIO_EXITO';
export const ELIMINAR_TRATAMIENTO_PRECIO_ERROR = 'ELIMINAR_TRATAMIENTO_PRECIO_ERROR';

export const LISTA_EMPLEADOS = 'LISTA_EMPLEADOS';
export const LISTA_EMPLEADOS_EXITO = 'LISTA_EMPLEADOS_EXITO';
export const LISTA_EMPLEADOS_ERROR  = 'LISTA_EMPLEADOS_ERROR';

export const COMENZAR_LISTA_COMISIONES_EMPLEADO = 'COMENZAR_LISTA_COMISIONES_EMPLEADO';
export const LISTA_COMISIONES_EMPLEADO = 'LISTA_COMISIONES_EMPLEADO';
export const LISTA_COMISIONES_EXITO = 'LISTA_COMISIONES_EXITO';
export const LISTA_COMISIONES_ERROR = 'LISTA_COMISIONES_ERROR';
export const CERRAR_MODAL_COMISIONES_EMPLEADO = 'CERRAR_MODAL_COMISIONES_EMPLEADO';

export const COMENZAR_AGREGAR_EMPLEADO = 'COMENZAR_AGREGAR_EMPLEADO';
export const AGREGAR_EMPLEADO = 'AGREGAR_EMPLEADO';
export const AGREGAR_EMPLEADO_EXITO = 'AGREGAR_EMPLEADO_EXITO';
export const AGREGAR_EMPLEADO_ERROR = 'AGREGAR_EMPLEADO_ERROR';
export const CERRAR_MODAL_AGREGAR_EMPLEADO = 'CERRAR_MODAL_AGREGAR_EMPLEADO';

export const COMENZAR_EDITAR_EMPLEADO = 'COMENZAR_EDITAR_EMPLEADO';
export const EDITAR_EMPLEADO = 'EDITAR_EMPLEADO';
export const EDITAR_EMPLEADO_EXITO = 'EDITAR_EMPLEADO_EXITO';
export const EDITAR_EMPLEADO_ERROR = 'EDITAR_EMPLEADO_ERROR';
export const CERRAR_MODAL_EDITAR_EMPLEADO = 'CERRAR_MODAL_EDITAR_EMPLEADO';

export const ELIMINAR_EMPLEADO = 'ELIMINAR_EMPLEADO';
export const ELIMINAR_EMPLEADO_EXITO = 'ELIMINAR_EMPLEADO_EXITO';
export const ELIMINAR_EMPLEADO_ERROR = 'ELIMINAR_EMPLEADO_ERROR';

export const LISTA_AGENDA = 'LISTA_AGENDA';
export const LISTA_AGENDA_EXITO = 'LISTA_AGENDA_EXITO';
export const LISTA_AGENDA_ERROR = 'LISTA_AGENDA_ERROR';

export const AGREGAR_AGENDA_EXITO = 'AGREGAR_AGENDA_EXITO';
export const AGREGAR_AGENDA_ERROR = 'AGREGAR_AGENDA_ERROR';

export const ACTUALIZAR_AGENDA_EXITO = 'ACTUALIZAR_AGENDA_EXITO';
export const ACTUALIZAR_AGENDA_ERROR = 'ACTUALIZAR_AGENDA_ERROR';

export const ELIMINAR_AGENDA_EXITO = 'ELIMINAR_AGENDA_EXITO';
export const ELIMINAR_AGENDA_ERROR = 'ELIMINAR_AGENDA_ERROR';

export const CONFIRMAR_CITA_EXITO = 'CONFIRMAR_CITA_EXITO';
export const CONFIRMAR_CITA_ERROR = 'CONFIRMAR_CITA_ERROR';

export const LISTA_HORARIOS_DISPONIBLES = 'LISTA_HORARIOS_DISPONIBLES';
export const LISTA_HORARIOS_DISPONIBLES_EXITO = 'LISTA_HORARIOS_DISPONIBLES_EXITO';
export const LISTA_HORARIOS_DISPONIBLES_ERROR = 'LISTA_HORARIOS_DISPONIBLES_ERROR';

export const LISTA_VENTAS = 'LISTA_VENTAS';
export const LISTA_VENTAS_EXITO =  'LISTA_VENTAS_EXITO';
export const LISTA_VENTAS_ERROR = 'LISTA_VENTAS_ERROR';

export const LISTA_PRODUCTOS_VENTA = 'LISTA_PRODUCTOS_VENTA';
export const LISTA_PRODUCTOS_VENTA_EXITO =  'LISTA_PRODUCTOS_VENTA_EXITO';
export const LISTA_PRODUCTOS_VENTA_ERROR = 'LISTA_PRODUCTOS_VENTA_ERROR';

export const AGREGAR_PRODUCTO_VENTA = 'AGREGAR_PRODUCTO_VENTA';
export const ELIMINAR_PRODUCTO_VENTA = 'ELIMINAR_PRODUCTO_VENTA';
export const ACTUALIZAR_PRODUCTO_VENTA = 'ACTUALIZAR_PRODUCTO_VENTA';
export const ACTUALIZAR_PRODUCTOS_VENTA = 'ACTUALIZAR_PRODUCTOS_VENTA';

export const ACTUALIZAR_DESCUENTO_PRODUCTO_VENTA = 'ACTUALIZAR_DESCUENTO_PRODUCTO_VENTA';
export const ACTUALIZAR_DESCUENTO_PRODUCTOS_VENTA = 'ACTUALIZAR_DESCUENTO_PRODUCTOS_VENTA';

export const AGREGAR_TRATAMIENTO_VENTA = 'AGREGAR_TRATAMIENTO_VENTA';
export const ACTUALIZAR_TRATAMIENTO_VENTA = 'ACTUALIZAR_TRATAMIENTO_VENTA';
export const ELIMINAR_TRATAMIENTO_VENTA = 'ELIMINAR_TRATAMIENTO_VENTA';

export const RECALCULAR_TOTALES_EXITO = 'RECALCULAR_TOTALES_EXITO';
export const RECALCULAR_TOTALES_ERROR = 'RECALCULAR_TOTALES_ERROR';

export const LIMPIAR_VENTA_TOTALES = 'LIMPIAR_VENTA_TOTALES';

export const MODAL_VENTA_TRATAMIENTOS_PRECIOS = 'MODAL_VENTA_TRATAMIENTOS_PRECIOS';
export const MODAL_VENTA_FINANCIADO = 'MODAL_VENTA_FINANCIADO';
export const MODAL_VENTA_EMPLEADOS = 'MODAL_VENTA_EMPLEADOS';

export const COMENZAR_FINANCIAR_TRATAMIENTO = 'COMENZAR_FINANCIAR_TRATAMIENTO';
export const COMENZAR_SELECCIONAR_EMPLEADO = 'COMENZAR_SELECCIONAR_EMPLEADO';

export const METODO_PAGO_VENTA = 'METODO_PAGO_VENTA';
export const MODAL_METODO_PAGO_VENTA = 'MODAL_METODO_PAGO_VENTA';

export const AGREGAR_EMPLEADO_VENTA = 'AGREGAR_EMPLEADO_VENTA';

export const AGREGAR_VENTA = 'AGREGAR_VENTA';
export const AGREGAR_VENTA_EXITO = 'AGREGAR_VENTA_EXITO';
export const AGREGAR_VENTA_ERROR = 'AGREGAR_VENTA_ERROR';

export const ACTUALIZAR_VENTA_STATE = 'ACTUALIZAR_VENTA_STATE';

export const ELIMINAR_VENTA = 'ELIMINAR_VENTA';
export const ELIMINAR_VENTA_EXITO = 'ELIMINAR_VENTA_EXITO';
export const ELIMINAR_VENTA_ERROR = 'ELIMINAR_VENTA_ERROR';

export const CAJA_DATOS_GENERALES = 'CAJA_DATOS_GENERALES';
export const CAJA_DATOS_GENERALES_EXITO = 'CAJA_DATOS_GENERALES_EXITO';
export const CAJA_DATOS_GENERALES_ERROR = 'CAJA_DATOS_GENERALES_ERROR';

export const CAJA_ESTADISTICAS  = 'CAJA_ESTADISTICAS';
export const CAJA_ESTADISTICAS_EXITO = 'CAJA_ESTADISTICAS_EXITO';
export const CAJA_ESTADISTICAS_ERROR = 'CAJA_ESTADISTICAS_ERROR';

export const CAJA_MOVIMIENTOS = 'CAJA_MOVIMIENTOS';
export const CAJA_MOVIMIENTOS_EXITO = 'CAJA_MOVIMIENTOS_EXITO';
export const CAJA_MOVIMIENTOS_ERROR = 'CAJA_MOVIMIENTOS_ERROR';


export const CAJA_AGREGAR_MOVIMIENTO = 'CAJA_AGREGAR_MOVIMIENTO';
export const CAJA_AGREGAR_MOVIMIENTO_EXITO = 'CAJA_AGREGAR_MOVIMIENTO_EXITO';
export const CAJA_AGREGAR_MOVIMIENTO_ERROR = 'CAJA_AGREGAR_MOVIMIENTO_ERROR';
export const MODAL_CAJA_AGREGAR_MOVIMIENTO = 'MODAL_CAJA_AGREGAR_MOVIMIENTO';

export const ELIMINAR_GASTO = 'ELIMINAR_GASTO';
export const ELIMINAR_GASTO_EXITO = 'ELIMINAR_GASTO_EXITO';
export const ELIMINAR_GASTO_ERROR = 'ELIMINAR_GASTO_ERROR';

export const LISTA_TIPOS_MOVIMIENTOS_CAJA = 'LISTA_TIPOS_MOVIMIENTOS_CAJA';
export const LISTA_TIPOS_MOVIMIENTOS_CAJA_EXITO = 'LISTA_TIPOS_MOVIMIENTOS_CAJA_EXITO';
export const LISTA_TIPOS_MOVIMIENTOS_CAJA_ERROR = 'LISTA_TIPOS_MOVIMIENTOS_CAJA_ERROR';

export const CAJA_APERTURA_EXITO = 'CAJA_APERTURA_EXITO';
export const CAJA_APERTURA_ERROR = 'CAJA_APERTURA_ERROR';
export const CAJA_CIERRE_EXITO = 'CAJA_CIERRE_EXITO';
export const CAJA_CIERRE_ERROR = 'CAJA_CIERRE_ERROR';
export const MODAL_CAJA_APERTURA_CIERRE = 'MODAL_CAJA_APERTURA_CIERRE';

export const MODAL_CAJA_CORTE_Z = 'MODAL_CAJA_CORTE_Z';

export const LISTA_PRODUCTOS = 'LISTA_PRODUCTOS';
export const LISTA_PRODUCTOS_EXITO = 'LISTA_PRODUCTOS_EXITO';
export const LISTA_PRODUCTOS_ERROR = 'LISTA_PRODUCTOS_ERROR';

export const AGREGAR_PRODUCTO = 'AGREGAR_PRODUCTO';
export const AGREGAR_PRODUCTO_EXITO = 'AGREGAR_PRODUCTO_EXITO';
export const AGREGAR_PRODUCTO_ERROR = 'AGREGAR_PRODUCTO_ERROR';
export const MODAL_AGREGAR_PRODUCTO = 'MODAL_AGREGAR_PRODUCTO';

export const COMENZAR_EDITAR_PRODUCTO = 'COMENZAR_EDITAR_PRODUCTO';
export const EDITAR_PRODUCTO = 'EDITAR_PRODUCTO';
export const EDITAR_PRODUCTO_EXITO = 'EDITAR_PRODUCTO_EXITO';
export const EDITAR_PRODUCTO_ERROR = 'EDITAR_PRODUCTO_ERROR';
export const MODAL_EDITAR_PRODUCTO = 'MODAL_EDITAR_PRODUCTO';

export const ELIMINAR_PRODUCTO_EXITO = 'ELIMINAR_PRODUCTO_EXITO';
export const ELIMINAR_PRODUCTO_ERROR = 'ELIMINAR_PRODUCTO_ERROR';

export const LISTA_UNIDADES_MEDICION = 'LISTA_UNIDADES_MEDICION';
export const LISTA_UNIDADES_MEDICION_EXITO = 'LISTA_UNIDADES_MEDICION_EXITO';
export const LISTA_UNIDADES_MEDICION_ERROR = 'LISTA_UNIDADES_MEDICION_ERROR';

export const LISTA_CATEGORIAS_PRODUCTOS = 'LISTA_CATEGORIAS_PRODUCTOS';
export const LISTA_CATEGORIAS_PRODUCTOS_EXITO = 'LISTA_CATEGORIAS_PRODUCTOS_EXITO';
export const LISTA_CATEGORIAS_PRODUCTOS_ERROR = 'LISTA_CATEGORIAS_PRODUCTOS_ERROR';

export const AGREGAR_CATEGORIA_PRODUCTO = 'AGREGAR_CATEGORIA_PRODUCTO';
export const AGREGAR_CATEGORIA_PRODUCTO_EXITO = 'AGREGAR_CATEGORIA_PRODUCTO_EXITO';
export const AGREGAR_CATEGORIA_PRODUCTO_ERROR = 'AGREGAR_CATEGORIA_PRODUCTO_ERROR';

export const COMENZAR_EDITAR_CATEGORIA_PRODUCTO = 'COMENZAR_EDITAR_CATEGORIA_PRODUCTO';
export const EDITAR_CATEGORIA_PRODUCTO = 'EDITAR_CATEGORIA_PRODUCTO';
export const EDITAR_CATEGORIA_PRODUCTO_EXITO = 'EDITAR_CATEGORIA_PRODUCTO_EXITO';
export const EDITAR_CATEGORIA_PRODUCTO_ERROR = 'EDITAR_CATEGORIA_PRODUCTO_ERROR';

export const MODAL_AGREGAR_CATEGORIA_PRODUCTO = 'MODAL_AGREGAR_CATEGORIA_PRODUCTO';
export const MODAL_EDITAR_CATEGORIA_PRODUCTO = 'MODAL_EDITAR_CATEGORIA_PRODUCTO';

export const ELIMINAR_CATEGORIA_PRODUCTO_EXITO = 'ELIMINAR_CATEGORIA_PRODUCTO_EXITO';
export const ELIMINAR_CATEGORIA_PRODUCTO_ERROR = 'ELIMINAR_CATEGORIA_PRODUCTO_ERROR';

export const LISTA_INVENTARIO = 'LISTA_INVENTARIO';
export const LISTA_INVENTARIO_EXITO = 'LISTA_INVENTARIO_EXITO';
export const LISTA_INVENTARIO_ERROR = 'LISTA_INVENTARIO_ERROR';

export const MODAL_REGISTRAR_ENTRADA_SALIDA = 'MODAL_REGISTRAR_ENTRADA_SALIDA';
export const REGISTRAR_ENTRADA_SALIDA = 'REGISTRAR_ENTRADA_SALIDA';
export const REGISTRAR_ENTRADA_SALIDA_EXITO = 'REGISTRAR_ENTRADA_SALIDA_EXITO';
export const REGISTRAR_ENTRADA_SALIDA_ERROR = 'REGISTRAR_ENTRADA_SALIDA_ERROR';

export const MODAL_LISTA_CLIENTES_TRATAMIENTOS = 'MODAL_LISTA_CLIENTES_TRATAMIENTOS';
export const LISTA_CLIENTES_TRATAMIENTOS = 'LISTA_CLIENTES_TRATAMIENTOS';
export const LISTA_CLIENTES_TRATAMIENTOS_EXITO = 'LISTA_CLIENTES_TRATAMIENTOS_EXITO';
export const LISTA_CLIENTES_TRATAMIENTOS_ERROR = 'LISTA_CLIENTES_TRATAMIENTOS_ERROR';

export const LISTA_ABONOS = 'LISTA_ABONOS';
export const LISTA_ABONOS_EXITO = 'LISTA_ABONOS_EXITO';
export const LISTA_ABONOS_ERROR = 'LISTA_ABONOS_ERROR';

export const LISTA_ABONOS_TRATAMIENTOS = 'LISTA_ABONOS_TRATAMIENTOS';
export const LISTA_ABONOS_TRATAMIENTOS_EXITO = 'LISTA_ABONOS_TRATAMIENTOS_EXITO';
export const LISTA_ABONOS_TRATAMIENTOS_ERROR = 'LISTA_ABONOS_TRATAMIENTOS_ERROR';

export const AGREGAR_ABONO = 'AGREGAR_ABONO';
export const AGREGAR_ABONO_EXITO = 'AGREGAR_ABONO_EXITO';
export const AGREGAR_ABONO_ERROR = 'AGREGAR_ABONO_ERROR';

export const ELIMINAR_ABONO_EXITO = 'ELIMINAR_ABONO_EXITO';
export const ELIMINAR_ABONO_ERROR = 'ELIMINAR_ABONO_ERROR';

export const MODAL_ABONOS = 'MODAL_ABONOS';
export const MODAL_AGREGAR_ABONO = 'MODAL_AGREGAR_ABONO';

export const BUSQUEDA_FILTRO = 'BUSQUEDA_FILTRO';
export const BUSQUEDA_FILTRO_EXITO = 'BUSQUEDA_FILTRO_EXITO';
export const BUSQUEDA_FILTRO_ERROR = 'BUSQUEDA_FILTRO_ERROR';
export const ELIMINAR_REGISTRO_FILTRO = 'ELIMINAR_REGISTRO_FILTRO';
export const BUSQUEDA_FILTRO_ACTUALIZACION = 'BUSQUEDA_FILTRO_ACTUALIZACION';
export const ACTUALIZAR_ROW_FILTRO = 'ACTUALIZAR_ROW_FILTRO';
export const ELIMINAR_ROW_FILTRO = 'ELIMINAR_ROW_FILTRO';

export const LIMPIAR_FILTRO_BUSQUEDA = 'LIMPIAR_FILTRO_BUSQUEDA';

export const SET_CRITERIA_FILTRO = 'SET_CRITERIA_FILTRO';
export const SET_REQUEST_FILTRO = 'SET_REQUEST_FILTRO';

export const CARGANDO_ESTADISTICAS = 'CARGANDO_ESTADISTICAS';
export const CARGANDO_ESTADISTICAS_EXITO = 'CARGANDO_ESTADISTICAS_EXITO';
export const CARGANDO_ESTADISTICAS_ERROR = 'CARGANDO_ESTADISTICAS_ERROR';

export const LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS = 'LISTA_TRATAMIENTOS_PRECIOS_PRODUCTOS';
export const LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO = 'LISTA_TRATAMIENTOS_PRECIOS_PRODUCTOS_EXITO';
export const LISTA_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR = 'LISTA_TRATAMIENTOS_PRECIOS_PRODUCTOS_ERROR';

export const AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS = 'AGREGAR_TRATAMIENTOS_PRECIOS_PRODUCTOS';
export const AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO = 'AGREGAR_TRATAMIENTOS_PRECIOS_PRODUCTOS_EXITO';
export const AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR = 'AGREGAR_TRATAMIENTOS_PRECIOS_PRODUCTOS_ERROR';

export const ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS = 'ACTUALIZAR_TRATAMIENTOS_PRECIOS_PRODUCTOS';
export const ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO = 'ACTUALIZAR_TRATAMIENTOS_PRECIOS_PRODUCTOS_EXITO';
export const ACTUALIZAR_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR = 'ACTUALIZAR_TRATAMIENTOS_PRECIOS_PRODUCTOS_ERROR';

export const ELIMINAR_TRATAMIENTO_PRECIOS_PRODUCTOS = 'ELIMINAR_TRATAMIENTOS_PRECIOS_PRODUCTOS';
export const ELIMINAR_TRATAMIENTO_PRECIOS_PRODUCTOS_EXITO = 'ELIMINAR_TRATAMIENTOS_PRECIOS_PRODUCTOS_EXITO';
export const ELIMINAR_TRATAMIENTO_PRECIOS_PRODUCTOS_ERROR = 'ELIMINAR_TRATAMIENTOS_PRECIOS_PRODUCTOS_ERROR';

export const MODAL_TRATAMIENTOS_PRECIOS_PRODUCTOS = 'MODAL_TRATAMIENTOS_PRECIOS_PRODUCTOS';
export const MODAL_AGREGAR_TRATAMIENTO_PRECIOS_PRODUCTOS = 'MODAL_AGREGAR_TRATAMIENTOS_PRECIOS_PRODUCTOS';

export const CARGANDO_CONFIGURACION = 'CARGANDO_CONFIGURACION';
export const CARGANDO_CONFIGURACION_EXITO = 'CARGANDO_CONFIGURACION_EXITO';
export const CARGANDO_CONFIGURACION_ERROR = 'CARGANDO_CONFIGURACION_ERROR';

export const ACTUALIZAR_CONFIGURACION = 'ACTUALIZAR_CONFIGURACION';
export const ACTUALIZAR_CONFIGURACION_EXITO = 'ACTUALIZAR_CONFIGURACION_EXITO';
export const ACTUALIZAR_CONFIGURACION_ERROR = 'ACTUALIZAR_CONFIGURACION_ERROR';

export const REQUEST_AXIOS = 'REQUEST_AXIOS';

export const LISTA_NOTIFICACIONES = 'LISTA_NOTIFICACIONES';
export const LISTA_NOTIFICACIONES_EXITO = 'LISTA_NOTIFICACIONES_EXITO';
export const LISTA_NOTIFICACIONES_ERROR = 'LISTA_NOTIFICACIONES_ERROR';

export const MARCAR_LEIDO_NOTIFICACIONES = 'MARCAR_LEIDO_NOTIFICACIONES';
export const MARCAR_LEIDO_NOTIFICACIONES_EXITO = 'MARCAR_LEIDO_NOTIFICACIONES_EXITO';
export const MARCAR_LEIDO_NOTIFICACIONES_ERROR = 'MARCAR_LEIDO_NOTIFICACIONES_ERROR';
export const AGREGAR_NOTIFICACION = 'AGREGAR_NOTIFICACION';

export const LISTA_KARDEX_DETALLES = 'LISTA_KARDEX_DETALLES';
export const LISTA_KARDEX_DETALLES_EXITO = 'LISTA_KARDEX_DETALLES_EXITO';
export const LISTA_KARDEX_DETALLES_ERROR = 'LISTA_KARDEX_DETALLES_ERROR';

export const LISTA_IMPUESTOS = 'LISTA_IMPUESTOS';
export const LISTA_IMPUESTOS_EXITO = 'LISTA_IMPUESTOS_EXITO';
export const LISTA_IMPUESTOS_ERROR = 'LISTA_IMPUESTOS_ERROR';

export const AGREGAR_IMPUESTO = 'AGREGAR_IMPUESTO';
export const AGREGAR_IMPUESTO_EXITO = 'AGREGAR_IMPUESTO_EXITO';
export const AGREGAR_IMPUESTO_ERROR = 'AGREGAR_IMPUESTO_ERROR';

export const ACTUALIZAR_IMPUESTO = 'ACTUALIZAR_IMPUESTO';
export const ACTUALIZAR_IMPUESTO_EXITO = 'ACTUALIZAR_IMPUESTO_EXITO';
export const ACTUALIZAR_IMPUESTO_ERROR = 'ACTUALIZAR_IMPUESTO_ERROR';

export const ELIMINAR_IMPUESTO_EXITO = 'ELIMINAR_IMPUESTO_EXITO';
export const ELIMINAR_IMPUESTO_ERROR = 'ELIMINAR_IMPUESTO_ERROR';

export const ACTUALIZAR_INPUT_IMPUESTO = 'ACTUALIZAR_INPUT_IMPUESTO';

export const MODAL_LECTURA_MEMBRESIA = 'MODAL_LECTURA_MEMBRESIA';
export const SET_NUMERO_MEMBRESIA = 'SET_NUMERO_MEMBRESIA';

export const LISTA_DATOS_ADICIONALES = 'LISTA_DATOS_ADICIONALES';
export const LISTA_DATOS_ADICIONALES_EXITO = 'LISTA_DATOS_ADICIONALES_EXITO';
export const LISTA_DATOS_ADICIONALES_ERROR = 'LISTA_DATOS_ADICIONALES_ERROR';
export const MODAL_DATOS_ADICIONALES = 'MODAL_DATOS_ADICIONALES';

export const AGREGAR_DATOS_ADICIONALES = 'AGREGAR_DATOS_ADICIONALES';
export const AGREGAR_DATOS_ADICIONALES_EXITO = 'AGREGAR_DATOS_ADICIONALES_EXITO';
export const AGREGAR_DATOS_ADICIONALES_ERROR = 'AGREGAR_DATOS_ADICIONALES_ERROR';
export const MODAL_AGREGAR_DATOS_ADICIONALES = 'MODAL_AGREGAR_DATOS_ADICIONALES';

export const COMENZAR_ACTUALIZAR_DATOS_ADICIONALES = 'COMENZAR_ACTUALIZAR_DATOS_ADICIONALES';
export const ACTUALIZAR_DATOS_ADICIONALES = 'ACTUALIZAR_DATOS_ADICIONALES';
export const ACTUALIZAR_DATOS_ADICIONALES_EXITO = 'ACTUALIZAR_DATOS_ADICIONALES_EXITO';
export const ACTUALIZAR_DATOS_ADICIONALES_ERROR = 'ACTUALIZAR_DATOS_ADICIONALES_ERROR';
export const MODAL_ACTUALIZAR_DATOS_ADICIONALES = 'MODAL_ACTUALIZAR_DATOS_ADICIONALES';

export const ELIMINAR_DATOS_ADICIONALES_EXITO = 'ELIMINAR_DATOS_ADICIONALES_EXITO';
export const ELIMINAR_DATOS_ADICIONALES_ERROR = 'ELIMINAR_DATOS_ADICIONALES_ERROR';

export const LISTA_DATOS_ADICIONALES_DETALLES = 'LISTA_DATOS_ADICIONALES_DETALLES';
export const LISTA_DATOS_ADICIONALES_DETALLES_EXITO = 'LISTA_DATOS_ADICIONALES_DETALLES_EXITO';
export const LISTA_DATOS_ADICIONALES_DETALLES_ERROR = 'LISTA_DATOS_ADICIONALES_DETALLES_ERROR';

export const LISTA_DATOS_ADICIONALES_PERMISO = 'LISTA_DATOS_ADICIONALES_PERMISO';
export const LISTA_DATOS_ADICIONALES_PERMISO_EXITO = 'LISTA_DATOS_ADICIONALES_PERMISO_EXITO';
export const LISTA_DATOS_ADICIONALES_PERMISO_ERROR = 'LISTA_DATOS_ADICIONALES_PERMISO_ERROR';

export const AGREGAR_DATOS_ADICIONALES_DETALLE = 'AGREGAR_DATOS_ADICIONALES_DETALLE';
export const ACTUALIZAR_DATOS_ADICIONALES_DETALLE = 'ACTUALIZAR_DATOS_ADICIONALES_DETALLE';
export const LIMPIAR_DATOS_ADICIONALES_DETALLE = 'LIMPIAR_DATOS_ADICIONALES_DETALLE';

export const AGREGAR_DATOS_ADICIONALES_PERMISO = 'AGREGAR_DATOS_ADICIONALES_PERMISO';
export const AGREGAR_DATOS_ADICIONALES_PERMISO_EXITO = 'AGREGAR_DATOS_ADICIONALES_PERMISO_EXITO';
export const AGREGAR_DATOS_ADICIONALES_PERMISO_ERROR = 'AGREGAR_DATOS_ADICIONALES_PERMISO_ERROR';
export const MODAL_AGREGAR_DATOS_ADICIONALES_PERMISO = 'MODAL_AGREGAR_DATOS_ADICIONALES_PERMISO';

export const ACTUALIZAR_DATOS_ADICIONALES_PERMISO = 'ACTUALIZAR_DATOS_ADICIONALES_PERMISO';
export const ACTUALIZAR_DATOS_ADICIONALES_PERMISO_EXITO = 'ACTUALIZAR_DATOS_ADICIONALES_PERMISO_EXITO';
export const ACTUALIZAR_DATOS_ADICIONALES_PERMISO_ERROR = 'ACTUALIZAR_DATOS_ADICIONALES_PERMISO_ERROR';
export const MODAL_ACTUALIZAR_DATOS_ADICIONALES_PERMISO = 'MODAL_ACTUALIZAR_DATOS_ADICIONALES_PERMISO';

export const LISTA_TABS_DATOS_ADICIONALES = 'LISTA_TABS_DATOS_ADICIONALES';
export const LISTA_TABS_DATOS_ADICIONALES_EXITO = 'LISTA_TABS_DATOS_ADICIONALES_EXITO';
export const LISTA_TABS_DATOS_ADICIONALES_ERROR = 'LISTA_TABS_DATOS_ADICIONALES_ERROR';

export const LISTA_ACCIONES = 'LISTA_ACCIONES';
export const LISTA_ACCIONES_EXITO = 'LISTA_ACCIONES_EXITO';
export const LISTA_ACCIONES_ERROR = 'LISTA_ACCIONES_ERROR';

export const LISTA_ACCIONES_ROLES = 'LISTA_ACCIONES_ROLES';
export const LISTA_ACCIONES_ROLES_EXITO = 'LISTA_ACCIONES_ROLES_EXITO';
export const LISTA_ACCIONES_ROLES_ERROR = 'LISTA_ACCIONES_ROLES_ERROR';
export const SET_SELECTED_ACCION_ROLES = 'SET_SELECTED_ACCION_ROLES';
export const ACTUALIZAR_ACCIONES_ROLES_EXITO = 'ACTUALIZAR_ACCIONES_ROLES_EXITO';
export const ACTUALIZAR_ACCIONES_ROLES_ERROR = 'ACTUALIZAR_ACCIONES_ROLES_ERROR';
