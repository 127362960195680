import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Modal, Button } from "@themesberg/react-bootstrap";
import ReactDatatable from '@mkikets/react-datatable';

export default (props) => {
  const [showModal, setShowModal] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setShowModal(props.showModal);

    let columnsDefault = [
      {
        text: "Usuario responsable",
        sortable: true,
        cell: (record) => {
          return record.audit_user_name;
        },
      },
      {
        text: "Tipo referencia",
        sortable: true,
        cell: (record) => {
          return record.referencia;
        },
      },
      {
        text: "ID referencia",
        sortable: true,
        cell: (record) => {
          return record.id_referencia;
        },
      },
    ];

    if (props.detalles.length > 0) {
      const columnasNoPermitidas = [
        "id",
        "created_at",
        "updated_at",
        "deleted_at",
        "creado_por",
        "modificado_por",
        "fecha",
      ];

      let values = Object.entries(props.detalles[0].values);
      values = values.filter(
        (value) => !columnasNoPermitidas.includes(value[0])
      );


      var extraColumns = values.map(
        (detalle, index) => {
          return {
            text: detalle[0],
            align: "left",
            sortable: true,
            cell: (record) => {
              if (detalle[0] == 'id_sucursal') {
                return props.sucursales[record.values[detalle[0]]]?.nombre || "";
              }
              
              return record.values[detalle[0]] ? record.values[detalle[0]] : "";
            },
          };
        }
      );

      setColumns([...columnsDefault, ...extraColumns]);
    }
  }, [props]);

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="xl"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Detalle notificación</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="table-wrapper table-responsive shadow-sm">
          <ReactDatatable hover records={props.detalles} columns={columns} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            className="text-gray ms-auto"
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
