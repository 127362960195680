import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { 
    agregarTratamientoPrecioAction, 
    cerrarModalAgregarAction 
} from '../../actions/tratamientosPreciosActions';

export default (props) => {
    const dispatch = useDispatch();
    
    const { tratamiento, showModalAgregar } = useSelector(state => state.tratamientosPrecios);

    const [ agregar, guardar] = useState({
        'descripcion': '',
        'precio': 0
    })

    const submitForm = e => {
        e.preventDefault();

        agregar.id_tratamiento = tratamiento.id;

        dispatch( agregarTratamientoPrecioAction(agregar) );
    }

    const handleClose = () => {
        dispatch( cerrarModalAgregarAction() );
    }

    const onChangeForm = (e) => {
        guardar({
            ...agregar,
            [e.target.name] : e.target.value
        })
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModalAgregar} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Nuevo precio tratamiento</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitForm}>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control type="text" name="descripcion" placeholder="Descripción" 
                        value={agregar.descripcion} onChange={onChangeForm} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Precio</Form.Label>
                    <Form.Control type="number" name="precio" placeholder="Precio" 
                        step="any" value={agregar.precio} onChange={onChangeForm} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary">
                    Agregar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}