import * as Types from '../types';

const initialState = {
    kardexDetalles: [],
    loadingKardexDetalles: false
}

export default function(state = initialState, action) {
    switch(action.type) {
        case Types.LISTA_KARDEX_DETALLES:
            return {
                ...state,
                loadingKardexDetalles: true
        }
        case Types.LISTA_KARDEX_DETALLES_EXITO:
            return {
                ...state,
                loadingKardexDetalles: false,
                kardexDetalles: action.payload
        }
        case Types.LISTA_KARDEX_DETALLES_ERROR:
            return {
                ...state,
                loadingKardexDetalles: false,
                kardexDetalles: []
        }
        default:
            return state;
    }
}