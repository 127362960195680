import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Tab,
  Row,
  Nav,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import Swal from "sweetalert2";

export default (props) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const [descuentoForm, guardar] = useState({
    tipo: "MONTO",
    porcentaje_valor: 0,
    monto_valor: 0,
    cupon_valor: "",
    descuento_general: false,
    descuento_por_cupon: false,
    descuento_comision: false,
  });

  useEffect(() => {
    setShowModal(props.showModal);
    guardar(props.stateDescuento);
  }, [props]);

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const submitForm = (e) => {
    e.preventDefault();

    descuentoForm.descuento_general =
      descuentoForm.porcentaje_valor > 0 ||
      descuentoForm.cupon_valor != "" ||
      descuentoForm.monto_valor
        ? true
        : false;

    props.onChange(descuentoForm);
    handleClose();
    console.log(descuentoForm);
  };

  const cambiarMetodo = (tipo) => {
    guardar({
      ...descuentoForm,
      tipo: tipo,
      cupon_valor: "",
      porcentaje_valor: 0,
      monto_valor: 0,
      descuento_por_cupon: tipo === "CUPON",
    });
  };

  const onChange = (e) => {
    guardar({
      ...descuentoForm,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckbox = (e) => {
    guardar({
      ...descuentoForm,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="lg"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Aplicar descuento</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body className="shadow-sm">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Check
                    type="switch"
                    name="descuento_comision"
                    checked={descuentoForm.descuento_comision}
                    onChange={onChangeCheckbox}
                    label="Incluir descuento en comisiones"
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr></hr>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={descuentoForm.tipo}
            >
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="MONTO"
                        onClick={() => cambiarMetodo("MONTO")}
                      >
                        Monto
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="PORCENTAJE"
                        onClick={() => cambiarMetodo("PORCENTAJE")}
                      >
                        Porcentaje
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="CUPON"
                        onClick={() => cambiarMetodo("CUPON")}
                      >
                        Cupón
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="PORCENTAJE">
                      <Row>
                        <Col>
                          <Form.Label htmlFor="porcentaje" visuallyHidden>
                            Porcentaje
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <InputGroup.Text>
                              <i class="fas fa-percentage"></i>
                            </InputGroup.Text>
                            <FormControl
                              type="number"
                              min={0}
                              id="porcentaje"
                              name="porcentaje_valor"
                              onChange={onChange}
                              value={descuentoForm.porcentaje_valor}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="MONTO">
                      <Row>
                        <Col>
                          <Form.Label htmlFor="monto" visuallyHidden>
                            Monto
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <InputGroup.Text>
                              <i class="fas fa-money-bill"></i>
                            </InputGroup.Text>
                            <FormControl
                              type="number"
                              min={0}
                              step="any"
                              id="monto"
                              name="monto_valor"
                              onChange={onChange}
                              value={descuentoForm.monto_valor}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="CUPON">
                      <Row>
                        <Col>
                          {/*<Form.Label htmlFor="porcentaje" visuallyHidden>
                            Cupón
                          </Form.Label>
                          <InputGroup className="mb-2">
                            <InputGroup.Text>
                              <i class="fas fa-tag"></i>
                            </InputGroup.Text>
                            <FormControl
                              id="cupon"
                              name="cupon_valor"
                              onChange={onChange}
                              value={descuentoForm.cupon_valor}
                            />
                          </InputGroup> */}
                          EN DESARROLLO
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              Aplicar descuento
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
