import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import React from "react";
import { HashRouter } from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

import { Provider } from "react-redux";
import store from "./store";

import { echo, SocketContext } from "./context/socket";
import {
  locale,
  addLocale,
  updateLocaleOption,
  updateLocaleOptions,
  localeOption,
  localeOptions,
} from "primereact/api";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css'; 
import { es } from "./data/lang/primereact/es";

addLocale("es", es);
locale("es");

const root = createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <Provider store={store}>
      <SocketContext.Provider value={echo}>
        <ScrollToTop />
        <HomePage />
      </SocketContext.Provider>
    </Provider>
  </HashRouter>
);
