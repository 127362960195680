import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Tab,
  Row,
  Nav,
  Col,
  Form,
  InputGroup,
  FormControl,
} from "@themesberg/react-bootstrap";

export default (props) => {
  const [showModal, setShowModal] = useState(false);

  const [descuentoForm, guardar] = useState({
    tipo: "PORCENTAJE",
    porcentaje_valor: 0,
    monto_valor: 0,
    descuento_general: false,
    descuento_comision: false,
  });

  useEffect(() => {
    setShowModal(props.showModal);
    guardar({
      ...descuentoForm,
      porcentaje_valor: props.producto
        ? props.producto.descuento_porcentaje
        : 0,
      monto_valor: props.producto ? props.producto.descuento_monto : 0,
    });
  }, [props]);

  const handleClose = () => {
    setShowModal(false);
    props.onHide();
  };

  const submitForm = (e) => {
    e.preventDefault();

    props.producto.descuento_general = false;
    props.producto.descuento_por_cupon = false;
    props.producto.descuento_tipo = descuentoForm.tipo;
    props.producto.descuento_porcentaje = descuentoForm.porcentaje_valor;
    props.producto.descuento_monto = descuentoForm.monto_valor;
    props.producto.descuento_comision = descuentoForm.descuento_comision;
    props.onChange(props.producto);
    handleClose();
  };

  const onChange = (e) => {
    guardar({
      ...descuentoForm,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeCheckbox = (e) => {
    guardar({
      ...descuentoForm,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <Modal
        as={Modal.Dialog}
        size="sm"
        centered
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Aplicar descuento</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body className="shadow-sm">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Check
                    type="switch"
                    name="descuento_comision"
                    checked={descuentoForm.descuento_comision}
                    onChange={onChangeCheckbox}
                    label="Incluir descuento en comisiones"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col sm={12}>
              <Row>
                <Form.Label htmlFor="porcentaje" visuallyHidden>
                  Porcentaje
                </Form.Label>
                <InputGroup className="mb-2">
                  <InputGroup.Text>
                    <i class="fas fa-percentage"></i>
                  </InputGroup.Text>
                  <FormControl
                    type="number"
                    min={0}
                    id="porcentaje"
                    name="porcentaje_valor"
                    onChange={onChange}
                    value={descuentoForm.porcentaje_valor}
                  />
                </InputGroup>
              </Row>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              Aplicar descuento
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
