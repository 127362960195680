import React, { useState, useEffect } from "react";
import { Card, Modal, Form, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { agregarAbonoAction, showModalAbonarAction } from "../../actions/abonosActions";

export default (props) => {
    const dispatch = useDispatch();
    
    const { cliente_tratamiento_abono, showModalAbonar } = useSelector(state => state.abonos);

    const [ agregar, guardar] = useState({
        'monto': 0,
        'id_metodo_pago': 1,
        'metodo_pago': 'EFECTIVO',
        'paga_con': 0,
        'cambio': 0,
    })

    const submitForm = e => {
        e.preventDefault();
    
        agregar.id_cliente = cliente_tratamiento_abono.id_cliente;
        agregar.tabla = props.tabla;
        agregar.id_tabla = cliente_tratamiento_abono.id;

        if(
            agregar.metodo_pago == 'TARJETA_CREDITO_DEBITO' ||
            agregar.metodo_pago == 'TRANSFERENCIA'
        ) {
            agregar.paga_con = agregar.monto;
            agregar.cambio = 0;
        }

        dispatch( agregarAbonoAction(agregar) );
    }

    const handleClose = () => {
        dispatch( showModalAbonarAction(false) );
    }

    const onChangeMetodoPagoForm = (e) => {
        guardar({
            ...agregar,
            [e.target.name] : e.target.value,
            'metodo_pago': e.target.value
        })
    }

    const onChangeMontosForm = (e) => {
        const cambio = calcularCambio(e.target.name, e.target.value);
        guardar({
            ...agregar,
            [e.target.name] : e.target.value,
            'cambio': cambio
        })
    }
    
    const calcularCambio = (inputName, valor) => {
        valor = parseFloat(valor);
        const monto = inputName === 'monto' ? valor : parseFloat(agregar.monto);
        let pagaCon = inputName === 'paga_con' ? valor : (agregar.id_metodo_pago == 1 ? parseFloat(agregar.paga_con) : monto);
        pagaCon = pagaCon == 0 ? monto : pagaCon;
        pagaCon = pagaCon < monto ? monto : pagaCon;
        
        return isNaN(pagaCon - monto) ? 0 : (pagaCon - monto);
    }

    return (
        <Modal as={Modal.Dialog} centered show={showModalAbonar} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="h6">Agregar abono</Modal.Title>
                <Button variant="close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Form onSubmit={submitForm}>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Método de pago</Form.Label>
                    <Form.Select name="id_metodo_pago" value={agregar.id_metodo_pago} onChange={onChangeMetodoPagoForm}>
                        <option value="EFECTIVO">EFECTIVO</option>
                        <option value="TARJETA_CREDITO_DEBITO">TARJETA DEBITO/CRÉDITO</option>
                        <option value="TRANSFERENCIA">TRANSFERENCIA</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Monto a abonar</Form.Label>
                    <Form.Control type="number" name="monto" placeholder="Monto" min="1"
                        value={agregar.monto} onChange={onChangeMontosForm} />
                </Form.Group>
                { agregar.id_metodo_pago == '1' &&
                    <Form.Group className="mb-3">
                        <Form.Label>Paga con:</Form.Label>
                        <Form.Control type="number" name="paga_con" min="1"
                            value={agregar.paga_con} onChange={onChangeMontosForm} />
                    </Form.Group>
                }
                <Form.Group className="mb-3">
                    <Form.Label>Su cambio</Form.Label>
                    <Form.Control type="number" name="cambio" readOnly
                        value={agregar.cambio} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button type="submit" variant="secondary">
                    Agregar
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    );
}