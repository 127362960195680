import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Breadcrumb,
  Col,
  Form,
  Row,
  Modal,
  Button,
} from "@themesberg/react-bootstrap";
import { faHome, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clienteAxios from "../../config/axios";
import "webdatarocks/webdatarocks.css";
import * as WebDataRocksReact from "react-webdatarocks";
import FilterModal from "./FilterModal";
import { etiquetas } from "../../data/lang/webdatarocks/es.js";

export default (props) => {
  const [nombreReporte, setNombreReporte] = useState(null);
  const [reporte, setReporte] = useState(null);
  const [modelo, setModelo] = useState(null);
  const [onlyQuery, setOnlyQuery] = useState(false);
  const [state, setState] = useState({
    showModalFilter: false,
    reporteCargado: false,
  });
  const [extraFilters, setExtraFilters] = useState([]);

  useEffect(() => {
    getReporte(props.match.params.nombre);
    if (state.reporteCargado) {
      webdatarocks.current.webdatarocks.updateData({
        data: [],
      });
    }
  }, [props]);

  useEffect(() => {
    setNombreReporte(props.match.params.nombre);
  }, [props]);

  const webdatarocks = useRef();

  const getReporte = (nombre) => {
    setExtraFilters([]);
    setState({
      ...state,
      reporteCargado: false,
    });
    clienteAxios.get("reportes", { params: { nombre } }).then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        const reporte = respuesta.data.data;
        setReporte(reporte);
        setModelo(reporte.modelo);
        setOnlyQuery(reporte.only_query);
        if (reporte.parametros && reporte.parametros.length > 0) {
          setExtraFilters(
            reporte.parametros.map((parametro) => {
              return {
                value: parametro.nombre_parametro,
                label: parametro.label,
                type: parametro.tipo,
                endpoint: parametro.endpoint,
                criteria: parametro.criteria,
              };
            })
          );
        }

        setState({
          ...state,
          reporteCargado: true,
        });
      } else {
        props.history.push("/");
      }
    });
  };

  const onHideModalFilter = () => {
    setState({
      ...state,
      showModalFilter: false,
    });
  };

  const customizeToolbar = (toolbar) => {
    var tabs = toolbar.getTabs(); // get all tabs from the toolbar
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];

      //Boton Exportar
      tabs[3].mobile = true;
      // Boton Formato
      tabs[4].mobile = false;
      // Boton Opciones
      tabs[5].mobile = false;
      // Boton Campos
      tabs[6].mobile = false;
      // Boton Pantalla completa
      tabs[7].mobile = true;

      tabs.unshift({
        id: "wdr-tab-lightblue",
        title: "Filtrar",
        handler: mostrarFiltros,
        icon: this.icons.format,
      });

      return tabs;
    };

    var mostrarFiltros = function () {
      setState({
        ...state,
        showModalFilter: true,
      });
    };
  };

  const onSuccessFilter = (respuesta) => {
    const columnas = Object.entries(respuesta[0]).map((value) => {
      return {
        uniqueName: value[0],
        type: value[1].type,
      };
    });

    webdatarocks.current.webdatarocks.setReport({
      dataSource: {
        data: [respuesta[0], ...respuesta[1]],
      },
      localization: etiquetas,
      options: {
        grid: {
          type: "flat",
          title: `Reporte ${reporte?.descripcion}`,
        },
      },
      slice: {
        columns: columnas,
      },
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              Reporte {reporte?.descripcion}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Reporte {reporte?.descripcion}</h5>
          {state.reporteCargado && (
            <WebDataRocksReact.Pivot
              ref={webdatarocks}
              toolbar={true}
              beforetoolbarcreated={customizeToolbar}
              width="100%"
              report={{
                dataSource: {
                  data: [],
                },
                localization: etiquetas,
                options: {
                  grid: {
                    type: "flat",
                    title: `Reporte ${reporte?.descripcion}`,
                  },
                },
              }}
            />
          )}
        </Card.Body>
      </Card>

      <FilterModal
        show={state.showModalFilter}
        onHide={onHideModalFilter}
        onSuccess={onSuccessFilter}
        extraFilters={extraFilters}
        modelo={modelo}
        nombreReporte={nombreReporte}
        onlyQuery={onlyQuery}
      />
    </>
  );
};
