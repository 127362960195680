import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Tab,
  Row,
  Nav,
  Col,
  Form,
  InputGroup,
  FormControl,
  ListGroup,
} from "@themesberg/react-bootstrap";
import ReactDatatable from "@mkikets/react-datatable";
import {
  showModalMetodoPagoAction,
  metodoPagoAction,
} from "../../actions/ventaActions";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";
import { Toast } from "primereact/toast";

export default (props) => {
  const dispatch = useDispatch();
  const toast = useRef(null);

  const { showModalMetodoPago, totales, metodosPago } = useSelector(
    (state) => state.venta
  );

  const [metodosPagoEdicion, guardar] = useState([
    {
      metodo_pago: null,
      id_metodo_pago: null,
      monto: 0,
      referencia: null,
    },
  ]);
  const [catalogoMetodosPago, setCatalogoMetodosPago] = useState([]);
  const [datos, setDatos] = useState({
    cambio: 0,
    total_metodos: 0,
  });

  useEffect(() => {
    getMetodosPago();
  }, []);

  useEffect(() => {
    if (showModalMetodoPago === true) {
      guardar([
        {
          metodo_pago: null,
          id_metodo_pago: null,
          monto: 0,
          referencia: null,
        },
      ]);
      setDatos({
        total_metodos: 0,
        cambio: 0,
      });
    }
  }, [showModalMetodoPago]);

  const getMetodosPago = () => {
    clienteAxios.get("metodo-pago").then((respuesta) => {
      if (respuesta && respuesta.data.data && respuesta.data.success === true) {
        setCatalogoMetodosPago(
          respuesta.data.data.filter((metodo) => {
            return metodo.descripcion != null && metodo.descripcion !== "";
          })
        );
      }
    });
  };

  const getTotalMetodosPago = (incluirEfectivo = true) => {
    return metodosPagoEdicion.reduce((acc, item) => {
      return (
        acc +
        ((incluirEfectivo === false && item.metodo_pago === "EFECTIVO") ||
        item.metodo_pago === "FINANCIADO"
          ? 0
          : parseFloat(item.monto) || 0)
      );
    }, 0);
  };

  const handleClose = () => {
    dispatch(showModalMetodoPagoAction(false));
  };

  const checarSiExisteMetodoFinanciado = () => {
    return (
      metodosPagoEdicion.filter((metodo) => metodo.metodo_pago == "FINANCIADO")
        .length > 0
    );
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const totalMetodos = getTotalMetodosPago();

    if (
      checarSiExisteMetodoFinanciado() === false &&
      totalMetodos < parseFloat(totales.total)
    ) {
      Swal.fire(
        "¡UPS!",
        "El monto a recibir es menor al total de la venta",
        "error"
      );
    }

    dispatch(metodoPagoAction(metodosPagoEdicion));

    props.onSubmitForm(metodosPagoEdicion);
  };

  const cambio = () => {
    const totalMetodoEfectivo = getTotalEfectivo();
    const totalMetodosSinEfectivo = getTotalMetodosPago(false);

    const residual = totalMetodosSinEfectivo - totalMetodoEfectivo;
    if (residual >= 0) {
      return 0;
    }

    const total = parseFloat(totales.total);

    const cambio = (
      totalMetodoEfectivo -
      (total - totalMetodosSinEfectivo)
    ).toFixed(2);

    return isNaN(cambio) || cambio < 0 ? 0 : cambio;
  };

  const getTotalEfectivo = () => {
    return metodosPagoEdicion.reduce((acc, item) => {
      return (
        acc +
        (item.metodo_pago === "EFECTIVO" ? parseFloat(item.monto) || 0 : 0)
      );
    }, 0);
  };

  const onChangeForm = (e, index) => {
    console.log(e.target.value);
    if (e.target.name === "id_metodo_pago") {
      metodosPagoEdicion[index]["metodo_pago"] = catalogoMetodosPago.find(
        (metodo) => metodo.id == e.target.value
      ).metodo;

      metodosPagoEdicion[index]["monto"] =
        metodosPagoEdicion[index]["metodo_pago"] == "FINANCIADO"
          ? totales.total
          : 0;
    }

    metodosPagoEdicion[index][e.target.name] = e.target.value;
    guardar(metodosPagoEdicion);
    recalcularDatos();
  };

  const recalcularDatos = () => {
    const totalMetodos = getTotalMetodosPago();

    setDatos({
      ...datos,
      cambio: cambio(),
      total_metodos: totalMetodos,
    });
  };

  const agregarMetodo = () => {
    if (metodosPagoEdicion.length == 2) {
      toast.current.show({
        severity: "error",
        summary: "No se puede agregar más de dos formas de pago",
        life: 2500,
      });
      return;
    }

    guardar([
      ...metodosPagoEdicion,
      { id_metodo_pago: null, monto: 0, referencia: null, metodo_pago: null },
    ]);
  };

  return (
    <>
      <Toast ref={toast} />
      <Modal
        as={Modal.Dialog}
        size="lg"
        centered
        show={showModalMetodoPago}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Métodos de pago</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Form onSubmit={submitForm}>
          <Modal.Body className="shadow-sm">
            <Row className="text-right mb-2">
              <Col>
                <Button
                  variant="success"
                  type="button"
                  size="sm"
                  onClick={agregarMetodo}
                  disabled={checarSiExisteMetodoFinanciado()}
                >
                  Agregar método de pago
                </Button>
              </Col>
            </Row>
            {metodosPagoEdicion.map((value, index) => {
              return (
                <>
                  <Row key={index}>
                    <Col md={3}>
                      <Form.Group className="mb-1">
                        <Form.Label>Forma de Pago*</Form.Label>
                        <Form.Select
                          name="id_metodo_pago"
                          onChange={(e) => onChangeForm(e, index)}
                          className="mb-2"
                          required
                        >
                          <option value="0">
                            Selecciona una forma de pago
                          </option>
                          {catalogoMetodosPago.map((metodo) => {
                            return (
                              <option value={metodo.id}>
                                {metodo.descripcion}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-1">
                        <Form.Label>Monto*</Form.Label>
                        <Form.Control
                          type="number"
                          name="monto"
                          required
                          onChange={(e) => onChangeForm(e, index)}
                          min={0}
                          disabled={
                            metodosPagoEdicion[index]["metodo_pago"] ==
                            "FINANCIADO"
                          }
                          value={
                            metodosPagoEdicion[index]["metodo_pago"] ==
                            "FINANCIADO"
                              ? totales.total
                              : metodosPagoEdicion[index]["monto"]
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>Referencia</Form.Label>
                        <Form.Control
                          type="text"
                          name="referencia"
                          onChange={(e) => onChangeForm(e, index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                      <Form.Label>Acción</Form.Label>
                      <br />
                      <Button
                        type="button"
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          metodosPagoEdicion.splice(index, 1);
                          recalcularDatos();
                        }}
                        className="mt-1"
                        disabled={index === 0}
                      >
                        <i class="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </>
              );
            })}
            <hr />
            <Row>
              <Col className="d-flex">
                <ListGroup className="my-2" style={{ width: "100%" }}>
                  <Row>
                    <Col sm={6}>
                      <ListGroup.Item
                        className="rounded-0"
                        style={{
                          backgroundColor: "rgb(16 141 175)",
                          color: "#fff",
                        }}
                      >
                        Total a pagar ($)
                      </ListGroup.Item>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="rounded-0"
                          disabled
                          value={totales.total}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <ListGroup.Item
                        className="rounded-0"
                        style={{ backgroundColor: "#05A677", color: "#fff" }}
                      >
                        Monto pago ($)
                      </ListGroup.Item>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="rounded-0"
                          disabled
                          value={datos.total_metodos}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <ListGroup.Item
                        className="rounded-0"
                        style={{
                          backgroundColor: "rgb(38, 43, 64)",
                          color: "#fff",
                        }}
                      >
                        Cambio ($)
                      </ListGroup.Item>
                    </Col>
                    <Col sm={6}>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="rounded-0"
                          disabled
                          value={datos.cambio}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </ListGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              className="text-gray ms-auto"
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <Button type="submit" variant="secondary">
              Confirmar Pago
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
